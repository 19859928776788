<template>
  <v-sheet
    class="h-100 d-flex flex-column align-center justify-center text-center"
    color="transparent"
  >
    <v-container style="max-width: 50rem">
      <img
        v-test-id="'home-logo'"
        :src="require('@/assets/images/logo-short.svg')"
        alt="Logo"
        style="height: 6rem"
      >

      <p class="grey--text font-italic lighten-5 text-h5 my-12" v-test-id="'home-quote'">
        &ldquo;{{ quote }}&rdquo;
      </p>

      <v-progress-linear v-model="progress" />
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import Identity from '@/modules/sdk/core/identity';

const delay = 7000;
let originalTs = 0;
let nextTs = 0;

@Component({})
export default class HomeView extends Vue {
  quote = ''
  progress = 0
  quoteList = [
    'Advances in medicine and agriculture have saved vastly more lives than have been lost in all the wars in history.',
    'America\'s doctors, nurses, and medical researchers are the best in the world, but our health care system is broken.',
    'Prior to penicillin and medical research, death was an everyday occurrence. It was intimate.',
    'Stem cell research can revolutionize medicine, more than anything since antibiotics.',
    'Medical research in the twentieth century mostly takes place in the lab; in the Renaissance, though, researchers went first and foremost to the library to see what the ancients had said.',
    'It is certainly important to be looking for cures to medical disorders, but it is equally important to conduct research on human health and well-being.',
    'A wise physician skilled our wounds to heal, is more than armies to the public weal.',
    'It is false to suggest that medical breakthroughs come only through government research.',
    'The realities are that it\'s difficult to find funding for research for a medical cure. I believe in developing technology as opposed to medical research.',
    'A doctor is a man who writes prescriptions till the patient either dies or is cured by nature.',
    'A fool will not only pay for a \'cure\' that does him no good but will write a testimonial to the effect that he was cured.',
    'I decided to take two years between finishing undergraduate and beginning medical school to devote fully to medical research. I knew that I wanted to go to medical school during undergraduate, but I was also eager to get a significant amount of research experience.',
    'The greatest wealth is health.',
    'An apple a day keeps the doctor away.',
    'Laughter is the best medicine.',
    'Health is a state of complete harmony of the body, mind, and spirit.',
    'Medicine is a science of uncertainty and an art of probability.',
    'The art of medicine consists of amusing the patient while nature cures the disease.',
    'In nothing do men more nearly approach the gods than in giving health to men.',
    'The doctor of the future will give no medicine but will interest his patients in the care of the human frame, in diet, and in the cause and prevention of disease.',
    'Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy.',
    'The good physician treats the disease; the great physician treats the patient who has the disease.',
    'Time and health are two precious assets that we don\'t recognize and appreciate until they have been depleted.',
    'To keep the body in good health is a duty, otherwise, we shall not be able to keep the mind strong and clear.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The best doctor gives the least medicines.',
    'Take care of your body. It\'s the only place you have to live.',
    'The greatest medicine of all is to teach people how not to need it.',
    'Prevention is better than cure.',
    'The doctor\'s bags contain nothing but drugs, whereas the patient\'s bag contains nothing but food.',
    'A healthy outside starts from the inside.',
    'A good laugh and a long sleep are the best cures in the doctor\'s book.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'The best and most efficient pharmacy is within your own system.',
    'The doctor of the future will be oneself.',
    'Health is not just the absence of disease; it\'s an inner joyfulness that should be ours all the time.',
    'The doctor is the servant and not the master of nature.',
    'Let food be thy medicine and medicine be thy food.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine is to teach people how not to need it.',
    'A healthy attitude is contagious but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money, we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued till sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope; and he who has hope has everything.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'The power of love to change bodies is legendary, built into folklore, common sense, and everyday experience. Love moves the flesh, it pushes matter around.',
    'Health is the greatest possession. Contentment is the greatest treasure. Confidence is the greatest friend.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine of all is to teach people how not to need it.',
    'A healthy attitude is contagious but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money, we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued till sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope; and he who has hope has everything.',
    'A healthy outside starts from the inside.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'In nothing do men more nearly approach the gods than in giving health to men.',
    'Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy.',
    'The good physician treats the disease; the great physician treats the patient who has the disease.',
    'Time and health are two precious assets that we don\'t recognize and appreciate until they have been depleted.',
    'To keep the body in good health is a duty, otherwise, we shall not be able to keep the mind strong and clear.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The best doctor gives the least medicines.',
    'Take care of your body. It\'s the only place you have to live.',
    'The greatest medicine of all is to teach people how not to need it.',
    'Prevention is better than cure.',
    'The doctor\'s bags contain nothing but drugs, whereas the patient\'s bag contains nothing but food.',
    'A healthy outside starts from the inside.',
    'A good laugh and a long sleep are the best cures in the doctor\'s book.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'The best and most efficient pharmacy is within your own system.',
    'The doctor of the future will be oneself.',
    'Health is not just the absence of disease; it\'s an inner joyfulness that should be ours all the time.',
    'The doctor is the servant and not the master of nature.',
    'Let food be thy medicine and medicine be thy food.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine is to teach people how not to need it.',
    'A healthy attitude is contagious but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money, we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued till sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope; and he who has hope has everything.',
    'A healthy outside starts from the inside.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'The power of love to change bodies is legendary, built into folklore, common sense, and everyday experience. Love moves the flesh, it pushes matter around.',
    'Health is the greatest possession. Contentment is the greatest treasure. Confidence is the greatest friend.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine of all is to teach people how not to need it.',
    'A healthy attitude is contagious, but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money; we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued until sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope, and he who has hope has everything.',
    'A healthy outside starts from the inside.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'The power of love to change bodies is legendary, built into folklore, common sense, and everyday experience.',
    'The greatest wealth is health.',
    'An apple a day keeps the doctor away.',
    'Laughter is the best medicine.',
    'Health is a state of complete harmony of the body, mind, and spirit.',
    'Medicine is a science of uncertainty and an art of probability.',
    'The art of medicine consists of amusing the patient while nature cures the disease.',
    'In nothing do men more nearly approach the gods than in giving health to men.',
    'Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy.',
    'The good physician treats the disease; the great physician treats the patient who has the disease.',
    'Time and health are two precious assets that we don\'t recognize and appreciate until they have been depleted.',
    'To keep the body in good health is a duty, otherwise, we shall not be able to keep the mind strong and clear.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The best doctor gives the least medicines.',
    'Take care of your body. It\'s the only place you have to live.',
    'The greatest medicine of all is to teach people how not to need it.',
    'Prevention is better than cure.',
    'The doctor\'s bags contain nothing but drugs, whereas the patient\'s bag contains nothing but food.',
    'A healthy outside starts from the inside.',
    'A good laugh and a long sleep are the best cures in the doctor\'s book.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'The best and most efficient pharmacy is within your own system.',
    'The doctor of the future will be oneself.',
    'Health is not just the absence of disease; it\'s an inner joyfulness that should be ours all the time.',
    'The doctor is the servant and not the master of nature.',
    'Let food be thy medicine and medicine be thy food.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine is to teach people how not to need it.',
    'A healthy attitude is contagious but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money, we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued till sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope; and he who has hope has everything.',
    'A healthy outside starts from the inside.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'The power of love to change bodies is legendary, built into folklore, common sense, and everyday experience.',
    'The greatest wealth is health.',
    'An apple a day keeps the doctor away.',
    'Laughter is the best medicine.',
    'Health is a state of complete harmony of the body, mind, and spirit.',
    'Medicine is a science of uncertainty and an art of probability.',
    'The art of medicine consists of amusing the patient while nature cures the disease.',
    'In nothing do men more nearly approach the gods than in giving health to men.',
    'Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy.',
    'Time and health are two precious assets that we don\'t recognize and appreciate until they have been depleted.',
    'To keep the body in good health is a duty, otherwise, we shall not be able to keep the mind strong and clear.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The best doctor gives the least medicines.',
    'Take care of your body. It\'s the only place you have to live.',
    'The greatest medicine of all is to teach people how not to need it.',
    'Prevention is better than cure.',
    'The doctor\'s bags contain nothing but drugs, whereas the patient\'s bag contains nothing but food.',
    'A healthy outside starts from the inside.',
    'A good laugh and a long sleep are the best cures in the doctor\'s book.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'The best and most efficient pharmacy is within your own system.',
    'The doctor of the future will be oneself.',
    'Health is not just the absence of disease; it\'s an inner joyfulness that should be ours all the time.',
    'The doctor is the servant and not the master of nature.',
    'Let food be thy medicine and medicine be thy food.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'The greatest medicine is to teach people how not to need it.',
    'A healthy attitude is contagious but don\'t wait to catch it from others. Be a carrier.',
    'The body is your temple. Keep it pure and clean for the soul to reside in.',
    'To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.',
    'Healthy citizens are the greatest asset any country can have.',
    'Health is like money, we never have a true idea of its value until we lose it.',
    'Happiness is the highest form of health.',
    'Health is not valued till sickness comes.',
    'A healthy lifestyle not only changes your body, it changes your mind, your attitude, and your mood.',
    'In the healthy body, resides a healthy mind.',
    'Good health is not something we can buy. However, it can be an extremely valuable savings account.',
    'He who has health has hope; and he who has hope has everything.',
    'A healthy outside starts from the inside.',
    'The best way to predict the future is to create it.',
    'A strong positive mental attitude will create more miracles than any wonder drug.',
    'The road to health is paved with good intestines!',
    'Medicine is a science of uncertainty and an art of probability.',
    'Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it.',
    'The best doctor gives the least medicines.',
    'An ounce of prevention is worth a pound of cure.',
    'Health is not simply the absence of sickness.',
    'Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. Enjoy the journey as you strive for wellness.',
    'The doctor of the future will no longer treat the human frame with drugs, but rather will cure and prevent disease with nutrition.',
    'The doctor is often more to be feared than the disease.',
    'If you don\'t take care of your body, where are you going to live?',
    'A healthy outside starts from the inside.',
    'The power of love to change bodies is legendary, built into folklore, common sense, and everyday experience.',
    'The greatest wealth is health.',
    'An apple a day keeps the doctor away.',
  ]

  newQuote() {
    let quote = ''
    do {
      quote = this.quoteList[Math.floor(Math.random() * this.quoteList.length)]
    }
    while (this.quote === quote)
    this.quote = quote
  }

  updateProgressFrame() {
    requestAnimationFrame(() => {
      const currentTs = new Date().getTime();
      const totalDiffTs = -(originalTs - nextTs);
      const currentDiffTs = nextTs - currentTs;
      this.progress = 100 - (currentDiffTs * 100 / totalDiffTs);
      if (this.progress >= 100) {
        this.resetTime();
        this.newQuote();
      }
      this.updateProgressFrame();
    })
  }

  resetTime() {
    originalTs = new Date().getTime();
    nextTs = new Date().getTime() + delay;
    this.progress = 0;
  }

  mounted() {
    this.newQuote()
  }

  created() {
    this.resetTime();
    this.updateProgressFrame();
  }
}
</script>

<style lang="scss" scoped>
.v-progress-linear,
.v-progress-liner > * {
  transition: none !important;
}
</style>

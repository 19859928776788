import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';

export default class ProjectStatusReasonModel extends Model {

  default() {
    return {
      stage: 'indepth',
      status: 'halt'
    };
  }

  relatedMap() {
    return {
      projectentity: ProjectModel,
    }
  }

}

<template>
  <v-form
    ref="form"
    v-test-id="'tutorial-modal-form'"
    v-model="formIsValid"
    :disabled="!canEdit"
    lazy-validation
    @submit="onSubmit"
  >
    <v-expand-transition>
      <v-alert
        v-if="!duringLogout && sent"
        type="success"
        prominent
        class="mb-0"
      >
        Thank you for providing your feedback.
        We value your input and will use it to improve our services.
        Your response has been successfully submitted.
      </v-alert>
    </v-expand-transition>

    <template v-if="duringLogout || !sent">
      <h3>Please rate your overall experience with our platform.</h3>

      <v-rating
        v-model="model.data.rating"
        :rules="[rules.required]"
        :error-messages="errors.rating"
        hover
        length="5"
        color="warning"
        background-color="warning"
        clearable
        class="mb-4"
      ></v-rating>

      <v-textarea
        v-model="model.data.message"
        :error-messages="errors.message"
        label="Additional Comments (Optional)"
        outlined
        hide-details="auto"
        clearable
      />
    </template>

  </v-form>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import Rules from '@/modules/sdk/core/rules';
import SatisfactionModel from '@/modules/sdk/models/satisfaction.model';
import SatisfactionService from '@/modules/sdk/services/satisfaction.service';

export type VForm = Vue & { validate: () => boolean, reset: () => boolean, resetValidation: () => boolean }

@Component
export default class SatisfactionSurveyForm extends Vue {
  @Ref() readonly form!: VForm;
  @Prop({ type: Boolean, default: false }) duringLogout!: boolean;

  formIsValid = false
  sending = false
  sent = false
  rules = {}
  errors = {}

  model = new SatisfactionModel();

  get canSubmit(): boolean {
    return this.formIsValid && this.model.data.rating > 0 && !this.sending;
  }

  get canEdit(): boolean {
    return !this.sending && !this.sent;
  }

  onSubmit(): Promise<any> {
    return new Promise((resolve) => {
      // @ts-ignore
      if (this.form.validate() && this.canSubmit) {
        this.sending = true;
        SatisfactionService.getInstance().save(this.model)
          .then(response => {
            this.sent = true;
            resolve(response);
          })
          .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
          .finally(() => this.sending = false);
      } else {
        resolve(false);
      }
    })
  }

  reset() {
    this.form.reset();
    this.model = new SatisfactionModel();
    this.sent = false;
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>

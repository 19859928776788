<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'roles'"
      index="role"
      title="Roles"
      :headers="headers"
      :service="service"
      hide-presets
      fill-height
      hide-collapsable
    >
      <!-- USER LIST -->
      <template v-slot:item.userlist="{item}">
        <v-chip
          v-for="user of item.data.userlist"
          :key="user.data.id"
          :to="{name: 'UserForm', params: {id: user.data.id}}"
          class="ma-2"
          label
          small
        >
          {{ user.getFullName() }}
        </v-chip>
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import RoleService from '@/modules/sdk/services/role.service';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class RoleList extends Vue {
  service = RoleService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'RoleForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Index',
      align: 'start',
      sortable: true,
      value: 'index',
      filterable: true
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: true,
    },
    {
      text: 'User',
      align: 'start',
      sortable: true,
      value: 'userlist',
      filterable: {
        field: 'UserNode.userId',
        type: 'distinct',
        service: UserService,
        itemText: (user: UserModel) => user.getFullName()
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

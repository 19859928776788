<template>
  <v-container class="px-0">
    <div
      v-for="(firstItem, firstItemIdx) in first"
      :key="firstItemIdx"
    >
      <v-divider v-if="firstItemIdx > 0" class="my-2" />
      <v-row>
        <v-col cols="12" md="6" class="d-flex align-center">
          <h3 v-text="firstItem"></h3>
        </v-col>
        <v-col cols="12" md="1" class="d-flex align-center justify-center">
          <v-icon>mdi-arrow-left-right</v-icon>
        </v-col>
        <v-col cols="12" md="5" class="d-flex align-center">
          <v-autocomplete
            v-model="model[firstItemIdx]"
            :items="remainingSeconds.concat(model[firstItemIdx])"
            :disabled="disabled"
            placeholder="- Select -"
            hide-details
            outlined
            dense
            clearable
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Prop, Watch} from 'vue-property-decorator';

@Component
export default class ArrayMapper extends Vue {

  @VModel({
    default: () => ([])
  }) model!: Array<string>;

  @Prop() readonly first!: Array<string>;
  @Prop() readonly second!: Array<string>;
  @Prop({ type: Boolean }) readonly startEmpty!: boolean;
  @Prop({ type: Boolean }) readonly preselect!: boolean;
  @Prop({ type: Boolean }) readonly disabled!: boolean;
  @Prop({ type: Boolean }) readonly allowDuplicates!: boolean;

  @Watch('first', {
    deep: true,
    immediate: true,
  })
  @Watch('second', {
    deep: true,
    immediate: true,
  })
  onModelChanged() {
    if (!this.startEmpty) {
      this.model = this.getDefaultSelection();
    }
  }

  get remainingSeconds(): Array<string> {
    return this.second.filter(item => this.allowDuplicates || this.model.indexOf(item) === -1);
  }

  getDefaultSelection(): Array<string> {
    const model: Array<string> = [];
    this.first.forEach((item, itemIdx) => {
      const index = this.second.findIndex(second => second === item);
      if (index !== -1 && this.preselect) {
        model[itemIdx] = this.second[index];

        // Check if previous value already assigned, otherwise fallback to indexed value
        for (let i = itemIdx - 1; i >= 0; i--) {
          if (model[i] === this.second[index]) {
            model[i] = this.second[itemIdx];
            break;
          }
        }
      } else {
        model[itemIdx] = this.second[itemIdx];
      }
    })

    return model
  }
}
</script>

<template>
  <div class="w-100">
    <v-datetime-picker
      v-model="_value"
      v-bind="$attrs"
      :text-field-props="{
        hideDetails: 'auto',
        readonly: true,
        clearable: true,
        prependInnerIcon: 'mdi-calendar-clock',
        outlined: true,
        dense: true,
      }"
      :time-picker-props="{
          ampmInTitle: true,
      }"
      :date-picker-props="datePickerAttrs"
    >
      <template #dateIcon>
        <v-icon>mdi-calendar-month</v-icon>
      </template>
      <template #timeIcon>
        <v-icon>mdi-clock-outline</v-icon>
      </template>
      <template #actions="{ parent }">
        <v-btn
          text
          color="primary"
          @click.native="parent.clearHandler"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
        <v-btn
          color="primary"
          @click="parent.okHandler"
        >
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
      </template>
    </v-datetime-picker>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DialogDateField extends Vue {

  @Prop({
    default: null
  }) value!: Date | string

  @Prop({
    type: Object,
    default: null
  }) datePickerAttrs?: null

  get _value(): Date | string {
    return this.value;
  }

  set _value(value: Date | string) {
    this.$emit('input', value);
  }
}
</script>

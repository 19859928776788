<template>
  <v-container class="pa-4" fluid>
    <h3>user-autocomplete-component</h3>
    <user-autocomplete-component
      v-model="users"
      label="Users"
      solo
      multiple
      clearable
      hide-details
      deletable-chips
      return-object
    />

    <h3 class="mt-6">tag components</h3>
    <TagsComponent
      :record-id="1"
      :project-id="1"
      hide-details="auto"
      clearable
      outlined
      class="mt-3"
      @input="$forceUpdate()"
    />

    <h3 class="mt-6">v-row-resize</h3>
    <v-row v-row-resize="rowResize">
      <v-col cols="6" :md="rowResize[0].md || '6'" :lg="rowResize[0].lg || '6'">
        abc
      </v-col>
      <v-col cols="6" :md="rowResize[1].md || '6'" :lg="rowResize[1].lg || '6'">
        def
      </v-col>
    </v-row>
    {{rowResize}}

    <h3 class="mt-6">upload pdf dialog</h3>
    <upload-pdf-dialog v-model="showUploadPdf" />
    <v-btn @click="showUploadPdf = true">
      Upload PDF
    </v-btn>

    <h3 class="mt-6">dashboard builder</h3>
    <dashboard-builder
      v-model="visualization"
    />

    <h3 class="mt-6">datatable filters</h3>
    <DataTable
      :headers="headers"
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import DashboardBuilder from '@/modules/common/components/DashboardBuilder.vue';
import TagsComponent from '@/views/Admin/Component/Record/TagsComponent.vue';
import UploadPdfDialog from '@/views/Admin/Dialog/UploadPdfDialog.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';
import UserAutocompleteComponent from '@/views/Admin/Component/UserAutocompleteComponent.vue';

@Component({
  components: {
    UserAutocompleteComponent,
    DataTable,
    DashboardBuilder,
    UploadPdfDialog,
    TagsComponent,
  }
})
export default class DevView extends Vue {
  visualization: VisualizationModel = new VisualizationModel()

  users: Array<UserModel> = [];
  userList: Array<UserModel> = [];
  showUploadPdf = false
  rowResize = [
    { md: 6, lg: 6 },
    { md: 6, lg: 6 },
  ]

  headers = [
    {
      text: 'Number',
      value: 'number',
      filterable: {
        type: 'number',
      }
    },
    {
      text: 'Range',
      value: 'range',
      filterable: {
        type: 'range',
      }
    },
    {
      text: 'Date',
      value: 'date',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Distinct',
      value: 'distinct',
      filterable: {
        type: 'distinct'
      },
    },
    {
      text: 'Text',
      value: 'text',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Boolean',
      value: 'boolean',
      filterable: {
        type: 'boolean',
        attrs: {
          trueValue: '1',
          falseValue: '0'
        }
      }
    },
    {
      text: 'Enum',
      value: 'enum',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Synthetic', value: 'synthetic' },
          { text: 'Plastic', value: 'plastic' },
          { text: 'Solid', value: 'solid' },
        ]
      }
    },
  ];

  created() {
    UserService.getInstance().getAll()
      .then(response => {
        this.userList = response.data.view.list;
        this.users = response.data.view.list.filter((user: UserModel) => user.data.deleted === 1);
      });
  }
}
</script>

import Vue, {DirectiveBinding} from 'vue';

const bindTestId = (el: HTMLElement, binding: DirectiveBinding<any>) => {
  // if (process.env.NODE_ENV === 'test') {
    el.setAttribute('data-test-id', binding.value);
  // }
}

Vue.directive('test-id', {
  bind: bindTestId,
});

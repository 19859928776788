<template>
  <ModalDialog
    v-model="syncedVisible"
    v-bind="$attrs"
    :max-width="600"
    icon="mdi-logout-variant"
    title="Logout"
    persistent
  >
    <template #content>
      <v-card-text>
        Are you sure you wish to log out of the system?

        <v-divider class="my-4" />

        <SatisfactionSurveyForm ref="satisfactionSurveyForm" during-logout/>
      </v-card-text>
    </template>
    <template #buttons>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        :disabled="loading"
        :loading="loading"
        large
        color="error"
        @click="onLogoutClick"
      >
        <span>Logout</span>
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        large
        outlined
        @click="() => syncedVisible = false"
      >
        Cancel
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, PropSync, Ref} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import SatisfactionSurveyForm from '@/modules/common/components/SatisfactionSurveyForm.vue';
import AuthService from '@/modules/sdk/services/auth.service';
import Identity from '@/modules/sdk/core/identity';
import Main from '@/main';

@Component({
  components: {
    ModalDialog,
    SatisfactionSurveyForm,
  }
})
export default class LogoutModal extends Vue {
  @Ref() readonly satisfactionSurveyForm!: SatisfactionSurveyForm;
  @PropSync('visible', { type: Boolean, default: false }) syncedVisible!: boolean;

  loading = false

  onLogoutClick(): void {
    this.loading = true
    if (this.satisfactionSurveyForm.canSubmit) {
      this.satisfactionSurveyForm.onSubmit().then(() => {
        this.logout()
      }).catch(() => this.loading = false)
    } else {
      this.logout()
    }
  }

  logout() {
    AuthService.getInstance().logout()
      .then(response => {
        if (!response.data.view.loggedIn) {
          Identity.removeIdentity()
          this.$router.push({ name: 'login' })
          Main.logout()
        }
        else {
          this.$root.$globalSnack.warning({
            message: this.$t('error.logout'),
            icon: 'mdi-emoticon-dead-outline'
          })
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false)
  }
}
</script>

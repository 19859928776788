<template>
  <div>
    <v-progress-linear
      :value="progress"
      height="20"
    >
      <template #default="{ value }">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="white"
          :size="16"
          :width="2"
        ></v-progress-circular>
        <span v-else class="grey--text text--lighten-5 text-capitalize text-body-2">
          {{ stage }}:
          {{ Math.ceil(value * 100) / 100 }}%
        </span>
      </template>
    </v-progress-linear>

    <v-progress-linear
      :indeterminate="loading"
      value="100"
      height="1"
    ></v-progress-linear>

    <v-sheet
      :style="{
        height: '20px',
        position: 'relative',
      }"
      class="text-center backgroundDark lighten-1 text-capitalize text-body-2"
      dark
    >
      <span
        :style="{
          position: 'absolute',
          width: '100%',
          left: '0',
        }">
        <span
          v-for="(progress, progressIdx) in _percentage"
          :key="progressIdx"
        >
          <span v-if="progressIdx > 0">, </span>
          <strong v-text="progress.key"></strong>:
          <span v-text="progress.percentage"></span>%
        </span>
      </span>
      <div
        v-for="(progress, progressIdx) in percentage"
        :key="progressIdx"
        :class="progress.color"
        :style="{
          width: progress.percentage + '%',
          height: '100%',
          float: 'left',
        }"
      ></div>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ProjectService from '@/services/project.service';
import Logger from '@/modules/sdk/core/logger';
import RecordService from '@/services/record.service';
import Identity from '@/modules/sdk/core/identity';

const d = new Logger('views/Admin/Component/ProjectProgressComponent');

@Component
export default class ProjectProgressComponent extends Vue {

  @Prop({type: String, default: null}) stage!: 'screening' | 'indepth' | 'final';
  @Prop({type: String, default: 'filtered'}) type!: 'filtered' | 'project';
  @Prop({type: Number, default: null}) limit!: number;
  @Prop({type: Number, default: null}) projectId!: number;
  @Prop({type: Array, default: () => ([])}) filters!: object[];
  @Prop({type: Object, default: () => ({})}) advanced!: object;
  @Prop({type: Number, default: null}) userId?: number;

  loading = false;
  progress = 0;
  percentage: any = {
    pass: {
      label: 'Pass',
      value: 0,
      percentage: 0,
      color: 'success',
    },
    pending: {
      label: 'Pending',
      value: 0,
      percentage: 0,
      color: 'warning',
    },
    halt: {
      label: 'Halt',
      value: 0,
      percentage: 0,
      color: 'error',
    },
    skip: {
      label: 'Skip',
      value: 0,
      percentage: 0,
      color: 'default',
    },
  };

  get _percentage(): Array<{
    key: string,
    percentage: number
  }> {
    const result: Array<{
      key: string,
      percentage: number
    }> = [];
    Object.keys(this.percentage).forEach(key => {
      let find = result.find(item => item.key === key);
      if (!find) {
        find = {
          key,
          percentage: 0,
        }
        result.push(find);
      }
      find.percentage += this.percentage[key].percentage;
    });
    result.forEach(item => {
      item.percentage = parseFloat(item.percentage.toFixed(1));
    });

    // Previous logic places halt in 2nd position, moving it to 3rd
    const element = result[2];
    result.splice(2, 1);
    result.splice(2, 0, element);

    return result;
  }

  @Watch('stage')
  @Watch('userId')
  @Watch('projectId')
  @Watch('type')
  onChange() {
    this.loadProgress();
  }

  loadProgress(
    stage = this.stage,
    id = this.projectId,
    userId = this.userId,
    filters = this.filters,
    advanced = this.advanced,
    type = this.type,
  ) {

    this.loading = true;

    if (this.type === 'project') {
      return ProjectService.getInstance().getProgress({
        stage,
        id,
        userId,
        type,
        filters,
        advanced,
      })
        .then(response => {
          const counts = response.data.view.recordStatusCount;
          const total = parseInt(response.data.view.recordListTotal);
          this.progress = parseFloat(response.data.view.progress) || 0;

          ['pass', 'pending', 'halt'].forEach(key => {
            this.percentage[key].value = parseInt(counts[key]);
            this.percentage[key].percentage = total? counts[key] * 100 / total : 0;
          })

          return {
            progress: this.progress,
            percentage: this.percentage,
          };
        })
        .finally(() => this.loading = false);
    } else if (this.type === 'filtered') {
      const userId = Identity.getIdentity()?.user.id;

      const filters = this.filters;
      const limit = this.limit;
      const advanced = {...this.advanced, userId};

      return RecordService.getInstance()
        .getMetrics({ filters, limit, advanced })
        .then((response) => {

          const metrics = response.data.view.metrics[this.stage];
          const total = Object.keys(metrics).reduce((total, key) => total + metrics[key], 0);
          this.progress = total === 0 ? 0 : Math.ceil((total - parseInt(metrics.pending)) * 100 / total * 100) / 100;

          ['pass', 'pending', 'halt', 'skip'].forEach(key => {
            this.percentage[key].value = metrics[key];
            this.percentage[key].percentage = total? metrics[key] * 100 / total : 0;
          })

          return {
            progress: this.progress,
            percentage: this.percentage,
          };
        })
        .finally(() => this.loading = false);
    }
  }

  created() {
    this.loadProgress();
  }
}
</script>

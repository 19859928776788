<template>
  <FileModelInput
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onChange"
    @click:clear="onClear"
  >
    <template #prepend-inner>
      <v-responsive :width="100" :height="100" class="mb-3 pa-2">
        <v-img
          v-if="src"
          :src="src"
          :width="84"
          :height="84"
        />
        <v-img
          v-else-if="model.data.id"
          v-secure-url.src="{
            file: model,
            onLoaded: onFileLoaded,
          }"
          :width="84"
          :height="84"
        />
      </v-responsive>
    </template>
    <template v-if="editable" #append>
      <ModalDialog
        v-model="showEditDialog"
        :max-width="1100"
        background-color="background"
        title="Edit image"
        persistent
      >
        <template #content>
          <ImageCropper
            v-if="croppedEnabled"
            v-model="src"
            ref="imageCropper"
            :aspectRatio="aspectRatio"
          />
        </template>
        <template #buttons>
          <v-btn color="primary" @click="onApply">
            <span v-text="$t('btn.apply')"></span>
          </v-btn>
          <v-btn outlined @click="onCancel">
            <span v-text="$t('btn.cancel')"></span>
          </v-btn>
        </template>
      </ModalDialog>

      <v-btn
        outlined
        small
        @click.stop="onEditImage"
      >
        Edit
      </v-btn>
    </template>
  </FileModelInput>
</template>

<script lang="ts">
import {Vue, Component, Ref, VModel, Prop, Watch} from 'vue-property-decorator';
import FileModel from '@/modules/sdk/models/file.model';
import FileModelInput from '@/modules/common/components/FileModelInput.vue';
import ImageCropper from '@/modules/common/components/ImageCropper.vue';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';

@Component({
  components: {
    ModalDialog,
    FileModelInput,
    ImageCropper,
  }
})
export default class ImageFileInput extends Vue {
  @VModel({ type: FileModel, default: () => new FileModel() }) model!: FileModel;
  @Prop({ type: Boolean, default: false, }) editable!: boolean;
  @Prop({ type: Number, default: 16 / 9, }) aspectRatio!: number;

  @Ref() imageCropper!: ImageCropper;

  @Watch('showEditDialog')
  onShowEditDialogChange(value: boolean) {
    if (!value) {
      setTimeout(() => this.croppedEnabled = false, 300);
    }
  }

  showEditDialog = false;
  croppedEnabled = false;
  src: string | null = null;

  onApply() {
    const data = this.imageCropper.getData();
    if (data) {
      this.src = data;

      const [header, base64Data] = data.split(',');
      const mimeString = header.split(':')[1].split(';')[0];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeString });
      const file = new File([blob], this.model.data.name, { type: mimeString });

      this.model.file = file;
      this.model.data.size = file.size;
    }
    this.showEditDialog = false;
  }

  onChange(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  onClear() {
    this.src = null;
    this.model = new FileModel();
  }

  onCancel() {
    this.showEditDialog = false;
  }

  onFileLoaded(url: string | null = null) {
    this.src = url;
  }

  onEditImage() {
    this.showEditDialog = true;
    this.croppedEnabled = true;
  }
}
</script>

<template>
  <div class="list-builder">

    <!-- PREPEND -->
    <v-row v-if="!disabled && (max !== 1 || max !== value.length)" no-gutters>
      <v-col v-if="_sortable" class="shrink" style="min-width: 48px">

      </v-col>
      <v-col class="grow">
        <slot name="prepend"></slot>
      </v-col>
      <v-col v-if="value.length > min && !$scopedSlots.header" class="shrink" style="min-width: 64px">

      </v-col>
    </v-row>

    <!-- SORTABLE LIST OF ITEMS -->
    <draggable
      v-model="filteredValues"
      :sort="sortable"
      :animation="200"
      :group="group"
      :move="onMove"
      handle=".handle"
      @start="onDragStart"
      @end="onDragStop"
    >
      <transition-group
        :name="!drag ? 'flip-list' : null"
        type="transition"
      >
        <v-container
          v-for="(item, itemIdx) in filteredValues"
          :key="isModel ? item.autoIncrementId : itemIdx"
          class="pa-0"
          style="position: relative"
          fluid
        >
          <!-- CONDITION -->
          <v-row
            v-if="conditional && ((futurePositions.length === 0 && itemIdx > 0) || futurePositions[itemIdx] === true)"
            class="my-1 andOr w-100 mb-n5"
          >
            <v-col cols="12" class="py-1">
              <v-divider />
              <v-select
                v-model="item.logic"
                :items="logicList"
                :disabled="disabled"
                background-color="backgroundVeryLight"
                placeholder="Condition"
                hide-details="auto"
                height="40"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="_sortable && !disabled" class="d-flex align-start pt-6 shrink" :style="{
              marginTop: (buttonOffset + 'px')
            }">
              <v-icon class="handle">mdi-drag-vertical</v-icon>
            </v-col>
            <v-col class="grow">
              <component v-bind="getComponentAttrs(itemIdx)">
                <div class="d-flex align-center" style="gap: 1rem">
                  <div style="flex: 1">
                    <slot
                      name="header"
                      :item="item"
                      :index="itemIdx"
                      :parent="parent"
                    ></slot>
                  </div>

                  <slot v-if="$scopedSlots.header && !disabled" name="actions">
                    <v-tooltip v-if="duplicable && value.length > 0" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="disabled || (max && (max <= value.length))"
                          icon
                          fab
                          text
                          small
                          color="primary"
                          @click="onDuplicate(itemIdx)"
                        >
                          <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="$t('btn.duplicate')"></span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-if="!isModel || item.data.deleted !== 1"
                          v-bind="attrs"
                          v-on="on"
                          :loading="isModel ? item.states.deleting : false"
                          :disabled="filteredValues.length - 1 < min || (isModel ? item.states.deleting : false)"
                          color="error"
                          icon
                          fab
                          text
                          small
                          @click="() => onRemove(itemIdx)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          v-bind="attrs"
                          v-on="on"
                          :loading="isModel ? item.states.restoring : false"
                          :disabled="filteredValues.length - 1 < min || (isModel ? item.states.restoring : false)"
                          color="success"
                          icon
                          fab
                          text
                          small
                          @click="() => onRestore(itemIdx)"
                        >
                          <v-icon>mdi-delete-restore</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        <span v-if="!isModel || item.data.deleted !== 1" v-text="_labels.remove"></span>
                        <span v-else v-text="_labels.restore"></span>
                      </span>
                    </v-tooltip>
                    <v-tooltip v-if="collapsable" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          fab
                          text
                          small
                          @click.stop.prevent="onCollapseClick(itemIdx)"
                        >
                          <v-icon v-if="innerCollapsed[itemIdx]">mdi-chevron-down</v-icon>
                          <v-icon v-else>mdi-chevron-up</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        <span v-if="!innerCollapsed[itemIdx]" v-text="$t('btn.collapse')"></span>
                        <span v-else v-text="$t('btn.expand')"></span>
                      </span>
                    </v-tooltip>
                  </slot>
                </div>

                <v-expand-transition>
                  <div v-if="!innerCollapsed[itemIdx]">
                    <v-alert
                      v-if="getGroup(item).length > 0"
                      class="mb-0 w-100 sheet"
                      :style="{
                        marginRight: depth > 0 ? '-17px' : null,
                      }"
                      border="left"
                      outlined
                    >
                      <ListBuilder
                        v-model="item.group"
                        :group="group"
                        :default-item="defaultItem"
                        :depth="depth + 1"
                        :disabled="disabled"
                        :parent-item-index="itemIdx"
                        :parent-list="filteredValues"
                        :nested="nested"
                        :conditional="conditional"
                        :sortable="sortable"
                        @ungroup="onUngroup"
                      >
                        <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                          <slot :name="key" v-bind="props"></slot>
                        </template>
                      </ListBuilder>
                    </v-alert>
                    <slot
                      v-else
                      name="item"
                      :item="item"
                      :index="itemIdx"
                      :parent="parent"
                    >
                      <v-row>
                        <v-col cols="12" md="4" lg="4">
                          <v-text-field
                            v-if="isModel"
                            v-model="item.data.label"
                            clearable
                            outlined
                            dense
                            hide-details
                          />
                          <v-text-field
                            v-else
                            v-model="item.label"
                            clearable
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </slot>
                  </div>
                </v-expand-transition>
              </component>
            </v-col>

            <!-- ACTIONS -->
            <v-col v-if="showActions && !$scopedSlots.header && !disabled" class="d-flex align-start flex-nowrap pt-4 shrink" :style="{
              marginTop: (buttonOffset + 'px')
            }">
              <slot name="actions">
                <div class="d-flex flex-column">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-if="!isModel || item.data.deleted !== 1"
                        v-bind="attrs"
                        v-on="on"
                        :loading="isModel ? item.states.deleting : false"
                        :disabled="filteredValues.length - 1 < min || (isModel ? item.states.deleting : false)"
                        color="error"
                        icon
                        fab
                        text
                        small
                        @click="() => onRemove(itemIdx)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        v-bind="attrs"
                        v-on="on"
                        :loading="isModel ? item.states.restoring : false"
                        :disabled="filteredValues.length - 1 < min || (isModel ? item.states.restoring : false)"
                        color="success"
                        icon
                        fab
                        text
                        small
                        @click="() => onRestore(itemIdx)"
                      >
                        <v-icon>mdi-delete-restore</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      <span v-if="!isModel || item.data.deleted !== 1" v-text="_labels.remove"></span>
                      <span v-else v-text="_labels.restore"></span>
                    </span>
                  </v-tooltip>
                </div>

                <v-tooltip v-if="depth > 0 && itemIdx === 0" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      fab
                      text
                      small
                      @click="onUngroupClick(itemIdx, parentItemIndex)"
                    >
                      <v-icon dark>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('btn.ungroup')"></span>
                </v-tooltip>
                <v-tooltip v-else-if="nested && filteredValues.length > 1" bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      fab
                      text
                      small
                      :disabled="itemIdx < min"
                      :style="{
                        visibility: itemIdx === 0 ? 'hidden' : 'visible',
                      }"
                      @click="() => onGroupClick(itemIdx)"
                    >
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="_labels.group"></span>
                </v-tooltip>
              </slot>
            </v-col>
          </v-row>
        </v-container>
      </transition-group>
    </draggable>

    <!-- APPEND -->
    <v-row v-if="!disabled && (max !== 1 || max !== value.length)" no-gutters>
      <v-col v-if="_sortable" class="shrink" style="min-width: 48px">

      </v-col>
      <v-col class="grow">
        <slot name="append"></slot>
      </v-col>
      <v-col v-if="value.length > min && !$scopedSlots.header" class="shrink" style="min-width: 64px">

      </v-col>
    </v-row>

    <!-- NO ITEM -->
    <v-alert
      v-if="value.length === 0"
      text
      dense
      type="info"
    >
      <span v-text="_labels.empty"></span>
    </v-alert>

    <!-- ADD -->
    <v-row v-if="canAdd && !disabled && (max !== 1 || max !== value.length)">
      <v-col v-if="_sortable" class="shrink" style="min-width: 48px">

      </v-col>
      <v-col class="grow">
        <v-btn v-bind="btnAttrs" :disabled="disabled || (max && (max <= value.length))" @click="onAdd">
          <v-icon left>mdi-plus</v-icon>
          <span v-text="_labels.add"></span>
        </v-btn>
      </v-col>
      <v-col v-if="showActions && value.length > min && !$scopedSlots.header" class="shrink" style="min-width: 64px">

      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import draggable from 'vuedraggable';
import Model from '@/modules/sdk/core/model';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { logicList } from '@/enums/global';
import Service from '@/modules/sdk/core/service';

@Component({
  components: {
    draggable
  }
})
export default class ListBuilder extends Vue {

  @Prop({ default: () => [] }) private value!: Array<any>
  @Prop({ default: () => new Model({}, false) }) private parent?: any
  @Prop() group?: null
  @Prop({ default: null }) defaultModel?: any
  @Prop({ default: null }) defaultItem?: any
  @Prop({ default: null }) defaultData?: any
  @Prop({ type: Object, default: () => ({ color: 'primary', outlined: true, block: true }) }) btnAttrs?: any
  @Prop({ type: Object, default: () => ({}) }) labels?: any
  @Prop() orderBy?: string
  @Prop({ default: 0 }) parentItemIndex!: number;
  @Prop({ default: 0 }) min!: number | string
  @Prop({ default: null }) max!: number | string | null
  @Prop({ default: 0 }) depth!: number;
  @Prop({ default: 0 }) buttonOffset!: number;
  @Prop({ type: Boolean, default: false }) sortable?: boolean
  @Prop({ type: Boolean, default: false }) nested?: boolean
  @Prop({ type: Boolean, default: false }) conditional?: boolean
  @Prop({ type: Boolean, default: false }) duplicable!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) collapsable!: boolean
  @Prop({ type: Boolean, default: false }) collapsed!: boolean
  @Prop({ type: Boolean, default: false }) restorable!: boolean
  @Prop({ type: Boolean, default: true }) canAdd!: boolean
  @Prop({ type: Boolean, default: false }) showRemoved!: boolean
  @Prop({ type: Boolean, default: true }) showActions!: boolean
  @Prop({ type: Service, default: null }) service!: Service
  @Prop({ type: [Boolean, String], default: false }) wrapped!: boolean | string

  drag = false
  innerCollapsed: Array<boolean> = []
  logicList = logicList
  futurePositions: Array<boolean> = []

  @Watch('value', {
    immediate: true,
    deep: true,
  })
  onValueChanged(value: Array<any>) {
    this.updatePositions(value);
    this.prepareCollapseArray(this.collapsed);
  }

  get filteredValues(): Array<any> {
    return this._value.filter(item => this.showRemoved || !this.isModel || item.data.deleted !== 1);
  }

  set filteredValues(values: Array<any>) {
    let results = values;
    if (!this.showRemoved) {
      const removedValues = this._value.filter(item => this.isModel && item.data.deleted === 1);
      results = results.concat(removedValues);
    }
    this._value = results;
  }

  get _value(): Array<any> {
    const orderBy = this.orderBy;
    let values = this.value;
    if (orderBy) {
      values = this.value.sort((a, b) => a.data[orderBy] > b.data[orderBy] ? 1 : -1);
    }

    return values;
  }

  set _value(values: Array<any>) {
    this.updatePositions(values);
    this.$emit('input', values);
  }

  updatePositions(values: Array<any>) {
    values.forEach((item: any, itemIdx) => {
      if (typeof item === 'object') {
        item.position = itemIdx;
        if (item.data) {
          item.data.position = itemIdx;
        }
      }
    })
  }

  getComponentAttrs(index: number): Array<any> {
    const attrs: any = {
      is: 'div',
    };
    if (this.wrapped) {
      Object.assign(attrs, {
        is: 'v-alert',
        class: 'mb-0 w-100',
        style: {
          marginRight: this.depth > 0 ? '-17px' : null,
        },
        color: typeof this.wrapped === 'string' ? this.filteredValues[index][this.wrapped] : 'wrapped',
        outlined: true,
        border: 'left',
      })
    }
    return attrs;
  }

  get _sortable(): boolean {
    return !!(this.sortable && this.value.length > 1);
  }

  get _labels(): any {
    return Object.assign({}, {
      add: this.$t('btn.add'),
      duplicate: this.$t('btn.duplicate'),
      remove: this.$t('btn.remove'),
      restore: this.$t('btn.restore'),
      empty: this.$t('state.empty'),
      group: this.$t('btn.group'),
      ungroup: this.$t('btn.ungroup'),
    }, this.labels);
  }

  get isModel(): boolean {
    return !!(this.defaultModel);
  }

  getGroup(item: any): Array<any> {
    const group = item && ((item.group) || (item.data && item.data.group));
    return Array.isArray(group) ? group : [];
  }

  onCollapseClick(index: number): void {
    this.innerCollapsed[index] = !this.innerCollapsed[index];
    this.$forceUpdate();
  }

  onAdd(): void {
    const newValue = this.isModel
      ? new this.defaultModel(this.defaultData instanceof Function ? this.defaultData() : this.defaultData, false)
      : structuredClone(this.defaultItem);
    if (this.isModel && this.orderBy) {
      newValue.data[this.orderBy] = this.value.length;
    }
    this._value.push(newValue);
    this.$forceUpdate();
  }

  onDuplicate(index: number): void {
    const clone: any = structuredClone(this._value[index]);
    this.innerCollapsed.splice(index, 0, this.innerCollapsed[index]);
    this._value.splice(index, 0, clone);
  }

  onGroupClick(index: number): void {
    const clone: any = structuredClone(this._value[index]);
    clone.group = [];
    this._value[index].group.push(clone);
  }

  onUngroupClick(
    index: number,
    parentIndex = this.parentItemIndex,
  ) {
    this.$emit('ungroup', {
      index: parentIndex,
      item: this._value[index]
    });
  }

  onUngroup(props: {
    index: number,
    item: any,
  }) {
    const children = this._value[props.index].group;
    this._value.splice(props.index, 1);
    children.forEach((child: any, childIdx: number) => {
      this._value.splice(props.index + childIdx, 0, child);
    })
    this.$forceUpdate();
  }

  onRemove(index: number): void {
    const callback = () => {
      this._value.splice(index, 1);
      if (this.filteredValues.length < this.min && this.depth === 0) {
        this.onAdd();
      }
    }
    const model = this.filteredValues[index]
    if (this.isModel && this.service && model?.data.id) {
      model.states.deleting = true;
      this.service.delete({ id: model?.data.id })
        .then(response => {
          model.assign(response.data.view.single);
          this.$root.$globalSnack.success({
            message: this.$i18n.t('dataForm.snack.itemDeleted')
          });
          return model;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => model.states.deleting = false);
    } else if (this.restorable && this.isModel && model?.data.id) {
      model.data.deleted = 1;
    } else {
      callback();
    }
  }

  onRestore(index: number): void {
    const model = this.filteredValues[index]
    if (this.isModel && this.service && model?.data.id) {
      model.states.restoring = true;
      this.service.restore({ id: model?.data.id })
        .then(response => {
          model.assign(response.data.view.single);
          this.$root.$globalSnack.success({
            message: this.$i18n.t('dataForm.snack.itemRestore')
          });
          return model;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => model.states.restoring = false);
    } else if (this.restorable && this.isModel) {
      model.data.deleted = 0;
    }
  }

  onDragStart(): void {
    this.drag = true;
  }

  onDragStop(): void {
    this.drag = false;
    this.futurePositions = [];
  }

  onMove(context: any): void {
    // this.filteredValues.forEach((val, index) => {
    //   this.futurePositions[index] = true;
    // })
    // this.futurePositions[0] = false;
    //
    // if (context.draggedContext.futureIndex === 0) {
    //   this.futurePositions[context.draggedContext.index] = false;
    // } else if (context.draggedContext.index === 0 && context.draggedContext.futureIndex !== 0) {
    //   for (let i = context.draggedContext.futureIndex; i < this.filteredValues.length; i++) {
    //     this.futurePositions[i] = false;
    //   }
    //
    //   this.futurePositions[context.draggedContext.index] = true;
    // }
    // this.$forceUpdate();
  }

  prepareCollapseArray(collapsed = false): void {
    for (let i = 0; i < this.filteredValues.length; i++) {
      if (this.innerCollapsed[i] === undefined) {
        this.innerCollapsed[i] = collapsed;
      }
    }
  }
}

Vue.component('ListBuilder', ListBuilder);
</script>

<style lang="scss" scoped>
.handle {
  cursor: move;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.andOr {
  padding-left: 3rem;
  position: relative;

  .v-select {
    max-width: 9rem;
  }

  hr {
    position: absolute;
    width: calc(100% - 3rem);
    top: 1.5rem;
  }
}
</style>

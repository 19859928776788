import Model from '@/modules/sdk/core/model';
import RecordModel from '@/models/record.model';
import AiRequestModel from '@/models/ai-request.model';
import AiResponseModel from '@/models/ai-response.model';

export default class RecordAiStatusModel extends Model {

  relatedMap () {
    return {
      recordentity: RecordModel,
      airequestentity: AiRequestModel,
      airesponseentity: AiResponseModel,
    }
  }

}

<template>
  <v-container fluid>

    <AskQuestionToAISurveyModal
      v-model="questionToAIModal.visible"
      :question.sync="questionToAIModal.question"
      :selection="questionToAIModal.selection"
      @answer="onAIAnswer"
      max-width="1200"
      scrollable
    />

    <ManageSurveyAnswersModal
      v-if="manageAnswers"
      v-model="viewAnswersModal.visible"
      :question="viewAnswersModal.question"
      :answers="viewAnswersModal.answers"
      max-width="600"
      scrollable
      @apply="onApplyAnswer"
    />

    <v-alert
      v-if="survey.data.surveygrouplist.length === 0"
      type="warning"
      outlined
      border="left"
      class="mb-0"
    >
      Nothing to preview yet.
    </v-alert>

    <v-card v-else color="transparent" flat>
      <v-alert
        v-for="(group, groupIdx) in survey.data.surveygrouplist"
        :key="group.autoIncrementId"
        :color="group.data.color"
        :class="{
          'mb-0 white elevation-2': true,
          'mt-3': groupIdx > 0,
        }"
        style="border-radius: 4px;"
        outlined
        border="left"
      >

        <!-- GROUP -->
        <v-sheet :class="{
        'd-flex align-center justify-space-between transparent': true,
        'mb-6': showGroup[group.data.id]
      }">
          <h3 v-text="group.data.label"></h3>

          <v-btn icon @click="() => onToggleShowGroupClick(group)">
            <v-icon v-if="showGroup[group.data.id]">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </v-sheet>

        <v-alert
          v-if="group.data.surveyquestionlist.length === 0"
          type="info"
          outlined
          border="left"
          class="mb-0"
        >
          No question yet.
        </v-alert>

        <template v-if="showGroup[group.data.id] !== false">
          <div
            v-for="(question, questionIdx) in group.data.surveyquestionlist"
            :key="questionIdx"
            :class="{
            'mb-0': true,
            'mt-6': questionIdx > 0,
          }"
          >

            <!-- QUESTION -->
            <div class="mb-3">
              <h4 class="text--text">
                <span v-if="question.data.required" class="error--text">* </span>
                <span v-text="question.data.label"></span>
              </h4>
              <p
                v-if="question.data.description"
                v-text="question.data.description"
                class="caption text--secondary mb-0"
              ></p>
            </div>

            <!-- SELECT -->
            <template v-if="question.data.type === 'select'">
              <template v-if="question.data.surveychoicelist.length > 0">
                <v-select
                  v-model="question.answer"
                  :items="question.data.surveychoicelist"
                  :rules="getRules(question)"
                  :placeholder="$t('surveyAnswer.selectOption')"
                  :multiple="question.data.multiple"
                  :readonly="readonly"
                  :item-value="preview ? 'autoIncrementId' : 'data.id'"
                  :style="maxWidthStyle"
                  hide-details="auto"
                  item-text="data.content"
                  clearable
                  outlined
                  dense
                  @input="onInput"
                />
              </template>
              <v-alert
                v-else
                text
                type="info"
                class="mb-0"
              >
                <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
              </v-alert>
            </template>

            <!-- INTEGER -->
            <v-text-field
              v-else-if="question.data.type === 'integer'"
              v-model.number="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              :style="maxWidthStyle"
              hide-details="auto"
              type="number"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- DECIMAL -->
            <v-text-field
              v-else-if="question.data.type === 'decimal'"
              v-model.number="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              :style="maxWidthStyle"
              hide-details="auto"
              type="number"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- NUMBER RANGE -->
            <div
              v-else-if="question.data.type === 'number_range'"
              class="d-flex align-center"
              style="gap: 1rem"
            >
              <v-text-field
                v-model.number="question.answer[0]"
                :rules="getRules(question)"
                :placeholder="$t('placeholder.from')"
                :readonly="readonly"
                :style="maxWidthStyle"
                hide-details="auto"
                type="number"
                clearable
                outlined
                dense
                @input="onInput"
              />
              <v-text-field
                v-model.number="question.answer[1]"
                :rules="getRules(question)"
                :placeholder="$t('placeholder.to')"
                :readonly="readonly"
                :style="maxWidthStyle"
                hide-details="auto"
                type="number"
                clearable
                outlined
                dense
                @input="onInput"
              />
            </div>

            <!-- COUNTRIES -->
            <v-autocomplete
              v-if="question.data.type === 'country'"
              v-model="question.answer"
              :items="countries && countries.data.view.list || []"
              :rules="getRules(question)"
              :placeholder="$tc('surveyAnswer.selectCountry', question.data.multiple ? 9 : 1)"
              :multiple="question.data.multiple"
              :item-value="preview ? 'autoIncrementId' : 'data.id'"
              :readonly="readonly"
              :style="maxWidthStyle"
              :loading="loadingCountries"
              :disabled="loadingCountries"
              hide-details="auto"
              prepend-inner-icon="mdi-earth"
              item-value="data.code"
              item-text="data.label"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- RATING -->
            <v-rating
              v-else-if="question.data.type === 'rating'"
              v-model="question.answer"
              :rules="getRules(question)"
              :readonly="readonly"
              :length="question.data.rating_count || 5"
              hide-details="auto"
              background-color="warning"
              color="warning"
              @input="onInput"
            />

            <!-- AUTOCOMPLETE -->
            <!-- BUG IN VUETIFY: RETURN-OBJECT IS TRUE BY DEFAULT -->
            <v-combobox
              v-if="question.data.type === 'autocomplete' && autocomplete[question.data.id]"
              v-model="question.answer"
              :items="getAutocompleteItems(question)"
              :rules="getRules(question)"
              :placeholder="$tc('surveyAnswer.selectQuestion', question.data.multiple ? 9 : 1)"
              :multiple="question.data.multiple"
              :readonly="readonly"
              :item-value="preview ? 'autoIncrementId' : 'data.id'"
              :style="maxWidthStyle"
              item-text="data.content"
              prepend-inner-icon="mdi-comment-question-outline"
              hide-details="auto"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- INPUT -->
            <v-text-field
              v-else-if="question.data.type === 'input'"
              v-model="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              hide-details="auto"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- RADIO -->
            <template v-if="question.data.type === 'radio'">
              <template v-if="question.data.surveychoicelist.length > 0">
                <v-radio-group
                  v-model="question.answer"
                  :rules="getRules(question)"
                  hide-details="auto"
                >
                  <v-radio
                    v-for="option in question.data.surveychoicelist"
                    :value="preview ? option.autoIncrementId : option.data.id"
                    :key="option.autoIncrementId"
                    :label="option.data.content"
                    :readonly="readonly"
                    @input="onInput"
                  />
                </v-radio-group>
              </template>
              <v-alert
                v-else
                text
                type="info"
                class="mb-0"
              >
                <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
              </v-alert>
            </template>

            <!-- CHECKBOX -->
            <template v-if="question.data.type === 'checkbox'">
              <template v-if="question.data.surveychoicelist.length > 0">
                <v-checkbox
                  v-model="question.answer"
                  v-for="option in question.data.surveychoicelist"
                  :rules="getRules(question)"
                  :key="option.autoIncrementId"
                  :readonly="readonly"
                  :label="option.data.content"
                  :value="option.data.id"
                  multiple
                  hide-details="auto"
                  @input="onInput"
                />
              </template>
              <v-alert
                v-else
                text
                type="info"
                class="mb-0"
              >
                <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
              </v-alert>
            </template>

            <!-- TEXTAREA -->
            <v-textarea
              v-else-if="question.data.type === 'textarea'"
              v-model="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              hide-details="auto"
              clearable
              outlined
              @input="onInput"
            />

            <!-- PERCENTAGE -->
            <v-text-field
              v-if="question.data.type === 'percentage'"
              v-model.number="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('placeholder.from')"
              :readonly="readonly"
              :style="maxWidthStyle"
              type="number"
              hide-details="auto"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- NUMBER -->
            <v-text-field
              v-else-if="question.data.type === 'number'"
              v-model="question.answer"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              :style="maxWidthStyle"
              type="number"
              hide-details="auto"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- YEAR -->
            <v-autocomplete
              v-else-if="question.data.type === 'year'"
              v-model="question.answer"
              :items="getYears(question)"
              :rules="getRules(question)"
              :placeholder="$t('surveyAnswer.selectOption')"
              :multiple="question.data.multiple"
              :readonly="readonly"
              :item-value="preview ? 'autoIncrementId' : 'data.id'"
              :style="maxWidthStyle"
              item-text="data.content"
              item-value="data.content"
              hide-details="auto"
              clearable
              outlined
              dense
              @input="onInput"
            />

            <!-- DATE -->
            <div
              v-if="question.data.type === 'date'"
              class="d-flex align-center"
              style="gap: 1rem"
            >
              <DialogDateField
                v-model="question.answer"
                :key="question.data.type + '_' + question.autoIncrementId"
                :input-attrs="{
                hideDetails: 'auto',
                rules: getRules(question),
                label: $t('placeholder.chooseDate'),
              }"
                @input="onInput"
              />
            </div>

            <!-- DATE RANGE -->
            <div
              v-if="question.data.type === 'date_range'"
              class="d-flex align-center"
              style="gap: 1rem"
            >
              <DialogDateField
                v-model="question.answer[range]"
                v-for="range in [0, 1]"
                :key="question.data.type + '_' + question.autoIncrementId + '_' + range"
                :input-attrs="{
                  hideDetails: 'auto',
                  rules: getRules(question),
                  label: range === 0 ? $t('placeholder.fromDate') : $t('placeholder.toDate'),
                }"
                :date-picker-attrs="
                range === 0
                  ? { max: question.answer[1] }
                  : { min: question.answer[0] }
                "
                @input="() => $forceUpdate()"
              />
            </div>

            <!-- DATETIME -->
            <div v-if="question.data.type === 'datetime'">
              <div class="d-flex align-center w-100" style="gap: 1rem">
                <DialogDateTimeField
                  v-model="question.answer"
                  :key="question.data.type + '_' + question.autoIncrementId"
                  :label="$t('placeholder.chooseDateTime')"
                  @input="() => $forceUpdate()"
                />
              </div>
            </div>

            <!-- DATETIME RANGE -->
            <div v-if="question.data.type === 'datetime_range'">
              <div class="d-flex align-center w-100" style="gap: 1rem">
                <DialogDateTimeField
                  v-for="range in [0, 1]"
                  v-model="question.answer[range]"
                  :key="question.data.type + '_' + question.autoIncrementId + '_' + range"
                  :label="range === 0 ? $t('placeholder.fromDateTime') : $t('placeholder.toDateTime')"
                  :date-picker-attrs="
                  range === 0
                    ? { max: question.answer[1] && question.answer[1].toISOString() }
                    : { min: question.answer[0] && question.answer[0].toISOString() }
                  "
                  @input="() => $forceUpdate()"
                />
              </div>
            </div>

            <!-- TIME -->
            <div
              v-if="question.data.type === 'time'"
              class="d-flex align-center"
              style="gap: 1rem"
            >
              <DialogTimeField
                v-model="question.answer"
                :key="question.data.type + '_' + question.autoIncrementId"
                :input-attrs="{
                hideDetails: 'auto',
                rules: getRules(question),
                label: $t('label.pickTime'),
                outlined: true,
                dense: true,
              }"
                @input="onInput"
              />
            </div>

            <!-- TIME RANGE -->
            <div
              v-if="question.data.type === 'time_range'"
              class="d-flex align-center"
              style="gap: 1rem"
            >
              <DialogTimeField
                v-for="range in [0, 1]"
                v-model="question.answer[range]"
                :key="question.data.type + '_' + question.autoIncrementId + '_' + range"
                :input-attrs="{
                  hideDetails: 'auto',
                  rules: getRules(question),
                  label: range === 0 ? $t('placeholder.fromTime') : $t('placeholder.toTime'),
                  outlined: true,
                  dense: true,
                }"
                :date-picker-attrs="
                range === 0
                  ? { max: question.answer[1] }
                  : { min: question.answer[0] }
              "
                @input="onInput"
              />
            </div>

            <!-- QUESTION FOR AI -->
            <div class="mt-1">
              <v-btn
                text
                x-small
                @mousedown.prevent.stop="onAskQuestionAi(question)"
              >
                <v-icon x-small left>mdi-robot</v-icon>
                <span v-text="$t('surveyAnswer.askQuestionAi')"></span>
              </v-btn>
            </div>

            <!-- MANAGE ANSWERS -->
            <template v-if="answersLoaded && manageAnswers">
              <div v-if="questionAnswers[question.data.id].items.length > 0">
                <v-btn
                  :color="questionAnswers[question.data.id].conflicting ? 'error': null"
                  text
                  x-small
                  @mousedown.prevent.stop="onViewAnswersClick(question, questionAnswers[question.data.id].items)"
                >
                  <template v-if="questionAnswers[question.data.id].conflicting">
                    <v-icon x-small left>mdi-alert</v-icon>
                    <span v-text="$t('surveyAnswer.conflictingAnswers', {
                      total: questionAnswers[question.data.id].items.length,
                    })"></span>
                  </template>
                  <template v-else>
                    <v-icon x-small left>mdi-eye</v-icon>
                    <span v-text="$tc('surveyAnswer.viewAnswers', questionAnswers[question.data.id].items.length, {
                      total: questionAnswers[question.data.id].items.length,
                    })"></span>
                  </template>
                </v-btn>

                <div v-if="questionAnswers[question.data.id].items.length === 1" class="mt-1 ml-2 caption warning--text">
                  <v-icon color="warning" x-small left>mdi-alert</v-icon>
                  <span>Partially answered</span>
                </div>
              </div>
              <div v-else class="mt-1 ml-2 caption warning--text">
                <v-icon color="warning" x-small left>mdi-alert</v-icon>
                <span>Not answered yet</span>
              </div>
            </template>

            <!-- DEBUG -->
            <pre v-if="false" class="overflow-auto" style="max-width: 100%; max-height: 150px">
              {{question.answer}}
            </pre>
          </div>
        </template>

        <template v-if="showSaveButton && showGroup[group.data.id]">
          <v-divider class="my-4"/>

          <v-btn
            block
            color="primary"
            :disabled="!canSaveGroup(group)"
            :loading="saving"
            @click="() => onSaveGroupClick(group)"
          >
            <span v-text="$t('btn.save')"></span>
          </v-btn>
        </template>
      </v-alert>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import Rules from '@/modules/sdk/core/rules';
import SurveyAnswerModel from '@/models/survey-answer.model';
import AskQuestionToAISurveyModal from '@/components/AskQuestionToAISurveyModal.vue';
import ManageSurveyAnswersModal from '@/components/ManageSurveyAnswersModal.vue';
import DialogDateField from '@/modules/common/components/DialogDateField.vue';
import DialogTimeField from '@/modules/common/components/DialogTimeField.vue';
import DialogDateTimeField from '@/modules/common/components/DialogDateTimeField.vue';
import SurveyQuestionModel from '@/models/survey-question.model';
import CountryService from '@/modules/sdk/services/country.service';
import { ZemitData } from '@/modules/sdk/core/service';
import SurveyAnswerService from '@/services/survey-answer.service';
import RecordModel from '@/models/record.model';
import SurveyGroupModel from '@/models/survey-group.model';
import Identity from '@/modules/sdk/core/identity';
import SurveyModel from '@/models/survey.model';

@Component({
  components: {
    AskQuestionToAISurveyModal,
    ManageSurveyAnswersModal,
    DialogDateTimeField,
    DialogDateField,
    DialogTimeField,
  }
})
export default class SurveyAnswer extends Vue {
  @Prop({
    type: Array as PropType<Array<SurveyAnswerModel>>,
    default: () => ([])
  }) value!: Array<SurveyAnswerModel>

  @Prop({
    type: Object as PropType<SurveyModel>,
    default: () => ({
      data: [],
    }),
  }) survey!: SurveyModel

  @Prop({
    type: RecordModel,
  }) record!: RecordModel

  @Prop({
    type: Boolean,
    default: false,
  }) readonly?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) preview?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) manageAnswers?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) fullWidth?: boolean

  saving = false
  loadingAutocomplete = false
  loadingCountries = false
  loadingAnswers = false
  countriesLoaded = false
  answersLoaded = false
  answers: Array<SurveyAnswerModel> = []
  questionAnswers: any = {}
  errors: any = {}
  showGroup: any = {}
  countries: ZemitData<any> | null = null;
  autocomplete: any = {};
  loading = false
  rules: any = {}
  questionToAIModal: {
    visible: boolean,
    selection: string | null,
    question: SurveyQuestionModel,
  } = {
    visible: false,
    selection: null,
    question: new SurveyQuestionModel(),
  }

  viewAnswersModal: {
    visible: boolean,
    question: SurveyQuestionModel,
    answers: Array<SurveyAnswerModel>,
  } = {
    visible: false,
    question: new SurveyQuestionModel(),
    answers: [],
  }

  @Watch('survey', { deep: true })
  onSurveyChanged() {
    if (this.answersLoaded) {
      setTimeout(() => {
        this.assignAnswers(this.answers);
      })
    }
  }

  @Watch('manageAnswers', {
    immediate: true
  })
  onManageAnswerChanged(active: boolean) {
    if (active && !this.answersLoaded) {
      this.loadAllAnswers()
        .then(answers => {
          this.setAnswers(answers);
          this.$forceUpdate();
        });
    }
  }

  get maxWidthStyle(): string {
    return this.fullWidth ? '100%' : '31rem';
  }

  get showSaveButton(): boolean {
    return !this.readonly && !this.preview;
  }

  getDifferentQuestionsFromGroup(group: SurveyGroupModel): Array<SurveyQuestionModel> {
    return group.data.surveyquestionlist.filter((group: any) => JSON.stringify(group.answer) !== JSON.stringify(group.originalAnswer));
  }

  getAutocompleteItems(question: SurveyQuestionModel): Array<any> {
    return this.autocomplete[question.data.id] || [];
  }

  canSaveGroup(group: SurveyGroupModel): boolean {
    const questions = this.getDifferentQuestionsFromGroup(group);
    return !this.saving && questions.length > 0;
  }

  onAskQuestionAi(question: SurveyQuestionModel): void {
    const activeElement: any = document.activeElement;
    const selObj = window.getSelection();
    let selection = null;
    try {
      const range = selObj ? selObj.getRangeAt(0) : null;
      selection = activeElement && ['TEXTAREA', 'INPUT'].includes(activeElement.nodeName)
          ? activeElement.value.substring(
              activeElement.selectionStart,
              activeElement.selectionEnd
            )
          : range
            ? range.toString()
            : null;
    }
    catch (e) {

    }

    Object.assign(this.questionToAIModal, {
      visible: true,
      question,
      selection,
    })
  }

  onViewAnswersClick(question: SurveyQuestionModel, answers: Array<SurveyAnswerModel>): void {
    Object.assign(this.viewAnswersModal, {
      visible: true,
      question,
      answers,
    })
  }

  onAIAnswer(answer: any): void {
    console.log(answer);
  }

  onInput(): void {
    this.$forceUpdate();
  }

  onToggleShowGroupClick(group: SurveyGroupModel) {
    this.showGroup[group.data.id] = !this.showGroup[group.data.id];
    this.$forceUpdate();
  }

  onSaveGroupClick(group: SurveyGroupModel): void {
    this.saveGroup(group);
  }

  onApplyAnswer(answer: SurveyAnswerModel): void {
    this.viewAnswersModal.question.answer = answer.data.content;
  }

  setAnswers(answers: Array<SurveyAnswerModel>): void {
    this.survey.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
      group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
        const items = answers.filter(answer => answer.data.surveyQuestionId === question.data.id);
        const content = items.map(item => item.data.content);
        const uniqueAnswers = content.filter((item, index) => content.indexOf(item) === index);

        this.questionAnswers[question.data.id] = {
          conflicting: uniqueAnswers.length > 1,
          equals: uniqueAnswers.length === 1 && items.length >= 1,
          items,
        };

        // If more than two answer with the same value, set it as default in the field
        const counts: {[key: string]: {
          count: number,
          content: any
        }} = {};
        items.forEach(item => {
          if (!counts[item.data.surveyQuestionId]) {
            counts[item.data.surveyQuestionId] = {
              count: 0,
              content: item.data.content,
            };
          }
          if (counts[item.data.surveyQuestionId].content === item.data.content) {
            counts[item.data.surveyQuestionId].count++;
          }
        })
        Object.keys(counts).forEach((surveyQuestionId: string) => {
          const item = counts[surveyQuestionId];
          if (item.count >= 2) {
            question.answer = item.content;
            question.originalAnswer = structuredClone(item.content);
          }
        });
      })
    })
  }

  prepareAnswers(): void {
    this.survey.data.surveygrouplist.forEach((group: any) => {
      this.showGroup[group.data.id] = true;
      group.data.surveyquestionlist.forEach((question: any) => {
        this.questionAnswers[question.data.id] = {
          items: [],
          conflicting: false,
          equals: false,
        };
      })
    });
  }

  questionsToAnswers(group: SurveyGroupModel): Array<SurveyAnswerModel> {
    const answers: Array<SurveyAnswerModel> = [];
    const questions = this.getDifferentQuestionsFromGroup(group);
    questions.forEach(question => {
      const defaultProps = {
        projectId: this.survey.data.projectId,
        surveyId: this.survey.data.id,
        surveyGroupId: group.data.id,
        surveyQuestionId: question.data.id,
        recordId: this.record?.data?.id,
      };

      const questionAnswers = Array.isArray(question.answer)
        ? question.answer
        : [question.answer];
      questionAnswers.forEach(a => {
        const additionnal: any = {};
        if (question.hasChoices() && (question.data.type !== 'autocomplete' || typeof a === 'number')) {
          additionnal.surveyChoiceId = a;
        } else {
          additionnal.content = a;
        }

        answers.push(new SurveyAnswerModel({
          ...defaultProps,
          ...additionnal,
        }));
      })
    });
    return answers;
  }

  getRules(question: SurveyQuestionModel): Array<() => boolean> {
    const rules = [];
    if (question.data.required) {
      rules.push(this.rules.required);
    }
    if (question.data.positiveOnly) {
      rules.push(this.rules.isPositive);
    }
    if (question.data.negativeOnly) {
      rules.push(this.rules.isNegative);
    }
    if (question.data.pastOnly) {
      rules.push(this.rules.isPast);
    }
    if (
      question.data.minLength !== null ||
      question.data.maxLength !== null
    ) {
      rules.push((value: string) => this.rules.isBetweenLength(value, question.data.minLength, question.data.maxLength));
    }
    if (
      question.data.numberMin !== null ||
      question.data.numberMax !== null
    ) {
      rules.push((value: number) => this.rules.isBetween(value, question.data.numberMin, question.data.numberMax));
    }
    if (
      question.data.decimalMin !== null ||
      question.data.decimalMax !== null
    ) {
      rules.push((value: number) => this.rules.isBetween(value, question.data.decimalMin, question.data.decimalMax));
    }
    return rules;
  }

  getYears(question: SurveyQuestionModel): Array<SurveyAnswerModel> {
    const years = [];
    for (let i = (question.data.numberMax || new Date().getFullYear()); i >= (question.data.numberMin || 1900); i--) {
      years.push(i);
    }
    return years;
  }

  assignAnswers(answers: Array<SurveyAnswerModel>): void {
    answers.forEach(answer => {
      this.survey.data.surveygrouplist.forEach((group: any) => {
        const question = group.data.surveyquestionlist.find((question: SurveyQuestionModel) => question.data.id === answer.data.surveyQuestionId);
        if (question && !(answer.data.content === null && answer.data.surveyChoiceId === null)) {

          let prop = 'content';
          if (question.hasChoices()) {
            prop = 'surveyChoiceId';

            if (question.data.type !== 'autocomplete' && !question.data.surveychoicelist.find((choice: any) => answer.data[prop] === choice.data.id)) {
              return;
            }
          }

          const value: any = question.data.type === 'autocomplete' ? answer : answer.data[prop];
          const isDifferentAnswerType = !Array.isArray(question.answer) && Array.isArray(value);
          if (question.isArray()) {
            if ((
                !question.hasChoices()
                || question.data.surveychoicelist.find((choice: any) => value === choice.data.id)
              ) && !question.answer.includes(value)
            ) {
              question.answer.push(value);
              question.originalAnswer = question.answer instanceof SurveyAnswerModel
                ? question.answer.clone()
                : structuredClone(question.answer);
            }
          } else {
            question.answer = value;
            question.originalAnswer = value instanceof SurveyAnswerModel
              ? value.clone()
              : structuredClone(value);
          }
        }
      });
    });
    this.$forceUpdate();
  }

  loadAnswers(): Promise<Array<SurveyAnswerModel>> {
    this.loading = true;
    return SurveyAnswerService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.survey.data.projectId,
      }, {
        field: 'surveyId',
        operator: 'equals',
        value: this.survey.data.id,
      }, {
        field: 'recordId',
        operator: 'equals',
        value: this.record?.data?.id,
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]
    })
      .then((response) => {
        this.assignAnswers(response.data.view.list);
        return response.data.view.list;
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.loading = false);
  }

  loadCountries(): Promise<any> {
    this.loadingCountries = true;
    return CountryService.getInstance().getAll()
      .then(countries => {
        Object.assign(this, { countries })
        this.countriesLoaded = true;
        return countries;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingCountries = false);
  }

  loadAutocomplete(questionId: number): Promise<any> {
    this.loadingAutocomplete = true;
    return SurveyAnswerService.getInstance().getAll({
      filters: [{
        field: 'surveyQuestionId',
        operator: 'equals',
        value: questionId,
      }]
    })
      .then(response => this.autocomplete[questionId] = response.data.view.list)
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingAutocomplete = false);
  }

  loadAllAnswers(): Promise<Array<SurveyAnswerModel>> {
    this.loadingAnswers = true;
    return SurveyAnswerService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.survey.data.projectId
      }, {
        field: 'surveyId',
        operator: 'equals',
        value: this.survey.data.id,
      }, {
        field: 'recordId',
        operator: 'equals',
        value: this.record?.data?.id,
      }]
    })
      .then(response => {
        this.answers = response.data.view.list;
        this.answersLoaded = true;
        return response.data.view.list;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingAnswers = false);
  }

  saveGroup(group: SurveyGroupModel) {
    const answers = this.questionsToAnswers(group);
    this.saving = true;
    return SurveyAnswerService.getInstance().save(answers)
      .then((response) => {
        this.assignAnswers(response.data.view.map((item: any) => new SurveyAnswerModel(item.single)));
        this.$emit('save', answers);
        this.$root.$globalSnack.info({
          message: 'Survey answers successfully saved.',
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.saving = false);
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
    this.rules.isPositive = (value: number) => Rules.isPositive(value) || this.$t('rules.mustBePositive');
    this.rules.isNegative = (value: number) => Rules.isNegative(value) || this.$t('rules.mustBeNegative');
    this.rules.isPast = (value: Date) => !Rules.isFuture(value) || this.$t('rules.mustBePast');
    this.rules.isBetween = (value: number, min: number, max: number) => Rules.isBetween(value, min, max) || this.$t('surveyBuilder.validation.isBetween', {
      value,
      min,
      max
    });
    this.rules.isBetweenLength = (value: string, min: number, max: number) => Rules.betweenLength(value, min, max) || this.$t('surveyBuilder.validation.isBetweenLength', {
      value,
      min,
      max
    });
    this.rules.isBetweenDates = (value: Date, min: Date, max: Date) => Rules.isBetweenDates(value, min, max) || this.$t('surveyBuilder.validation.isBetweenDates', {
      value,
      min,
      max
    });

    this.prepareAnswers();

    if (!this.preview) {
      const promises = [this.loadAnswers()];
      for (let i = 0; i < this.survey.data.surveygrouplist.length; i++) {
        const group = this.survey.data.surveygrouplist[i];
        for (let j = 0; j < group.data.surveyquestionlist.length; j++) {
          const question = group.data.surveyquestionlist[j];
          if (question.data.type === 'country') {
            promises.push(this.loadCountries());
          }
          if (question.data.type === 'autocomplete') {
            promises.push(this.loadAutocomplete(question.data.id));
          }
        }
      }
      Promise.all(promises)
        .then(responses => this.$forceUpdate());
    }
  }
}
</script>

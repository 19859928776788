import Model from '@/modules/sdk/core/model';
import RecordModel from '@/models/record.model';
import UserModel from '@/modules/sdk/models/user.model';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';

export default class RecordUserStatusModel extends Model {

  relatedMap () {
    return {
      recordentity: RecordModel,
      userentity: UserModel,
      projectstatusreasonentity: ProjectStatusReasonModel,
    }
  }

}

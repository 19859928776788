import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import KeywordModel from '@/models/keyword.model';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tinycolor = require('tinycolor2');

export default class SynonymModel extends Model {
  selected = false;

  relatedMap () {
    return {
      projectentity: ProjectModel,
      keywordentity: KeywordModel,
    }
  }

  isDark() {
    return tinycolor(this.data.color).isDark();
  }

  getTextClass() {
    return 'text--' + (this.isDark() ? 'lighten-3' : 'darken-3');
  }

  getTextColor(force = false) {
    return this.selected || force? (this.isDark() ? 'white' : 'black') : this.data.color;
  }

  selectedToggle() {
    this.selected = !this.selected;
  }

  validate(str = '') {

    if (!this.data.regexp) {
      return true;
    }

    const prefix = this.data.wordOnly ? '\\b(' : '(';
    const suffix = this.data.wordOnly ? ')\\b' : ')';
    const flags = this.data.caseSensitive ? 'g' : 'gi';
    const escapeRegExp = (str: string, isRegexp = false) => {
      return isRegexp ? str : str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    try {
      const reg = new RegExp(prefix + escapeRegExp(this.data.label, this.data.regexp) + suffix, flags);
      if (str) {
        return reg.test(str);
      }
      return true;
    } catch (e: any) {
      console.error(e);
      return false;
    }
  }
}

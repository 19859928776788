<template>
  <!-- DARK MODE -->
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-test-id="'app-theme-toggle-button'"
        v-bind="{ ...attrs, ...btnAttrs }"
        v-on="on"
        icon
        @click="onSwitchThemeClick"
      >
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </template>
    <span v-test-id="'app-theme-toggle-tooltip'">
      <template v-if="isDarkMode">Switch to light mode</template>
      <template v-else>Switch to dark mode</template>
    </span>
  </v-tooltip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import store from 'store2'

@Component
export default class ThemeToggle extends Vue {
  @Prop({
    type: Object,
    default: null
  }) btnAttrs?: null

  get isDarkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  onSwitchThemeClick(): void {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    store.local.set('dark', this.$vuetify.theme.dark.toString())
    this.applyColorScheme()
  }

  applyColorScheme(): void {
    const preferredTheme = this.$vuetify.theme.dark ? 'dark' : 'light'
    document.documentElement.style.setProperty('color-scheme', preferredTheme)
  }

  created() {
    this.applyColorScheme()
  }
}
</script>

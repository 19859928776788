import {Route} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import Identity, {NestedArrayOr} from '@/modules/sdk/core/identity';
import Main from '@/main';
import Auth from '@/Auth.vue';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('router/guards/role.guard');

const roleGuard = (role: NestedArrayOr<string>) => {

  return (to: Route, from: Route, next: NavigationGuardNext) => {

    // User logged in
    if (Identity.isLoggedIn()) {

      // User not authorized
      if (!Identity.hasRole(role)) {

        // Dispatch to the Error View (Forbidden)
        return next({name: 'error', params: {type: 'forbidden'}});
      }
    }

    // User not logged in
    else {

      const rewrite = {name: 'login', params: {redirect: to.fullPath}};
      next(rewrite);

      if (Main.$vue) {
        Main.render(Auth, (vue: Vue) => {
          vue.$route.params.redirect = rewrite.params.redirect;
        });
      }
      return;
    }

    return next();
  };
}

export default roleGuard;

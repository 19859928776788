<template>
  <div v-bind="$attrs">
    <slot name="title">
      <h3 v-if="title">{{title}}</h3>
      <v-divider class="mt-1 mb-3" />
    </slot>

    <!-- LOADING -->
    <v-row v-if="loading">
      <slot name="loading">
        <v-col cols="12">
          <v-progress-linear
            :loading="loading"
            indeterminate
          ></v-progress-linear>
        </v-col>
      </slot>
    </v-row>

    <!-- METRICS -->
    <div v-else-if="hasMetrics">
      <v-row>
        <v-col cols="4" v-for="(stage, stageIndex) in stageList" :key="stageIndex">
          <template v-if="hasStatusData(stage.key)">
            <h4>{{stage.label}}</h4>
            <Pie
              :chart-data="getStatusData(stage.key)"
              :chart-options="statusOptions"
            />

            <h5>Match rate</h5>
            <v-progress-linear
              :value="getMatchRatePercentage(stage.key)"
              color="success"
              background-color="error"
              height="18"
            >
              <template v-slot:default="{ value }">
                <span class="white--text">{{ value }}%</span>
              </template>
            </v-progress-linear>
          </template>
          <div
            v-else
            class="d-flex flex-column h-100 w-100"
          >
            <h4 class="mb-2" style="flex: 0">{{stage.label}}</h4>
            <div
              class="d-flex flex-column align-center justify-center text-center py-6"
              style="flex: 1; border: 3px dashed lightgrey; border-radius: 1rem"
            >
              <v-icon disabled large>mdi-cloud-question</v-icon>
              <span class="text-body-1">No data yet</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>

</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import { Bar, Pie, Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import ProjectProgressComponent from '@/views/Admin/Component/ProjectProgressComponent.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

const d = new Logger('views/Admin/Component/MetricsComponent');

export interface MetricsStatusInterface {
  pass: number,
  pending: number,
  halt: number,
  skip: number,
  mismatch: number,
}

export interface MetricsInterface {
  screening: MetricsStatusInterface,
  indepth: MetricsStatusInterface,
  final: MetricsStatusInterface,
}

@Component({
  components: {
    Bar,
    Pie,
    Doughnut,
    ProjectProgressComponent,
  }
})
export default class MetricsComponent extends Vue {
  @Prop() metrics!: MetricsInterface;
  @Prop({default: () => false}) loading!: boolean;
  @Prop({default: () => {}}) statusOptions: any;
  @Prop() title!: string;

  stageList: Array<{
    label: string,
    key: 'screening'|'indepth'|'final',
  }> = [
    {
      label: 'Screening',
      key: 'screening'
    },
    {
      label: 'Indepth',
      key: 'indepth'
    },
    {
      label: 'Final',
      key: 'final'
    }
  ];

  get hasMetrics(): boolean {
    return !!Object.keys(this.metrics).length
  }

  hasStatusData(status: 'screening'|'indepth'|'final') {
    return (
        this.metrics[status].pass
        + this.metrics[status].pending
        + this.metrics[status].halt
        // + this.metrics[status].skip
      )
      > 0;
  }

  getStatusData(status: 'screening'|'indepth'|'final') {
    return {
      labels: [
        ' Pass ',
        ' Pending ',
        ' Halt ',
        ' Skip ',
      ],
      datasets: [
        {
          label: status,
          data: [
            this.metrics[status].pass,
            this.metrics[status].pending,
            this.metrics[status].halt,
            this.metrics[status].skip,
          ],
          backgroundColor: [
            this.$vuetify.theme.currentTheme.success,
            this.$vuetify.theme.currentTheme.warning,
            this.$vuetify.theme.currentTheme.error,
            this.$vuetify.theme.currentTheme.skip,
          ]
        }
      ],
    };
  }

  getMatchRatePercentage(status: 'screening'|'indepth'|'final') {
    const totalHaltPass = this.metrics[status].halt + this.metrics[status].pass;
    return 100 - (Math.round(((this.metrics[status].mismatch * 100 / totalHaltPass) || 0) * 100) / 100);
  }
}
</script>

<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SubView extends Vue {

}
</script>

import Service from '@/modules/sdk/core/service';
import AiModel from '@/models/ai.model';

export default class AiService extends Service {
  modelUrl = '/ai';
  baseUrl = '/api';
  model = AiModel;

  endpointList = {
    ...new Service().endpointList,
    ...{
      getKeywordSuggestions: '/get-keyword-suggestions',
      getRecordAnswers: '/get-record-answers',
      getRecordStatus: '/get-record-status',
    }
  }

  getKeywordSuggestionsUrl = () => this.getUrl('getKeywordSuggestions');
  getRecordAnswersUrl = () => this.getUrl('getRecordAnswers');
  getRecordStatusUrl = () => this.getUrl('getRecordStatus');

  getKeywordSuggestions = (data = {}, config = {}) => this.handleRequest(this.getKeywordSuggestionsUrl(), data, config);
  getRecordAnswers = (data = {}, config = {}) => this.handleRequest(this.getRecordAnswersUrl(), data, config);
  getRecordStatus = (data = {}, config = {}) => this.handleRequest(this.getRecordStatusUrl(), data, config);
}

import Model, { Data } from '../core/model';
import DashboardChartModel from './dashboard-chart.model';

export default class VisualizationModel extends Model {

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.data.graphs = this.data.graphs.map((entry: any) => new DashboardChartModel(entry).data);
  }

  default() {
    return {
      title: '',
      graphs: []
    }
  }

  jsonCast(): Array<string> {
    return [
      'graphs',
    ]
  }

}

<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :buttons="buttons"
    :max-width="maxWidth"
    :color="color"
    :dark="dark"
  >
    <template #body>
      <div v-html="body"></div>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import { Component, Vue } from 'vue-property-decorator';
import {TranslateResult} from 'vue-i18n';

@Component({
  components: {
    ModalDialog,
  }
})
export default class GlobalModal extends Vue {
  name = 'GlobalModal';

  visible = false
  title: string | TranslateResult | null = null
  body: string | TranslateResult | null = null
  buttons: Array<any> = []
  maxWidth: string | number = '100%'
  color: string | null = null
  dark = false

  ask(
    title: string | TranslateResult,
    body: string | TranslateResult,
    buttons: Array<any>,
    type = 'default',
  ) {
    this.visible = true;
    this.title = title;
    this.body = body;
    this.buttons = buttons;
    this.maxWidth = 500;

    switch (type) {
      case 'success': this.color = 'primary'; this.dark = true; break;
      case 'warning': this.color = 'warning'; this.dark = true; break;
      case 'info': this.color = 'info'; this.dark = true; break;
      case 'danger': this.color = 'error'; this.dark = true; break;
      default: this.color = null; this.dark = false; break;
    }
  }

  info(
    title: string | TranslateResult,
    body: string | TranslateResult,
    type = 'default',
  ) {
    this.visible = true;
    this.title = title;
    this.body = body;
    this.buttons = [{
      text: this.$i18n.t('btn.close'),
      attrs: {
        text: true,
      },
      events: {
        click: () => {
          this.$root.$globalModal.hide();
        },
      }
    }];
    this.maxWidth = 500;

    switch (type) {
      case 'success': this.color = 'primary'; this.dark = true; break;
      case 'warning': this.color = 'warning'; this.dark = true; break;
      case 'info': this.color = 'info'; this.dark = true; break;
      case 'danger': this.color = 'error'; this.dark = true; break;
      default: this.color = null; this.dark = false; break;
    }
  }

  setDisabled(value: boolean) {
    this.buttons[0].attrs.disabled = value;
    this.buttons[1].attrs.disabled = value;
    this.$forceUpdate();
  }

  setLoading(value: boolean) {
    this.buttons[1].attrs.loading = value;
    this.setDisabled(value);
    this.$forceUpdate();
  }

  hide() {
    this.visible = false;
  }

  created() {
    this.$root.$globalModal = this;
  }
}
</script>

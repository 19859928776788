import Model from '@/modules/sdk/core/model';
import UserModel from '@/modules/sdk/models/user.model';
import RecordModel from '@/models/record.model';
import Identity from '@/modules/sdk/core/identity';

export default class CommentModel extends Model {

  relatedMap () {
    return {
      userentity: UserModel,
      recordentity: RecordModel,
    }
  }

  togglePrivate() {
    this.data.private = !this.data.private;
  }

  isPrivate() {
    return !!this.data.private;
  }

  isMine(userId = Identity.getIdentity()?.user.id) {
    return this.data.userId === userId;
  }

  /**
   * Can see
   */
  canSee(selfOnly = false): boolean {

    // Admin can always see
    if (Identity.hasRole(['dev', 'admin'])) {
      return true;
    }

    // If use can edit, he can see
    if (this.canEdit()) {
      return true;
    }

    // If self only (i.e. during the screening process)
    if (selfOnly && !this.isMine()) {
      return false;
    }

    // If the comment is not private
    if (!this.isPrivate()) {
      return true;
    }

    return false;
  }

  /**
   * Can edit
   */
  canEdit(): boolean {

    // Admin can always see
    if (Identity.hasRole(['dev', 'admin'])) {
      return true;
    }

    // Can edit his own comments
    if (this.isMine()) {
      return true;
    }

    return false;
  }
}

<template>
  <v-chip
    v-bind="attrs"
    v-on="getEvents(_model)"
    :outlined="!_model.selected"
    :dark="_model.selected"
    :small="small"
    :color="$vuetify.theme.dark && !_model.selected ? 'white' : 'primary'"
    label
  >
    <!-- SETTINGS DIALOG -->
    <ModalDialog
      v-if="detailed && settingsDialog.enabled"
      v-model="settingsDialog.visible"
      title="Keyword settings"
      width="800"
      persistent
      background-color="background"
      scrollable
      draggable
      @close="onCancelClick(settingsDialog)"
    >
      <template #body>
        <v-form ref="settingsDialogForm" v-model="settingsDialog.valid" class="pt-4">
          <v-card>
            <v-card-text>
              <KeywordFormInner
                v-model="settingsDialog.item"
                :project-id="settingsDialog.item.data.projectId"
                :service="service"
                :auto-edit-id="isSynonym ? synonym.data.id : null"
                :auto-add-synonym="autoAddSynonym"
              />
            </v-card-text>
          </v-card>
        </v-form>
      </template>
      <template #buttons>
        <v-btn v-if="_model.data.id" :disabled="!canDelete" :loading="_model.states.deleting" color="error" outlined @click="() => onDeleteClick(_model, settingsDialog)">
          <span v-text="$t('btn.delete')"></span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!canCancel" text @click="onCancelClick(settingsDialog)">
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
        <v-btn :disabled="!canApply(settingsDialog)" :loading="_model.states.saving" color="primary" @click="onApplyClick(settingsDialog)">
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <!-- PARAMETERS DIALOG -->
    <ModalDialog
      v-else-if="parametersDialog.enabled"
      v-model="parametersDialog.visible"
      title="Keyword parameters"
      width="390"
      persistent
      scrollable
      @close="onCancelClick(parametersDialog)"
    >
      <template #body>
        <v-form ref="parametersDialogForm" v-model="parametersDialog.valid">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="parametersDialog.item.data.label"
                label="Keyword"
                :rules="[form.rules.required, form.rules.regex]"
                type="text"
                hide-details="auto"
                clearable
                required
                autofocus
                @input="form.errors = {}"
              >
              </v-text-field>
            </v-col>
            <template v-if="parametersOptions">
              <v-col cols="12">
                <v-checkbox
                  v-model="parametersDialog.item.data.regexp"
                  class="pt-0"
                  label="Regular Expression"
                  prepend-icon="mdi-code-braces"
                  hide-details="auto"
                  @change="validate"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="parametersDialog.item.data.caseSensitive"
                  class="pt-0"
                  label="Case Sensitive"
                  prepend-icon="mdi-format-letter-matches"
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="parametersDialog.item.data.wordOnly"
                  class="pt-0"
                  label="Match Word Only"
                  prepend-icon="mdi-file-word-box-outline"
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
            </template>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-color-picker
            v-model="_colorPicker"
            mode="hexa"
            hide-canvas
            hide-inputs
            hide-mode-switch
            show-swatches
            width="340"
            swatches-max-height="340"
          ></v-color-picker>
        </v-form>
      </template>
      <template #buttons>
        <v-btn v-if="_model.data.id" :disabled="!canDelete" :loading="_model.states.deleting" color="error" outlined @click="() => onDeleteClick(_model, parametersDialog)">
          <span v-text="$t('btn.delete')"></span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!canCancel" text @click="onCancelClick(parametersDialog)">
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
        <v-btn :disabled="!canApply(parametersDialog)" :loading="_model.states.saving" color="primary" @click="onApplyClick(parametersDialog)">
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <!-- CONTENT -->
    <div
      class="d-flex align-center"
      style="gap: 0.5rem"
    >
      <!-- COLOR -->
      <v-progress-circular v-if="_model.states.saving" :color="_model.data.color" :size="small ? 14 : 20" width="2" indeterminate />
      <v-icon v-else-if="!filled && !_model.selected" :color="_model.data.color" :size="small ? 14 : 20">mdi-circle-outline</v-icon>
      <v-icon v-else :color="_model.data.color" :size="small ? 14 : 20">mdi-circle</v-icon>

      <!-- KEYWORD -->
      <span v-text="_model.data.label"></span>

      <!-- EDIT -->
      <v-btn v-if="editable" icon x-small @click.stop="() => onEditClick(detailed ? model : _model)">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <!-- ICON TYPE -->
      <template v-if="isSynonym">
        <v-icon v-if="_model.data.regexp" small>mdi-code-braces</v-icon>
        <v-icon v-if="_model.data.caseSensitive" small>mdi-format-letter-matches</v-icon>
        <v-icon v-if="_model.data.wordOnly" small>mdi-file-word-box-outline</v-icon>
      </template>

      <!-- REMOVE -->
      <v-btn v-if="removable" icon x-small @click.stop="() => $emit('remove', _model)">
        <v-icon small>$delete</v-icon>
      </v-btn>
    </div>
  </v-chip>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, Watch, Ref } from 'vue-property-decorator';
import KeywordModel from '@/models/keyword.model';
import SynonymModel from '@/models/synonym.model';
import KeywordService from '@/services/keyword.service';
import SynonymService from '@/services/synonym.service';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import KeywordFormInner from '@/views/Admin/Form/KeywordFormInner.vue';
import Rules from '@/modules/sdk/core/rules';

export type VForm = Vue & { validate: () => boolean, reset: () => boolean, resetValidation: () => boolean }

@Component({
  components: {
    ModalDialog,
    KeywordFormInner,
  }
})
export default class KeywordChip extends Vue {
  @Ref() readonly settingsDialogForm!: VForm;
  @Ref() readonly parametersDialogForm!: VForm;

  @VModel({ default: () => new KeywordModel() }) model!: KeywordModel
  @Prop({ type: SynonymModel, default: null }) synonym!: SynonymModel
  @Prop({ type: Boolean, default: false }) editable!: boolean
  @Prop({ type: Boolean, default: false }) selectable!: boolean
  @Prop({ type: Boolean, default: false }) removable!: boolean
  @Prop({ type: Boolean, default: false }) deletable!: boolean
  @Prop({ type: Boolean, default: false }) filled!: boolean
  @Prop({ type: Boolean, default: false }) small!: boolean
  @Prop({ type: Boolean, default: false }) detailed!: boolean

  service = KeywordService.getInstance();
  defaultColor = '#E0E0E0FF';
  parametersOptions = false;
  loaded = false;
  colorPicker = {} as any;
  autoAddSynonym = false
  settingsDialog: {
    enabled: boolean,
    visible: boolean,
    valid: boolean,
    item: KeywordModel | SynonymModel,
    original: KeywordModel | SynonymModel,
  } = {
    enabled: false,
    visible: false,
    valid: false,
    item: new SynonymModel(),
    original: new SynonymModel(),
  };

  parametersDialog: {
    enabled: boolean,
    visible: boolean,
    valid: boolean,
    item: KeywordModel | SynonymModel,
    original: KeywordModel | SynonymModel,
  } = {
    enabled: false,
    visible: false,
    valid: false,
    item: new SynonymModel(),
    original: new SynonymModel(),
  };

  lastInstance: any = null

  form: any = {
    name: 'Keyword Form',
    model: new KeywordModel(),
    service: KeywordService.getInstance(),
    errors: {},
    rules: {},
    loading: false,
  };

  @Watch('colorPicker')
  onColorPickerChange(value: any) {
    if (this.lastInstance.item) {
      this.lastInstance.item.data.color = value;
    }
  }

  get _model(): KeywordModel | SynonymModel {
    return this.synonym ? this.synonym : this.model;
  }

  get canDelete(): boolean {
    return !this._model.states.deleting
      && !this._model.states.saving;
  }

  get canCancel(): boolean {
    return !this._model.states.deleting
      && !this._model.states.saving;
  }

  canApply(instance: any): boolean {
    return instance.valid
      && instance.item.isDifferentFromOriginal()
      && !this._model.states.deleting
      && !this._model.states.saving;
  }

  get isSynonym(): boolean {
    return this._model instanceof SynonymModel;
  }

  get attrs(): any {
    return Object.assign({}, this.$attrs);
  }

  set _colorPicker(value) {
    this.colorPicker = value;
  }

  get _colorPicker() {
    return this.colorPicker;
  }

  getEvents(model: KeywordModel | SynonymModel): any {
    const events: any = {};
    if (this.selectable) {
      events.click = () => model.selectedToggle();
    }
    return Object.assign({}, events, this.$listeners);
  }

  onEditClick(model: KeywordModel | SynonymModel): void {
    this.editItem(model, model instanceof KeywordModel ? 'settings' : 'parameters')
  }

  onDeleteClick(model: KeywordModel | SynonymModel, instance: any): void {
    try {
      this.$root.$globalModal.ask(
        this.$i18n.t('keywordChip.remove.title' + (model instanceof KeywordModel ? 'Keyword' : 'Synonym')),
        this.$i18n.t('keywordChip.remove.body' + (model instanceof KeywordModel ? 'Keyword' : 'Synonym')),
        [{
          text: this.$i18n.t('btn.cancel'),
          attrs: {
            text: true,
          },
          events: {
            click: () => {
              this.$root.$globalModal.hide();
            },
          }
        }, {
          text: this.$i18n.t('btn.remove'),
          attrs: {
            outlined: true,
          },
          events: {
            click: () => {
              this.$root.$globalModal.setLoading(true);
              this.deleteItem(model, instance)
                .then(() => {
                  this.$root.$globalSnack.success({
                    message: this.$i18n.t('keywordChip.remove.snack' + (model instanceof KeywordModel ? 'Keyword' : 'Synonym'))
                  });
                  this.$root.$globalModal.setLoading(false);
                  this.$root.$globalModal.hide();
                  this.close(instance);
                });
            },
          }
        }],
        'danger',
      )
    } catch (e) {

    }
  }

  onCancelClick(instance: any) {
    this.close(instance);
    this.$emit('cancel', instance.original);
  }

  onApplyClick(instance: any) {
    if (this.validate(instance)) {
      // @ts-ignore
      instance.original.data = instance.item.clone().data;
      Object.assign(instance, {
        visible: false,
      });

      this.$emit('apply', instance.original);
    } else {
      this.$root.$globalSnack.error({
        message: 'Invalid regular expression'
      });
    }
  }

  editKeywordSynonym(keyword: KeywordModel) {
    this.autoAddSynonym = true;
    this.editItem(keyword, 'settings');
  }

  editItem(item: SynonymModel | KeywordModel, instanceType: 'settings' | 'parameters' = 'settings') {
    this._colorPicker = item.data.color || this.defaultColor;
    this.parametersOptions = item instanceof SynonymModel;
    const instance = instanceType === 'settings' ? this.settingsDialog : this.parametersDialog;
    this.lastInstance = instance;
    Object.assign(instance, {
      enabled: true,
      visible: true,
      original: item,
    })

    instance.item = item.clone();
    setTimeout(() => {
      this.validate(instance);
    })
  }

  deleteItem(model: SynonymModel | KeywordModel, instance: any): Promise<any> {
    model.states.deleting = true;
    const service = model instanceof KeywordModel
      ? KeywordService.getInstance()
      : SynonymService.getInstance();
    return service.delete(model)
      .then(response => {
        instance.original.assign(response.data.view.single);
        this.$emit('deleted', model);
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => {
        model.states.deleting = false;
        this.$forceUpdate();
      });
  }

  validate(instance: any): boolean {
    if (this.settingsDialogForm) {
      if (!this.settingsDialogForm.validate()) {
        return false;
      }
    }
    if (this.parametersDialogForm) {
      if (!this.parametersDialogForm.validate()) {
        return false;
      }
    }
    if (instance.item instanceof SynonymModel) {
      return instance.item.validate();
    }
    return true;
  }

  close(instance: any): void {
    instance.visible = false;
    setTimeout(() => {
      instance.enabled = false;
    }, 300)
  }

  created() {
    this.form.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
    this.form.rules.regex = () => {
      if (this.settingsDialog.item instanceof SynonymModel) {
        return this.settingsDialog.item.validate() || this.$t('rules.regex');
      }
      return !this.settingsDialog.item.data.regexp || this.$t('rules.regex')
    };
  }
}
</script>

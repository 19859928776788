<template>
  <v-card>
    <v-card-text class="adjust-font-size px-0 pt-0">

      <!-- ARTICLE LABEL -->
      <template v-if="!editing">
        <h4>Title</h4>
        <p v-text="article.data.title" v-highlight-keywords="highlightLogic"></p>
      </template>
      <v-text-field
        v-else
        v-model="article.data.title"
        :error-messages="errors.latitude"
        :disabled="disabled"
        label="Title"
        hide-details="auto"
        prepend-inner-icon="mdi-label-outline"
        clearable
        required
        outlined
        dense
      />

      <!-- FILE INPUT -->
      <div v-if="editing || !!article.data.fileentity.data.path" class="mt-4">
        <div class="d-flex align-center" style="gap: 1rem">
          <v-file-input
            v-if="editing"
            v-model="file"
            :label="article.data.fileentity.data.name || $t('label.file')"
            :loading="loading"
            :disabled="disabled || !!article.data.fileentity.data.path"
            ref="fileInput"
            accept="application/pdf"
            hide-details="auto"
            show-size
            chips
            prepend-icon=""
            prepend-inner-icon="mdi-attachment"
            outlined
            dense
            clearable
          />

          <template v-else-if="!!article.data.fileentity.data.path">
            <v-btn
              :disabled="disabled || downloading"
              :loading="downloading"
              outlined
              @click="onDownloadFileClick"
            >
              <v-icon left>mdi-download-outline</v-icon>
              <span v-text="$t('btn.download')"></span>
            </v-btn>

            <v-btn
              :loading="parsing"
              :disabled="disabled || parsing"
              color="primary"
              outlined
              @click="onParseFile"
            >
              <v-icon left>mdi-auto-fix</v-icon>
              <span v-text="$t('btn.parseText')"></span>
            </v-btn>

            <v-tooltip bottom>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabled"
                  icon
                  color="error"
                  @click="onDeleteFile"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Delete file</span>
            </v-tooltip>
          </template>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="mt-6">
        <div v-if="!editing">
          <h4>Content</h4>
          <div v-html="article.data.content" v-highlight-keywords="highlightLogic"></div>
        </div>
        <Wysiwyg
          v-else
          v-model="article.data.content"
          :disabled="disabled"
          label="Article Content"
          hide-details="auto"
        ></Wysiwyg>
      </div>
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-actions>
      <template v-if="!editing">
        <v-spacer />
        <v-btn
          :disabled="!canEdit"
          color="primary"
          @click="onEditClick"
        >
          <span v-text="$t('btn.edit')"></span>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          :disabled="!canDoAction"
          :loading="removing"
          outlined
          color="error"
          @click="() => onRemove(false)"
        >
          <span v-text="$t('btn.remove')"></span>
        </v-btn>
        <v-spacer />
        <div class="d-flex align-center" style="gap: 1rem">
          <v-btn
            v-if="article.data.id"
            :disabled="disabled"
            text
            @click="onCancelClick"
          >
            <span v-text="$t('btn.cancel')"></span>
          </v-btn>
          <v-btn
            :disabled="!canDoAction"
            :loading="saving"
            color="primary"
            @click="onSave"
          >
            <span v-text="$t('btn.save')"></span>
          </v-btn>
        </div>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import ArticleModel from '@/models/article.model';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import ArticleService from '@/services/article.service';
import FileModel from '@/modules/sdk/models/file.model';
import FileService from '@/modules/sdk/services/file.service';
import {saveAs} from 'file-saver';

@Component({
  components: {
    Wysiwyg,
  }
})
export default class ArticleComponent extends Vue {

  @VModel() article!: ArticleModel;
  @Prop() loading!: boolean;
  @Prop() highlightLogic!: any;
  @Prop({ default: () => false }) disabled!: boolean;

  downloading = false;
  editing = false;
  saving = false;
  removing = false;
  parsing = false;
  file = null;
  errors: any = {}

  get canDoAction(): boolean {
    return !this.disabled && !this.removing && !this.saving;
  }

  get canEdit(): boolean {
    return !this.disabled && !this.editing;
  }

  onEditClick(): void {
    this.editing = true;
  }

  onCancelClick(): void {
    this.editing = false;
  }

  onDownloadFileClick(): void {
    this.downloading = true;
    FileService.getInstance().downloadAsBlob(this.article.data.fileentity)
      .then((response: any) => {
        saveAs(response.data, this.article.data.fileentity.data.name);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.downloading = false);
  }

  onDeleteFile(): void {
    this.article.data.fileId = null;
    this.article.data.fileentity = new FileModel();
  }

  onSave(): void {
    this.saving = true;
    const saveArticle = () => {
      return ArticleService.getInstance().save(this.article)
        .then((response) => {
          this.article.assign(response.data.view.single);
          this.$emit('save', this.article);
          this.$root.$globalSnack.info({
            message: 'Article successfully saved.',
          });
          this.editing = false;
        })
        .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
        .finally(() => this.saving = false);
    };

    if (this.file) {
      ArticleService.getInstance().uploadPdf(this.file)
        .then(response => {
          this.file = null;
          this.article.data.fileId = response.data.view[0].file.id;
          return saveArticle();
        })
        .catch(reason => {
          this.$root.$zemit.handleError(reason);
          this.saving = false;
        });
    } else {
      saveArticle();
    }
  }

  onRemove(proceed = false): void {
    if (!proceed && this.article.data.id) {
      this.$root.$globalModal.ask(
        this.$i18n.t('recordComponent.removeArticleConfirm.title'),
        this.$i18n.t('recordComponent.removeArticleConfirm.body'),
        [{
          text: this.$i18n.t('btn.remove'),
          attrs: {
            outlined: true,
          },
          events: {
            click: () => {
              this.onRemove(true);
              this.$root.$globalSnack.info({
                message: this.$i18n.t('recordComponent.removeArticleConfirm.snack')
              });
              this.$root.$globalModal.hide();
            },
          }
        }, {
          text: this.$i18n.t('btn.cancel'),
          attrs: {
            text: true,
          },
          events: {
            click: () => {
              this.$root.$globalModal.hide();
            },
          }
        }],
        'danger',
      )
    } else if (this.article.data.id) {
      this.removing = true;
      ArticleService.getInstance().delete({
        id: this.article.data.id,
      })
        .then((response) => {
          this.$emit('remove', this.article);
        })
        .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
        .finally(() => this.removing = false);
    } else {
      this.$emit('remove', this.article);
    }
  }

  onParseFile() {
    this.parsing = true;
    const id = this.article.data.id;
    ArticleService.getInstance().parseFile({id})
      .then((response) => this.article.assign(response.data.view.single))
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.parsing = false);
  }

  created() {
    this.editing = !(this.article.data.id);
  }
}
</script>

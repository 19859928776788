import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';

export default class TaskModel extends Model {

  relatedMap () {
    return {
      projectentity: ProjectModel
    }
  }

  jsonCast(): Array<string> {
    return [
      'meta',
      'result',
    ]
  }

  getMetaRange() {
    return this.data.meta?.range
  }

  getMetaStage() {
    return this.data.meta?.stage
  }

  getMetaGptModel() {
    return this.data.meta?.gptModel
  }

}

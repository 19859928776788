import Vue, { DirectiveBinding } from 'vue';
import Hash from '@/modules/sdk/core/hash';

interface IDraggable {
  dragging: boolean,
  startX: number,
  startY: number,
  lastX: number,
  lastY: number,
  limit: { top: number, left: number, bottom: number, right: number },
  handle: HTMLElement,
  container: HTMLElement,
  originalTransform: string,
  onMouseDown: (event: MouseEvent) => void,
  onMouseMove: (event: MouseEvent) => void,
  onMouseUp: (event: MouseEvent) => void,
}
const instances: {[key: string]: IDraggable} = {};

const onMouseDown = (event: MouseEvent, instance: IDraggable) => {
  instance.dragging = true;
  instance.startX = event.clientX - instance.lastX;
  instance.startY = event.clientY - instance.lastY;
  document.body.classList.add('draggable-active');
};
const onMouseMove = (event: MouseEvent, instance: IDraggable) => {
  if (instance.dragging) {
    let x = event.clientX - instance.startX;
    let y = event.clientY - instance.startY;

    instance.container.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';
    const bounding: DOMRect = instance.container.getBoundingClientRect();

    if (instance.limit) {
      const top = bounding.top - instance.limit.top;
      if (top < 0) {
        y -= top;
      }
      const left = bounding.left - instance.limit.left;
      if (left < 0) {
        x -= left;
      }
      const bottom = (window.innerHeight - instance.limit.bottom) - bounding.bottom;
      if (bottom < 0) {
        y += bottom;
      }
      const right = (window.innerWidth - instance.limit.right) - bounding.right;
      if (right < 0) {
        x += right;
      }
    }

    instance.container.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';

    instance.lastX = x;
    instance.lastY = y;
  }
};
const onMouseUp = (event: MouseEvent, instance: IDraggable) => {
  instance.dragging = false;
  instance.lastX = event.clientX - instance.startX;
  instance.lastY = event.clientY - instance.startY;
  document.body.classList.remove('draggable-active');
};

const bind = (el: HTMLElement, binding: DirectiveBinding<any>) => {
  if (binding.value) {
    if (!el.id) {
      el.id = Hash.guid();
    }
    let instance = instances[el.id];
    if (!instance) {
      instance = instances[el.id] = {
        dragging: false,
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0,
        limit: binding.value.limit || { top: 15, left: 15, bottom: 15, right: 36 },
        handle: binding.value.handle || el,
        container: binding.value.container || el,
        originalTransform: (binding.value.container || el).style.transform,
        onMouseDown: (event: MouseEvent) => onMouseDown.call(this, event, instance),
        onMouseMove: (event: MouseEvent) => onMouseMove.call(this, event, instance),
        onMouseUp: (event: MouseEvent) => onMouseUp.call(this, event, instance),
      };
      instance.handle.classList.add('draggable-handle');
    }
    binding.value.handle.addEventListener('mousedown', instance.onMouseDown);
    document.addEventListener('mousemove', instance.onMouseMove);
    document.addEventListener('mouseup', instance.onMouseUp);
  }
};

const unbind = (el: any, binding: DirectiveBinding<any>) => {
  const instance = instances[el.id];
  if (binding.value && instance) {
    binding.value.handle.removeEventListener('mousedown', instance.onMouseDown);
    document.removeEventListener('mousemove', instance.onMouseMove);
    document.removeEventListener('mouseup', instance.onMouseUp);
    instance.container.style.transform = instance.originalTransform;
    instance.handle.classList.remove('draggable-handle');
    delete instances[el.id];
  }
};

Vue.directive('draggable', {
  bind,
  unbind,
  update: (el: HTMLElement, binding: DirectiveBinding<any>) => {
    unbind(el, binding);
    bind(el, binding);
  }
});

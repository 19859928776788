<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'tags'"
      title="Tags"
      :headers="headers"
      :service="service"
      index="tag"
      searchable
      hide-presets
      fill-height
      hide-collapsable
    >
      <!-- TAG LABEL -->
      <template v-slot:item.label="{ item }">
        <v-chip
          v-text="item.data.label"
          :color="item.data.color"
          :text-color="item.getTextColor(true)"
          :to="{name: 'TagForm', params: {id: item.data.id}}"
          label
          chips
          link
          small
        >
        </v-chip>
      </template>

      <!-- PROJECTS -->
      <template #item.projectlist="{ item }">
        <span v-if="getAvailableProjects(item).length === 0">All Projects</span>
        <v-chip
          v-for="project of getAvailableProjects(item)"
          v-text="project.getLabel()"
          :key="project.data.id"
          :to="{name: 'ProjectForm', params: {id: project.data.id}}"
          label
          chips
          class="ma-1"
          link
          small
        >
        </v-chip>
      </template>

      <!-- CATEGORIES -->
      <template #item.categorylist="{ item }">
        <span v-if="getAvailableCategories(item).length === 0">-</span>
        <v-chip
          v-for="category of getAvailableCategories(item)"
          v-text="category.data.label"
          :key="category.data.id"
          :to="{name: 'CategoryForm', params: {id: category.data.id}}"
          label
          chips
          class="ma-1"
          link
          small
        >
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import DataTable from '@/modules/common/components/DataTable.vue';
import TagService from '@/services/tag.service';
import ProjectModel from '@/models/project.model';
import NotificationModel from '@/modules/sdk/models/notification.model';
import ProjectService from '@/services/project.service';
import CategoryService from '@/services/category.service'
import CategoryModel from '@/models/category.model'

@Component({
  components: {
    ListView,
  }
})
export default class TagList extends Vue {
  @Ref() readonly datatable!: DataTable

  name = 'TagList';
  service = TagService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'TagForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Tag',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Projects',
      align: 'start',
      value: 'projectlist',
      sortable: false,
      filterable: {
        field: 'recordnode.projectId',
        type: 'distinct',
        service: ProjectService,
      }
    },
    {
      text: 'Categories',
      align: 'start',
      value: 'categorylist',
      sortable: false,
      filterable: {
        field: 'recordnode.categoryId',
        type: 'distinct',
        service: CategoryService,
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  getAvailableProjects(item: NotificationModel): Array<any> {
    return item.data.projectlist.filter((project: ProjectModel) => !project.data.deleted);
  }

  getAvailableCategories(item: NotificationModel): Array<any> {
    return item.data.categorylist.filter((category: CategoryModel) => !category.data.deleted);
  }
}
</script>

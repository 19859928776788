<template>
  <v-sheet
    color="transparent"
    class="d-flex flex-column align-center justify-center text-center fill-height"
    style="max-width: 25rem; margin: 0 auto"
  >
    <v-icon style="font-size: 120px;">mdi-robot-dead</v-icon>
    <h1
      class="display-3"
      v-text="$t('error.title.' + type)"
    >
      Error
    </h1>
    <p
      class="grey--text lighten-5 mt-2"
      v-text="$t('error.description.' + type)"
    >
      Something terribly wrong happened.
    </p>
    <div class="paragraph-text mt-2">
      <v-btn
        v-test-id="'home-page-link'"
        color="primary"
        :to="{name: 'HomeView'}"
      >
        <v-icon left>mdi-home</v-icon>
        <span>Home page</span>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ErrorView extends Vue {

  type = 'unknown';
  typeList = [
    'unknown',
    'notFound',
    'unauthorized',
    'forbidden',
    'fatal',
  ];

  created() {
    if (this.$route.params.type) {
      this.type = this.typeList.includes(this.$route.params.type)
        ? this.$route.params.type
        : 'notFound';
    }
  }

}
</script>

<style>
.paragraph-text {
  font-size: 18px;
}
</style>

<template>
  <ModalDialog
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="uploading"
    title="Upload PDF"
    icon="mdi-file-upload-outline"
    max-width="400"
    persistent
  >
    <template #body>

      <!-- FILE INPUT -->
      <v-file-input
        v-model="file"
        :loading="uploading"
        :disabled="uploading"
        hide-details="auto"
        label="PDF File"
        accept="application/pdf"
        show-size
        chips
        outlined
      />

      <v-divider></v-divider>
    </template>
    <template #buttons>
      <v-btn
        :disabled="uploading"
        :loading="uploading"
        color="success"
        @click="onUploadClick"
      >
        <v-icon left>mdi-upload</v-icon>
        <span v-text="$t('btn.uploadPdf')"></span>
      </v-btn>
      <v-btn
        :disabled="uploading"
        text
        @click="onCloseClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import Logger from '@/modules/sdk/core/logger';
import ArticleService from '@/services/article.service';

const d = new Logger('views/Admin/Form/ProjectForm');

@Component({
  components: {
    ModalDialog,
  }
})
export default class ImportDialog extends Vue {
  @VModel({
    type: Boolean
  }) model!: boolean

  file = null
  uploading = false
  service = new ArticleService()

  onUploadClick(): void {
    if (this.file) {
      this.uploading = true;
      this.service.uploadPdf(this.file)
        .then((response: any) => {
          this.model = false;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.uploading = false);
    }
  }

  onCloseClick() {
    this.model = false;
  }
}
</script>

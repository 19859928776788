<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :search-input.sync="search"
    :items="_items"
    :loading="loading"
    placeholder="Select a project"
    label="Project"
    ref="projectRef"
    item-text="data.label"
    item-value="data.id"
    cache-items
    deletable-chips
    @update:search-input="onSearch"
  >
    <template #item="{ item }">
      <div class="w-100 d-flex align-center justify-space-between" style="gap: 1rem; height: 60px">
        <div>
          <div>{{ item.data.label }}</div>
          <div class="caption text--disabled">Created at: {{ item.data.createdAt }}</div>
        </div>

        <!-- STAGE -->
        <v-chip class="ma-2" label small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectService from '@/services/project.service';
import ProjectModel from '@/models/project.model';
import { stageList, objectiveList, picotList, criteriaList } from '@/enums/global';
// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete/VAutocomplete'

let projectSearchTimeout: any;

@Component({
  components: {
    ModalDialog
  },
})
export default class ProjectAutocomplete extends Vue {

  @Ref() readonly projectRef!: VAutocomplete;
  @Prop({ type: Boolean, default: false }) skipLoading!: boolean;
  @Prop({ type: Boolean, default: false }) autoFocus!: boolean;
  @Prop({ type: Array, default: [] }) filters!: Array<any>;
  @PropSync('items', { default: () => ([]) }) _items!: Array<ProjectModel>;

  search = '';
  loading = false;
  loaded = false;
  stageList = stageList

  onSearch(search: string) {
    clearTimeout(projectSearchTimeout);
    projectSearchTimeout = setTimeout(() => {

      if (this._items.find(item => item.data.label === search)) {
        return;
      }

      this.loading = true;

      let filters = this.filters || [];

      // cache-items will not load the first item for pre-selected project id
      if (this.projectRef && this.projectRef.value) {
        filters.push({
          field: 'id',
          operator: 'equals',
          value: this.projectRef.value
        });
        filters = [filters]; // switch to or
      }

      ProjectService.getInstance().getAll({ search, filters, order: 'createdAt DESC', })
        .then(response => {
          if (!this.loaded && this.projectRef) {
            if (this.autoFocus) {
              this.projectRef.isMenuActive = true;
              this.projectRef.isFocused = true;
            }
            this.loaded = true;
          }
          this.$emit('load', response.data.view.list);
          this._items = response.data.view.list;
        })
        .finally(() => this.loading = false);
    }, 1000);
  }
}
</script>

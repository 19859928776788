<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'devices'"
      title="Devices"
      :hide-new-button="true"
      :hide-presets="true"
      :query="query"
      :headers="headers"
      :service="service"
      index="device"
      icon="mdi-devices"
    >
      <!-- TRUSTED -->
      <template #item.trusted="{item}">
        <v-chip
          :color="item.data.trusted | color('trusted')"
          class="ma-2"
          label
          small
        >
          {{ $t('trusted.' + item.data.trusted) }}
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import UserDeviceService from '@/modules/sdk/services/user-device.service';

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class UserDeviceList extends Vue {
  @Prop({ type: [Number, String] }) readonly userId!: number;
  service = UserDeviceService.getInstance();

  get query(): Array<object> {
    const query = [];
    if (this.userId) {
      query.push({
        field: 'userId',
        operator: 'equals',
        value: this.userId,
      })
    }
    return query;
  }

  headers = [
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Trusted',
      align: 'start',
      sortable: true,
      value: 'trusted',
      filterable: {
        type: 'boolean',
      },
    },
    {
      text: 'Code',
      align: 'start',
      sortable: true,
      value: 'code',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Unique ID',
      align: 'start',
      sortable: true,
      value: 'deviceUid',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'User Agent',
      align: 'start',
      sortable: true,
      value: 'userAgent',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Token',
      align: 'start',
      sortable: true,
      value: 'token',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-progress-linear',{attrs:{"value":_vm.progress,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":16,"width":2}}):_c('span',{staticClass:"grey--text text--lighten-5 text-capitalize text-body-2"},[_vm._v(" "+_vm._s(_vm.stage)+": "+_vm._s(Math.ceil(value * 100) / 100)+"% ")])]}}])}),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading,"value":"100","height":"1"}}),_c('v-sheet',{staticClass:"text-center backgroundDark lighten-1 text-capitalize text-body-2",style:({
      height: '20px',
      position: 'relative',
    }),attrs:{"dark":""}},[_c('span',{style:({
        position: 'absolute',
        width: '100%',
        left: '0',
      })},_vm._l((_vm._percentage),function(progress,progressIdx){return _c('span',{key:progressIdx},[(progressIdx > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('strong',{domProps:{"textContent":_vm._s(progress.key)}}),_vm._v(": "),_c('span',{domProps:{"textContent":_vm._s(progress.percentage)}}),_vm._v("% ")])}),0),_vm._l((_vm.percentage),function(progress,progressIdx){return _c('div',{key:progressIdx,class:progress.color,style:({
        width: progress.percentage + '%',
        height: '100%',
        float: 'left',
      })})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
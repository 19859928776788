import ProjectStatusReasonModel from '@/models/project-status-reason.model'
import Model from '@/modules/sdk/core/model';
import UserModel from '@/modules/sdk/models/user.model';
import ProjectUserModel from '@/models/project-user.model';
import RecordModel from '@/models/record.model';
import SurveyModel from '@/models/survey.model';
import CategoryModel from '@/models/category.model';
import Identity, {NestedArrayOr} from '@/modules/sdk/core/identity';

export default class ProjectModel extends Model {

  default() {
    return {
      label: '',
      type: 'systematic_review',
      status: 'new',
      description: '',
      stage: 'screening',
      pilot: true,
      mainObjective: [],
      specificObjective: [],
      picotPopulation: [],
      picotIntervention: [],
      picotComparator: [],
      picotOutcomes: [],
      picotTiming: [],
      picotSetting: [],
      inclusionCriteria: [],
      exclusionCriteria: [],
      usernode: [],
      userlist: [],
      recordlist: [],
      surveylist: [],
      categorylist: [],
      projectstatusreasonlist: [],
    };
  }

  relatedMap () {
    return {
      usernode: ProjectUserModel,
      userlist: UserModel,
      recordlist: RecordModel,
      surveylist: SurveyModel,
      categorylist: CategoryModel,
      projectstatusreasonlist: ProjectStatusReasonModel,
    }
  }

  relatedDeleteMissing() {
    return {
      usernode: true,
      userlist: false,
      recordlist: false,
      surveylist: true,
      categorylist: true,
      projectstatusreasonlist: true,
    };
  }

  jsonCast(): Array<string> {
    return [
      'mainObjective',
      'specificObjective',
      'picotPopulation',
      'picotIntervention',
      'picotComparator',
      'picotOutcomes',
      'picotTiming',
      'picotSetting',
      'inclusionCriteria',
      'exclusionCriteria',
    ]
  }

  columnCast() {
    return {
      mainObjective: 'array',
      specificObjective: 'array',
      picotPopulation: 'array',
      picotIntervention: 'array',
      picotComparator: 'array',
      picotOutcomes: 'array',
      picotTiming: 'array',
      picotSetting: 'array',
      inclusionCriteria: 'array',
      exclusionCriteria: 'array',
    };
  }

  isPilotOrSystematic(): boolean {
    return this.data.pilot || this.data.type === 'systematic_review';
  }

  getLabel(withStatus = false) {
    let status = '';
    if (withStatus) {
      status = this.data.status;
      status = ' (' + status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() + ')';
    }
    return this.data.label + status;
  }

  hasRole(roleList: NestedArrayOr<string> = [], userId: number = Identity.getIdentity()?.user.id, or = false) {
    const userRoleList = [];
    for (const node of this.data.usernode) {
      if (node.data.userId === userId) {
        userRoleList.push(node.data.type);
      }
    }
    return Identity.has(roleList, userRoleList, or);
  }

  getUserById(id: number): UserModel | null {
    const projectUser = this.data.usernode.find((item: ProjectUserModel) => item.data.userId === id);
    if (!projectUser) {
      return null;
    }
    return projectUser.data.userentity;
  }
}

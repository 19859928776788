<template>
  <div v-bind="$attrs" v-if="filteredSurveyList.length > 0">
    <div :key="survey.data.id" v-for="survey in filteredSurveyList">
      <Survey
        v-if="record"
        :value="survey"
        :answers="answers"
        :record="record"
        :manage-answers="manageAnswers"
        class="pa-0"
        full-width
      />
    </div>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import ProjectModel from '@/models/project.model';
import Survey from '@/components/Survey/Survey.vue';
import SurveyModel from '@/models/survey.model';
import { stageList } from '@/enums/global';
import SurveyGroupModel from '@/models/survey-group.model';

@Component({
  components: {
    Survey
  }
})
export default class SurveyAnswerComponent extends Vue {

  @Prop({
    type: RecordModel
  }) record!: RecordModel;

  @Prop({
    type: ProjectModel,
  }) project!: ProjectModel;

  @Prop({
    type: Boolean,
    default: false,
  }) manageAnswers?: boolean

  @Prop({
    type: String,
    default: null,
  }) stage?: string

  answers = []
  stageList = stageList

  get filteredSurveyList(): Array<SurveyModel> {
    const surveys: Array<SurveyModel> = [];
    this.project.data.surveylist.forEach((survey: SurveyModel) => {
      const groups = survey.data.surveygrouplist.filter((group: SurveyGroupModel) => {
        const currentIndex = stageList.findIndex(item => item.value === this.stage);
        const surveyIndex = stageList.findIndex(item => item.value === group.data.stage);
        return !this.stage || currentIndex >= surveyIndex;
      })
      if (groups.length > 0) {
        survey.data.surveygrouplist = groups;
        surveys.push(survey);
      }
    })
    return surveys;
  }
}
</script>

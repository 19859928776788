<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'keyword'"
      v-model="model"
      :service="service"
      :loading="loading"
      :parent-route="parentRoute"
      name="keyword"
      title="Keyword"
      create-and-new
    >
      <template #form="{ formErrors, rules }">
        <KeywordFormInner
          v-model="model"
          :id="_id"
          :project-id="_projectId"
          :form-errors="formErrors"
          :rules="rules"
          :service="service"
          @loading="val => loading = val"
        />
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import KeywordFormInner from '@/views/Admin/Form/KeywordFormInner.vue';
import KeywordService from '@/services/keyword.service';
import Logger from '@/modules/sdk/core/logger';
import KeywordModel from '@/models/keyword.model';
import { Route } from 'vue-router';

const d = new Logger('views/Admin/Form/KeywordForm');

@Component({
  components: {
    DataForm,
    KeywordFormInner,
  }
})
export default class KeywordForm extends Vue {

  @Prop({ type: Number, default: null }) id!: number
  @Prop({ type: Number, default: null }) projectId!: number

  test = ''
  loading = false
  service = KeywordService.getInstance();
  model: KeywordModel = new KeywordModel();
  parentRoute: Partial<Route> = { name: 'KeywordList' };

  get _id(): number | null {
    return this.id || parseInt(this.$route.params.id);
  }

  get _projectId(): number | null {
    return this.projectId || parseInt(this.$route.params.projectId);
  }

  created() {
    if (this._projectId) {

      // set parent route
      this.parentRoute = { name: 'ProjectForm', params: { id: this._projectId.toString() } }

      // automatically select the projectId
      if (!this.model.data.projectId) {
        this.model.data.projectId = this._projectId;
      }
    }
  }
}
</script>

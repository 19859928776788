import Model from '../core/model';
import UserModel from './user.model';

export default class RoleModel extends Model {

  relatedMap() {
    return {
      userlist: UserModel,
    }
  }

  relatedDeleteMissing() {
    return {
      userlist: true,
    };
  }

}

import TagService from '@/services/tag.service';
import TagModel from '@/models/tag.model';
import ProjectService from '@/services/project.service';
import KeywordService from '@/services/keyword.service';
import KeywordModel from '@/models/keyword.model';
import ProjetModel from '@/models/project.model';

export class SharedQuery {

  static getProject(projectId: number): Promise<ProjetModel> {
    return ProjectService.getInstance().get({
      id: projectId
    }).then(response => response.data.view.single);
  }

  static getProjectKeywords(projectId: number): Promise<Array<KeywordModel>> {
    return KeywordService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        value: projectId,
        operator: 'equals'
      }]
    }).then(response => response.data.view.list);
  }

  static getProjectTagsByCategoryId(
    projectId: number,
    categoryId?: number
  ): Promise<Array<TagModel>> {
    return TagService.getInstance().getAll({ filters: [{
      field: 'recordnode.projectId',
      value: projectId,
      operator: 'equals',
    }, {
      field: 'recordnode.categoryId',
      value: categoryId,
      operator: categoryId ? 'equals' : 'is null',
    }]})
      .then(response => response.data.view.list);
  }

  static getAllProjectTags(
    projectId: number,
    userId?: number,
  ): Promise<Array<TagModel>> {
    const filters = [{
      field: 'recordnode.projectId',
      value: projectId,
      operator: 'equals',
    }];
    if (userId) {
      filters.push({
        field: 'createdBy',
        value: userId,
        operator: 'equals',
      });
    }
    return TagService.getInstance().getAll({ filters })
      .then(response => response.data.view.list);
  }
}

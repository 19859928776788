<template>
  <div>
    <v-container fluid class="pa-4">
      <DataForm
        v-test-id="'user'"
        v-model="model"
        :service="service"
        :loading="loading"
        name="user"
        title="User"
        autocomplete="off"
      >
        <template #form="{ formErrors, rules }">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.email"
                :error-messages="formErrors.email"
                label="Email"
                hide-details="auto"
                outlined
                clearable
                autocomplete="off"
                @input="delete formErrors.email"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="model.data.firstName"
                :error-messages="formErrors.firstName"
                :rules="[rules.required]"
                label="First Name"
                hide-details="auto"
                outlined
                clearable
                required
                @input="delete formErrors.firstName"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="model.data.lastName"
                :error-messages="formErrors.lastName"
                :rules="[rules.required]"
                label="Last Name"
                hide-details="auto"
                outlined
                clearable
                required
                @input="delete formErrors.lastName"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="model.data.rolelist"
                :items="relations.roleList"
                :item-text="(item) => $t('role.' + item.data.index)"
                :error-messages="formErrors.rolelist"
                item-value="data.id"
                label="Roles"
                hide-details="auto"
                outlined
                chips
                clearable
                deletable-chips
                multiple
                return-object
                @input="delete formErrors.rolelist"
              >
                <template v-slot:selection="data">
                  <v-chip
                    class="pl-0"
                    v-bind="data.attrs"
                    label
                    close
                    @click:close="remove(model.data.rolelist, data.item)"
                  >
                    <v-btn
                      class="ml-0"
                      left
                      icon
                      small
                      depressed
                      :to="{name: 'RoleForm', params: {id: data.item.data.id}}"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    {{ $t('role.' + data.item.data.index) }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-alert
            class="mt-8"
            type="warning"
            border="left"
            text
          >
            Keep the fields below empty if you want to keep the same password
          </v-alert>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="model.data.password"
                :error-messages="formErrors.password"
                :type="showPassword ? 'text' : 'password'"
                :rules="[]"
                :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                autocomplete="off"
                prepend-inner-icon="mdi-lock"
                hide-details="auto"
                outlined
                clearable
                required
                @click:append="showPassword = !showPassword"
                @input="() => onInputPassword(formErrors)"
                @click:clear="onPasswordClean"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="model.data.passwordConfirm"
                :error-messages="formErrors.passwordConfirm"
                :type="showPassword ? 'text' : 'password'"
                :rules="[]"
                :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :disabled="!model.data.password"
                autocomplete="off"
                label="Password Confirmation"
                prepend-inner-icon="mdi-lock"
                hide-details="auto"
                outlined
                clearable
                required
                @click:append="showPassword = !showPassword"
                @input="() => onInputPassword(formErrors)"
              />
            </v-col>
          </v-row>
          <PasswordStrengthIndicator
            v-show="model.data.password"
            v-model="model.data.password"
            :confirmation="model.data.passwordConfirm"
            :rules="passwordStrengthRules"
            class="mt-4"
            hide-details
          />
        </template>
      </DataForm>
    </v-container>

    <user-device-list
      v-if="model.data.id"
      :user-id="model.data.id"
      class="mt-n4"
      collapsed
    ></user-device-list>

    <user-ip-list
      v-if="model.data.id"
      :user-id="model.data.id"
      class="mt-n4"
      collapsed
    ></user-ip-list>
  </div>

</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator'
import UserService from '@/modules/sdk/services/user.service';
import RoleService from '@/modules/sdk/services/role.service';
import DataForm from '@/modules/common/components/DataForm.vue';
import PasswordStrengthIndicator from '@/modules/common/components/PasswordStrengthIndicator.vue';
import UserModel from '@/modules/sdk/models/user.model';
import UserDeviceList from '@/modules/common/views/list/UserDeviceList.vue';
import UserIpList from '@/modules/common/views/list/UserIpList.vue';
import RoleModel from '@/modules/sdk/models/role.model'
import Model from '@/modules/sdk/core/model'
import { RawLocation } from 'vue-router/types/router'
import Rules from '@/modules/sdk/core/rules';

@Component({
  components: {
    UserDeviceList,
    UserIpList,
    DataForm,
    PasswordStrengthIndicator,
  }
})
export default class UserForm extends Vue {
  @Prop() id?: number;
  name = 'UserForm';
  service = UserService.getInstance();
  loading = false;
  showPassword = false;
  model: UserModel = new UserModel();
  relations = {
    roleList: []
  };

  get passwordStrengthRules(): Array<{ rule: (password: any) => boolean, text: string }> {
    return this.model.data.password ? [
      { rule: (password: any) => Rules.minLength(password, 8), text: this.$i18n.t('rules.minLength', { min: 8 }).toString() },
      { rule: (password: any) => Rules.hasSpecialChar(password), text: this.$i18n.t('rules.hasSpecialChar').toString() },
      { rule: (password: any) => Rules.hasDigit(password), text: this.$i18n.t('rules.hasDigit').toString() },
      { rule: (password: any) => Rules.identical(password, this.model.data.passwordConfirm), text: this.$i18n.t('rules.passwordConfirmation').toString() },
    ] : [];
  }

  onInputPassword(formErrors: any) {
    delete formErrors.password;
    delete formErrors.passwordConfirm;
  }

  onPasswordClean() {
    this.model.data.password = '';
    this.model.data.passwordConfirm = '';
  }

  remove (list: Array<Model>, item: RoleModel) {
    const index = list.findIndex((role: Model) => role.data.id === item.data.id)
    if (index >= 0) list.splice(index, 1)
  }

  openInNew(route: RawLocation) {
    const routeData = this.$router.resolve(route);
    window.open(routeData.href, '_blank');
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadRelations() {
    RoleService.getInstance().getAll()
      .then(response => this.relations.roleList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.id) {
      this.load(this.id);
    }
    else if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }

    // Load relations
    this.loadRelations();
  }
}
</script>

import Vue, { DirectiveBinding } from 'vue';

/**
 * Empty to allow executing logics without impact
 * in the context of the template rendering. Can
 * be useful for changing the context of a v-for
 */
Vue.directive('context', {
  bind: (el: HTMLElement, binding: DirectiveBinding<any>) => {
    console.log(binding.value);
  }
});

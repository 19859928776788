<template>
  <div>
    <v-responsive :aspect-ratio="containerAspectRatio">
      <img
        v-if="!destroyed"
        id="image"
        :src="src"
        :style="{
          opacity: ready ? 1 : 0.01
        }"
        alt=""
      />
      <v-overlay v-if="!ready" absolute>
        <v-progress-circular :size="128" color="primary" indeterminate />
      </v-overlay>
    </v-responsive>
    <v-toolbar flat color="background">

      <v-btn text @click="fit">
        <v-icon left>mdi-fit-to-page-outline</v-icon>
        <span>Fit</span>
      </v-btn>

      <v-divider vertical inset class="mx-3" />

      <v-btn-toggle v-model="syncedAspectRatio" tile group dense class="d-flex" @change="onAspectRatio">
        <v-btn style="flex: 1" :value="16 / 9">16/9</v-btn>
        <v-btn style="flex: 1" :value="4 / 3">4/3</v-btn>
        <v-btn style="flex: 1" :value="1">1/1</v-btn>
        <v-btn style="flex: 1" :value="NaN">Free</v-btn>
      </v-btn-toggle>

      <v-spacer />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="flip('vertical')">
            <v-icon>mdi-menu-swap-outline</v-icon>
          </v-btn>
        </template>
        <span>Flip vertical</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="flip('horizontal')">
            <v-icon>mdi-menu-swap-outline mdi-rotate-90</v-icon>
          </v-btn>
        </template>
        <span>Flip horizontal</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="ml-3" @click="rotate(-90)">
            <v-icon>mdi-format-rotate-90</v-icon>
          </v-btn>
        </template>
        <span>Rotate left</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="rotate(90)">
            <v-icon>mdi-format-rotate-90 mdi-flip-h</v-icon>
          </v-btn>
        </template>
        <span>Rotate right</span>
      </v-tooltip>

      <v-slider
        v-model="zoomValue"
        :step="10"
        :min="10"
        :max="400"
        ticks
        hide-details
        class="ml-3"
        @input="zoom"
      />
      <div class="text-center" style="min-width: 3rem">{{ zoomValue }}%</div>

      <v-divider vertical inset class="mx-3" />

      <v-btn text @click="reset">
        Reset
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import {Vue, Component, VModel, PropSync, Watch} from 'vue-property-decorator';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

@Component
export default class ImageCropper extends Vue {
  @VModel({ type: String, default: null }) src!: string;
  @PropSync('aspectRatio', { type: Number, default: 16 / 9, }) syncedAspectRatio!: number;

  @Watch('src')
  onSrcChange() {
    this.instance?.destroy();
    this.destroyed = true;
    setTimeout(() => {
      this.destroyed = false;
      setTimeout(() => {
        this.update();
      })
    })
  }

  data = null
  destroyed = false
  ready = false
  containerAspectRatio = 16 / 9;
  instance: Cropper | null = null;
  lastFlipV = 1
  lastFlipH = 1
  zoomValue = 100

  onAspectRatio(aspectRatio: number) {
    this.instance?.setAspectRatio(aspectRatio);
  }

  getData(mimeType: 'image/jpeg' | 'image/png' | 'image/gif' = 'image/jpeg'): string | undefined {
    return this.instance?.getCroppedCanvas().toDataURL(mimeType);
  }

  fit() {
    const data = this.instance?.getCanvasData();
    if (data) {
      this.instance?.setCropBoxData(data);
    }
  }

  rotate(amount: number) {
    this.instance?.rotate(amount);
  }

  flip(direction: 'vertical' | 'horizontal') {
    if (direction === 'horizontal') {
      this.lastFlipH = this.lastFlipH === 1 ? -1 : 1;
    } else {
      this.lastFlipV = this.lastFlipV === 1 ? -1 : 1;
    }
    this.instance?.scale(this.lastFlipH, this.lastFlipV);
  }

  zoom(amount: number) {
    this.instance?.zoomTo(amount / 100);
    this.zoomValue = amount;
  }

  reset() {
    this.lastFlipV = 1;
    this.lastFlipH = 1;
    this.zoomValue = 50;
    this.instance?.reset();
  }

  update() {
    this.ready = false;
    const image = document.getElementById('image') as HTMLImageElement;
    this.instance = new Cropper(image, {
      aspectRatio: this.syncedAspectRatio,
      ready: () => {
        this.zoom(50);
        this.fit();
        this.ready = true;
      }
    });
  }

  mounted() {
    this.update();
  }
}
</script>

<style lang="scss" scoped>
img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <v-container class="pa-4" fluid>

    <TutorialModal
      v-model="currentItemModal.item"
      :visible.sync="currentItemModal.visible"
    />

    <CollapsableCard disabled>
      <template #title>Tutorials</template>
      <template #body>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="search"
            placeholder="Filter items..."
            prepend-inner-icon="mdi-magnify"
            outlined
            clearable
            hide-details
          />
        </v-card-text>
        <v-card-text v-if="loading">
          <v-progress-linear indeterminate color="primary" />
        </v-card-text>
        <div v-else-if="filteredItems.length === 0" class="pa-4 pt-0">
          <v-alert
            class="mb-0"
            type="info"
            border="left"
            text
          >
            No tutorial found based on your search criteria.
          </v-alert>
        </div>
        <v-card-text v-else class="background">
          <v-row dense>
            <v-col
              v-for="item in filteredItems.slice(offset, offset + limit)"
              :key="item.autoIncrementId"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-card class="h-100" @click="onItemClick(item)">
                <v-img v-secure-url:src="item.data.thumbnailfileentity" :aspect-ratio="16/9">
                  <template #placeholder>
                    <v-overlay absolute :opacity="0.1">
                      <v-progress-circular color="primary" size="48" indeterminate />
                    </v-overlay>
                  </template>
                </v-img>
                <v-card-title>{{ item.data.label }}</v-card-title>
                <v-card-subtitle>{{ stripHtml(item.data.content, 100) }}</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="totalPages > 1">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :disabled="filteredItems.length <= limit"
            :total-visible="6"
            @input="value => offset = (value - 1) * limit"
          ></v-pagination>
        </v-card-text>
      </template>
    </CollapsableCard>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import TutorialModal from '@/modules/common/components/TutorialModal.vue';
import Identity from '@/modules/sdk/core/identity';
import VideoLibraryService from '@/modules/sdk/services/video-library.service';
import VideoLibraryModel from '@/modules/sdk/models/video-library.model';

@Component({
  components: {
    CollapsableCard,
    TutorialModal,
  }
})
export default class TutorialView extends Vue {

  loading = false;
  search = ''
  page = 1
  offset = 0
  type: 'dev' | 'general' = 'general'
  items: Array<VideoLibraryModel> = []
  currentItemModal: {
    visible: boolean,
    item: VideoLibraryModel | null,
  } = {
    visible: false,
    item: null,
  }

  @Watch('search')
  onSearchChange() {
    if (this.search === null) {
      this.page = 1;
      this.offset = 0;
    }
    else if (this.page > this.totalPages) {
      this.page = this.totalPages || 1;
      this.offset = (this.page - 1) * this.limit;
    }
  }

  onItemClick(item: VideoLibraryModel) {
    this.openItem(item.data.index);
  }

  get limit(): number {
    return this.$vuetify.breakpoint.smAndDown
      ? 6
      : this.$vuetify.breakpoint.mdAndDown
        ? 9
        : this.$vuetify.breakpoint.lgAndDown
          ? 12
          : 18
  }

  get filteredItems(): Array<VideoLibraryModel> {
    return this.getItemsByType(this.type);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredItems.length / this.limit);
  }

  getItemsByType(type: string): Array<VideoLibraryModel> {
    const items = this.items.filter((item: any) => {
      const keys = ['label', 'content'];
      const keywords = (this.search || '').toLowerCase().trim().split(' ');
      for (let i = 0; i < keywords.length; i++) {
        const keyword = keywords[i];
        const found = keys.find(key => item.data[key].toLowerCase().indexOf(keyword) !== -1);
        if (!found) {
          return false;
        }
      }
      return item.data.category === type;
    })

    return items;
  }

  stripHtml(html: string, limit = 99999) {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return this.$options?.filters?.excerpt(tmp.textContent || tmp.innerText || '', limit);
  }

  openItem(index: string) {
    const item = this.items.find(item => item.data.index === index);
    Object.assign(this.currentItemModal, {
      visible: item !== undefined,
      item,
    });
  }

  load(): Promise<any> {
    this.loading = true;
    return VideoLibraryService.getInstance().getAll({
      order: 'position asc'
    })
      .then(response => this.items = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    this.load().then(() => {
      if (this.$route.params.index) {
        this.openItem(this.$route.params.index);
      }
    })
  }
}
</script>

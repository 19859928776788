import {AxiosError} from 'axios';
import {VueConstructor} from 'vue';
import ZemitVue, {ZemitOptions} from './vue';
import Logger from '@/modules/sdk/core/logger';
import Service, {ZemitData} from '@/modules/sdk/core/service';
import store from 'store2';
import events from 'events';
import deepmerge from 'deepmerge';
import moment from 'moment';
import {SnackError} from '@/modules/common/core/error';

const d = new Logger('zemit/zemit');

export default class Zemit {

  modal: any = {}
  store = store;
  merge = deepmerge;
  moment = moment;
  events = events;
  logger = new Logger('ZEMIT');
  crypt = require('crypto-js');

  // http: Axios;
  // service: Service;

  services: { [key: string]: Service } = {};

  vm?: Vue;

  constructor(
    public vue: VueConstructor,
    public config: Partial<ZemitOptions> = {},
  ) {
    this.config = deepmerge.all([ZemitVue.config, this.config]);
    // this.http = axios.create(config.axiosRequestConfig);
    // this.service = this.getService({name: 'zemit'});
  }

  init(vm: Vue, config: Partial<ZemitOptions> = {}) {
    this.config = deepmerge.all([this.config, config]);
    this.vm = vm;
  }

  handleError(reason: SnackError | AxiosError<ZemitData<any>>, formErrors: { [key: string]: Array<string> } = {}) {
    if (reason instanceof SnackError) {
      this.vm?.$root.$globalSnack.error(reason.config);
    } else {
      if (reason?.response?.data?.view?.messages?.length) {
        const msgs = [];
        for (const message of reason.response.data.view.messages) {
          const finalMessage = this.vm?.$i18n.te('backendError.' + message.message)
            ? this.vm?.$i18n.t('backendError.' + message.message)
            : message.message;
          if (message.field) {
            if (!Array.isArray(message.field)) {
              message.field = [message.field];
            }
            for (const field of message.field) {
              if (!formErrors[field]) {
                formErrors[field] = [];
              }
              formErrors[field].push(finalMessage);
            }
          } else {
            formErrors._ = [message.message];
          }
          const msg = message.code + ': ' + finalMessage + ' (' + message.field.join(', ') + ')';
          msgs.push(msg);
        }
        this.vm?.$root.$globalSnack.error({message: msgs.join('\r\n')});
        d.error(msgs);
      } else {
        const code = reason.response?.data?.code || reason.response?.status || reason.code || 0;
        const msg = (code + ': ' + reason.message || 'Request failed by server') + (reason.response?.data?.status ? ' (' + reason.response.data.status + ')' : '');
        this.vm?.$root.$globalSnack.error({message: msg, icon: 'mdi-robot-dead-outline'});
        formErrors._ = [msg];
        d.error(msg);
      }
    }
  }

  locale(locale: string) {
    if (this.vm?.$root.$i18n.locale) {
      this.vm.$root.$i18n.locale = locale;
    }
  }
}

<template>
  <v-input
    v-model="mergedValues"
    :rules="inputRules"
    hide-details
  >
    <ul style="list-style-type: none" class="pa-0">
      <li v-for="(result, resultIdx) in results" :key="resultIdx">
        <template v-if="result.valid">
          <v-icon color="success" left>mdi-check</v-icon>
          <span class="success--text">{{ result.text }}</span>
        </template>
        <template v-else>
          <v-icon color="error" left>mdi-close</v-icon>
          <span class="error--text">{{ result.text }}</span>
        </template>
      </li>
    </ul>
  </v-input>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

interface IResult {
  valid: boolean,
  text: string,
}

@Component
export default class PasswordStrengthIndicator extends Vue {
  @VModel({ default: null }) password!: string | null
  @Prop({ default: null }) confirmation!: string | null
  @Prop({ default: () => ([]) }) rules!: Array<{ rule: (password: string | null) => boolean, text: string }>

  get mergedValues(): string {
    return (this.password || '') + (this.confirmation || '');
  }

  get inputRules(): Array<any> {
    return [
      () => this.results.every(v => v.valid)
    ]
  }

  get results(): Array<IResult> {
    const results: Array<IResult> = [];
    for (let i = 0; i < this.rules.length; i++) {
      const answer = this.rules[i].rule(this.password);
      results.push({
        valid: answer,
        text: this.rules[i].text,
      })
    }
    return results;
  }
}
</script>

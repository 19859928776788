import Vue from 'vue';
import VueI18n, { I18nOptions } from 'vue-i18n'
import en from '@/locales/en-US.json';

Vue.use(VueI18n);

const messages: any = {
  en,
};

const opts: I18nOptions = {
  locale: 'en',
  fallbackLocale: ['en'],
  formatFallbackMessages: true,
  messages,
};

export default new VueI18n(opts);

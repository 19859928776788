import Vue, {DirectiveBinding, VNode} from 'vue';

const style = document.createElement('style');
style.type = 'text/css';

const applyCallback = (el: HTMLElement, binding: DirectiveBinding<any>, vnode: any) => {

  const columns: Array<{[key: string]: any}> = vnode.componentOptions.propsData.headers;
  const showSelect = (vnode.componentOptions.propsData.showSelect !== undefined && vnode.componentOptions.propsData.showSelect !== false) || false;

  const fixedIndexes: Array<number> = [];
  columns.forEach((column, columnIdx) => {
    if (column.fixed) {
      fixedIndexes.push(columnIdx + (showSelect ? 2 : 1));
    }
  })
  if (showSelect) {
    fixedIndexes.push(1);
  }

  const uniqId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  el.setAttribute('v-fixed-columns', uniqId);

  // Apply fixed cells current background colors
  let css = `[v-fixed-columns="${uniqId}"] tbody > tr:hover > td,
    [v-fixed-columns="${uniqId}"] tbody > tr.v-data-table__selected > td {
      background: inherit !important;
    }`;

  // Make cells sticky
  fixedIndexes.forEach((index, indexIdx) => {
    css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}),
      [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index})`
    if (indexIdx < fixedIndexes.length - 1) {
      css += ',';
    }
  });

  css += `{
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: var(--v-sheet-base);
  }`

  // Make fixed cells appear above non-fixed cells
  fixedIndexes.forEach((index, indexIdx) => {
    css += `[v-fixed-columns="${uniqId}"] > tbody > tr > td:nth-child(${index}),
      [v-fixed-columns="${uniqId}"] > .v-data-table__wrapper > table > thead > tr > th:nth-child(${index})`
    if (indexIdx < fixedIndexes.length - 1) {
      css += ',';
    }
  });
  css += `{
    z-index: 4;
  }`

  // Apply fixed cells left positioning if available
  let left = 0;
  columns.forEach((column, columnIdx) => {
    if (column.fixed) {
      const index = columnIdx + (showSelect ? 2 : 1);
      css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}),
      [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index}) {
        left: ${left + (showSelect ? 64 : 0)}px !important;
      }`
      left += (column.width || 0) + left;
    }
  });
  style.innerHTML = css;
  document.head.appendChild(style);
};

const unbindCallback = (el: HTMLElement, binding: DirectiveBinding<any>) => {
  style.innerHTML = '';
  el.removeAttribute('v-fixed-columns');
  style.remove();
};

Vue.directive('fixed-columns', {
  bind: applyCallback,
  componentUpdated: applyCallback,
  unbind: unbindCallback,
});

import { TranslateResult } from 'vue-i18n';

class SnackErrorConfig {
  title?: string | TranslateResult;
  message?: string | TranslateResult = 'Unexpected Error';
  type?: string = 'error';
  code?: number;
  icon?: string = 'mdi-robot-dead-outline';
  color?: string = 'error';
  timeout?: number = 3000;
}

export class SnackError extends Error {
  public config: SnackErrorConfig;

  constructor(config: Partial<SnackErrorConfig> = {}) {
    const errorConfig = new SnackErrorConfig();
    Object.assign(errorConfig, config);

    // @ts-ignore
    super(errorConfig.message);
    Object.setPrototypeOf(this, SnackError.prototype);

    this.config = errorConfig;
  }
}

export default class zError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, SnackError.prototype);
  }
}

<template>
  <v-card v-if="editable" flat tile>

    <!-- TABS -->
    <v-tabs v-model="tab" grow>
      <v-tab>
        <span v-text="$t('survey.edit')"></span>
      </v-tab>
      <v-tab>
        <span v-text="$t('survey.preview')"></span>
      </v-tab>
    </v-tabs>

    <!-- TABS CONTENT -->
    <v-tabs-items v-model="tab" class="pt-4 overflow-visible">
      <v-tab-item :transition="false">
        <SurveyBuilder
          v-model="value"
          v-bind="$attrs"
          v-on="$listeners"
          :show-templates="showTemplates"
        />
      </v-tab-item>
      <v-tab-item :transition="false">
        <SurveyAnswer
          v-bind="$attrs"
          v-on="$listeners"
          v-model="_answers"
          :survey="value"
          :full-width="fullWidth"
          preview
          class="pa-0 ma-0"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>

  <!-- ANSWERS -->
  <SurveyAnswer
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    v-model="_answers"
    :survey="value"
    :full-width="fullWidth"
  ></SurveyAnswer>
</template>

<script lang="ts">
import 'reflect-metadata';
import { PropType } from 'vue';
import { Vue, Prop, Component } from 'vue-property-decorator';
import SurveyBuilder from '@/components/Survey/SurveyBuilder.vue';
import SurveyAnswer from '@/components/Survey/SurveyAnswer.vue';
import SurveyModel from '@/models/survey.model';
import SurveyAnswerModel from '@/models/survey-answer.model';

export interface ISurveyAnswer {
  autoIncrementId: number,
  data: {
    id: number,
    value: string,
    surveyId: number,
    surveyGroupId: number,
    surveyQuestionId: number,
    surveyChoiceId?: number,
    content: string,
  }
}

export interface ISurveyChoice {
  autoIncrementId: number,
  data: {
    id: number,
    value: string,
    surveyId: number,
    surveyGroupId: number,
    surveyQuestionId: number,
  }
}

export interface ISurveyAiQuestion {
  autoIncrementId: number,
  data: {
    id: number,
    content: string,
  }
}

export interface ISurveyQuestion {
  autoIncrementId: number,
  answer?: any,
  originalAnswer?: any,
  data: {
    id: number,
    value: string,
    type: string,
    required: boolean,
    positiveOnly: boolean,
    negativeOnly: boolean,
    pastOnly: boolean,
    multiple: boolean,
    minLength: number | null,
    maxLength: number | null,
    numberMin: number | null,
    numberMax: number | null,
    decimalMin: number | null,
    decimalMax: number | null,
    surveychoicelist: Array<ISurveyChoice>
    surveyanswerlist: Array<ISurveyAnswer> | ISurveyAnswer
    surveyaiquestionlist: Array<ISurveyAiQuestion>
  }
}

export interface ISurveyGroup {
  autoIncrementId: number,
  data: {
    id: number,
    label: string,
    color: string,
    surveyquestionlist: Array<ISurveyQuestion>
  }
}

export interface ISurvey {
  autoIncrementId: number,
  data: {
    id: number,
    label: string,
    projectId: number,
    surveygrouplist: Array<ISurveyGroup>,
  }
}

@Component({
  components: {
    SurveyBuilder,
    SurveyAnswer
  }
})
export default class Survey extends Vue {
  @Prop({
    type: Object as PropType<SurveyModel>,
    default: () => ({
      data: [],
    }),
  }) value!: SurveyModel

  @Prop({
    type: Array as PropType<Array<SurveyAnswerModel>>,
    default: () => ([]),
  }) answers!: Array<SurveyAnswerModel>

  @Prop({
    type: Boolean,
    default: false,
  }) editable?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) fullWidth?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) showTemplates?: boolean

  tab = null

  get _answers() {
    return this.answers;
  }

  set _answers(values) {
    this.$emit('update:answers', values);
  }
}
</script>

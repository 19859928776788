<template>
  <CollapsableCard>
    <template #title>Dashboard Builder</template>
    <template #body>
      <v-card-text class="background">

        <!-- QUERY BUILDER -->
        <v-card>
          <v-card-title>
            <div class="d-flex align-center flex-wrap justify-space-between w-100" style="gap: 1rem">
              <div class="text-truncate">
                <v-icon left>mdi-magnify</v-icon>
                <span>Advanced Search Builder</span>
              </div>
              <PresetManager
                v-if="model.data.id"
                ref="presetManager"
                :value="filterList"
                :default-item="defaultPresetItem"
                :loading="loadingPresets"
                :saving="savingPreset"
                :default-item-args="{
                  projectId: project.data.id,
                }"
                :load-callback="loadPresets"
                :save-callback="savePresets"
                :remove-callback="removePreset"
                id="visualization_filters"
                label="Presets"
                hide-details="auto"
                outlined
                dense
                clearable
                style="width: 25rem; max-width: 35rem"
                class="mr-4"
                @input="onApplyPresetFilters"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <!-- CONTENT -->
            <ProjectQueryBuilder
              v-model="filterList"
              :project-id="project.data.id"
            />
          </v-card-text>
        </v-card>

        <v-expand-transition>
          <div v-if="loading" class="d-flex align-center justify-center" style="min-height: 30rem">
            <v-progress-circular
              color="primary"
              indeterminate
              size="96"
              width="3"
            />
          </div>
        </v-expand-transition>

        <!-- BUILDER -->
        <dashboard-builder
          v-for="(item, itemIdx) in groupedSyncedItems"
          v-model="item.model"
          :key="item.autoIncrementId"
          :items="syncedItems"
          :filters="filterList"
          :fields="fields"
          :data="data"
          :type-id="project.data.id"
          type="record"
          ref="dashboard_builder"
          class="mt-3"
        >
          <template #bottom_left>
            <v-btn
              :disabled="!canDelete(item.model)"
              :loading="item.model.states.deleting"
              color="error"
              outlined
              @click="onDeleteClick(item.model, itemIdx)"
            >
              {{ item.model.data.id ? 'Delete' : 'Remove' }}
            </v-btn>
          </template>
        </dashboard-builder>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          block
          @click="onAddClick"
        >
          Add new dashboard
        </v-btn>
      </v-card-actions>
    </template>
  </CollapsableCard>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, PropSync } from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import ProjectModel from '@/models/project.model';
import VisualizationService from '@/modules/sdk/services/visualization.service';
import ProjectFilterStateModel from '@/models/project-filter-state.model';
import ProjectFilterStateService from '@/services/project-filter-state.service';
import ProjectQueryBuilder from '@/components/ProjectQueryBuilder.vue';
import DashboardBuilder from '@/modules/common/components/DashboardBuilder.vue';
import Identity from '@/modules/sdk/core/identity';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';

@Component({
  components: {
    DataForm,
    ProjectQueryBuilder,
    DashboardBuilder,
    PresetManager,
    CollapsableCard,
  }
})
export default class ProjectDashboardBuilderForm extends Vue {

  @VModel({ default: () => new VisualizationModel() }) model!: VisualizationModel
  @Prop({ default: () => new ProjectModel() }) project!: ProjectModel
  @PropSync('items', { default: () => ([]) }) syncedItems!: Array<VisualizationModel>

  loading = false
  parsing = true
  data: any = null
  fields: Array<any> = []
  service = VisualizationService.getInstance();

  // Filters and options
  loadingPresets = false;
  savingPreset = false;
  filterList: Array<any> = [];
  defaultPresetItem: Array<any> = [{
    logic: 'and',
    operator: 'contains',
    field: null,
    value: null,
    group: [] as any,
  }];

  canDelete(model: VisualizationModel): boolean {
    return !model.states.deleting
  }

  get groupedSyncedItems(): Array<any> {
    return this.syncedItems.map(model => ({
      model
    }))
  }

  onApplyPresetFilters(preset: Array<any>) {
    this.filterList = preset;
  }

  onAddClick() {
    this.syncedItems.push(new VisualizationModel());
  }

  onDeleteClick(model: VisualizationModel, index: number) {
    const id = model.data.id;
    if (!id) {
      this.syncedItems.splice(index, 1);
      return;
    }

    this.$root.$shouldTakeAction.askDelete({
      onAccept: () => {
        model.states.deleting = true;
        VisualizationService.getInstance().delete({ id })
          .then(() => {
            this.syncedItems.splice(index, 1);
            this.$root.$globalSnack.success({
              message: this.$i18n.t('dataForm.snack.itemDeleted')
            });
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => model.states.deleting = false);
      }
    })
  }

  loadPresets(): Promise<any> {
    return ProjectFilterStateService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.project.data.id,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }
}
</script>

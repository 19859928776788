import Service from '../core/service';
import Model from '../core/model';
import FileModel from '../models/file.model';

export default class FileService extends Service {
  modelUrl = '/file';
  baseUrl = '/api';
  model = Model;

  downloadAsBlob(file: FileModel) {
    const path = [
      file.data.category,
      file.data.id,
      file.data.path,
    ];

    return this.handleRequest('file/download/' + path.join('/'), undefined, {
      method: 'GET',
      responseType: 'blob',
    })
  }

  downloadAsString(file: FileModel) {
    const path = [
      file.data.category,
      file.data.id,
      file.data.path,
    ];

    return this.handleRequest('file/download/' + path.join('/'), undefined, {
      method: 'GET',
    })
  }
}

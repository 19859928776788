<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('askQuestionToAISurveyModal.title')"
    :loading="asking"
    icon="mdi-robot"
  >
    <!-- TABS -->
    <template #header>
      <v-tabs v-model="tab" grow>
        <v-tab>
          <span v-text="$t('askQuestionToAISurveyModal.questions')"></span>
        </v-tab>
        <v-tab>
          <span v-text="$t('askQuestionToAISurveyModal.history')"></span>
        </v-tab>
      </v-tabs>
    </template>
    <template #body>

      <!-- BACKEND ERRORS -->
      <v-alert
        v-for="error in errors"
        :key="error.index"
        class="mt-2 mb-0"
        type="error"
      >
        <span v-text="error.message.content"></span>
      </v-alert>

      <v-tabs-items v-model="tab">

        <!-- QUESTION -->
        <v-tab-item>
          <v-form v-model="formIsValid">

            <!-- IN CONTEXT -->
            <h3 class="mt-6 mb-4" v-text="$t('askQuestionToAISurveyModal.inContext')"></h3>
            <v-textarea
              v-model="_selection"
              :rules="[rules.required]"
              :disabled="asking"
              outlined
              hide-details="auto"
            />

            <!-- PROJECT DATA -->
            <h3 class="mt-6 mb-4">Include the following project data:</h3>
            <v-sheet color="pa-4" rounded outlined>
              <v-treeview
                :items="projectContentList"
                selected-color="primary"
                selection-type="leaf"
                selectable
                return-object
              />
            </v-sheet>

            <!-- AI QUESTION LIST -->
            <template v-if="questions.length > 0">
              <h3 class="mt-6" v-text="$t('askQuestionToAISurveyModal.askQuestion')"></h3>

              <v-radio-group
                v-model="selectedQuestion"
              >
                <div
                  v-for="(question, questionIdx) in questions"
                  :key="question.autoIncrementId"
                >
                  <v-text-field
                    v-model="question.data.content"
                    :disabled="asking"
                    :rules="selectedQuestion === questionIdx ? [rules.required] : []"
                    :class="{
                      'mt-3': questionIdx > 0
                    }"
                    outlined
                    hide-details="auto"
                  >
                    <template #prepend-inner>
                      <v-radio
                        :value="questionIdx"
                        :disabled="asking"
                        hide-details
                      />
                    </template>
                  </v-text-field>
                </div>
              </v-radio-group>
            </template>
            <template v-else>
              <h3 class="mt-6 mb-3" v-text="$t('askQuestionToAISurveyModal.askCustomQuestion')"></h3>

              <v-text-field
                v-model="customQuestion"
                :disabled="asking"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
              />
            </template>
          </v-form>
        </v-tab-item>

        <!-- HISTORY -->
        <v-tab-item>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="history"
            :sort-by="['data.createdAt']"
            :sort-desc="[true]"
            show-select
            single-select
            item-key="data.id"
            checkbox-color="primary"
            class="mt-3"
          >
            <template #item.data.question="{ item }">
              <div
                v-text="item.data.question"
                style="max-height: 5rem"
                class="overflow-auto my-4"
              />
            </template>
            <template #item.data.answer="{ item }">
              <div
                v-text="item.data.answer"
                style="max-height: 5rem"
                class="overflow-auto"
              />
            </template>
            <template #item.data.confidence="{ item }">
              {{ item.data.confidence }}%
            </template>
            <template #item.data.rating="{ item }">
              <v-rating
                :value="parseInt(item.data.rating)"
                color="warning"
                background-color="warning"
                large
                @input="onRatingChange(item)"
              ></v-rating>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template #buttons>
      <v-btn
        v-if="tab === 0"
        large
        color="primary"
        :loading="asking"
        :disabled="!canAsk"
        @click="onAskClick"
      >
        <span v-text="$t('btn.askAi')"></span>
      </v-btn>
      <v-btn
        v-if="tab === 1"
        large
        color="primary"
        :disabled="!canApply"
        @click="onApplyAnswer"
      >
        <span v-text="$t('btn.apply')"></span>
      </v-btn>
      <v-btn
        large
        text
        :disabled="asking"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, PropSync, Watch } from 'vue-property-decorator';
import { objectiveList, picotList, criteriaList } from '@/enums/global'
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import SurveyAiQuestionModel from '@/models/survey-ai-question.model';
import Rules from '@/modules/sdk/core/rules';
import SurveyQuestionModel from '@/models/survey-question.model';
import AiService from '@/services/ai.service';
import SurveyAiAnswerService from '@/services/survey-ai-answer.service';
import SurveyAiAnswerModel from '@/models/survey-ai-answer.model';

@Component({
  components: {
    ModalDialog,
  }
})
export default class askQuestionToAISurveyModal extends Vue {

  @VModel({ type: Boolean }) visible!: boolean
  @PropSync('question', { type: SurveyQuestionModel }) _question!: SurveyQuestionModel
  @PropSync('selection', { type: String }) _selection!: string | null

  rules: any = {}
  errors: any = {}
  tab = 0
  customQuestion = ''
  selected: Array<any> = []
  selectedQuestion = 0
  loading = false
  asking = false
  formIsValid = false
  objectiveList = objectiveList;
  picotList = picotList;
  criteriaList = criteriaList;
  history: Array<any> = []
  service = AiService.getInstance()

  @Watch('visible')
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.tab = 0;
      this.selectedQuestion = 0;
      this.selected = [];
      this.asking = false;
      this.errors = {};
    }
  }

  @Watch('question.data.id', {
    immediate: true
  })
  onQuestionIdChanged(id: number) {
    if (id) {
      this.loadHistory(id);
    }
  }

  get projectContentList(): Array<any> {
    return [
      {
        id: 'objectives',
        name: 'Objectives',
      },
      {
        id: 'picots',
        name: 'PICOTS',
      },
      {
        id: 'criteria',
        name: 'Selection Criteria',
      },
      {
        id: 'abstract',
        name: 'Abstract',
      },
      {
        id: 'full-text',
        name: 'Full-Text',
      },
    ];
  }

  get headers(): Array<any> {
    return [{
      class: 'text-no-wrap',
      cellClass: 'text-no-wrap',
      width: 0,
      text: this.$i18n.t('askQuestionToAISurveyModal.headers.date'),
      value: 'data.createdAt',
    }, {
      class: 'text-no-wrap',
      text: this.$i18n.t('askQuestionToAISurveyModal.headers.question'),
      value: 'data.question',
      width: '25%',
    }, {
      class: 'text-no-wrap',
      text: this.$i18n.t('askQuestionToAISurveyModal.headers.answer'),
      value: 'data.answer',
      width: '33%',
    }, {
      class: 'text-no-wrap',
      width: 0,
      text: this.$i18n.t('askQuestionToAISurveyModal.headers.confidence'),
      value: 'data.confidence',
    }, {
      class: 'text-no-wrap',
      width: 0,
      text: this.$i18n.t('askQuestionToAISurveyModal.headers.rating'),
      value: 'data.rating',
    }]
  }

  get questions(): Array<SurveyAiQuestionModel> {
    return this._question.data.surveyaiquestionlist;
  }

  get canAsk(): boolean {
    return this.selectedQuestion >= 0
      && !this.asking
      && this.formIsValid
      && this.tab === 0;
  }

  get canApply(): boolean {
    return this.selected.length > 0
      && this.tab === 1;
  }

  onAskClick(): void {
    this.asking = true;
    this.service.getRecordAnswers({
      recordId: this._question.data.recordId,
      projectId: this._question.data.projectId,
      surveyId: this._question.data.surveyId,
      surveyGroupId: this._question.data.surveyGroupId,
      surveyQuestionId: this._question.data.id,
      contextContent: this._selection,
      questionContent: this.questions.length > 0
        ? this.questions[this.selectedQuestion].data.content
        : this.customQuestion,
    }).then(response => {
      this.asking = false;
      if (response.data.view.list) {
        this.errors = {};
        this.tab = 1;
        const answers = response.data.view.list.map((item: any) => new SurveyAiAnswerModel(item.data.entity));
        this.history.push(...answers);
        this.$emit('answer', answers);
      } else {
        this.errors = JSON.parse(response.data.view.response.entity.choices);
      }
    });
  }

  onRatingChange(answer: SurveyAiAnswerModel): void {
    answer.states.saving = true;
    SurveyAiAnswerService.getInstance().save(answer)
      .then(response => answer.assign(response.data.view.single))
      .finally(() => answer.states.saving = false);
  }

  onApplyAnswer(): void {
    if (this.selected.length > 0) {
      this._question.answer = this.selected[0].data.answer;
      this.visible = false;
    }
  }

  onCancelClick(): void {
    this.visible = false;
  }

  loadHistory(questionId: number): Promise<any> {
    this.loading = true;
    return SurveyAiAnswerService.getInstance().getAll({
      filters: [{
        field: 'surveyQuestionId',
        operator: 'equals',
        value: questionId
      }]
    })
      .then(response => this.history = response.data.view.list)
      .finally(() => this.loading = false);
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>

<template>
  <ModalDialog
    v-model="visible"
    title="Latest Updates"
    icon="mdi-newspaper"
    background-color="background"
    max-width="600"
    scrollable
  >
    <template #body>
      <v-expansion-panels v-model="panels" class="mt-6" multiple>
        <v-expansion-panel v-for="log in newLogs" :key="log.timestamp">
          <v-expansion-panel-header class="font-weight-bold">
            {{ toDate(log.timestamp) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li v-for="(item, itemIdx) in log.items" :key="itemIdx">
                {{ item}}
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <template #buttons>
      <v-btn
        large
        outlined
        @click="visible = false"
      >
        Close
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Watch } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import moment from 'moment';

export interface IChangeLog {
  timestamp: number,
  items: string[],
}

@Component({
  components: {
    ModalDialog,
  }
})
export default class ChangeLogModal extends Vue {
  @VModel({ type: Array, default: () => ([]) }) logs!: IChangeLog[]

  @Watch('visible')
  onVisibleChange(visible: boolean) {
    if (!visible) {
      localStorage.setItem(this.key, this.newLogs[0].timestamp.toString());
    }
  }

  visible = false
  panels: number[] = [0]
  key = 'changelog_last_ts'
  oldLogs: IChangeLog[] = []
  newLogs: IChangeLog[] = []

  toDate(timestamp: number) {
    return moment(timestamp).format('YYYY-MM-DD');
  }

  created() {
    if (this.logs.length > 0) {
      const previousTimestamp = parseInt(localStorage.getItem(this.key) || new Date().getTime().toString());
      this.logs.sort((a, b) => b.timestamp - a.timestamp);
      this.logs.forEach(log => {
        if (previousTimestamp >= log.timestamp) {
          this.oldLogs.push(log);
        } else {
          this.newLogs.push(log);
        }
      })
      if (this.newLogs.length > 0) {
        this.visible = true;
      }
    }
  }
}
</script>

<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'ai-prompt'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="ai-prompt"
      title="AI Prompt"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="model.data.action"
              :items="promptActionList"
              :rules="[rules.required]"
              outlined
              label="Action"
              hide-details="auto"
              cache-items
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="model.data.title"
              :error-messages="formErrors.title"
              label="Title"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
              required
              @input="formErrors = {}"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="model.data.content"
              :error-messages="formErrors.content"
              label="Content"
              :rules="[]"
              hide-details="auto"
              outlined
              clearable
              required
              rows="25"
              row-height="20"
              @input="formErrors = {}"
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import Logger from '@/modules/sdk/core/logger';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import {debounce} from 'debounce';
import TagModel from '@/models/tag.model';
import AiPromptService from '@/services/ai-prompt.service';

const d = new Logger('views/Admin/Form/TagForm');

@Component({
  components: {
    Wysiwyg,
    DataForm,
  }
})
export default class AiPromptForm extends Vue {

  service = AiPromptService.getInstance();
  loading = false;
  model: TagModel = new TagModel();

  promptActionList = [
    {
      text: 'Default',
      value: 'default'
    },
    {
      text: 'Synonym',
      value: 'synonym'
    },
    {
      text: 'Record Review',
      value: 'record_review'
    },
    {
      text: 'Record Answer',
      value: 'record_answer'
    },
  ];

  recordList: Array<RecordModel> = [];
  recordListSearch = '';
  recordListLoading = false;

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadRecord(search: string = this.recordListSearch) {
    this.loadRecordDebounce.clear();
    const order = 'title';
    const advanced = {};
    this.recordListLoading = true;
    RecordService.getInstance().getAll({search, order, advanced})
      .then(response => this.recordList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.recordListLoading = false);
  }

  loadRecordDebounce = debounce(() => {
    this.loadRecord();
  }, 333);

  @Watch('recordListSearch')
  onRecordActiveRecordSearch(search: string) {
    this.loadRecordDebounce()
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>

<template>
  <v-snackbars :objects.sync="snackbars">
    <template #default="{ index, message }">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbars[index].icon }}
        </v-icon>
        <v-layout column>
          <div v-if="snackbars[index].title">
            <strong>
              {{ snackbars[index].title }}
            </strong>
          </div>
          <div v-if="snackbars[index].text">
            {{ snackbars[index].text }}
          </div>
          <div v-if="message">
            {{ message }}
          </div>
        </v-layout>
      </v-layout>
    </template>
    <template #action="{ close, index }">
      <v-btn
        icon
        @click="close()"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </template>
  </v-snackbars>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import VSnackbars from 'v-snackbars';

@Component({
  components: {
    'v-snackbars': VSnackbars
  }
})
export default class GlobalSnack extends Vue {
  snackbars: Array<any> = [];

  default = {
    name: 'GlobalSnack',
    message: '',
    color: 'success',
    icon: 'mdi-check',
    timeout: 6000,
    top: true,
  };

  show(data: any, defaultSnack = this.default) {
    const object = structuredClone(defaultSnack);
    Object.assign(object, data);
    if (!this.snackbars.find(snack => snack.message === data.message)) {
      this.snackbars.push(object);
    }
  }

  success(data: any) {
    const object = structuredClone(this.default);
    object.color = 'success';
    object.icon = 'mdi-check';
    this.show(data, object);
  }

  info(data: any) {
    const object = structuredClone(this.default);
    object.color = 'info';
    object.icon = 'mdi-information-outline';
    this.show(data, object);
  }

  warning(data: any) {
    const object = structuredClone(this.default);
    object.color = 'warning';
    object.icon = 'mdi-message-alert-outline';
    this.show(data, object);
  }

  error(data: any) {
    const object = structuredClone(this.default);
    object.color = 'error';
    object.icon = 'mdi-alert-circle-outline';
    this.show(data, object);
  }

  mounted() {
    this.$root.$globalSnack = this;
  }
}
</script>

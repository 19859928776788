<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="iconList"
  >
    <template #selection="{ item }">
      <v-icon left>mdi-{{ item }}</v-icon>
      mdi-{{ item }}
    </template>
    <template #item="{ item }">
      <v-icon left>mdi-{{ item }}</v-icon>
      mdi-{{ item }}
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class IconAutocomplete extends Vue {

  iconList = [
    'access-point',
    'access-point-network',
    'access-point-network-off',
    'account',
    'account-alert',
    'account-alert-outline',
    'account-arrow-left',
    'account-arrow-left-outline',
    'account-arrow-right',
    'account-arrow-right-outline',
    'account-badge',
    'account-badge-alert',
    'account-badge-alert-outline',
    'account-badge-horizontal',
    'account-badge-horizontal-outline',
    'account-badge-outline',
    'account-box',
    'account-box-multiple',
    'account-box-outline',
    'account-card-details',
    'account-card-details-outline',
    'account-check',
    'account-check-outline',
    'account-child',
    'account-child-circle',
    'account-circle',
    'account-circle-outline',
    'account-clock',
    'account-clock-outline',
    'account-convert',
    'account-details',
    'account-edit',
    'account-group',
    'account-group-outline',
    'account-heart',
    'account-heart-outline',
    'account-key',
    'account-key-outline',
    'account-minus',
    'account-minus-outline',
    'account-multiple',
    'account-multiple-check',
    'account-multiple-minus',
    'account-multiple-minus-outline',
    'account-multiple-outline',
    'account-multiple-plus',
    'account-multiple-plus-outline',
    'account-network',
    'account-network-outline',
    'account-off',
    'account-off-outline',
    'account-outline',
    'account-plus',
    'account-plus-outline',
    'account-question',
    'account-question-outline',
    'account-remove',
    'account-remove-outline',
    'account-search',
    'account-search-outline',
    'account-settings',
    'account-star',
    'account-star-outline',
    'account-supervisor',
    'account-supervisor-circle',
    'account-switch',
    'account-tie',
    'accusoft',
    'adchoices',
    'adjust',
    'adobe',
    'air-conditioner',
    'air-filter',
    'air-horn',
    'air-purifier',
    'airbag',
    'airballoon',
    'airplane',
    'airplane-landing',
    'airplane-off',
    'airplane-takeoff',
    'airplay',
    'airport',
    'alarm',
    'alarm-bell',
    'alarm-check',
    'alarm-light',
    'alarm-light-outline',
    'alarm-multiple',
    'alarm-note',
    'alarm-note-off',
    'alarm-off',
    'alarm-plus',
    'alarm-snooze',
    'album',
    'alert',
    'alert-box',
    'alert-box-outline',
    'alert-circle',
    'alert-circle-outline',
    'alert-decagram',
    'alert-decagram-outline',
    'alert-octagon',
    'alert-octagon-outline',
    'alert-octagram',
    'alert-octagram-outline',
    'alert-outline',
    'alien',
    'all-inclusive',
    'alpha',
    'alpha-a',
    'alpha-a-box',
    'alpha-a-box-outline',
    'alpha-a-circle',
    'alpha-a-circle-outline',
    'alpha-b',
    'alpha-b-box',
    'alpha-b-box-outline',
    'alpha-b-circle',
    'alpha-b-circle-outline',
    'alpha-c',
    'alpha-c-box',
    'alpha-c-box-outline',
    'alpha-c-circle',
    'alpha-c-circle-outline',
    'alpha-d',
    'alpha-d-box',
    'alpha-d-box-outline',
    'alpha-d-circle',
    'alpha-d-circle-outline',
    'alpha-e',
    'alpha-e-box',
    'alpha-e-box-outline',
    'alpha-e-circle',
    'alpha-e-circle-outline',
    'alpha-f',
    'alpha-f-box',
    'alpha-f-box-outline',
    'alpha-f-circle',
    'alpha-f-circle-outline',
    'alpha-g',
    'alpha-g-box',
    'alpha-g-box-outline',
    'alpha-g-circle',
    'alpha-g-circle-outline',
    'alpha-h',
    'alpha-h-box',
    'alpha-h-box-outline',
    'alpha-h-circle',
    'alpha-h-circle-outline',
    'alpha-i',
    'alpha-i-box',
    'alpha-i-box-outline',
    'alpha-i-circle',
    'alpha-i-circle-outline',
    'alpha-j',
    'alpha-j-box',
    'alpha-j-box-outline',
    'alpha-j-circle',
    'alpha-j-circle-outline',
    'alpha-k',
    'alpha-k-box',
    'alpha-k-box-outline',
    'alpha-k-circle',
    'alpha-k-circle-outline',
    'alpha-l',
    'alpha-l-box',
    'alpha-l-box-outline',
    'alpha-l-circle',
    'alpha-l-circle-outline',
    'alpha-m',
    'alpha-m-box',
    'alpha-m-box-outline',
    'alpha-m-circle',
    'alpha-m-circle-outline',
    'alpha-n',
    'alpha-n-box',
    'alpha-n-box-outline',
    'alpha-n-circle',
    'alpha-n-circle-outline',
    'alpha-o',
    'alpha-o-box',
    'alpha-o-box-outline',
    'alpha-o-circle',
    'alpha-o-circle-outline',
    'alpha-p',
    'alpha-p-box',
    'alpha-p-box-outline',
    'alpha-p-circle',
    'alpha-p-circle-outline',
    'alpha-q',
    'alpha-q-box',
    'alpha-q-box-outline',
    'alpha-q-circle',
    'alpha-q-circle-outline',
    'alpha-r',
    'alpha-r-box',
    'alpha-r-box-outline',
    'alpha-r-circle',
    'alpha-r-circle-outline',
    'alpha-s',
    'alpha-s-box',
    'alpha-s-box-outline',
    'alpha-s-circle',
    'alpha-s-circle-outline',
    'alpha-t',
    'alpha-t-box',
    'alpha-t-box-outline',
    'alpha-t-circle',
    'alpha-t-circle-outline',
    'alpha-u',
    'alpha-u-box',
    'alpha-u-box-outline',
    'alpha-u-circle',
    'alpha-u-circle-outline',
    'alpha-v',
    'alpha-v-box',
    'alpha-v-box-outline',
    'alpha-v-circle',
    'alpha-v-circle-outline',
    'alpha-w',
    'alpha-w-box',
    'alpha-w-box-outline',
    'alpha-w-circle',
    'alpha-w-circle-outline',
    'alpha-x',
    'alpha-x-box',
    'alpha-x-box-outline',
    'alpha-x-circle',
    'alpha-x-circle-outline',
    'alpha-y',
    'alpha-y-box',
    'alpha-y-box-outline',
    'alpha-y-circle',
    'alpha-y-circle-outline',
    'alpha-z',
    'alpha-z-box',
    'alpha-z-box-outline',
    'alpha-z-circle',
    'alpha-z-circle-outline',
    'alphabetical',
    'altimeter',
    'amazon',
    'amazon-alexa',
    'amazon-drive',
    'ambulance',
    'ammunition',
    'ampersand',
    'amplifier',
    'anchor',
    'android',
    'android-auto',
    'android-debug-bridge',
    'android-head',
    'android-messages',
    'android-studio',
    'angle-acute',
    'angle-obtuse',
    'angle-right',
    'angular',
    'angularjs',
    'animation',
    'animation-outline',
    'animation-play',
    'animation-play-outline',
    'anvil',
    'apple',
    'apple-finder',
    'apple-icloud',
    'apple-ios',
    'apple-keyboard-caps',
    'apple-keyboard-command',
    'apple-keyboard-control',
    'apple-keyboard-option',
    'apple-keyboard-shift',
    'apple-safari',
    'application',
    'application-export',
    'application-import',
    'apps',
    'apps-box',
    'arch',
    'archive',
    'arrange-bring-forward',
    'arrange-bring-to-front',
    'arrange-send-backward',
    'arrange-send-to-back',
    'arrow-all',
    'arrow-bottom-left',
    'arrow-bottom-left-bold-outline',
    'arrow-bottom-left-thick',
    'arrow-bottom-right',
    'arrow-bottom-right-bold-outline',
    'arrow-bottom-right-thick',
    'arrow-collapse',
    'arrow-collapse-all',
    'arrow-collapse-down',
    'arrow-collapse-horizontal',
    'arrow-collapse-left',
    'arrow-collapse-right',
    'arrow-collapse-up',
    'arrow-collapse-vertical',
    'arrow-decision',
    'arrow-decision-auto',
    'arrow-decision-auto-outline',
    'arrow-decision-outline',
    'arrow-down',
    'arrow-down-bold',
    'arrow-down-bold-box',
    'arrow-down-bold-box-outline',
    'arrow-down-bold-circle',
    'arrow-down-bold-circle-outline',
    'arrow-down-bold-hexagon-outline',
    'arrow-down-bold-outline',
    'arrow-down-box',
    'arrow-down-circle',
    'arrow-down-circle-outline',
    'arrow-down-drop-circle',
    'arrow-down-drop-circle-outline',
    'arrow-down-thick',
    'arrow-expand',
    'arrow-expand-all',
    'arrow-expand-down',
    'arrow-expand-horizontal',
    'arrow-expand-left',
    'arrow-expand-right',
    'arrow-expand-up',
    'arrow-expand-vertical',
    'arrow-left',
    'arrow-left-bold',
    'arrow-left-bold-box',
    'arrow-left-bold-box-outline',
    'arrow-left-bold-circle',
    'arrow-left-bold-circle-outline',
    'arrow-left-bold-hexagon-outline',
    'arrow-left-bold-outline',
    'arrow-left-box',
    'arrow-left-circle',
    'arrow-left-circle-outline',
    'arrow-left-drop-circle',
    'arrow-left-drop-circle-outline',
    'arrow-left-right',
    'arrow-left-right-bold',
    'arrow-left-right-bold-outline',
    'arrow-left-thick',
    'arrow-right',
    'arrow-right-bold',
    'arrow-right-bold-box',
    'arrow-right-bold-box-outline',
    'arrow-right-bold-circle',
    'arrow-right-bold-circle-outline',
    'arrow-right-bold-hexagon-outline',
    'arrow-right-bold-outline',
    'arrow-right-box',
    'arrow-right-circle',
    'arrow-right-circle-outline',
    'arrow-right-drop-circle',
    'arrow-right-drop-circle-outline',
    'arrow-right-thick',
    'arrow-split-horizontal',
    'arrow-split-vertical',
    'arrow-top-left',
    'arrow-top-left-bold-outline',
    'arrow-top-left-bottom-right',
    'arrow-top-left-bottom-right-bold',
    'arrow-top-left-thick',
    'arrow-top-right',
    'arrow-top-right-bold-outline',
    'arrow-top-right-bottom-left',
    'arrow-top-right-bottom-left-bold',
    'arrow-top-right-thick',
    'arrow-up',
    'arrow-up-bold',
    'arrow-up-bold-box',
    'arrow-up-bold-box-outline',
    'arrow-up-bold-circle',
    'arrow-up-bold-circle-outline',
    'arrow-up-bold-hexagon-outline',
    'arrow-up-bold-outline',
    'arrow-up-box',
    'arrow-up-circle',
    'arrow-up-circle-outline',
    'arrow-up-down',
    'arrow-up-down-bold',
    'arrow-up-down-bold-outline',
    'arrow-up-drop-circle',
    'arrow-up-drop-circle-outline',
    'arrow-up-thick',
    'artist',
    'artist-outline',
    'artstation',
    'aspect-ratio',
    'assistant',
    'asterisk',
    'at',
    'atlassian',
    'atm',
    'atom',
    'atom-variant',
    'attachment',
    'audio-video',
    'audiobook',
    'augmented-reality',
    'auto-fix',
    'auto-upload',
    'autorenew',
    'av-timer',
    'aws',
    'axe',
    'axis',
    'axis-arrow',
    'axis-arrow-lock',
    'axis-lock',
    'axis-x-arrow',
    'axis-x-arrow-lock',
    'axis-x-rotate-clockwise',
    'axis-x-rotate-counterclockwise',
    'axis-x-y-arrow-lock',
    'axis-y-arrow',
    'axis-y-arrow-lock',
    'axis-y-rotate-clockwise',
    'axis-y-rotate-counterclockwise',
    'axis-z-arrow',
    'axis-z-arrow-lock',
    'axis-z-rotate-clockwise',
    'axis-z-rotate-counterclockwise',
    'azure',
    'babel',
    'baby',
    'baby-bottle',
    'baby-bottle-outline',
    'baby-buggy',
    'baby-face',
    'baby-face-outline',
    'backburger',
    'backspace',
    'backspace-outline',
    'backspace-reverse',
    'backspace-reverse-outline',
    'backup-restore',
    'bacteria',
    'bacteria-outline',
    'badminton',
    'bag-carry-on',
    'bag-carry-on-check',
    'bag-carry-on-off',
    'bag-checked',
    'bag-personal',
    'bag-personal-off',
    'bag-personal-off-outline',
    'bag-personal-outline',
    'baguette',
    'balloon',
    'ballot',
    'ballot-outline',
    'ballot-recount',
    'ballot-recount-outline',
    'bandage',
    'bandcamp',
    'bank',
    'bank-minus',
    'bank-outline',
    'bank-plus',
    'bank-remove',
    'bank-transfer',
    'bank-transfer-in',
    'bank-transfer-out',
    'barcode',
    'barcode-scan',
    'barley',
    'barley-off',
    'barn',
    'barrel',
    'baseball',
    'baseball-bat',
    'basecamp',
    'basket',
    'basket-fill',
    'basket-unfill',
    'basketball',
    'basketball-hoop',
    'basketball-hoop-outline',
    'bat',
    'battery',
    'battery-10',
    'battery-10-bluetooth',
    'battery-20',
    'battery-20-bluetooth',
    'battery-30',
    'battery-30-bluetooth',
    'battery-40',
    'battery-40-bluetooth',
    'battery-50',
    'battery-50-bluetooth',
    'battery-60',
    'battery-60-bluetooth',
    'battery-70',
    'battery-70-bluetooth',
    'battery-80',
    'battery-80-bluetooth',
    'battery-90',
    'battery-90-bluetooth',
    'battery-alert',
    'battery-alert-bluetooth',
    'battery-bluetooth',
    'battery-bluetooth-variant',
    'battery-charging',
    'battery-charging-10',
    'battery-charging-100',
    'battery-charging-20',
    'battery-charging-30',
    'battery-charging-40',
    'battery-charging-50',
    'battery-charging-60',
    'battery-charging-70',
    'battery-charging-80',
    'battery-charging-90',
    'battery-charging-outline',
    'battery-charging-wireless',
    'battery-charging-wireless-10',
    'battery-charging-wireless-20',
    'battery-charging-wireless-30',
    'battery-charging-wireless-40',
    'battery-charging-wireless-50',
    'battery-charging-wireless-60',
    'battery-charging-wireless-70',
    'battery-charging-wireless-80',
    'battery-charging-wireless-90',
    'battery-charging-wireless-alert',
    'battery-charging-wireless-outline',
    'battery-minus',
    'battery-negative',
    'battery-outline',
    'battery-plus',
    'battery-positive',
    'battery-unknown',
    'battery-unknown-bluetooth',
    'battlenet',
    'beach',
    'beaker',
    'beaker-outline',
    'beats',
    'bed-empty',
    'beer',
    'behance',
    'bell',
    'bell-alert',
    'bell-alert-outline',
    'bell-circle',
    'bell-circle-outline',
    'bell-off',
    'bell-off-outline',
    'bell-outline',
    'bell-plus',
    'bell-plus-outline',
    'bell-ring',
    'bell-ring-outline',
    'bell-sleep',
    'bell-sleep-outline',
    'beta',
    'betamax',
    'biathlon',
    'bible',
    'bike',
    'billiards',
    'billiards-rack',
    'bing',
    'binoculars',
    'bio',
    'biohazard',
    'bitbucket',
    'bitcoin',
    'black-mesa',
    'blackberry',
    'blender',
    'blender-software',
    'blinds',
    'block-helper',
    'blogger',
    'blood-bag',
    'bluetooth',
    'bluetooth-audio',
    'bluetooth-connect',
    'bluetooth-off',
    'bluetooth-settings',
    'bluetooth-transfer',
    'blur',
    'blur-linear',
    'blur-off',
    'blur-radial',
    'bolnisi-cross',
    'bolt',
    'bomb',
    'bomb-off',
    'bone',
    'book',
    'book-lock',
    'book-lock-open',
    'book-minus',
    'book-minus-multiple',
    'book-multiple',
    'book-open',
    'book-open-outline',
    'book-open-page-variant',
    'book-open-variant',
    'book-outline',
    'book-play',
    'book-play-outline',
    'book-plus',
    'book-plus-multiple',
    'book-remove',
    'book-remove-multiple',
    'book-search',
    'book-search-outline',
    'book-variant',
    'book-variant-multiple',
    'bookmark',
    'bookmark-check',
    'bookmark-minus',
    'bookmark-minus-outline',
    'bookmark-multiple',
    'bookmark-multiple-outline',
    'bookmark-music',
    'bookmark-off',
    'bookmark-off-outline',
    'bookmark-outline',
    'bookmark-plus',
    'bookmark-plus-outline',
    'bookmark-remove',
    'boom-gate',
    'boom-gate-alert',
    'boom-gate-alert-outline',
    'boom-gate-down',
    'boom-gate-down-outline',
    'boom-gate-outline',
    'boom-gate-up',
    'boom-gate-up-outline',
    'boombox',
    'bootstrap',
    'border-all',
    'border-all-variant',
    'border-bottom',
    'border-bottom-variant',
    'border-color',
    'border-horizontal',
    'border-inside',
    'border-left',
    'border-left-variant',
    'border-none',
    'border-none-variant',
    'border-outside',
    'border-right',
    'border-right-variant',
    'border-style',
    'border-top',
    'border-top-variant',
    'border-vertical',
    'bottle-wine',
    'bow-tie',
    'bowl',
    'bowling',
    'box',
    'box-cutter',
    'box-shadow',
    'boxing-glove',
    'braille',
    'brain',
    'bread-slice',
    'bread-slice-outline',
    'bridge',
    'briefcase',
    'briefcase-account',
    'briefcase-account-outline',
    'briefcase-check',
    'briefcase-download',
    'briefcase-download-outline',
    'briefcase-edit',
    'briefcase-edit-outline',
    'briefcase-minus',
    'briefcase-minus-outline',
    'briefcase-outline',
    'briefcase-plus',
    'briefcase-plus-outline',
    'briefcase-remove',
    'briefcase-remove-outline',
    'briefcase-search',
    'briefcase-search-outline',
    'briefcase-upload',
    'briefcase-upload-outline',
    'brightness-1',
    'brightness-2',
    'brightness-3',
    'brightness-4',
    'brightness-5',
    'brightness-6',
    'brightness-7',
    'brightness-auto',
    'brightness-percent',
    'broom',
    'brush',
    'buddhism',
    'buffer',
    'bug',
    'bug-check',
    'bug-check-outline',
    'bug-outline',
    'bugle',
    'bulldozer',
    'bullet',
    'bulletin-board',
    'bullhorn',
    'bullhorn-outline',
    'bullseye',
    'bullseye-arrow',
    'bus',
    'bus-alert',
    'bus-articulated-end',
    'bus-articulated-front',
    'bus-clock',
    'bus-double-decker',
    'bus-multiple',
    'bus-school',
    'bus-side',
    'cached',
    'cactus',
    'cake',
    'cake-layered',
    'cake-variant',
    'calculator',
    'calculator-variant',
    'calendar',
    'calendar-account',
    'calendar-account-outline',
    'calendar-alert',
    'calendar-blank',
    'calendar-blank-outline',
    'calendar-check',
    'calendar-check-outline',
    'calendar-clock',
    'calendar-edit',
    'calendar-export',
    'calendar-heart',
    'calendar-import',
    'calendar-minus',
    'calendar-month',
    'calendar-month-outline',
    'calendar-multiple',
    'calendar-multiple-check',
    'calendar-multiselect',
    'calendar-outline',
    'calendar-plus',
    'calendar-question',
    'calendar-range',
    'calendar-range-outline',
    'calendar-remove',
    'calendar-remove-outline',
    'calendar-repeat',
    'calendar-repeat-outline',
    'calendar-search',
    'calendar-star',
    'calendar-text',
    'calendar-text-outline',
    'calendar-today',
    'calendar-week',
    'calendar-week-begin',
    'calendar-weekend',
    'calendar-weekend-outline',
    'call-made',
    'call-merge',
    'call-missed',
    'call-received',
    'call-split',
    'camcorder',
    'camcorder-box',
    'camcorder-box-off',
    'camcorder-off',
    'camera',
    'camera-account',
    'camera-burst',
    'camera-control',
    'camera-enhance',
    'camera-enhance-outline',
    'camera-front',
    'camera-front-variant',
    'camera-gopro',
    'camera-image',
    'camera-iris',
    'camera-metering-center',
    'camera-metering-matrix',
    'camera-metering-partial',
    'camera-metering-spot',
    'camera-off',
    'camera-outline',
    'camera-party-mode',
    'camera-plus',
    'camera-plus-outline',
    'camera-rear',
    'camera-rear-variant',
    'camera-retake',
    'camera-retake-outline',
    'camera-switch',
    'camera-timer',
    'camera-wireless',
    'camera-wireless-outline',
    'campfire',
    'cancel',
    'candle',
    'candycane',
    'cannabis',
    'caps-lock',
    'car',
    'car-back',
    'car-battery',
    'car-brake-abs',
    'car-brake-alert',
    'car-brake-hold',
    'car-brake-parking',
    'car-connected',
    'car-convertible',
    'car-cruise-control',
    'car-defrost-front',
    'car-defrost-rear',
    'car-door',
    'car-electric',
    'car-esp',
    'car-estate',
    'car-hatchback',
    'car-key',
    'car-light-dimmed',
    'car-light-fog',
    'car-light-high',
    'car-limousine',
    'car-multiple',
    'car-off',
    'car-parking-lights',
    'car-pickup',
    'car-shift-pattern',
    'car-side',
    'car-sports',
    'car-tire-alert',
    'car-traction-control',
    'car-wash',
    'caravan',
    'card',
    'card-bulleted',
    'card-bulleted-off',
    'card-bulleted-off-outline',
    'card-bulleted-outline',
    'card-bulleted-settings',
    'card-bulleted-settings-outline',
    'card-outline',
    'card-text',
    'card-text-outline',
    'cards',
    'cards-club',
    'cards-diamond',
    'cards-heart',
    'cards-outline',
    'cards-playing-outline',
    'cards-spade',
    'cards-variant',
    'carrot',
    'cart',
    'cart-arrow-down',
    'cart-arrow-right',
    'cart-arrow-up',
    'cart-minus',
    'cart-off',
    'cart-outline',
    'cart-plus',
    'cart-remove',
    'case-sensitive-alt',
    'cash',
    'cash-100',
    'cash-marker',
    'cash-multiple',
    'cash-refund',
    'cash-register',
    'cash-usd',
    'cassette',
    'cast',
    'cast-connected',
    'cast-education',
    'cast-off',
    'castle',
    'cat',
    'cctv',
    'ceiling-light',
    'cellphone',
    'cellphone-android',
    'cellphone-arrow-down',
    'cellphone-basic',
    'cellphone-dock',
    'cellphone-erase',
    'cellphone-information',
    'cellphone-iphone',
    'cellphone-key',
    'cellphone-link',
    'cellphone-link-off',
    'cellphone-lock',
    'cellphone-message',
    'cellphone-nfc',
    'cellphone-off',
    'cellphone-screenshot',
    'cellphone-settings',
    'cellphone-settings-variant',
    'cellphone-sound',
    'cellphone-text',
    'cellphone-wireless',
    'celtic-cross',
    'certificate',
    'chair-rolling',
    'chair-school',
    'charity',
    'chart-arc',
    'chart-areaspline',
    'chart-areaspline-variant',
    'chart-bar',
    'chart-bar-stacked',
    'chart-bell-curve',
    'chart-bubble',
    'chart-donut',
    'chart-donut-variant',
    'chart-gantt',
    'chart-histogram',
    'chart-line',
    'chart-line-stacked',
    'chart-line-variant',
    'chart-multiline',
    'chart-pie',
    'chart-scatter-plot',
    'chart-scatter-plot-hexbin',
    'chart-timeline',
    'chart-timeline-variant',
    'chart-tree',
    'chat',
    'chat-alert',
    'chat-outline',
    'chat-processing',
    'check',
    'check-all',
    'check-bold',
    'check-box-multiple-outline',
    'check-box-outline',
    'check-circle',
    'check-circle-outline',
    'check-decagram',
    'check-network',
    'check-network-outline',
    'check-outline',
    'check-underline',
    'check-underline-circle',
    'check-underline-circle-outline',
    'checkbook',
    'checkbox-blank',
    'checkbox-blank-circle',
    'checkbox-blank-circle-outline',
    'checkbox-blank-outline',
    'checkbox-intermediate',
    'checkbox-marked',
    'checkbox-marked-circle',
    'checkbox-marked-circle-outline',
    'checkbox-marked-outline',
    'checkbox-multiple-blank',
    'checkbox-multiple-blank-circle',
    'checkbox-multiple-blank-circle-outline',
    'checkbox-multiple-blank-outline',
    'checkbox-multiple-marked',
    'checkbox-multiple-marked-circle',
    'checkbox-multiple-marked-circle-outline',
    'checkbox-multiple-marked-outline',
    'checkerboard',
    'chef-hat',
    'chemical-weapon',
    'chess-bishop',
    'chess-king',
    'chess-knight',
    'chess-pawn',
    'chess-queen',
    'chess-rook',
    'chevron-double-down',
    'chevron-double-left',
    'chevron-double-right',
    'chevron-double-up',
    'chevron-down',
    'chevron-down-box',
    'chevron-down-box-outline',
    'chevron-down-circle',
    'chevron-down-circle-outline',
    'chevron-left',
    'chevron-left-box',
    'chevron-left-box-outline',
    'chevron-left-circle',
    'chevron-left-circle-outline',
    'chevron-right',
    'chevron-right-box',
    'chevron-right-box-outline',
    'chevron-right-circle',
    'chevron-right-circle-outline',
    'chevron-triple-down',
    'chevron-triple-left',
    'chevron-triple-right',
    'chevron-triple-up',
    'chevron-up',
    'chevron-up-box',
    'chevron-up-box-outline',
    'chevron-up-circle',
    'chevron-up-circle-outline',
    'chili-hot',
    'chili-medium',
    'chili-mild',
    'chip',
    'christianity',
    'christianity-outline',
    'church',
    'circle',
    'circle-double',
    'circle-edit-outline',
    'circle-expand',
    'circle-medium',
    'circle-outline',
    'circle-slice-1',
    'circle-slice-2',
    'circle-slice-3',
    'circle-slice-4',
    'circle-slice-5',
    'circle-slice-6',
    'circle-slice-7',
    'circle-slice-8',
    'circle-small',
    'circular-saw',
    'cisco-webex',
    'city',
    'city-variant',
    'city-variant-outline',
    'clipboard',
    'clipboard-account',
    'clipboard-account-outline',
    'clipboard-alert',
    'clipboard-alert-outline',
    'clipboard-arrow-down',
    'clipboard-arrow-down-outline',
    'clipboard-arrow-left',
    'clipboard-arrow-left-outline',
    'clipboard-arrow-right',
    'clipboard-arrow-right-outline',
    'clipboard-arrow-up',
    'clipboard-arrow-up-outline',
    'clipboard-check',
    'clipboard-check-outline',
    'clipboard-flow',
    'clipboard-outline',
    'clipboard-play',
    'clipboard-play-outline',
    'clipboard-plus',
    'clipboard-pulse',
    'clipboard-pulse-outline',
    'clipboard-text',
    'clipboard-text-outline',
    'clipboard-text-play',
    'clipboard-text-play-outline',
    'clippy',
    'clock',
    'clock-alert',
    'clock-alert-outline',
    'clock-digital',
    'clock-end',
    'clock-fast',
    'clock-in',
    'clock-out',
    'clock-outline',
    'clock-start',
    'close',
    'close-box',
    'close-box-multiple',
    'close-box-multiple-outline',
    'close-box-outline',
    'close-circle',
    'close-circle-outline',
    'close-network',
    'close-network-outline',
    'close-octagon',
    'close-octagon-outline',
    'close-outline',
    'closed-caption',
    'closed-caption-outline',
    'cloud',
    'cloud-alert',
    'cloud-braces',
    'cloud-check',
    'cloud-circle',
    'cloud-download',
    'cloud-download-outline',
    'cloud-off-outline',
    'cloud-outline',
    'cloud-print',
    'cloud-print-outline',
    'cloud-question',
    'cloud-search',
    'cloud-search-outline',
    'cloud-sync',
    'cloud-tags',
    'cloud-upload',
    'cloud-upload-outline',
    'clover',
    'code-array',
    'code-braces',
    'code-brackets',
    'code-equal',
    'code-greater-than',
    'code-greater-than-or-equal',
    'code-less-than',
    'code-less-than-or-equal',
    'code-not-equal',
    'code-not-equal-variant',
    'code-parentheses',
    'code-string',
    'code-tags',
    'code-tags-check',
    'codepen',
    'coffee',
    'coffee-off',
    'coffee-off-outline',
    'coffee-outline',
    'coffee-to-go',
    'coffin',
    'cogs',
    'coin',
    'coins',
    'collage',
    'collapse-all',
    'collapse-all-outline',
    'color-helper',
    'comma',
    'comma-box',
    'comma-box-outline',
    'comma-circle',
    'comma-circle-outline',
    'comment',
    'comment-account',
    'comment-account-outline',
    'comment-alert',
    'comment-alert-outline',
    'comment-arrow-left',
    'comment-arrow-left-outline',
    'comment-arrow-right',
    'comment-arrow-right-outline',
    'comment-check',
    'comment-check-outline',
    'comment-eye',
    'comment-eye-outline',
    'comment-multiple',
    'comment-multiple-outline',
    'comment-outline',
    'comment-plus',
    'comment-plus-outline',
    'comment-processing',
    'comment-processing-outline',
    'comment-question',
    'comment-question-outline',
    'comment-remove',
    'comment-remove-outline',
    'comment-search',
    'comment-search-outline',
    'comment-text',
    'comment-text-multiple',
    'comment-text-multiple-outline',
    'comment-text-outline',
    'compare',
    'compass',
    'compass-off',
    'compass-off-outline',
    'compass-outline',
    'console',
    'console-line',
    'console-network',
    'console-network-outline',
    'contact-mail',
    'contact-mail-outline',
    'contact-phone',
    'contact-phone-outline',
    'contactless-payment',
    'contacts',
    'contain',
    'contain-end',
    'contain-start',
    'content-copy',
    'content-cut',
    'content-duplicate',
    'content-paste',
    'content-save',
    'content-save-alert',
    'content-save-alert-outline',
    'content-save-all',
    'content-save-all-outline',
    'content-save-edit',
    'content-save-edit-outline',
    'content-save-move',
    'content-save-move-outline',
    'content-save-outline',
    'content-save-settings',
    'content-save-settings-outline',
    'contrast',
    'contrast-box',
    'contrast-circle',
    'controller-classic',
    'controller-classic-outline',
    'cookie',
    'copyright',
    'cordova',
    'corn',
    'counter',
    'cow',
    'cowboy',
    'cpu-32-bit',
    'cpu-64-bit',
    'crane',
    'creation',
    'creative-commons',
    'credit-card',
    'credit-card-clock',
    'credit-card-clock-outline',
    'credit-card-marker',
    'credit-card-multiple',
    'credit-card-off',
    'credit-card-plus',
    'credit-card-refund',
    'credit-card-scan',
    'credit-card-settings',
    'credit-card-wireless',
    'cricket',
    'crop',
    'crop-free',
    'crop-landscape',
    'crop-portrait',
    'crop-rotate',
    'crop-square',
    'crosshairs',
    'crosshairs-gps',
    'crosshairs-off',
    'crown',
    'cryengine',
    'crystal-ball',
    'cube',
    'cube-outline',
    'cube-scan',
    'cube-send',
    'cube-unfolded',
    'cup',
    'cup-off',
    'cup-water',
    'cupboard',
    'cupboard-outline',
    'cupcake',
    'curling',
    'currency-bdt',
    'currency-brl',
    'currency-btc',
    'currency-chf',
    'currency-cny',
    'currency-eth',
    'currency-eur',
    'currency-gbp',
    'currency-ils',
    'currency-inr',
    'currency-jpy',
    'currency-krw',
    'currency-kzt',
    'currency-ngn',
    'currency-php',
    'currency-rial',
    'currency-rub',
    'currency-sign',
    'currency-try',
    'currency-twd',
    'currency-usd',
    'currency-usd-off',
    'current-ac',
    'current-dc',
    'cursor-default',
    'cursor-default-click',
    'cursor-default-click-outline',
    'cursor-default-outline',
    'cursor-move',
    'cursor-pointer',
    'cursor-text',
    'database',
    'database-check',
    'database-edit',
    'database-export',
    'database-import',
    'database-lock',
    'database-minus',
    'database-plus',
    'database-refresh',
    'database-remove',
    'database-search',
    'database-settings',
    'death-star',
    'death-star-variant',
    'deathly-hallows',
    'debian',
    'debug-step-into',
    'debug-step-out',
    'debug-step-over',
    'decagram',
    'decagram-outline',
    'decimal-decrease',
    'decimal-increase',
    'delete',
    'delete-circle',
    'delete-circle-outline',
    'delete-empty',
    'delete-empty-outline',
    'delete-forever',
    'delete-forever-outline',
    'delete-outline',
    'delete-restore',
    'delete-sweep',
    'delete-sweep-outline',
    'delete-variant',
    'delta',
    'desk-lamp',
    'deskphone',
    'desktop-classic',
    'desktop-mac',
    'desktop-mac-dashboard',
    'desktop-tower',
    'desktop-tower-monitor',
    'details',
    'dev-to',
    'developer-board',
    'deviantart',
    'dialpad',
    'diameter',
    'diameter-outline',
    'diameter-variant',
    'diamond',
    'diamond-outline',
    'diamond-stone',
    'dice-1',
    'dice-2',
    'dice-3',
    'dice-4',
    'dice-5',
    'dice-6',
    'dice-d10',
    'dice-d12',
    'dice-d20',
    'dice-d4',
    'dice-d6',
    'dice-d8',
    'dice-multiple',
    'dictionary',
    'dip-switch',
    'directions',
    'directions-fork',
    'disc',
    'disc-alert',
    'disc-player',
    'discord',
    'dishwasher',
    'disqus',
    'disqus-outline',
    'diving-flippers',
    'diving-helmet',
    'diving-scuba',
    'diving-scuba-flag',
    'diving-scuba-tank',
    'diving-scuba-tank-multiple',
    'diving-snorkel',
    'division',
    'division-box',
    'dlna',
    'dna',
    'dns',
    'dns-outline',
    'do-not-disturb',
    'do-not-disturb-off',
    'docker',
    'doctor',
    'dog',
    'dog-service',
    'dog-side',
    'dolby',
    'dolly',
    'domain',
    'domain-off',
    'donkey',
    'door',
    'door-closed',
    'door-open',
    'doorbell-video',
    'dot-net',
    'dots-horizontal',
    'dots-horizontal-circle',
    'dots-horizontal-circle-outline',
    'dots-vertical',
    'dots-vertical-circle',
    'dots-vertical-circle-outline',
    'douban',
    'download',
    'download-multiple',
    'download-network',
    'download-network-outline',
    'download-outline',
    'drag',
    'drag-horizontal',
    'drag-variant',
    'drag-vertical',
    'drama-masks',
    'draw',
    'drawing',
    'drawing-box',
    'dresser',
    'dresser-outline',
    'dribbble',
    'dribbble-box',
    'drone',
    'dropbox',
    'drupal',
    'duck',
    'dumbbell',
    'dump-truck',
    'ear-hearing',
    'ear-hearing-off',
    'earth',
    'earth-box',
    'earth-box-off',
    'earth-off',
    'edge',
    'egg',
    'egg-easter',
    'eight-track',
    'eject',
    'eject-outline',
    'electric-switch',
    'elephant',
    'elevation-decline',
    'elevation-rise',
    'elevator',
    'ellipse',
    'ellipse-outline',
    'email',
    'email-alert',
    'email-box',
    'email-check',
    'email-check-outline',
    'email-edit',
    'email-edit-outline',
    'email-lock',
    'email-mark-as-unread',
    'email-minus',
    'email-minus-outline',
    'email-multiple',
    'email-multiple-outline',
    'email-open',
    'email-open-multiple',
    'email-open-multiple-outline',
    'email-open-outline',
    'email-outline',
    'email-plus',
    'email-plus-outline',
    'email-search',
    'email-search-outline',
    'email-variant',
    'ember',
    'emby',
    'emoticon',
    'emoticon-angry',
    'emoticon-angry-outline',
    'emoticon-cool',
    'emoticon-cool-outline',
    'emoticon-cry',
    'emoticon-cry-outline',
    'emoticon-dead',
    'emoticon-dead-outline',
    'emoticon-devil',
    'emoticon-devil-outline',
    'emoticon-excited',
    'emoticon-excited-outline',
    'emoticon-frown',
    'emoticon-frown-outline',
    'emoticon-happy',
    'emoticon-happy-outline',
    'emoticon-kiss',
    'emoticon-kiss-outline',
    'emoticon-neutral',
    'emoticon-neutral-outline',
    'emoticon-outline',
    'emoticon-poop',
    'emoticon-poop-outline',
    'emoticon-sad',
    'emoticon-sad-outline',
    'emoticon-tongue',
    'emoticon-tongue-outline',
    'emoticon-wink',
    'emoticon-wink-outline',
    'engine',
    'engine-off',
    'engine-off-outline',
    'engine-outline',
    'equal',
    'equal-box',
    'equalizer',
    'equalizer-outline',
    'eraser',
    'eraser-variant',
    'escalator',
    'eslint',
    'et',
    'ethereum',
    'ethernet',
    'ethernet-cable',
    'ethernet-cable-off',
    'etsy',
    'ev-station',
    'eventbrite',
    'evernote',
    'exclamation',
    'exit-run',
    'exit-to-app',
    'expand-all',
    'expand-all-outline',
    'exponent',
    'exponent-box',
    'export',
    'export-variant',
    'eye',
    'eye-check',
    'eye-check-outline',
    'eye-circle',
    'eye-circle-outline',
    'eye-off',
    'eye-off-outline',
    'eye-outline',
    'eye-plus',
    'eye-plus-outline',
    'eye-settings',
    'eye-settings-outline',
    'eyedropper',
    'eyedropper-variant',
    'face',
    'face-agent',
    'face-outline',
    'face-profile',
    'face-recognition',
    'facebook',
    'facebook-box',
    'facebook-messenger',
    'facebook-workplace',
    'factory',
    'fan',
    'fan-off',
    'fast-forward',
    'fast-forward-10',
    'fast-forward-30',
    'fast-forward-outline',
    'fax',
    'feather',
    'feature-search',
    'feature-search-outline',
    'fedora',
    'ferris-wheel',
    'ferry',
    'file',
    'file-account',
    'file-alert',
    'file-alert-outline',
    'file-cabinet',
    'file-cad',
    'file-cad-box',
    'file-cancel',
    'file-cancel-outline',
    'file-chart',
    'file-check',
    'file-check-outline',
    'file-cloud',
    'file-compare',
    'file-delimited',
    'file-delimited-outline',
    'file-document',
    'file-document-box',
    'file-document-box-check',
    'file-document-box-check-outline',
    'file-document-box-minus',
    'file-document-box-minus-outline',
    'file-document-box-multiple',
    'file-document-box-multiple-outline',
    'file-document-box-outline',
    'file-document-box-plus',
    'file-document-box-plus-outline',
    'file-document-box-remove',
    'file-document-box-remove-outline',
    'file-document-box-search',
    'file-document-box-search-outline',
    'file-document-edit',
    'file-document-edit-outline',
    'file-document-outline',
    'file-download',
    'file-download-outline',
    'file-excel',
    'file-excel-box',
    'file-export',
    'file-eye',
    'file-eye-outline',
    'file-find',
    'file-find-outline',
    'file-hidden',
    'file-image',
    'file-image-outline',
    'file-import',
    'file-lock',
    'file-move',
    'file-multiple',
    'file-music',
    'file-music-outline',
    'file-outline',
    'file-pdf',
    'file-pdf-box',
    'file-pdf-outline',
    'file-percent',
    'file-plus',
    'file-plus-outline',
    'file-powerpoint',
    'file-powerpoint-box',
    'file-presentation-box',
    'file-question',
    'file-remove',
    'file-replace',
    'file-replace-outline',
    'file-restore',
    'file-search',
    'file-search-outline',
    'file-send',
    'file-table',
    'file-table-outline',
    'file-tree',
    'file-undo',
    'file-upload',
    'file-upload-outline',
    'file-video',
    'file-video-outline',
    'file-word',
    'file-word-box',
    'file-xml',
    'film',
    'filmstrip',
    'filmstrip-off',
    'filter',
    'filter-minus',
    'filter-minus-outline',
    'filter-outline',
    'filter-plus',
    'filter-plus-outline',
    'filter-remove',
    'filter-remove-outline',
    'filter-variant',
    'finance',
    'find-replace',
    'fingerprint',
    'fingerprint-off',
    'fire',
    'fire-extinguisher',
    'fire-truck',
    'firebase',
    'firefox',
    'fireplace',
    'fireplace-off',
    'firework',
    'fish',
    'fishbowl',
    'fishbowl-outline',
    'fit-to-page',
    'fit-to-page-outline',
    'flag',
    'flag-checkered',
    'flag-minus',
    'flag-outline',
    'flag-plus',
    'flag-remove',
    'flag-triangle',
    'flag-variant',
    'flag-variant-outline',
    'flare',
    'flash',
    'flash-alert',
    'flash-alert-outline',
    'flash-auto',
    'flash-circle',
    'flash-off',
    'flash-outline',
    'flash-red-eye',
    'flashlight',
    'flashlight-off',
    'flask',
    'flask-empty',
    'flask-empty-outline',
    'flask-outline',
    'flattr',
    'flickr',
    'flip-to-back',
    'flip-to-front',
    'floor-lamp',
    'floor-plan',
    'floppy',
    'floppy-variant',
    'flower',
    'flower-outline',
    'flower-poppy',
    'flower-tulip',
    'flower-tulip-outline',
    'focus-auto',
    'focus-field',
    'focus-field-horizontal',
    'focus-field-vertical',
    'folder',
    'folder-account',
    'folder-account-outline',
    'folder-alert',
    'folder-alert-outline',
    'folder-clock',
    'folder-clock-outline',
    'folder-download',
    'folder-edit',
    'folder-edit-outline',
    'folder-google-drive',
    'folder-image',
    'folder-key',
    'folder-key-network',
    'folder-key-network-outline',
    'folder-lock',
    'folder-lock-open',
    'folder-move',
    'folder-multiple',
    'folder-multiple-image',
    'folder-multiple-outline',
    'folder-network',
    'folder-network-outline',
    'folder-open',
    'folder-open-outline',
    'folder-outline',
    'folder-plus',
    'folder-plus-outline',
    'folder-pound',
    'folder-pound-outline',
    'folder-remove',
    'folder-remove-outline',
    'folder-search',
    'folder-search-outline',
    'folder-star',
    'folder-star-outline',
    'folder-sync',
    'folder-sync-outline',
    'folder-text',
    'folder-text-outline',
    'folder-upload',
    'font-awesome',
    'food',
    'food-apple',
    'food-apple-outline',
    'food-croissant',
    'food-fork-drink',
    'food-off',
    'food-variant',
    'foot-print',
    'football',
    'football-australian',
    'football-helmet',
    'forklift',
    'format-align-bottom',
    'format-align-center',
    'format-align-justify',
    'format-align-left',
    'format-align-middle',
    'format-align-right',
    'format-align-top',
    'format-annotation-minus',
    'format-annotation-plus',
    'format-bold',
    'format-clear',
    'format-color-fill',
    'format-color-highlight',
    'format-color-text',
    'format-columns',
    'format-float-center',
    'format-float-left',
    'format-float-none',
    'format-float-right',
    'format-font',
    'format-font-size-decrease',
    'format-font-size-increase',
    'format-header-1',
    'format-header-2',
    'format-header-3',
    'format-header-4',
    'format-header-5',
    'format-header-6',
    'format-header-decrease',
    'format-header-equal',
    'format-header-increase',
    'format-header-pound',
    'format-horizontal-align-center',
    'format-horizontal-align-left',
    'format-horizontal-align-right',
    'format-indent-decrease',
    'format-indent-increase',
    'format-italic',
    'format-letter-case',
    'format-letter-case-lower',
    'format-letter-case-upper',
    'format-line-spacing',
    'format-line-style',
    'format-line-weight',
    'format-list-bulleted',
    'format-list-bulleted-square',
    'format-list-bulleted-type',
    'format-list-checkbox',
    'format-list-checks',
    'format-list-numbered',
    'format-list-numbered-rtl',
    'format-list-triangle',
    'format-overline',
    'format-page-break',
    'format-paint',
    'format-paragraph',
    'format-pilcrow',
    'format-quote-close',
    'format-quote-open',
    'format-rotate-90',
    'format-section',
    'format-size',
    'format-strikethrough',
    'format-strikethrough-variant',
    'format-subscript',
    'format-superscript',
    'format-text',
    'format-text-rotation-down',
    'format-text-rotation-none',
    'format-text-variant',
    'format-text-wrapping-clip',
    'format-text-wrapping-overflow',
    'format-text-wrapping-wrap',
    'format-textbox',
    'format-textdirection-l-to-r',
    'format-textdirection-r-to-l',
    'format-title',
    'format-underline',
    'format-vertical-align-bottom',
    'format-vertical-align-center',
    'format-vertical-align-top',
    'format-wrap-inline',
    'format-wrap-square',
    'format-wrap-tight',
    'format-wrap-top-bottom',
    'forum',
    'forum-outline',
    'forward',
    'forwardburger',
    'fountain',
    'fountain-pen',
    'fountain-pen-tip',
    'foursquare',
    'freebsd',
    'frequently-asked-questions',
    'fridge',
    'fridge-bottom',
    'fridge-outline',
    'fridge-top',
    'fuel',
    'fullscreen',
    'fullscreen-exit',
    'function',
    'function-variant',
    'fuse',
    'fuse-blade',
    'gamepad',
    'gamepad-circle',
    'gamepad-circle-down',
    'gamepad-circle-left',
    'gamepad-circle-outline',
    'gamepad-circle-right',
    'gamepad-circle-up',
    'gamepad-down',
    'gamepad-left',
    'gamepad-right',
    'gamepad-round',
    'gamepad-round-down',
    'gamepad-round-left',
    'gamepad-round-outline',
    'gamepad-round-right',
    'gamepad-round-up',
    'gamepad-square',
    'gamepad-square-outline',
    'gamepad-up',
    'gamepad-variant',
    'gamepad-variant-outline',
    'gantry-crane',
    'garage',
    'garage-alert',
    'garage-open',
    'gas-cylinder',
    'gas-station',
    'gas-station-outline',
    'gate',
    'gate-and',
    'gate-nand',
    'gate-nor',
    'gate-not',
    'gate-or',
    'gate-xnor',
    'gate-xor',
    'gatsby',
    'gauge',
    'gauge-empty',
    'gauge-full',
    'gauge-low',
    'gavel',
    'gender-female',
    'gender-male',
    'gender-male-female',
    'gender-transgender',
    'gentoo',
    'gesture',
    'gesture-double-tap',
    'gesture-pinch',
    'gesture-spread',
    'gesture-swipe',
    'gesture-swipe-down',
    'gesture-swipe-horizontal',
    'gesture-swipe-left',
    'gesture-swipe-right',
    'gesture-swipe-up',
    'gesture-swipe-vertical',
    'gesture-tap',
    'gesture-tap-hold',
    'gesture-two-double-tap',
    'gesture-two-tap',
    'ghost',
    'ghost-off',
    'gif',
    'gift',
    'gift-outline',
    'git',
    'github-box',
    'github-circle',
    'github-face',
    'gitlab',
    'glass-cocktail',
    'glass-flute',
    'glass-mug',
    'glass-stange',
    'glass-tulip',
    'glass-wine',
    'glassdoor',
    'glasses',
    'globe-model',
    'gmail',
    'gnome',
    'go-kart',
    'go-kart-track',
    'gog',
    'golf',
    'gondola',
    'goodreads',
    'google',
    'google-adwords',
    'google-allo',
    'google-analytics',
    'google-assistant',
    'google-cardboard',
    'google-chrome',
    'google-circles',
    'google-circles-communities',
    'google-circles-extended',
    'google-circles-group',
    'google-classroom',
    'google-controller',
    'google-controller-off',
    'google-drive',
    'google-earth',
    'google-fit',
    'google-glass',
    'google-hangouts',
    'google-home',
    'google-keep',
    'google-lens',
    'google-maps',
    'google-nearby',
    'google-pages',
    'google-photos',
    'google-physical-web',
    'google-play',
    'google-plus',
    'google-plus-box',
    'google-podcast',
    'google-spreadsheet',
    'google-street-view',
    'google-translate',
    'gpu',
    'gradient',
    'grain',
    'graphql',
    'grave-stone',
    'grease-pencil',
    'greater-than',
    'greater-than-or-equal',
    'grid',
    'grid-large',
    'grid-off',
    'grill',
    'group',
    'guitar-acoustic',
    'guitar-electric',
    'guitar-pick',
    'guitar-pick-outline',
    'guy-fawkes-mask',
    'hackernews',
    'hail',
    'halloween',
    'hamburger',
    'hammer',
    'hand',
    'hand-left',
    'hand-okay',
    'hand-peace',
    'hand-peace-variant',
    'hand-pointing-down',
    'hand-pointing-left',
    'hand-pointing-right',
    'hand-pointing-up',
    'hand-right',
    'hand-saw',
    'handball',
    'hanger',
    'hard-hat',
    'harddisk',
    'hat-fedora',
    'hazard-lights',
    'hdr',
    'hdr-off',
    'headphones',
    'headphones-bluetooth',
    'headphones-box',
    'headphones-off',
    'headphones-settings',
    'headset',
    'headset-dock',
    'headset-off',
    'heart',
    'heart-box',
    'heart-box-outline',
    'heart-broken',
    'heart-broken-outline',
    'heart-circle',
    'heart-circle-outline',
    'heart-flash',
    'heart-half',
    'heart-half-full',
    'heart-half-outline',
    'heart-multiple',
    'heart-multiple-outline',
    'heart-off',
    'heart-outline',
    'heart-pulse',
    'helicopter',
    'help',
    'help-box',
    'help-circle',
    'help-circle-outline',
    'help-network',
    'help-network-outline',
    'help-rhombus',
    'help-rhombus-outline',
    'hexagon',
    'hexagon-multiple',
    'hexagon-outline',
    'hexagon-slice-1',
    'hexagon-slice-2',
    'hexagon-slice-3',
    'hexagon-slice-4',
    'hexagon-slice-5',
    'hexagon-slice-6',
    'hexagram',
    'hexagram-outline',
    'high-definition',
    'high-definition-box',
    'highway',
    'hiking',
    'hinduism',
    'history',
    'hockey-puck',
    'hockey-sticks',
    'hololens',
    'home',
    'home-account',
    'home-alert',
    'home-analytics',
    'home-assistant',
    'home-automation',
    'home-circle',
    'home-city',
    'home-city-outline',
    'home-currency-usd',
    'home-export-outline',
    'home-flood',
    'home-floor-0',
    'home-floor-1',
    'home-floor-2',
    'home-floor-3',
    'home-floor-a',
    'home-floor-b',
    'home-floor-g',
    'home-floor-l',
    'home-floor-negative-1',
    'home-group',
    'home-heart',
    'home-import-outline',
    'home-lock',
    'home-lock-open',
    'home-map-marker',
    'home-minus',
    'home-modern',
    'home-outline',
    'home-plus',
    'home-thermometer',
    'home-thermometer-outline',
    'home-variant',
    'home-variant-outline',
    'hook',
    'hook-off',
    'hops',
    'horseshoe',
    'hospital',
    'hospital-building',
    'hospital-marker',
    'hot-tub',
    'hotel',
    'houzz',
    'houzz-box',
    'hubspot',
    'hulu',
    'human',
    'human-child',
    'human-female',
    'human-female-boy',
    'human-female-female',
    'human-female-girl',
    'human-greeting',
    'human-handsdown',
    'human-handsup',
    'human-male',
    'human-male-boy',
    'human-male-female',
    'human-male-girl',
    'human-male-height',
    'human-male-height-variant',
    'human-male-male',
    'human-pregnant',
    'humble-bundle',
    'ice-cream',
    'ice-pop',
    'identifier',
    'iframe',
    'iframe-outline',
    'image',
    'image-album',
    'image-area',
    'image-area-close',
    'image-broken',
    'image-broken-variant',
    'image-filter',
    'image-filter-black-white',
    'image-filter-center-focus',
    'image-filter-center-focus-strong',
    'image-filter-center-focus-strong-outline',
    'image-filter-center-focus-weak',
    'image-filter-drama',
    'image-filter-frames',
    'image-filter-hdr',
    'image-filter-none',
    'image-filter-tilt-shift',
    'image-filter-vintage',
    'image-frame',
    'image-move',
    'image-multiple',
    'image-off',
    'image-outline',
    'image-plus',
    'image-search',
    'image-search-outline',
    'image-size-select-actual',
    'image-size-select-large',
    'image-size-select-small',
    'import',
    'inbox',
    'inbox-arrow-down',
    'inbox-arrow-up',
    'inbox-multiple',
    'inbox-multiple-outline',
    'incognito',
    'infinity',
    'information',
    'information-outline',
    'information-variant',
    'instagram',
    'instapaper',
    'internet-explorer',
    'invert-colors',
    'invert-colors-off',
    'ip',
    'ip-network',
    'ip-network-outline',
    'ipod',
    'islam',
    'itunes',
    'jabber',
    'jeepney',
    'jellyfish',
    'jellyfish-outline',
    'jira',
    'jquery',
    'jsfiddle',
    'json',
    'judaism',
    'kabaddi',
    'karate',
    'keg',
    'kettle',
    'kettle-outline',
    'key',
    'key-change',
    'key-minus',
    'key-outline',
    'key-plus',
    'key-remove',
    'key-variant',
    'keyboard',
    'keyboard-backspace',
    'keyboard-caps',
    'keyboard-close',
    'keyboard-off',
    'keyboard-off-outline',
    'keyboard-outline',
    'keyboard-return',
    'keyboard-settings',
    'keyboard-settings-outline',
    'keyboard-tab',
    'keyboard-variant',
    'kickstarter',
    'knife',
    'knife-military',
    'kodi',
    'label',
    'label-off',
    'label-off-outline',
    'label-outline',
    'label-variant',
    'label-variant-outline',
    'ladybug',
    'lambda',
    'lamp',
    'lan',
    'lan-connect',
    'lan-disconnect',
    'lan-pending',
    'language-c',
    'language-cpp',
    'language-csharp',
    'language-css3',
    'language-go',
    'language-haskell',
    'language-html5',
    'language-java',
    'language-javascript',
    'language-lua',
    'language-php',
    'language-python',
    'language-python-text',
    'language-r',
    'language-ruby-on-rails',
    'language-swift',
    'language-typescript',
    'laptop',
    'laptop-chromebook',
    'laptop-mac',
    'laptop-off',
    'laptop-windows',
    'laravel',
    'lasso',
    'lastfm',
    'lastpass',
    'latitude',
    'launch',
    'lava-lamp',
    'layers',
    'layers-minus',
    'layers-off',
    'layers-off-outline',
    'layers-outline',
    'layers-plus',
    'layers-remove',
    'layers-triple',
    'layers-triple-outline',
    'lead-pencil',
    'leaf',
    'leaf-maple',
    'leak',
    'leak-off',
    'led-off',
    'led-on',
    'led-outline',
    'led-strip',
    'led-variant-off',
    'led-variant-on',
    'led-variant-outline',
    'less-than',
    'less-than-or-equal',
    'library',
    'library-books',
    'library-movie',
    'library-music',
    'library-music-outline',
    'library-plus',
    'library-shelves',
    'library-video',
    'lifebuoy',
    'light-switch',
    'lightbulb',
    'lightbulb-off',
    'lightbulb-off-outline',
    'lightbulb-on',
    'lightbulb-on-outline',
    'lightbulb-outline',
    'lighthouse',
    'lighthouse-on',
    'link',
    'link-box',
    'link-box-outline',
    'link-box-variant',
    'link-box-variant-outline',
    'link-off',
    'link-plus',
    'link-variant',
    'link-variant-off',
    'linkedin',
    'linkedin-box',
    'linux',
    'linux-mint',
    'litecoin',
    'loading',
    'lock',
    'lock-alert',
    'lock-clock',
    'lock-open',
    'lock-open-outline',
    'lock-outline',
    'lock-pattern',
    'lock-plus',
    'lock-question',
    'lock-reset',
    'lock-smart',
    'locker',
    'locker-multiple',
    'login',
    'login-variant',
    'logout',
    'logout-variant',
    'longitude',
    'looks',
    'loop',
    'loupe',
    'lumx',
    'lyft',
    'magnet',
    'magnet-on',
    'magnify',
    'magnify-close',
    'magnify-minus',
    'magnify-minus-cursor',
    'magnify-minus-outline',
    'magnify-plus',
    'magnify-plus-cursor',
    'magnify-plus-outline',
    'mail',
    'mail-ru',
    'mailbox',
    'mailbox-open',
    'mailbox-open-outline',
    'mailbox-open-up',
    'mailbox-open-up-outline',
    'mailbox-outline',
    'mailbox-up',
    'mailbox-up-outline',
    'map',
    'map-check',
    'map-check-outline',
    'map-clock',
    'map-clock-outline',
    'map-legend',
    'map-marker',
    'map-marker-alert',
    'map-marker-alert-outline',
    'map-marker-check',
    'map-marker-circle',
    'map-marker-distance',
    'map-marker-minus',
    'map-marker-multiple',
    'map-marker-off',
    'map-marker-outline',
    'map-marker-path',
    'map-marker-plus',
    'map-marker-question',
    'map-marker-question-outline',
    'map-marker-radius',
    'map-marker-remove',
    'map-marker-remove-variant',
    'map-minus',
    'map-outline',
    'map-plus',
    'map-search',
    'map-search-outline',
    'mapbox',
    'margin',
    'markdown',
    'markdown-outline',
    'marker',
    'marker-cancel',
    'marker-check',
    'mastodon',
    'mastodon-variant',
    'material-design',
    'material-ui',
    'math-compass',
    'math-cos',
    'math-sin',
    'math-tan',
    'matrix',
    'maxcdn',
    'medal',
    'medical-bag',
    'medium',
    'meetup',
    'memory',
    'menu',
    'menu-down',
    'menu-down-outline',
    'menu-left',
    'menu-left-outline',
    'menu-open',
    'menu-right',
    'menu-right-outline',
    'menu-swap',
    'menu-swap-outline',
    'menu-up',
    'menu-up-outline',
    'merge',
    'message',
    'message-alert',
    'message-alert-outline',
    'message-bulleted',
    'message-bulleted-off',
    'message-draw',
    'message-image',
    'message-outline',
    'message-plus',
    'message-processing',
    'message-reply',
    'message-reply-text',
    'message-settings',
    'message-settings-variant',
    'message-text',
    'message-text-outline',
    'message-video',
    'meteor',
    'metronome',
    'metronome-tick',
    'micro-sd',
    'microphone',
    'microphone-minus',
    'microphone-off',
    'microphone-outline',
    'microphone-plus',
    'microphone-settings',
    'microphone-variant',
    'microphone-variant-off',
    'microscope',
    'microsoft',
    'microsoft-dynamics',
    'microwave',
    'middleware',
    'middleware-outline',
    'midi',
    'midi-port',
    'mine',
    'minecraft',
    'mini-sd',
    'minidisc',
    'minus',
    'minus-box',
    'minus-box-outline',
    'minus-circle',
    'minus-circle-outline',
    'minus-network',
    'minus-network-outline',
    'mixcloud',
    'mixed-martial-arts',
    'mixed-reality',
    'mixer',
    'molecule',
    'monitor',
    'monitor-cellphone',
    'monitor-cellphone-star',
    'monitor-dashboard',
    'monitor-lock',
    'monitor-multiple',
    'monitor-off',
    'monitor-screenshot',
    'monitor-speaker',
    'monitor-speaker-off',
    'monitor-star',
    'moon-first-quarter',
    'moon-full',
    'moon-last-quarter',
    'moon-new',
    'moon-waning-crescent',
    'moon-waning-gibbous',
    'moon-waxing-crescent',
    'moon-waxing-gibbous',
    'more',
    'mother-nurse',
    'motion-sensor',
    'motorbike',
    'mouse',
    'mouse-bluetooth',
    'mouse-off',
    'mouse-variant',
    'mouse-variant-off',
    'move-resize',
    'move-resize-variant',
    'movie',
    'movie-outline',
    'movie-roll',
    'muffin',
    'multiplication',
    'multiplication-box',
    'mushroom',
    'mushroom-outline',
    'music',
    'music-accidental-double-flat',
    'music-accidental-double-sharp',
    'music-accidental-flat',
    'music-accidental-natural',
    'music-accidental-sharp',
    'music-box',
    'music-box-outline',
    'music-circle',
    'music-circle-outline',
    'music-clef-alto',
    'music-clef-bass',
    'music-clef-treble',
    'music-note',
    'music-note-bluetooth',
    'music-note-bluetooth-off',
    'music-note-eighth',
    'music-note-eighth-dotted',
    'music-note-half',
    'music-note-half-dotted',
    'music-note-off',
    'music-note-off-outline',
    'music-note-outline',
    'music-note-plus',
    'music-note-quarter',
    'music-note-quarter-dotted',
    'music-note-sixteenth',
    'music-note-sixteenth-dotted',
    'music-note-whole',
    'music-note-whole-dotted',
    'music-off',
    'music-rest-eighth',
    'music-rest-half',
    'music-rest-quarter',
    'music-rest-sixteenth',
    'music-rest-whole',
    'nail',
    'nas',
    'nativescript',
    'nature',
    'nature-people',
    'navigation',
    'near-me',
    'necklace',
    'needle',
    'netflix',
    'network',
    'network-off',
    'network-off-outline',
    'network-outline',
    'network-strength-1',
    'network-strength-1-alert',
    'network-strength-2',
    'network-strength-2-alert',
    'network-strength-3',
    'network-strength-3-alert',
    'network-strength-4',
    'network-strength-4-alert',
    'network-strength-off',
    'network-strength-off-outline',
    'network-strength-outline',
    'new-box',
    'newspaper',
    'newspaper-minus',
    'newspaper-plus',
    'nfc',
    'nfc-off',
    'nfc-search-variant',
    'nfc-tap',
    'nfc-variant',
    'nfc-variant-off',
    'ninja',
    'nintendo-switch',
    'nodejs',
    'not-equal',
    'not-equal-variant',
    'note',
    'note-circle',
    'note-multiple',
    'note-multiple-outline',
    'note-outline',
    'note-plus',
    'note-plus-outline',
    'note-text',
    'notebook',
    'notebook-multiple',
    'notebook-outline',
    'notification-clear-all',
    'npm',
    'npm-variant',
    'npm-variant-outline',
    'nuke',
    'null',
    'numeric',
    'numeric-0',
    'numeric-0-box',
    'numeric-0-box-multiple',
    'numeric-0-box-multiple-outline',
    'numeric-0-box-outline',
    'numeric-0-circle',
    'numeric-0-circle-outline',
    'numeric-1',
    'numeric-1-box',
    'numeric-1-box-multiple',
    'numeric-1-box-multiple-outline',
    'numeric-1-box-outline',
    'numeric-1-circle',
    'numeric-1-circle-outline',
    'numeric-10-box',
    'numeric-10-box-outline',
    'numeric-2',
    'numeric-2-box',
    'numeric-2-box-multiple',
    'numeric-2-box-multiple-outline',
    'numeric-2-box-outline',
    'numeric-2-circle',
    'numeric-2-circle-outline',
    'numeric-3',
    'numeric-3-box',
    'numeric-3-box-multiple',
    'numeric-3-box-multiple-outline',
    'numeric-3-box-outline',
    'numeric-3-circle',
    'numeric-3-circle-outline',
    'numeric-4',
    'numeric-4-box',
    'numeric-4-box-multiple',
    'numeric-4-box-multiple-outline',
    'numeric-4-box-outline',
    'numeric-4-circle',
    'numeric-4-circle-outline',
    'numeric-5',
    'numeric-5-box',
    'numeric-5-box-multiple',
    'numeric-5-box-multiple-outline',
    'numeric-5-box-outline',
    'numeric-5-circle',
    'numeric-5-circle-outline',
    'numeric-6',
    'numeric-6-box',
    'numeric-6-box-multiple',
    'numeric-6-box-multiple-outline',
    'numeric-6-box-outline',
    'numeric-6-circle',
    'numeric-6-circle-outline',
    'numeric-7',
    'numeric-7-box',
    'numeric-7-box-multiple',
    'numeric-7-box-multiple-outline',
    'numeric-7-box-outline',
    'numeric-7-circle',
    'numeric-7-circle-outline',
    'numeric-8',
    'numeric-8-box',
    'numeric-8-box-multiple',
    'numeric-8-box-multiple-outline',
    'numeric-8-box-outline',
    'numeric-8-circle',
    'numeric-8-circle-outline',
    'numeric-9',
    'numeric-9-box',
    'numeric-9-box-multiple',
    'numeric-9-box-multiple-outline',
    'numeric-9-box-outline',
    'numeric-9-circle',
    'numeric-9-circle-outline',
    'numeric-9-plus-box',
    'numeric-9-plus-box-multiple',
    'numeric-9-plus-box-multiple-outline',
    'numeric-9-plus-box-outline',
    'numeric-9-plus-circle',
    'numeric-9-plus-circle-outline',
    'nut',
    'nutrition',
    'oar',
    'ocarina',
    'octagon',
    'octagon-outline',
    'octagram',
    'octagram-outline',
    'odnoklassniki',
    'office',
    'office-building',
    'oil',
    'oil-lamp',
    'oil-temperature',
    'omega',
    'one-up',
    'onedrive',
    'onenote',
    'onepassword',
    'opacity',
    'open-in-app',
    'open-in-new',
    'open-source-initiative',
    'openid',
    'opera',
    'orbit',
    'origin',
    'ornament',
    'ornament-variant',
    'outlook',
    'owl',
    'pac-man',
    'package',
    'package-down',
    'package-up',
    'package-variant',
    'package-variant-closed',
    'page-first',
    'page-last',
    'page-layout-body',
    'page-layout-footer',
    'page-layout-header',
    'page-layout-header-footer',
    'page-layout-sidebar-left',
    'page-layout-sidebar-right',
    'page-next',
    'page-next-outline',
    'page-previous',
    'page-previous-outline',
    'palette',
    'palette-advanced',
    'palette-outline',
    'palette-swatch',
    'pan',
    'pan-bottom-left',
    'pan-bottom-right',
    'pan-down',
    'pan-horizontal',
    'pan-left',
    'pan-right',
    'pan-top-left',
    'pan-top-right',
    'pan-up',
    'pan-vertical',
    'panda',
    'pandora',
    'panorama',
    'panorama-fisheye',
    'panorama-horizontal',
    'panorama-vertical',
    'panorama-wide-angle',
    'paper-cut-vertical',
    'paperclip',
    'parachute',
    'parachute-outline',
    'parking',
    'passport',
    'passport-biometric',
    'patio-heater',
    'patreon',
    'pause',
    'pause-circle',
    'pause-circle-outline',
    'pause-octagon',
    'pause-octagon-outline',
    'paw',
    'paw-off',
    'paypal',
    'pdf-box',
    'peace',
    'pen',
    'pen-lock',
    'pen-minus',
    'pen-off',
    'pen-plus',
    'pen-remove',
    'pencil',
    'pencil-box',
    'pencil-box-outline',
    'pencil-circle',
    'pencil-circle-outline',
    'pencil-lock',
    'pencil-lock-outline',
    'pencil-minus',
    'pencil-minus-outline',
    'pencil-off',
    'pencil-off-outline',
    'pencil-outline',
    'pencil-plus',
    'pencil-plus-outline',
    'pencil-remove',
    'pencil-remove-outline',
    'penguin',
    'pentagon',
    'pentagon-outline',
    'percent',
    'periodic-table',
    'periodic-table-co2',
    'periscope',
    'perspective-less',
    'perspective-more',
    'pharmacy',
    'phone',
    'phone-alert',
    'phone-bluetooth',
    'phone-classic',
    'phone-forward',
    'phone-hangup',
    'phone-in-talk',
    'phone-incoming',
    'phone-lock',
    'phone-log',
    'phone-minus',
    'phone-missed',
    'phone-off',
    'phone-outgoing',
    'phone-outline',
    'phone-paused',
    'phone-plus',
    'phone-return',
    'phone-rotate-landscape',
    'phone-rotate-portrait',
    'phone-settings',
    'phone-voip',
    'pi',
    'pi-box',
    'pi-hole',
    'piano',
    'pickaxe',
    'picture-in-picture-bottom-right',
    'picture-in-picture-bottom-right-outline',
    'picture-in-picture-top-right',
    'picture-in-picture-top-right-outline',
    'pier',
    'pier-crane',
    'pig',
    'pill',
    'pillar',
    'pin',
    'pin-off',
    'pin-off-outline',
    'pin-outline',
    'pine-tree',
    'pine-tree-box',
    'pinterest',
    'pinterest-box',
    'pinwheel',
    'pinwheel-outline',
    'pipe',
    'pipe-disconnected',
    'pipe-leak',
    'pirate',
    'pistol',
    'piston',
    'pizza',
    'play',
    'play-box-outline',
    'play-circle',
    'play-circle-outline',
    'play-network',
    'play-network-outline',
    'play-outline',
    'play-pause',
    'play-protected-content',
    'play-speed',
    'playlist-check',
    'playlist-edit',
    'playlist-minus',
    'playlist-music',
    'playlist-music-outline',
    'playlist-play',
    'playlist-plus',
    'playlist-remove',
    'playlist-star',
    'playstation',
    'plex',
    'plus',
    'plus-box',
    'plus-box-outline',
    'plus-circle',
    'plus-circle-multiple-outline',
    'plus-circle-outline',
    'plus-minus',
    'plus-minus-box',
    'plus-network',
    'plus-network-outline',
    'plus-one',
    'plus-outline',
    'pocket',
    'podcast',
    'podium',
    'podium-bronze',
    'podium-gold',
    'podium-silver',
    'point-of-sale',
    'pokeball',
    'pokemon-go',
    'poker-chip',
    'polaroid',
    'poll',
    'poll-box',
    'polymer',
    'pool',
    'popcorn',
    'postage-stamp',
    'pot',
    'pot-mix',
    'pound',
    'pound-box',
    'power',
    'power-cycle',
    'power-off',
    'power-on',
    'power-plug',
    'power-plug-off',
    'power-settings',
    'power-sleep',
    'power-socket',
    'power-socket-au',
    'power-socket-eu',
    'power-socket-uk',
    'power-socket-us',
    'power-standby',
    'powershell',
    'prescription',
    'presentation',
    'presentation-play',
    'printer',
    'printer-3d',
    'printer-3d-nozzle',
    'printer-3d-nozzle-outline',
    'printer-alert',
    'printer-off',
    'printer-settings',
    'printer-wireless',
    'priority-high',
    'priority-low',
    'professional-hexagon',
    'progress-alert',
    'progress-check',
    'progress-clock',
    'progress-download',
    'progress-upload',
    'progress-wrench',
    'projector',
    'projector-screen',
    'publish',
    'pulse',
    'pumpkin',
    'purse',
    'purse-outline',
    'puzzle',
    'puzzle-outline',
    'qi',
    'qqchat',
    'qrcode',
    'qrcode-edit',
    'qrcode-scan',
    'quadcopter',
    'quality-high',
    'quality-low',
    'quality-medium',
    'quicktime',
    'quora',
    'rabbit',
    'racing-helmet',
    'racquetball',
    'radar',
    'radiator',
    'radiator-disabled',
    'radiator-off',
    'radio',
    'radio-am',
    'radio-fm',
    'radio-handheld',
    'radio-tower',
    'radioactive',
    'radioactive-off',
    'radiobox-blank',
    'radiobox-marked',
    'radius',
    'radius-outline',
    'railroad-light',
    'raspberry-pi',
    'ray-end',
    'ray-end-arrow',
    'ray-start',
    'ray-start-arrow',
    'ray-start-end',
    'ray-vertex',
    'react',
    'read',
    'receipt',
    'record',
    'record-circle',
    'record-circle-outline',
    'record-player',
    'record-rec',
    'rectangle',
    'rectangle-outline',
    'recycle',
    'reddit',
    'redo',
    'redo-variant',
    'reflect-horizontal',
    'reflect-vertical',
    'refresh',
    'regex',
    'registered-trademark',
    'relative-scale',
    'reload',
    'reminder',
    'remote',
    'remote-desktop',
    'remote-off',
    'remote-tv',
    'remote-tv-off',
    'rename-box',
    'reorder-horizontal',
    'reorder-vertical',
    'repeat',
    'repeat-off',
    'repeat-once',
    'replay',
    'reply',
    'reply-all',
    'reply-all-outline',
    'reply-outline',
    'reproduction',
    'resistor',
    'resistor-nodes',
    'resize',
    'resize-bottom-right',
    'responsive',
    'restart',
    'restart-off',
    'restore',
    'restore-clock',
    'rewind',
    'rewind-10',
    'rewind-30',
    'rewind-outline',
    'rhombus',
    'rhombus-medium',
    'rhombus-outline',
    'rhombus-split',
    'ribbon',
    'rice',
    'ring',
    'rivet',
    'road',
    'road-variant',
    'robot',
    'robot-industrial',
    'robot-vacuum',
    'robot-vacuum-variant',
    'rocket',
    'roller-skate',
    'rollerblade',
    'rollupjs',
    'room-service',
    'room-service-outline',
    'rotate-3d',
    'rotate-3d-variant',
    'rotate-left',
    'rotate-left-variant',
    'rotate-orbit',
    'rotate-right',
    'rotate-right-variant',
    'rounded-corner',
    'router-wireless',
    'router-wireless-settings',
    'routes',
    'rowing',
    'rss',
    'rss-box',
    'rss-off',
    'ruby',
    'rugby',
    'ruler',
    'ruler-square',
    'run',
    'run-fast',
    'sack',
    'sack-percent',
    'safe',
    'safety-goggles',
    'sailing',
    'sale',
    'salesforce',
    'sass',
    'satellite',
    'satellite-uplink',
    'satellite-variant',
    'sausage',
    'saw-blade',
    'saxophone',
    'scale',
    'scale-balance',
    'scale-bathroom',
    'scanner',
    'scanner-off',
    'scatter-plot',
    'scatter-plot-outline',
    'school',
    'scissors-cutting',
    'screen-rotation',
    'screen-rotation-lock',
    'screw-flat-top',
    'screw-lag',
    'screw-machine-flat-top',
    'screw-machine-round-top',
    'screw-round-top',
    'screwdriver',
    'script',
    'script-outline',
    'script-text',
    'script-text-outline',
    'sd',
    'seal',
    'search-web',
    'seat',
    'seat-flat',
    'seat-flat-angled',
    'seat-individual-suite',
    'seat-legroom-extra',
    'seat-legroom-normal',
    'seat-legroom-reduced',
    'seat-outline',
    'seat-recline-extra',
    'seat-recline-normal',
    'seatbelt',
    'security',
    'security-network',
    'seed',
    'seed-outline',
    'segment',
    'select',
    'select-all',
    'select-color',
    'select-compare',
    'select-drag',
    'select-group',
    'select-inverse',
    'select-off',
    'selection',
    'selection-drag',
    'selection-ellipse',
    'selection-ellipse-arrow-inside',
    'selection-off',
    'send',
    'send-circle',
    'send-circle-outline',
    'send-lock',
    'serial-port',
    'server',
    'server-minus',
    'server-network',
    'server-network-off',
    'server-off',
    'server-plus',
    'server-remove',
    'server-security',
    'set-all',
    'set-center',
    'set-center-right',
    'set-left',
    'set-left-center',
    'set-left-right',
    'set-none',
    'set-right',
    'set-top-box',
    'settings',
    'settings-box',
    'settings-helper',
    'settings-outline',
    'shape',
    'shape-circle-plus',
    'shape-outline',
    'shape-plus',
    'shape-polygon-plus',
    'shape-rectangle-plus',
    'shape-square-plus',
    'share',
    'share-off',
    'share-off-outline',
    'share-outline',
    'share-variant',
    'sheep',
    'shield',
    'shield-account',
    'shield-account-outline',
    'shield-airplane',
    'shield-airplane-outline',
    'shield-alert',
    'shield-alert-outline',
    'shield-car',
    'shield-check',
    'shield-check-outline',
    'shield-cross',
    'shield-cross-outline',
    'shield-half-full',
    'shield-home',
    'shield-home-outline',
    'shield-key',
    'shield-key-outline',
    'shield-link-variant',
    'shield-link-variant-outline',
    'shield-lock',
    'shield-lock-outline',
    'shield-off',
    'shield-off-outline',
    'shield-outline',
    'shield-plus',
    'shield-plus-outline',
    'shield-remove',
    'shield-remove-outline',
    'shield-search',
    'ship-wheel',
    'shoe-formal',
    'shoe-heel',
    'shoe-print',
    'shopify',
    'shopping',
    'shopping-music',
    'shopping-search',
    'shovel',
    'shovel-off',
    'shower',
    'shower-head',
    'shredder',
    'shuffle',
    'shuffle-disabled',
    'shuffle-variant',
    'sigma',
    'sigma-lower',
    'sign-caution',
    'sign-direction',
    'sign-text',
    'signal',
    'signal-2g',
    'signal-3g',
    'signal-4g',
    'signal-5g',
    'signal-cellular-1',
    'signal-cellular-2',
    'signal-cellular-3',
    'signal-cellular-outline',
    'signal-distance-variant',
    'signal-hspa',
    'signal-hspa-plus',
    'signal-off',
    'signal-variant',
    'signature',
    'signature-freehand',
    'signature-image',
    'signature-text',
    'silo',
    'silverware',
    'silverware-fork',
    'silverware-fork-knife',
    'silverware-spoon',
    'silverware-variant',
    'sim',
    'sim-alert',
    'sim-off',
    'sina-weibo',
    'sitemap',
    'skate',
    'skew-less',
    'skew-more',
    'skip-backward',
    'skip-backward-outline',
    'skip-forward',
    'skip-forward-outline',
    'skip-next',
    'skip-next-circle',
    'skip-next-circle-outline',
    'skip-next-outline',
    'skip-previous',
    'skip-previous-circle',
    'skip-previous-circle-outline',
    'skip-previous-outline',
    'skull',
    'skull-crossbones',
    'skull-crossbones-outline',
    'skull-outline',
    'skype',
    'skype-business',
    'slack',
    'slackware',
    'sleep',
    'sleep-off',
    'slope-downhill',
    'slope-uphill',
    'smog',
    'smoke-detector',
    'smoking',
    'smoking-off',
    'snapchat',
    'snowflake',
    'snowflake-alert',
    'snowflake-variant',
    'snowman',
    'soccer',
    'soccer-field',
    'sofa',
    'solar-panel',
    'solar-panel-large',
    'solar-power',
    'solid',
    'sort',
    'sort-alphabetical',
    'sort-ascending',
    'sort-descending',
    'sort-numeric',
    'sort-variant',
    'sort-variant-lock',
    'sort-variant-lock-open',
    'soundcloud',
    'source-branch',
    'source-commit',
    'source-commit-end',
    'source-commit-end-local',
    'source-commit-local',
    'source-commit-next-local',
    'source-commit-start',
    'source-commit-start-next-local',
    'source-fork',
    'source-merge',
    'source-pull',
    'source-repository',
    'source-repository-multiple',
    'soy-sauce',
    'spa',
    'spa-outline',
    'space-invaders',
    'spade',
    'speaker',
    'speaker-bluetooth',
    'speaker-multiple',
    'speaker-off',
    'speaker-wireless',
    'speedometer',
    'speedometer-medium',
    'speedometer-slow',
    'spellcheck',
    'spider-web',
    'spotify',
    'spotlight',
    'spotlight-beam',
    'spray',
    'spray-bottle',
    'sprout',
    'sprout-outline',
    'square',
    'square-edit-outline',
    'square-inc',
    'square-inc-cash',
    'square-medium',
    'square-medium-outline',
    'square-outline',
    'square-root',
    'square-root-box',
    'square-small',
    'squeegee',
    'ssh',
    'stack-exchange',
    'stack-overflow',
    'stadium',
    'stairs',
    'stamper',
    'standard-definition',
    'star',
    'star-box',
    'star-box-outline',
    'star-circle',
    'star-circle-outline',
    'star-face',
    'star-four-points',
    'star-four-points-outline',
    'star-half',
    'star-off',
    'star-outline',
    'star-three-points',
    'star-three-points-outline',
    'steam',
    'steam-box',
    'steering',
    'steering-off',
    'step-backward',
    'step-backward-2',
    'step-forward',
    'step-forward-2',
    'stethoscope',
    'sticker',
    'sticker-emoji',
    'stocking',
    'stop',
    'stop-circle',
    'stop-circle-outline',
    'store',
    'store-24-hour',
    'stove',
    'strava',
    'stretch-to-page',
    'stretch-to-page-outline',
    'subdirectory-arrow-left',
    'subdirectory-arrow-right',
    'subtitles',
    'subtitles-outline',
    'subway',
    'subway-alert-variant',
    'subway-variant',
    'summit',
    'sunglasses',
    'surround-sound',
    'surround-sound-2-0',
    'surround-sound-3-1',
    'surround-sound-5-1',
    'surround-sound-7-1',
    'svg',
    'swap-horizontal',
    'swap-horizontal-bold',
    'swap-horizontal-variant',
    'swap-vertical',
    'swap-vertical-bold',
    'swap-vertical-variant',
    'swim',
    'switch',
    'sword',
    'sword-cross',
    'symfony',
    'sync',
    'sync-alert',
    'sync-off',
    'tab',
    'tab-minus',
    'tab-plus',
    'tab-remove',
    'tab-unselected',
    'table',
    'table-border',
    'table-column',
    'table-column-plus-after',
    'table-column-plus-before',
    'table-column-remove',
    'table-column-width',
    'table-edit',
    'table-large',
    'table-large-plus',
    'table-large-remove',
    'table-merge-cells',
    'table-of-contents',
    'table-plus',
    'table-remove',
    'table-row',
    'table-row-height',
    'table-row-plus-after',
    'table-row-plus-before',
    'table-row-remove',
    'table-search',
    'table-settings',
    'table-tennis',
    'tablet',
    'tablet-android',
    'tablet-cellphone',
    'tablet-dashboard',
    'tablet-ipad',
    'taco',
    'tag',
    'tag-faces',
    'tag-heart',
    'tag-heart-outline',
    'tag-minus',
    'tag-multiple',
    'tag-outline',
    'tag-plus',
    'tag-remove',
    'tag-text-outline',
    'tank',
    'tape-measure',
    'target',
    'target-account',
    'target-variant',
    'taxi',
    'tea',
    'tea-outline',
    'teach',
    'teamviewer',
    'telegram',
    'telescope',
    'television',
    'television-box',
    'television-classic',
    'television-classic-off',
    'television-guide',
    'television-off',
    'television-pause',
    'television-play',
    'television-stop',
    'temperature-celsius',
    'temperature-fahrenheit',
    'temperature-kelvin',
    'tennis',
    'tennis-ball',
    'tent',
    'terrain',
    'test-tube',
    'test-tube-empty',
    'test-tube-off',
    'text',
    'text-shadow',
    'text-short',
    'text-subject',
    'text-to-speech',
    'text-to-speech-off',
    'textbox',
    'textbox-password',
    'texture',
    'theater',
    'theme-light-dark',
    'thermometer',
    'thermometer-alert',
    'thermometer-chevron-down',
    'thermometer-chevron-up',
    'thermometer-lines',
    'thermometer-minus',
    'thermometer-plus',
    'thermostat',
    'thermostat-box',
    'thought-bubble',
    'thought-bubble-outline',
    'thumb-down',
    'thumb-down-outline',
    'thumb-up',
    'thumb-up-outline',
    'thumbs-up-down',
    'ticket',
    'ticket-account',
    'ticket-confirmation',
    'ticket-outline',
    'ticket-percent',
    'tie',
    'tilde',
    'timelapse',
    'timeline',
    'timeline-alert',
    'timeline-alert-outline',
    'timeline-help',
    'timeline-help-outline',
    'timeline-outline',
    'timeline-plus',
    'timeline-plus-outline',
    'timeline-text',
    'timeline-text-outline',
    'timer',
    'timer-10',
    'timer-3',
    'timer-off',
    'timer-sand',
    'timer-sand-empty',
    'timer-sand-full',
    'timetable',
    'toaster-oven',
    'toggle-switch',
    'toggle-switch-off',
    'toggle-switch-off-outline',
    'toggle-switch-outline',
    'toilet',
    'toolbox',
    'toolbox-outline',
    'tooltip',
    'tooltip-account',
    'tooltip-edit',
    'tooltip-image',
    'tooltip-image-outline',
    'tooltip-outline',
    'tooltip-plus',
    'tooltip-plus-outline',
    'tooltip-text',
    'tooltip-text-outline',
    'tooth',
    'tooth-outline',
    'tor',
    'tortoise',
    'tournament',
    'tower-beach',
    'tower-fire',
    'towing',
    'track-light',
    'trackpad',
    'trackpad-lock',
    'tractor',
    'trademark',
    'traffic-light',
    'train',
    'train-car',
    'train-variant',
    'tram',
    'transcribe',
    'transcribe-close',
    'transfer-down',
    'transfer-left',
    'transfer-right',
    'transfer-up',
    'transit-connection',
    'transit-connection-variant',
    'transit-detour',
    'transit-transfer',
    'transition',
    'transition-masked',
    'translate',
    'translate-off',
    'transmission-tower',
    'trash-can',
    'trash-can-outline',
    'treasure-chest',
    'tree',
    'tree-outline',
    'trello',
    'trending-down',
    'trending-neutral',
    'trending-up',
    'triangle',
    'triangle-outline',
    'triforce',
    'trophy',
    'trophy-award',
    'trophy-broken',
    'trophy-outline',
    'trophy-variant',
    'trophy-variant-outline',
    'truck',
    'truck-check',
    'truck-delivery',
    'truck-fast',
    'truck-trailer',
    'tshirt-crew',
    'tshirt-crew-outline',
    'tshirt-v',
    'tshirt-v-outline',
    'tumble-dryer',
    'tumblr',
    'tumblr-box',
    'tumblr-reblog',
    'tune',
    'tune-vertical',
    'turnstile',
    'turnstile-outline',
    'turtle',
    'twitch',
    'twitter',
    'twitter-box',
    'twitter-circle',
    'twitter-retweet',
    'two-factor-authentication',
    'typewriter',
    'uber',
    'ubisoft',
    'ubuntu',
    'ultra-high-definition',
    'umbraco',
    'umbrella',
    'umbrella-closed',
    'umbrella-outline',
    'undo',
    'undo-variant',
    'unfold-less-horizontal',
    'unfold-less-vertical',
    'unfold-more-horizontal',
    'unfold-more-vertical',
    'ungroup',
    'unicode',
    'unity',
    'unreal',
    'untappd',
    'update',
    'upload',
    'upload-multiple',
    'upload-network',
    'upload-network-outline',
    'upload-outline',
    'usb',
    'van-passenger',
    'van-utility',
    'vanish',
    'variable',
    'vector-arrange-above',
    'vector-arrange-below',
    'vector-bezier',
    'vector-circle',
    'vector-circle-variant',
    'vector-combine',
    'vector-curve',
    'vector-difference',
    'vector-difference-ab',
    'vector-difference-ba',
    'vector-ellipse',
    'vector-intersection',
    'vector-line',
    'vector-point',
    'vector-polygon',
    'vector-polyline',
    'vector-radius',
    'vector-rectangle',
    'vector-selection',
    'vector-square',
    'vector-triangle',
    'vector-union',
    'venmo',
    'vhs',
    'vibrate',
    'vibrate-off',
    'video',
    'video-3d',
    'video-3d-variant',
    'video-4k-box',
    'video-account',
    'video-image',
    'video-input-antenna',
    'video-input-component',
    'video-input-hdmi',
    'video-input-scart',
    'video-input-svideo',
    'video-minus',
    'video-off',
    'video-off-outline',
    'video-outline',
    'video-plus',
    'video-stabilization',
    'video-switch',
    'video-vintage',
    'video-wireless',
    'video-wireless-outline',
    'view-agenda',
    'view-array',
    'view-carousel',
    'view-column',
    'view-comfy',
    'view-compact',
    'view-compact-outline',
    'view-dashboard',
    'view-dashboard-outline',
    'view-dashboard-variant',
    'view-day',
    'view-grid',
    'view-grid-plus',
    'view-headline',
    'view-list',
    'view-module',
    'view-parallel',
    'view-quilt',
    'view-sequential',
    'view-split-horizontal',
    'view-split-vertical',
    'view-stream',
    'view-week',
    'vimeo',
    'violin',
    'virtual-reality',
    'visual-studio',
    'visual-studio-code',
    'vk',
    'vk-box',
    'vk-circle',
    'vlc',
    'voice',
    'voice-off',
    'voicemail',
    'volleyball',
    'volume-high',
    'volume-low',
    'volume-medium',
    'volume-minus',
    'volume-mute',
    'volume-off',
    'volume-plus',
    'volume-variant-off',
    'vote',
    'vote-outline',
    'vpn',
    'vuejs',
    'vuetify',
    'walk',
    'wall',
    'wall-sconce',
    'wall-sconce-flat',
    'wall-sconce-variant',
    'wallet',
    'wallet-giftcard',
    'wallet-membership',
    'wallet-outline',
    'wallet-plus',
    'wallet-plus-outline',
    'wallet-travel',
    'wallpaper',
    'wan',
    'wardrobe',
    'wardrobe-outline',
    'warehouse',
    'washing-machine',
    'watch',
    'watch-export',
    'watch-export-variant',
    'watch-import',
    'watch-import-variant',
    'watch-variant',
    'watch-vibrate',
    'watch-vibrate-off',
    'water',
    'water-boiler',
    'water-off',
    'water-outline',
    'water-percent',
    'water-pump',
    'water-pump-off',
    'watermark',
    'wave',
    'waves',
    'waze',
    'weather-cloudy',
    'weather-cloudy-alert',
    'weather-cloudy-arrow-right',
    'weather-fog',
    'weather-hail',
    'weather-hazy',
    'weather-hurricane',
    'weather-lightning',
    'weather-lightning-rainy',
    'weather-night',
    'weather-night-partly-cloudy',
    'weather-partly-cloudy',
    'weather-partly-lightning',
    'weather-partly-rainy',
    'weather-partly-snowy',
    'weather-partly-snowy-rainy',
    'weather-pouring',
    'weather-rainy',
    'weather-snowy',
    'weather-snowy-heavy',
    'weather-snowy-rainy',
    'weather-sunny',
    'weather-sunny-alert',
    'weather-sunset',
    'weather-sunset-down',
    'weather-sunset-up',
    'weather-tornado',
    'weather-windy',
    'weather-windy-variant',
    'web',
    'web-box',
    'webcam',
    'webhook',
    'webpack',
    'wechat',
    'weight',
    'weight-gram',
    'weight-kilogram',
    'weight-pound',
    'whatsapp',
    'wheelchair-accessibility',
    'whistle',
    'white-balance-auto',
    'white-balance-incandescent',
    'white-balance-iridescent',
    'white-balance-sunny',
    'widgets',
    'wifi',
    'wifi-off',
    'wifi-star',
    'wifi-strength-1',
    'wifi-strength-1-alert',
    'wifi-strength-1-lock',
    'wifi-strength-2',
    'wifi-strength-2-alert',
    'wifi-strength-2-lock',
    'wifi-strength-3',
    'wifi-strength-3-alert',
    'wifi-strength-3-lock',
    'wifi-strength-4',
    'wifi-strength-4-alert',
    'wifi-strength-4-lock',
    'wifi-strength-alert-outline',
    'wifi-strength-lock-outline',
    'wifi-strength-off',
    'wifi-strength-off-outline',
    'wifi-strength-outline',
    'wii',
    'wiiu',
    'wikipedia',
    'wind-turbine',
    'window-close',
    'window-closed',
    'window-maximize',
    'window-minimize',
    'window-open',
    'window-restore',
    'windows',
    'windows-classic',
    'wiper',
    'wiper-wash',
    'wordpress',
    'worker',
    'wrap',
    'wrap-disabled',
    'wrench',
    'wrench-outline',
    'wunderlist',
    'xamarin',
    'xamarin-outline',
    'xaml',
    'xbox',
    'xbox-controller',
    'xbox-controller-battery-alert',
    'xbox-controller-battery-charging',
    'xbox-controller-battery-empty',
    'xbox-controller-battery-full',
    'xbox-controller-battery-low',
    'xbox-controller-battery-medium',
    'xbox-controller-battery-unknown',
    'xbox-controller-menu',
    'xbox-controller-off',
    'xbox-controller-view',
    'xda',
    'xing',
    'xing-box',
    'xing-circle',
    'xml',
    'xmpp',
    'yahoo',
    'yammer',
    'yeast',
    'yelp',
    'yin-yang',
    'youtube',
    'youtube-creator-studio',
    'youtube-gaming',
    'youtube-subscription',
    'youtube-tv',
    'z-wave',
    'zend',
    'zigbee',
    'zip-box',
    'zip-disk',
    'zodiac-aquarius',
    'zodiac-aries',
    'zodiac-cancer',
    'zodiac-capricorn',
    'zodiac-gemini',
    'zodiac-leo',
    'zodiac-libra',
    'zodiac-pisces',
    'zodiac-sagittarius',
    'zodiac-scorpio',
    'zodiac-taurus',
    'zodiac-virgo',
    'blank'
  ]
}
</script>

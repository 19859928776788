import Service from '../core/service';
import NotificationModel from '../models/notification.model';

export default class NotificationService extends Service {
  modelUrl = '/notification';
  baseUrl = '/api';
  model = NotificationModel;

  endpointList = {
    ...new Service().endpointList,
    ...{
      ack: '/ack',
    }
  }

  getAckUrl = () => this.getUrl('ack');

  ack = (data = {}, config = {}) => this.handleRequest(this.getAckUrl(), data, config);
}

<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'ai-model'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="ai-model"
      title="AI Model"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              :error-messages="formErrors.label"
              label="Label"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.label"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.description"
              :error-messages="formErrors.description"
              label="Description"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.description"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="model.data.baseModel"
              :items="baseModelList"
              :rules="[rules.required]"
              outlined
              label="Base Model"
              hide-details="auto"
              cache-items
              deletable-chips
              @change="() => onBaseModelChange(formErrors)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="9">
            <v-autocomplete
              v-model="model.data.parentId"
              :loading="aiModelListLoading"
              :search-input.sync="aiModelListSearch"
              :items="aiModelList"
              item-text="data.label"
              item-value="data.id"
              outlined
              label="Parent Model"
              hide-details="auto"
              deletable-chips
              clearable
              @change="delete formErrors.model"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="model.data.projectStatus"
              class="pa-0 ma-0"
              label="Train Project Record Status"
              hide-details="auto"
            ></v-checkbox>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="model.data.questionAnswer"
              class="pa-0 ma-0"
              label="Train Project Record Questions/Answers"
              hide-details="auto"
            ></v-checkbox>
          </v-col>

          <v-col cols="12">
            <ListBuilder
              v-model="model.data.files"
              :labels="{
                add: 'Add a fine-tuning file',
                empty: 'No fine-tuning file found'
              }"
              :default-model="FileModel"
            >
              <template #item="file">
                <v-file-input
                  v-if="!file.item.data.id"
                  v-model="file.item.data.file"
                  prepend-icon=""
                  append-icon="mdi-link"
                  hide-details="auto"
                  outlined
                  show-size
                  chips
                  label="Fine-tuning File"
                  accept=".jsonl"
                >
                </v-file-input>
                <v-text-field
                  v-else
                  v-model="file.item.data.name"
                  :loading="file.item.downloading"
                  hide-details="auto"
                  outlined
                  readonly
                  chips
                  label="Fine-tuning File"
                  append-icon="mdi-download"
                  @click:append="() => downloadFile(file.item)"
                >
                </v-text-field>
              </template>
            </ListBuilder>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import Logger from '@/modules/sdk/core/logger';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import { debounce } from 'debounce';
import TagModel from '@/models/tag.model';
import AiModelService from '@/services/ai-model.service';
import FileModel from '@/modules/sdk/models/file.model';
import FileService from '@/modules/sdk/services/file.service';
import { saveAs } from 'file-saver';
import { gptBaseModelList } from '@/enums/global';

const d = new Logger('views/Admin/Form/AiModelForm');

@Component({
  components: {
    Wysiwyg,
    DataForm,
  }
})
export default class AiModelForm extends Vue {

  service = AiModelService.getInstance();
  model: TagModel = new TagModel();
  loading = false;

  aiModelList: Array<RecordModel> = [];
  aiModelListSearch = '';
  aiModelListLoading = false;

  baseModelList = gptBaseModelList;

  get hasFiles() {
    return this.model.data.files && this.model.data.files.length;
  }

  get FileModel() {
    return FileModel;
  }

  isFile(file: any) {
    return file instanceof File;
  }

  isFileModel(model: any) {
    return model instanceof FileModel;
  }

  downloadFile(file: FileModel) {
    file.downloading = true;
    FileService.getInstance().downloadAsBlob(file)
      .then((response: any) => {
        saveAs(response.data, file.data.name);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => file.downloading = false);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({ id })
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadAiModelList(search: string = this.aiModelListSearch, baseModel: string = this.model.data.baseModel) {
    this.loadAiModelListDebounce.clear();
    const order = 'label';
    const advanced = {};
    const filters = [{
      field: 'baseModel',
      operator: 'equals',
      value: baseModel
    }];
    if (baseModel) {
      this.aiModelListLoading = true;
      AiModelService.getInstance().getAll({ search, filters, order, advanced })
        .then(response => this.aiModelList = response.data.view.list)
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.aiModelListLoading = false);
    }
  }

  loadAiModelListDebounce = debounce(() => {
    this.loadAiModelList();
  }, 333);

  @Watch('aiModelListSearch', { immediate: true })
  onAiModelListSearch(search: string) {
    this.loadAiModelListDebounce()
  }

  onBaseModelChange(formErrors: any = {}) {
    delete formErrors.baseModel;
    this.model.data.parentId = null;
    this.loadAiModelList();
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>

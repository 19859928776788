import { IChangeLog } from '@/modules/common/components/ChangeLogModal.vue';

const changeLogs: IChangeLog[] = [
  // {
  //   timestamp: 1725897864000,
  //   items: [
  //     'We did this and that',
  //     'We did this and that',
  //     'We did this and that',
  //   ]
  // },
]
export default changeLogs

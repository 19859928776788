<template>
  <user-form
    :id="userId"
  />
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import UserForm from '@/modules/common/views/form/UserForm.vue'
import Identity from '@/modules/sdk/core/identity'

@Component({
  components: {
    UserForm
  }
})
export default class MyProfileView extends Vue {
  userId = Identity.getIdentity()?.user.id;
}
</script>

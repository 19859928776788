<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'records'"
      title="Records"
      :new-route="{name: 'RecordForm'}"
      :headers="headers"
      :service="service"
      :query="query"
      index="record"
    >
      <!-- UID -->
      <template v-slot:item.uid="{item}">
        <span class="text-no-wrap">
          <a :href="item.data.url" target="_blank">{{item.data.uid}}</a>
        </span>
      </template>

      <!-- PROJECT -->
      <template v-slot:item.projectId="{item}">
        <span class="text-no-wrap">
          {{item.data.projectentity.data.label}}
        </span>
      </template>

      <!-- DOI -->
      <template v-slot:item.doi="{item}">
        <span class="text-no-wrap" v-if="item.data.doi">
          <a :href="item.data.doi" target="_blank">{{item.data.doi}}</a>
        </span>
      </template>

      <!-- TITLE -->
      <template v-slot:item.title="{item}">
        <div style="width: 200px;">
          {{item.data.title}}
        </div>
      </template>

      <!-- ABSTRACT -->
      <template v-slot:item.abstract="{item}">
        <div style="width: 600px;">
          {{item.data.abstract}}
        </div>
      </template>

      <!-- AUTHORS -->
      <template v-slot:item.authors="{item}">
        <div style="width: 200px;">
          {{item.data.authors}}
        </div>
      </template>

      <!-- AFFILIATIONS -->
      <template v-slot:item.affiliations="{item}">
        <div style="width: 300px;">
          {{item.data.affiliations}}
        </div>
      </template>

      <!-- REFERENCES -->
      <template v-slot:item.references="{item}">
        <div style="width: 300px;">
          {{item.data.references}}
        </div>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import RecordService from '@/services/record.service';

@Component({
  components: {
    ListView,
  }
})
export default class RecordList extends Vue {
  @Prop([Number]) readonly projectId?: number;

  get query(): Array<object> {
    const query = [];
    if (this.projectId) {
      query.push({
        field: 'projectId',
        operator: 'equals',
        value: this.projectId,
      })
    }
    return query;
  }

  service = RecordService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'RecordForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Project',
      align: 'start',
      sortable: true,
      value: 'projectId',
      filterable: {
        type: 'int',
      }
    },
    {
      text: 'Ovid',
      align: 'start',
      sortable: true,
      value: 'uid',
      filterable: {
        type: 'int',
      }
    },
    {
      text: 'DOI',
      align: 'start',
      sortable: true,
      value: 'doi',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Language',
      align: 'start',
      value: 'language',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Year',
      align: 'start',
      value: 'year',
      filterable: {
        type: 'int',
      }
    },
    {
      text: 'Title',
      align: 'start',
      value: 'title',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Abstract',
      align: 'start',
      value: 'abstract',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Authors',
      align: 'start',
      value: 'authors',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Affiliations',
      align: 'start',
      value: 'affiliations',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'References',
      align: 'start',
      value: 'affiliations',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

<template>
  <v-menu v-bind="menuAttrs">
    <template #activator="menu">
      <v-tooltip :disabled="!tooltip" bottom>
        <template #activator="tooltip">
          <v-btn
            v-bind="{ ...menu.attrs, ...tooltip.attrs, ...btnAttrs }"
            v-on="{ ...menu.on, ...tooltip.on, ...$listeners, ...btnListeners }"
          >
            <v-icon v-if="icon" v-bind="iconAttrs" :left="btnText !== null">{{ icon }}</v-icon>
            <span v-text="btnText"></span>
            <v-icon v-if="chevronDown" right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MenuTooltip extends Vue {
  @Prop({ type: String, default: null }) icon!: string
  @Prop({ type: String, default: '' }) tooltip!: string
  @Prop({ type: String, default: null }) btnText!: string
  @Prop({ type: Boolean, default: false }) chevronDown!: boolean
  @Prop({ type: Object, default: () => ({}) }) menuAttrs!: any
  @Prop({ type: Object, default: () => ({}) }) btnAttrs!: any
  @Prop({ type: Object, default: () => ({}) }) btnListeners!: any
  @Prop({ type: Object, default: () => ({}) }) iconAttrs!: any
}
</script>

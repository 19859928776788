var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-100"},[_c('v-datetime-picker',_vm._b({attrs:{"text-field-props":{
      hideDetails: 'auto',
      readonly: true,
      clearable: true,
      prependInnerIcon: 'mdi-calendar-clock',
      outlined: true,
      dense: true,
    },"time-picker-props":{
        ampmInTitle: true,
    },"date-picker-props":_vm.datePickerAttrs},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar-month")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-outline")])]},proxy:true},{key:"actions",fn:function({ parent }){return [_c('v-btn',{attrs:{"text":"","color":"primary"},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.cancel'))}})]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":parent.okHandler}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.apply'))}})])]}}]),model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},'v-datetime-picker',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'ai_tasks'"
      title="AI Tasks"
      :headers="headers"
      :service="service"
      index="ai_task"
      hide-new-button
      fill-height
      hide-collapsable
    >
      <!-- ACTION -->
      <template v-slot:item.action="{item}">
        <v-chip class="ma-2" label :color="item.data.action | color('action')" small>
          {{ $t('action.' + item.data.action) }}
        </v-chip>
      </template>

      <!-- STAGE -->
      <template v-slot:item.stage="{item}">
        <v-chip class="ma-2" label :color="item.data.stage | color('stage')" small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </template>

      <!-- MODEL -->
      <template v-slot:item.model="{item}">
        {{item.getMetaGptModel()}}
      </template>

      <!-- RANGE -->
      <template v-slot:item.range="{item}">
        {{item.getMetaRange()}}
      </template>

      <!-- PROJECT -->
      <template v-slot:item.projectId="{item}">
        <v-chip
          :to="{name: 'ProjectForm', params: {id: item.data.projectentity.data.id}}"
          class="ma-2"
          label
          small
        >
          {{ item.data.projectentity.getLabel() }}
        </v-chip>
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import TaskService from '@/services/task.service';
import ProjectService from '@/services/project.service';
import ProjectModel from '@/models/project.model';

@Component({
  components: {
    ListView,
  }
})
export default class TaskList extends Vue {
  service = TaskService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'TaskForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Project',
      align: 'start',
      sortable: true,
      value: 'projectId',
      filterable: {
        field: 'projectId',
        type: 'distinct',
        service: ProjectService,
        itemText: (project: ProjectModel) => project.getLabel()
      }
    },
    {
      text: 'Action',
      align: 'start',
      sortable: true,
      value: 'action',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Stage',
      align: 'start',
      value: 'stage',
    },
    {
      text: 'Model',
      align: 'start',
      value: 'model',
      cellClass: 'text-no-wrap',
    },
    {
      text: 'Range',
      align: 'start',
      value: 'range',
      cellClass: 'text-no-wrap',
    },
    {
      text: 'Success',
      align: 'start',
      sortable: true,
      value: 'count',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Failed',
      align: 'start',
      sortable: true,
      value: 'failedCount',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Started at',
      align: 'start',
      sortable: true,
      value: 'startedAt',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'date',
      },
    },
    {
      text: 'Finished at',
      align: 'start',
      sortable: true,
      value: 'finishedAt',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'date',
      },
    },
    {
      text: 'Failed at',
      align: 'start',
      sortable: true,
      value: 'failedAt',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'date',
      },
    },
    {
      text: 'Canceled at',
      align: 'start',
      sortable: true,
      value: 'canceledAt',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'date',
      },
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

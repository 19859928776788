<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'data-extraction-presets'"
      title="Data Extraction Presets"
      :headers="headers"
      :service="service"
      :options="{
        filters: [{
          field: 'template',
          operator: 'equals',
          value: 1,
        }]
      }"
      searchable
      hide-presets
      index="data_extraction_presets"
      fill-height
      hide-collapsable
    >
      <!-- Project -->
      <template v-slot:item.project.label="{item}">
        {{ item.data.projectentity?.data.label }}
      </template>

      <!-- STAGE -->
      <template v-slot:item.stage="{item}">
        <v-chip class="ma-2" label :color="item.data.stage | color('stage')" small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import SurveyService from '@/services/survey.service';

@Component({
  components: {
    ListView,
  }
})
export default class DataExtractionPresetsList extends Vue {

  service = SurveyService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      value: '_first',
      width: 72,
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'DataExtractionPresetsForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'string',
      },
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

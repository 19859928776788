import Model from '../core/model';
import UserModel from './user.model';

export default class UserIpModel extends Model {

  relatedMap() {
    return {
      userentity: UserModel,
    }
  }

}

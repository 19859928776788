<template>
  <ModalDialog
    v-model="syncedVisible"
    :title="model && model.data.label || 'General Help'"
    :max-width="section === 'form' ? 600 : 1200"
    icon="mdi-video-outline"
    background-color="background"
    scrollable
    default-buttons
    persistent
  >
    <template #content>
      <v-card-text class="background pt-6">
        <v-container class="pa-0 ma-0" fluid>
          <v-row v-if="model && section === 'video'" no-gutters>
            <v-col cols="12">
              <v-card dark>
                <v-responsive :aspect-ratio="16/9">
                  <video
                    v-if="syncedVisible"
                    ref="video"
                    playsinline
                    controls
                    autoplay
                    style="float: left; width: 100%; aspect-ratio: 16/9; max-height: 75vh; z-index: 1; position: relative"
                  >
                    <source
                      v-secure-url:src="{
                        file: model.data.videofileentity,
                      }"
                      :type="model.data.videofileentity.data.type"
                      src=""
                    >
                    <track
                      v-if="model.data.vttfileentity.data.id"
                      v-secure-url:src="model.data.vttfileentity"
                      label="English"
                      kind="subtitles"
                      srclang="en"
                      src=""
                      default
                    />
                  </video>
                </v-responsive>
                <v-card-title style="flex: 0">Details</v-card-title>
                <v-card-text class="d-flex overflow-auto">
                  <div class="body-1 mb-n3" v-html="model.data.content"></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="section === 'form'">
            <v-col cols="12">
              <v-expand-transition>
                <v-alert
                  v-if="sent"
                  type="success"
                  prominent
                  class="mb-0"
                >
                  Your request has been successfully received. Our team will review it and respond to you as promptly as
                  possible. Thank you for reaching out to us.
                </v-alert>
              </v-expand-transition>

              <v-card v-if="!sent">
                <v-card-text>
                  <v-form
                    ref="form"
                    v-test-id="'tutorial-modal-form'"
                    v-model="formIsValid"
                    lazy-validation
                    @submit="onSubmitForm"
                  >
                    <v-autocomplete
                      v-model="contactModel.data.type"
                      :rules="[rules.required]"
                      :items="contactTypeList"
                      label="Request Type"
                      class="mb-4"
                      outlined
                      hide-details="auto"
                      clearable
                    />
                    <v-textarea
                      v-model="contactModel.data.message"
                      :rules="[rules.required]"
                      label="Message"
                      outlined
                      hide-details="auto"
                      clearable
                    />
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template #buttons>
      <template v-if="section === 'form'">
        <v-switch
          v-if="section === 'form' && !sent"
          v-model="contactModel.data.urgent"
          label="Urgent"
          color="error"
          class="ma-2 pa-0 ml-4"
          inset
          hide-details="auto"
        ></v-switch>
        <v-spacer/>
        <v-btn
          v-if="section === 'form' && !sent"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="!canSendForm"
          :loading="sending"
          large
          color="primary"
          @click="onSubmitForm"
        >
          <span>Submit</span>
        </v-btn>
      </template>
      <v-btn
        v-else
        :block="$vuetify.breakpoint.smAndDown"
        large
        color="primary"
        @click="() => section = 'form'"
      >
        <span>I need more help</span>
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        large
        outlined
        @click="() => syncedVisible = false"
      >
        <span v-if="!sent">Cancel</span>
        <span v-else>Close</span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, PropSync, VModel, Watch, Ref} from 'vue-property-decorator';
import {contactTypeList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import Rules from '@/modules/sdk/core/rules';
import ContactService from '@/modules/sdk/services/contact.service';
import ContactModel from '@/modules/sdk/models/contact.model';
import Logger from '@/modules/sdk/core/logger';
import VideoLibraryModel from '@/modules/sdk/models/video-library.model';
import FileModel from '@/modules/sdk/models/file.model';

const d = new Logger('components/TutorialModal');

export type VForm = Vue & { validate: () => boolean, reset: () => boolean, resetValidation: () => boolean }

@Component({
  components: {
    ModalDialog,
  }
})
export default class TutorialModal extends Vue {
  @Ref() readonly form!: VForm;
  @Ref() readonly video!: HTMLVideoElement;
  @VModel({type: VideoLibraryModel, default: null}) model!: VideoLibraryModel;
  @PropSync('visible', {type: Boolean, default: false}) syncedVisible!: boolean;

  contactTypeList = contactTypeList
  formIsValid = false
  sending = false
  sent = false
  section = 'video'
  contactModel = new ContactModel();
  rules: {
    required: (value: string) => any,
  } = {
    required: (value) => true,
  }

  @Watch('visible')
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.form && this.form.resetValidation();
      this.resetSection();
    }
    if (this.sent) {
      setTimeout(() => {
        this.resetContactForm();
      }, 333);
    }
  }

  get fileList(): FileModel[] {
    const files = [];
    if (this.model.data.videofileentity.data.id) {
      files.push(this.model.data.videofileentity);
    }
    if (this.model.data.vttfileentity.data.id) {
      files.push(this.model.data.vttfileentity);
    }
    return files;
  }

  get canSendForm(): boolean {
    return this.formIsValid && !this.sending && !this.sent;
  }

  onSubmitForm(): void {
    if (this.form.validate()) {
      this.sending = true;
      ContactService.getInstance().save(this.contactModel)
        .then(response => {
          if (response.data.view.single) {
            this.contactModel = response.data.view.single;
            this.sent = true;
          }
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.sending = false);
    }
  }

  resetContactForm(): void {
    this.sent = false;
    this.contactModel = new ContactModel();
    this.form && this.form.reset();
  }

  resetSection(): void {
    this.section = 'video';
    if (!this.model) {
      this.section = 'form';
    }
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
    this.resetSection();
  }
}
</script>

<template>
  <ModalDialog
    v-model="dialog"
    :disabled="importing"
    title="Import CSV"
    icon="mdi-database-import"
    width="840"
    background-color="background"
    scrollable
    persistent
    @close="onCloseModal"
  >

    <!-- UPLOAD BUTTON -->
    <template v-if="!hideActivator" #activator="{ on: on1, attrs: attrs1 }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: on2, attrs: attrs2 }">
          <v-btn
            v-bind="{...attrs1, ...attrs2}"
            v-on="{...on1, ...on2}"
            :disabled="disabled"
            color="default"
            text
          >
            <v-icon>mdi-database-import</v-icon>
            Import Records from <strong>CSV</strong>
          </v-btn>
        </template>
        <span>Import Records from <strong>CSV</strong></span>
      </v-tooltip>
    </template>

    <!-- BODY -->
    <template #body>
      <v-window v-model="panelIdx" class="mt-4">

        <!-- FILE INPUT -->
        <v-window-item>
          <v-card>
            <v-card-text>

              <v-file-input
                ref="fileInput"
                v-model="file"
                hide-details="auto"
                show-size
                chips
                label="CSV File"
                accept="text/csv"
                outlined
                @change="onFileInputChange"
                @click:clear="() => clearData()"
              ></v-file-input>

              <!-- EXTRA CSV OPTIONS -->
              <v-expansion-panels class="ml-2" flat>
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header>
                    <div>
                      <v-icon left>mdi-card-bulleted-settings-outline</v-icon>
                      <span>Options</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-checkbox
                      v-model="papaConfig.header"
                      :disabled="hasParsedPreview || hasParsedData || hasParsedDataError"
                      label="Use first line as header"
                      class="ma-0 pa-0"
                      hide-details
                    ></v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>

          <!-- MAPPER -->
          <v-card class="mt-4">
            <v-card-title class="d-flex align-center justify-space-between">
              <span>Mapping</span>
              <div class="d-flex align-center" style="gap: 0.5rem">
                <v-btn :disabled="!file" outlined small @click="onSelectDefaultClick">
                  <v-icon left>mdi-select-all</v-icon>
                  <span v-text="$t('importDiablog.selectDefault')"></span>
                </v-btn>
                <v-btn :disabled="!file" outlined small @click="onClearMapping">
                  <v-icon left>mdi-broom</v-icon>
                  <span v-text="$t('btn.clear')"></span>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <ArrayMapper
                v-model="mapping"
                :first="recordMapping"
                :second="secondList"
                :disabled="!file"
                ref="arrayMapper"
                preselect
                allow-duplicates
              />
            </v-card-text>
          </v-card>
        </v-window-item>

        <!-- PREVIEW -->
        <v-window-item>
          <v-card v-if="panelName === 'preview'">
            <v-card-title>
              <span v-text="$t('importDialog.previewFirstEntry')"></span>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item v-for="(map, mapIdx) in mapping" :key="map + '.' + mapIdx">
                  <v-list-item-content>
                    <v-list-item-title v-text="recordMapping[mapIdx]"></v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      <span v-if="parsedPreview.data[0][map]" v-text="parsedPreview.data[0][map]"></span>
                      <v-chip v-else label x-small>NULL</v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-window-item>

        <!-- REPORT -->
        <v-window-item>
          <v-card>
            <v-alert
              :type="reportAlertType"
              border="bottom"
              colored-border
              class="mb-0"
            >
              <template v-if="reportAlertType === 'info'">
                <strong>Loading</strong>
                <v-progress-linear
                  :indeterminate="!importPercentage"
                  :value="importPercentage"
                  color="info"
                  height="25"
                  striped
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear color="info" indeterminate/>
                <span>Import in progress...</span>
              </template>
              <template v-else-if="reportAlertType === 'success'">
                <strong>Parsed records:</strong> {{parsedData.data.length}}
                <br/><span class="font-italic">Make sure the number of parsed records correspond to the number of rows from the CSV file before importing records.</span>
              </template>
              <template v-if="reportAlertType === 'warning' || reportAlertType === 'error'">
                <strong>Failed records:</strong> {{parsedData.errors.length}}
              </template>
              <template v-if="reportAlertType === 'error'">

                <!-- FRONTEND ERRORS -->
                <div
                  v-for="(error, errorIdx) of parsedData.errors"
                  :key="errorIdx"
                >
                  Row #{{error.row}}: {{error.message}} ({{error.index}})
                </div>

                <!-- BACKEND ERRORS -->
                <p
                  v-for="(backendView, backendViewIndex) of importResult"
                  :key="backendViewIndex"
                >
                  <strong>Import Error</strong>
                  <br/>
                  <strong>Record - </strong> {{backendView.entity.uid}}:
                  {{backendView.entity.title}}
                  <br/>
                  <template v-for="(message, messageIndex) of backendView.messages">
                    <span :key="backendViewIndex + '_' + messageIndex">
                      <br/>
                      <strong>Field "{{message.field}}": </strong>
                      {{message.message}}
                    </span>
                  </template>
                </p>
              </template>
            </v-alert>
          </v-card>
        </v-window-item>
      </v-window>
    </template>

    <!-- BUTTONS -->
    <template #buttons>

      <!-- CANCEL -->
      <v-btn
        outlined
        :disabled="!canCancel"
        @click="onCloseModalClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>

      <v-spacer />

      <!-- PREVIOUS -->
      <v-btn
        :disabled="!canGoPrevious"
        color="primary"
        outlined
        @click="onPreviousClick"
      >
        <v-icon left>mdi-chevron-left</v-icon>
        <span class="pr-1" v-text="$t('btn.previous')"></span>
      </v-btn>

      <!-- NEXT -->
      <v-btn
        :disabled="!canGoNext"
        color="primary"
        outlined
        @click="onNextClick"
      >
        <span class="pr-1" v-text="$t('btn.next')"></span>
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>

      <!-- IMPORT -->
      <v-btn
        :disabled="!canImport"
        :loading="importing"
        color="primary"
        @click="onImportClick"
      >
        <v-icon left>mdi-database-import</v-icon>
        <span class="pr-1">Import</span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Watch, Ref, VModel} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ArrayMapper from '@/modules/common/components/ArrayMapper.vue';
import Papa from 'papaparse';

const d = new Logger('views/Admin/Form/ProjectForm');

interface IRecord {
  [key: string]: string
}

@Component({
  components: {
    ModalDialog,
    ArrayMapper,
  }
})
export default class ImportDialog extends Vue {

  @Ref() readonly arrayMapper!: ArrayMapper;

  @VModel({ type: Boolean, default: () => false }) dialog!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) hideActivator!: boolean;
  @Prop({ type: Number }) readonly projectId!: number;

  importing = false;
  panelIdx = 0;
  panels = ['edit', 'preview', 'report']
  file = null;
  parsedPreview = {} as any;
  parsedData = {} as any;
  recordList: Array<RecordModel> = [];
  importResult: Array<any> = [];
  mapping: Array<string> = [];
  secondList: Array<string> = [];

  /**
   * Default Record Mapping
   */
  recordMapping: Array<string> = [
    'increment',
    'authors',
    'year',
    'title',
    'abstract',
    'doi',
    'affiliations',
    'language',
    'references',
    'uid',
    'url',
  ];

  importMax = 0;
  importBufferValue = 0;
  importValue = 0;

  get importPercentage () {
    return this.importMax? this.importValue * 100 / this.importMax : 0;
  }

  get importBufferPercentage () {
    return this.importMax? this.importPercentage + this.importBufferValue * 100 / this.importMax : 0;
  }

  /**
   * PapaParse Config
   */
  papaConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: '',
    worker: false,
    comments: false,
    step: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: ['\t', ',', '|', ';']
  };

  @Watch('dialog')
  onDialogChange(open: boolean) {
    if (!open) {
      this.close();
    }
  }

  @Watch('panelIdx')
  onPanelIdxChange() {
    if (this.panelName === 'report') {
      this.parse(this.file);
    }
  }

  get hasParsedPreview() : boolean {
    return !!(this.parsedPreview.data && this.parsedPreview.data.length);
  }

  get hasParsedData() : boolean {
    return !!(this.parsedData.data && this.parsedData.data.length);
  }

  get hasParsedDataError() : boolean {
    return !!(this.parsedData.error && this.parsedData.error.length);
  }

  get canGoPrevious(): boolean {
    return (this.panelName !== 'edit'
      && !this.importing
      && this.file)
      || false;
  }

  get canGoNext(): boolean {
    return (this.panelName !== 'report'
      && this.hasParsedPreview
      && !this.importing
      && this.file
      && this.mapping.length > 0)
      || false;
  }

  get canCancel(): boolean {
    return !this.importing;
  }

  get canImport(): boolean {
    return this.panelName === 'report' && !this.importing && !this.hasParsedDataError;
  }

  get panelName(): string {
    return this.panels[this.panelIdx];
  }

  get reportAlertType(): string {
    if (this.importing) {
      return 'info';
    } else if (this.hasParsedData && !this.importResult.length) {
      return 'success';
    } else if ((this.parsedData.errors && this.parsedData.errors.length > 0) || this.importResult.length > 0) {
      return 'error';
    } else if (this.hasParsedDataError) {
      return 'warning';
    }

    return 'info';
  }

  onSelectDefaultClick() {
    // @ts-ignore
    this.mapping = this.arrayMapper.getDefaultSelection();
  }

  onClearMapping() {
    this.mapping = [];
  }

  onCloseModalClick() {
    this.close();
  }

  onCloseModal() {
    this.close();
  }

  onPreviousClick() {
    this.panelIdx--;
  }

  onNextClick() {
    this.panelIdx++;
  }

  onFileInputChange() {
    this.clearData();
    if (this.file) {
      this.parse(this.file, {
        preview: 1
      });
    }
  }

  onImportClick() {
    const records: Array<IRecord> = [];
    this.parsedData.data.forEach((data: any) => {
      const record: IRecord = {};
      this.mapping.forEach((map, mapIdx) => {
        record[this.recordMapping[mapIdx]] = data[map];
      })
      records.push(record);
    });
    this.importRecords(records);
  }

  setSecondList(data: any) {
    this.secondList = Object.keys(data);
  }

  /**
   * Parse CSV using PapaParse
   * - Clear Data first
   * @param file
   * @param config
   * @param callback
   */
  parse(
    file: any,
    config: any = {},
    callback = (data: any) => {},
  ) {
    this.clearData();
    config = {
      ...this.papaConfig,
      ...config,
      ...{
        complete: (results: any, file: any) => {
          if (config.preview) {
            this.parsedPreview = results;
            this.setSecondList(results.data[0]);
          } else {
            this.parsedData = results;
          }

          callback(results);
        },
      }
    };
    Papa.parse(file, config);
  }

  /**
   * Build record for backend
   * @param data
   */
  buildRecord(data: IRecord): RecordModel {
    const model = new RecordModel();
    const keys = Object.keys(data);
    keys.forEach(key => {
      model.data[key] = data[key];
    })
    model.data.projectId = this.projectId;
    return model;
  }

  /**
   * Import Record Request
   * @param data
   */
  importRecords(data: Array<any>) {
    // Clear model & backend view response
    this.recordList = [];
    this.importResult = [];

    // Import Request
    const recordService = RecordService.getInstance();

    this.importMax = data.length;
    this.importBufferValue = data.length;
    this.importValue = 0;

    this.importing = true;
    const importChunk = () => {

      this.recordList = [];
      const chunkData = data.splice(0, 50);
      chunkData.forEach(record => {
        const rowModel = this.buildRecord(record);
        this.recordList.push(rowModel);
      });

      this.importBufferValue = this.recordList.length;
      recordService.import(this.recordList).then((response) => {
        if (!response.data.view.length && data.length) {
          this.importValue += this.recordList.length;
          importChunk();
        } else {
          this.importing = false;
          if (!response.data.view.length && !data.length) {
            this.close();
            this.$root.$globalSnack.success({
              message: 'Records have been successfully imported.',
            });
          } else {
            this.importResult = response.data.view;
            this.$root.$globalSnack.error({
              message: 'An error has occurred on ' + response.data.view.length + ' records during the import request.',
            });
          }
        }
      })
        .catch(reason => {
          this.importing = false;
          this.$root.$zemit.handleError(reason)
        })
    };

    importChunk();
  }

  /**
   * Clear the data
   */
  clearData() {
    this.importResult = [];
    this.importing = false;
  }

  /**
   * Reset File Input
   */
  resetFileInput() {
    const fileInput = this.$refs.fileInput as any;
    fileInput.reset();
  }

  /**
   * Close the dialog
   * - Clear the data
   * - Clear File Input
   */
  close() {
    this.clearData();
    this.resetFileInput();
    this.dialog = false;

    setTimeout(() => {
      this.panelIdx = 0;
    }, 300);
  }
}
</script>

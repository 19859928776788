<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'role'"
      v-model="model"
      :service="service"
      :loading="isLoading"
      name="role"
      title="Role"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              name="index"
              v-model="model.data.index"
              :error-messages="formErrors.index"
              :rules="[rules.required]"
              :disabled="!!(model.data.id)"
              label="Index"
              hide-details="auto"
              outlined
              clearable
              required
              @input="formErrors = {}"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              name="label"
              v-model="model.data.label"
              :error-messages="formErrors.label"
              label="Label"
              hide-details="auto"
              outlined
              clearable
              @input="formErrors = {}"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-autocomplete
              name="userlist"
              v-model="model.data.userlist"
              :items="relations.userList"
              :item-text="(item) => item.getFullName()"
              :error-messages="formErrors.userlist"
              :loading="userListLoading && !loading"
              item-value="data.id"
              label="Users"
              hide-details="auto"
              outlined
              chips
              clearable
              deletable-chips
              multiple
              return-object
              @input="formErrors = {}"
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import RoleModel from '@/modules/sdk/models/role.model';
import UserService from '@/modules/sdk/services/user.service';
import RoleService from '@/modules/sdk/services/role.service';

@Component({
  components: {
    DataForm,
  }
})
export default class RoleForm extends Vue {
  service = RoleService.getInstance();
  loading = false;
  model: RoleModel = new RoleModel();

  userListLoading = false;
  relations = {
    userList: []
  };

  get isLoading() {
    return this.loading || this.userListLoading;
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadRelations() {
    this.userListLoading = true;
    UserService.getInstance().getAll()
      .then(response => this.relations.userList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.userListLoading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }

    // Load relations
    this.loadRelations();
  }
}
</script>

import _Vue, { VueConstructor } from 'vue';
import Zemit from './zemit';
import { AxiosRequestConfig } from 'axios';
import deepmerge from 'deepmerge';

export class ZemitOptions {
  apiUrl = '';
  axiosRequestConfig?: AxiosRequestConfig;
}

export default class ZemitVue {

  // @ts-ignore
  public zemit: Zemit;
  static installed = false;
  static config: Partial<ZemitOptions> = {};
  // installed: string[] = [];
  // services: {[key: string]: ZemitService} = {};

  constructor (config: Partial<ZemitOptions> = {}) {
    ZemitVue.config = deepmerge.all([ZemitVue.config, config]);
  }

  static install (Vue: VueConstructor, config: Partial<ZemitOptions> = {}) {
    if (this.installed) return;
    this.installed = true;

    if (_Vue !== Vue) {
      // consoleError(`Multiple instances of Vue detected`);
    }

    ZemitVue.config = deepmerge.all([ZemitVue.config, config]);
    Vue.prototype.$zemit = new Zemit(Vue, ZemitVue.config);

    // Used to avoid multiple mixins being setup
    // when in dev mode and hot module reload
    // https://github.com/vuejs/vue/issues/5089#issuecomment-284260111
    if (Vue.$zemitInstalled) return;
    Vue.$zemitInstalled = true;

    // @todo check if this is too heavy for nothing
    Vue.mixin({
      beforeCreate () {
        if (this.$options.zemit) {
          this.$zemit.init(this, ZemitVue.config);
        }
      },
    });
  }
}



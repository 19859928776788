<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'survey'"
      v-bind="$attrs"
      v-model="_model"
      :service="service"
      :loading="loading"
      :redirect-on-create="!projectId"
      name="survey"
      title="Data Extraction"
      icon="mdi-comment-question-outline"
      hide-back-button
    >
      <template #form>
        <Survey
          :value="_model"
          :labels="{
            groups: {
              add: $t('survey.labels.addGroup'),
              remove: $t('survey.labels.removeGroup'),
            },
            questions: {
              add: $t('survey.labels.addQuestion'),
              remove: $t('survey.labels.removeQuestion'),
            },
            questionsAi: {
              add: $t('survey.labels.addQuestionAi'),
              remove: $t('survey.labels.removeQuestionAi'),
            },
          }"
          :group-attrs="{
            label: $t('survey.group.label')
          }"
          :question-attrs="{
            label: $t('survey.question.label')
          }"
          editable
          sortable
          disable-if-answered
          show-templates
          @append-template="onAppendTemplate"
        />
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, ModelSync} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import Logger from '@/modules/sdk/core/logger';
import SurveyModel from '@/models/survey.model';
import SurveyService from '@/services/survey.service';
import Survey from '@/components/Survey/Survey.vue';

const d = new Logger('views/Admin/Form/SurveyForm');

@Component({
  components: {
    DataForm,
    Survey,
  }
})
export default class SurveyForm extends Vue {
  @Prop() id!: number;
  @ModelSync('vModel', 'change', {default: () => (new SurveyModel())}) _model!: SurveyModel;
  @Prop() projectId?: number;

  service = SurveyService.getInstance();
  loading = false;

  onAppendTemplate(survey: SurveyModel) {
    if (this.projectId) {
      this._model.setReferenceKeyValue('projectId', this.projectId);
    }
    if (this._model.data.id) {
      this._model.setReferenceKeyValue('surveyId', this._model.data.id);
    }
  }
}
</script>

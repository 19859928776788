import Model, {Data} from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyModel from '@/models/survey.model';
import SurveyChoiceModel from '@/models/survey-choice.model';
import SurveyQuestionModel from '@/models/survey-question.model';
import SurveyGroupModel from '@/models/survey-group.model';
import RecordModel from '@/models/record.model';
import UserModel from '@/modules/sdk/models/user.model';

export default class SurveyAnswerModel extends Model {

  default() {
    return {
      surveychoicelist: [],
      recordlist: [],
    };
  }

  relatedMap () {
    return {
      projectentity: ProjectModel,
      surveyentity: SurveyModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
      surveychoicelist: SurveyChoiceModel,
      recordlist: RecordModel,
      userentity: UserModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveychoicelist: true,
      recordlist: false,
    };
  }

  constructor(
    data: Data | Model = {}
  ) {
    super(data);

    const type = this.data.surveyquestionentity?.data?.type;
    if (this.data.content !== null) {
      switch (type) {
        case 'datetime':
        case 'datetime_range':
          this.data.content = new Date(this.data.content);
          break;
        case 'rating':
        case 'year':
          this.data.content = parseInt(this.data.content);
          break;
        case 'checkbox':
          this.data.content = !!(this.data.content);
          break;
      }
    }
    this.setOriginalData();
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveychoicelist', 'position');
  }
}

<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="asking"
    title="Ask question to the AI"
    icon="mdi-robot"
  >
    <template #header>
      <v-tabs v-model="tab" grow>
        <v-tab>
          <span>Questions</span>
        </v-tab>
        <v-tab v-if="project">
          <span>Tasks</span>
        </v-tab>
        <v-tab v-if="record">
          <span>History</span>
        </v-tab>
      </v-tabs>
    </template>
    <template #body>
      <v-alert
        v-for="error in errors"
        :key="error.index"
        class="mt-2 mb-0"
        type="error"
      >
        <span v-text="error.message.content"></span>
      </v-alert>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form v-model="formIsValid">
            <v-row>
              <v-col cols="12" class="mt-6">
                <v-autocomplete
                  v-model="config.gptModel"
                  :items="gptModelList"
                  label="GPT Model"
                  hide-details="auto"
                  outlined
                  attach
                >
                  <template #item="{ item }">
                    <div class="d-flex align-center py-3" style="gap: 1rem">
                      <v-icon color="primary" large left>mdi-robot</v-icon>
                      <div>
                        <strong>{{ item.text }}</strong>
                        <br><span>{{ item.description }}</span>
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="mb-4">System prompt:</h3>
                <v-select
                  v-model="aiPrompt"
                  ref="aiPromptSelect"
                  :rules="[rules.required]"
                  :items="aiPromptList"
                  :loading="loadingAiPrompt"
                  item-text="data.title"
                  item-value="data.id"
                  outlined
                  hide-details="auto"
                  clearable
                  required
                  return-object
                  attach
                  @change="onAiPromptChange"
                >
                  <template #append>
                    <v-btn
                      v-if="aiPrompt"
                      class="ma-0 pa-0"
                      style="margin-top: -5px !important"
                      icon
                      @click="onAiPromptEditClick"
                    >
                      <v-icon v-if="aiPromptEdit">mdi-eye-off-outline</v-icon>
                      <v-icon v-else>mdi-eye-outline</v-icon>
                    </v-btn>
                  </template>
                </v-select>
                <v-textarea
                  v-show="aiPromptEdit"
                  v-if="aiPrompt"
                  v-model="aiPromptSystemContent"
                  :loading="loadingAiPrompt"
                  height="22rem"
                  class="mt-3"
                  outlined
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12">

                <h3 class="mb-4">Any additional info to pass to the AI?:</h3>
                <v-textarea
                  v-model="aiPromptUserContent"
                  :disabled="asking"
                  placeholder="This is optional.."
                  height="8rem"
                  outlined
                  hide-details="auto"
                />

                <!-- SETTINGS -->
                <template v-if="project">
                  <h3 class="mt-4">Settings</h3>
                  <v-row no-gutters>
                    <v-col cols="12" class="mb-4">
                      <h5>Records range</h5>
                      <v-range-slider
                        v-model="config.range"
                        :loading="loadingRecordRange"
                        :min="1"
                        :max="maxRecordRange"
                        :step="1"
                        thumb-label
                        hide-details="auto"
                        thumb-color="primary"
                        class="align-center"
                      >
                        <template #prepend>
                          <v-text-field
                            :value="config.range[0]"
                            :loading="loadingRecordRange"
                            type="number"
                            step="1"
                            outlined
                            dense
                            hide-details="auto"
                            style="max-width: 10rem"
                            @change="$set(config.range, 0, $event)"
                          />
                        </template>
                        <template #append>
                          <v-text-field
                            :value="config.range[1]"
                            :loading="loadingRecordRange"
                            type="number"
                            step="1"
                            outlined
                            dense
                            hide-details="auto"
                            style="max-width: 10rem"
                            @change="$set(config.range, 1, $event)"
                          />
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>

                  <v-divider class="my-1" />
                </template>

                <v-expansion-panels flat tile>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">Advanced options</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mx-n6" no-gutters>
                        <v-col cols="12">
                          <v-slider
                            v-model="config.temperature"
                            :min="0"
                            :max="2"
                            :step="0.1"
                            class="align-center"
                            label="Temperature"
                            thumb-label
                            hide-details="auto"
                            thumb-color="primary"
                          >
                            <template #prepend>
                              <v-tooltip left max-width="250">
                                <template #activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                  </v-icon>
                                </template>
                                <span>What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. OpenAI models are non-deterministic, meaning that identical inputs can yield different outputs. Setting temperature to 0 will make the outputs mostly deterministic, but a small amount of variability may remain.</span>
                              </v-tooltip>
                            </template>
                            <template #append>
                              <v-text-field
                                v-model.number="config.temperature"
                                type="number"
                                step="0.1"
                                outlined
                                dense
                                hide-details="auto"
                                style="max-width: 6rem"
                              />
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col cols="12">
                          <v-slider
                            v-model="config.frequencyPenalty"
                            :min="-2"
                            :max="2"
                            :step="0.1"
                            class="align-center"
                            label="Frequency Penalty"
                            thumb-label
                            hide-details="auto"
                            thumb-color="primary"
                          >
                            <template #prepend>
                              <v-tooltip left max-width="250">
                                <template #activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                  </v-icon>
                                </template>
                                <span>Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.</span>
                              </v-tooltip>
                            </template>
                            <template #append>
                              <v-text-field
                                v-model.number="config.frequencyPenalty"
                                type="number"
                                step="0.1"
                                outlined
                                dense
                                hide-details="auto"
                                style="max-width: 6rem"
                              />
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col cols="12">
                          <v-slider
                            v-model="config.presencePenalty"
                            :min="-2"
                            :max="2"
                            :step="0.1"
                            class="align-center"
                            label="Presence Penalty"
                            thumb-label
                            hide-details="auto"
                            thumb-color="primary"
                          >
                            <template #prepend>
                              <v-tooltip left max-width="250">
                                <template #activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                  </v-icon>
                                </template>
                                <span>Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.</span>
                              </v-tooltip>
                            </template>
                            <template #append>
                              <v-text-field
                                v-model.number="config.presencePenalty"
                                type="number"
                                step="0.1"
                                outlined
                                dense
                                hide-details="auto"
                                style="max-width: 6rem"
                              />
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col cols="12">
                          <v-slider
                            v-model="config.topP"
                            :min="0"
                            :max="1"
                            :step="0.1"
                            class="align-center"
                            label="Top P"
                            thumb-label
                            hide-details="auto"
                            thumb-color="primary"
                          >
                            <template #prepend>
                              <v-tooltip left max-width="250">
                                <template #activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                  </v-icon>
                                </template>
                                <span>An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.</span>
                              </v-tooltip>
                            </template>
                            <template #append>
                              <v-text-field
                                v-model.number="config.topP"
                                type="number"
                                step="0.1"
                                outlined
                                dense
                                hide-details="auto"
                                style="max-width: 6rem"
                              />
                            </template>
                          </v-slider>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item v-if="project">
          <v-data-table
            v-model="selected"
            :headers="taskHeaders"
            :items="tasks"
            :loading="loading"
            :sort-by="['data.createdAt']"
            :sort-desc="[true]"
            fixed-header
            show-select
            single-select
            item-key="data.id"
            checkbox-color="primary"
            class="mt-3"
          >
            <template #item.data.action="{ item }">
              {{ getTaskActionAttrs(item).text }}
            </template>
            <template #item.data.stage="{ item }">
              <v-chip v-bind="getTaskStageAttrs(item)" label small>
                <v-icon left small>{{ getTaskStageAttrs(item).icon }}</v-icon>
                <span v-text="getTaskStageAttrs(item).text"></span>
              </v-chip>
            </template>
            <template #item.data.status="{ item }">
              <v-chip v-bind="getTaskStatusAttrs(item)" label small>
                <v-icon left small>{{ getTaskStatusAttrs(item).icon }}</v-icon>
                <span v-text="getTaskStatusAttrs(item).text"></span>
              </v-chip>
            </template>
            <template #item.data.result="{ item }">
              <v-chip v-if="!item.data.result" label small>Empty</v-chip>
              <span v-else>{{ item.data.result }}</span>
            </template>
            <template #item.data.count="{ item }">
              <v-chip
                :color="item.data.count > 0 ? 'success' : null"
                label
                small
              >{{ item.data.count }}</v-chip>
            </template>
            <template #item.data.failedCount="{ item }">
              <v-chip
                :color="item.data.failedCount > 0 ? 'error' : null"
                label
                small
              >{{ item.data.failedCount }}</v-chip>
            </template>
            <template #item.__action="{ item, index }">
              <v-btn
                :disabled="item.states.deleting"
                :loading="item.states.deleting"
                icon
                color="error"
                @click="onDeleteTask(item, index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="record">
          <v-data-table
            v-model="selected"
            :headers="historyHeaders"
            :items="history"
            :loading="loading"
            :sort-by="['data.createdAt']"
            :sort-desc="[true]"
            fixed-header
            show-select
            single-select
            item-key="data.id"
            checkbox-color="primary"
            class="mt-3"
          >
            <template #item.data.status="{ item }">
              <v-chip v-bind="getStatusAttrs(item)" label v-text="getStatusAttrs(item).text"></v-chip>
            </template>
            <template #item.data.comment="{ item }">
              <div
                v-text="item.data.comment"
                style="max-height: 5rem"
                class="overflow-auto"
              />
            </template>
            <template #item.data.confidence="{ item }">
              {{ item.data.confidence }}%
            </template>
            <template #item.data.rating="{ item }">
              <v-rating
                v-model.number="item.data.rating"
                color="warning"
                background-color="warning"
                large
                @input="val => onRatingChange(item)"
              ></v-rating>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template #prepend_actions>
      <v-btn
        v-if="tab === 0"
        large
        outlined
        :disabled="!canReset"
        @click="onResetClick"
      >
        <span>Reset</span>
      </v-btn>
      <v-btn
        v-if="tab === 1"
        large
        outlined
        :disabled="loading"
        :loading="loading"
        @click="onRefreshClick"
      >
        <span>Refresh</span>
      </v-btn>
    </template>
    <template #buttons>
      <v-btn
        v-if="tab === 0"
        large
        color="primary"
        :loading="asking"
        :disabled="!canAsk"
        @click="onAskClick"
      >
        <span v-text="$t('btn.askAi')"></span>
      </v-btn>
      <v-btn
        v-if="tab === 1 && !project"
        large
        color="primary"
        :disabled="!canApply"
        @click="onApplyAnswer"
      >
        <span v-text="$t('btn.apply')"></span>
      </v-btn>
      <v-btn
        large
        text
        :disabled="asking"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import {Vue, Component, VModel, Watch, Prop, Ref} from 'vue-property-decorator'
import { gptModelList, stageList, statusList, taskActionList, taskStatusList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import Rules from '@/modules/sdk/core/rules';
import RecordModel from '@/models/record.model';
import AiService from '@/services/ai.service';
import RecordAiStatusModel from '@/models/record-ai-status.model';
import RecordAiStatusService from '@/services/record-ai-status.service';
import ProjectModel from '@/models/project.model';
import TaskModel from '@/models/task.model';
import TaskService from '@/services/task.service';
import AiPromptService from '@/services/ai-prompt.service';
import RecordService from '@/services/record.service';
import AiPromptModel from '@/models/ai-prompt.model';
import Identity from '@/modules/sdk/core/identity';

let pullInterval: any;

export type VSelect = Vue & { isMenuActive: boolean }

@Component({
  components: {
    ModalDialog,
  }
})
export default class AskQuestionToAIProjectModal extends Vue {
  @Ref() readonly aiPromptSelect!: VSelect

  @VModel({
    type: Boolean
  }) visible!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean

  @Prop({
    type: RecordModel
  }) record!: RecordModel

  @Prop({
    type: ProjectModel
  }) project!: ProjectModel

  @Prop({
    type: String,
  }) stage!: 'screening' | 'indepth' | 'final' | string

  gptModelList = gptModelList
  rules: any = {}
  errors: any = {}
  tab = 0
  maxRecordRange = 1
  selected: Array<any> = []
  loading = false
  loadingAiPrompt = false
  loadingRecordRange = false
  memoryIsGlobal = true
  asking = false
  formIsValid = false
  history: Array<any> = []
  tasks: Array<any> = []

  aiPromptList: Array<AiPromptModel> = []
  aiPrompt: AiPromptModel|null = null;
  aiPromptSystemContent = '';
  aiPromptUserContent = '';
  aiPromptEdit = false;

  config: any = {}
  defaultConfig: any = {
    gptModel: 'gpt-4o-mini',
    temperature: 1,
    range: [0, 100],
    frequencyPenalty: 0,
    presencePenalty: 0,
    topP: 1,
  }

  historyHeaders: Array<any> = [{
    class: 'text-no-wrap',
    cellClass: 'text-no-wrap',
    width: 0,
    text: 'Date',
    value: 'data.createdAt',
  }, {
    class: 'text-no-wrap',
    text: 'Status',
    value: 'data.status',
    width: '10%',
  }, {
    class: 'text-no-wrap',
    text: 'Comment',
    value: 'data.comment',
    width: '33%',
  }, {
    class: 'text-no-wrap',
    width: 0,
    text: 'Confidence',
    value: 'data.confidence',
  }, {
    class: 'text-no-wrap',
    width: 0,
    text: 'Rating',
    value: 'data.rating',
  }];

  taskHeaders: Array<any> = [{
    class: 'text-no-wrap',
    cellClass: 'text-no-wrap',
    width: 0,
    text: 'Date',
    value: 'data.createdAt',
  }, {
    class: 'text-no-wrap',
    text: 'Action',
    value: 'data.action',
    width: '15%',
  }, {
    class: 'text-no-wrap',
    text: 'Stage',
    value: 'data.stage',
    width: 0,
  }, {
    class: 'text-no-wrap',
    text: 'Status',
    value: 'data.status',
    width: 0,
  }, {
    class: 'text-no-wrap',
    width: 0,
    text: 'Success',
    value: 'data.count',
  }, {
    class: 'text-no-wrap',
    width: 0,
    text: 'Failed',
    value: 'data.failedCount',
  }, {
    class: 'text-no-wrap',
    width: 0,
    text: '',
    value: '__action',
  }];

  @Watch('visible', {
    immediate: true,
  })
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.tab = 0;
      this.selected = [];
      this.asking = false;
      this.errors = {};
      this.loadServerPrompt();
      this.loadUserPrompt();

      if (this.project) {
        this.countRecordRange();
        this.loadTasks();
      }
      if (this.record) {
        this.loadHistory();
      }
    }
  }

  get canAsk(): boolean {
    const hasRoleAi = Identity.hasRole(['dev', 'admin']) || (this.project && this.project.hasRole(['ai-manager']));
    return !this.asking
      && this.formIsValid
      && this.tab === 0
      && hasRoleAi;
  }

  get canApply(): boolean {
    return this.selected.length > 0
      && this.tab === 1
      && !this.disabled;
  }

  get canReset(): boolean {
    return JSON.stringify(this.config) !== JSON.stringify(this.defaultConfig)
      || (this.aiPromptUserContent || '').length > 0;
  }

  onAiPromptEditClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.aiPromptEdit = !this.aiPromptEdit;
    this.aiPromptSelect.isMenuActive = false;
  }

  onAiPromptChange() {
    this.aiPromptSystemContent = this.aiPrompt?.data.content || '';
  }

  onResetClick(): void {
    this.loadUserPrompt();
    this.config = JSON.parse(JSON.stringify(this.defaultConfig));
  }

  onRefreshClick(): void {
    if (this.project) {
      this.loadTasks();
    }
    if (this.record) {
      this.loadHistory();
    }
  }

  onAskClick(): void {
    if (this.record) {
      this.askRecord();
    } else if (this.project) {
      this.askProject();
    }
  }

  onDeleteTask(task: TaskModel, index: number): void {
    this.$root.$shouldTakeAction.askDelete({
      onAccept: () => {
        task.states.deleting = true;
        TaskService.getInstance().delete({
          id: task.data.id,
        })
          .then(() => {
            this.$root.$globalSnack.success({
              message: 'Task deleted successfully!'
            })
            this.tasks.splice(index, 1);
          })
          .finally(() => task.states.deleting = false);
      }
    })
  }

  onRatingChange(answer: RecordAiStatusModel): void {
    answer.states.saving = true;
    RecordAiStatusService.getInstance().save({
      id: answer.data.id,
      rating: answer.data.rating,
    })
      .then(response => answer.assign(response.data.view.single))
      .finally(() => answer.states.saving = false);
  }

  onApplyAnswer(): void {
    if (this.selected.length > 0) {
      this.visible = false;
      this.$emit('apply', this.selected[0]);
    }
  }

  onCancelClick(): void {
    this.visible = false;
  }

  getTaskActionAttrs(item: any): any {
    const found = taskActionList.find(entry => entry.value === item.data.action);
    if (found) {
      return found;
    }
    return {};
  }

  getTaskStatusAttrs(item: any): any {
    const found = taskStatusList.find(entry => entry.value === item.data.status);
    if (found) {
      return found;
    }
    return {};
  }

  getTaskStageAttrs(item: any): any {
    const found = stageList.find(entry => entry.value === item.data.stage);
    if (found) {
      return found;
    }
    return {};
  }

  getStatusAttrs(item: RecordAiStatusModel): any {
    const found = statusList.find(entry => entry.value === item.data.status);
    if (found) {
      return found;
    }
    return {};
  }

  askRecord() {
    this.asking = true;

    const projectId = this.record.data.projectId;
    const recordId = this.record.data.id;
    const stage = this.stage;
    const action = 'ai_project_review';
    const aiPromptId = this.aiPrompt?.data.id;
    const aiPromptSystemContent = this.aiPromptSystemContent;
    const aiPromptUserContent = this.aiPromptUserContent;

    const data = { recordId, projectId, stage, action, aiPromptId, aiPromptSystemContent, aiPromptUserContent, ...structuredClone(this.config) };
    delete data.range;

    this.saveUserPrompt();
    AiService.getInstance().getRecordStatus(data)
      .then(response => {
        if (response.data.view.list && Array.isArray(response.data.view.list)) {
          this.errors = {};
          this.tab = 1;
          const answers = response.data.view.list.map((item: any) => new RecordAiStatusModel(item.data.entity));
          this.history.push(...answers);
          this.$emit('answer', answers);
        } else {
          this.errors = JSON.parse(response.data.view.response.entity.choices);
        }
      })
      .catch(reason => {
        this.$root.$globalSnack.error({
          message: reason.response.data.view?.chat?.error || 'An unexpected error has occurred during the AI request or response.',
          icon: 'mdi-emoticon-dead-outline'
        });
      })
      .finally(() => this.asking = false)
  }

  askProject() {
    this.asking = true;

    const projectId = this.project.data.id;
    const stage = this.project.data.stage;
    const action = 'ai_project_review';
    const aiPromptId = this.aiPrompt?.data.id;
    const aiPromptSystemContent = this.aiPromptSystemContent;
    const aiPromptUserContent = this.aiPromptUserContent;
    const data = { projectId, stage, action, aiPromptId, aiPromptSystemContent, aiPromptUserContent };
    const model = new TaskModel(data);
    model.data.meta = {
      ...data,
      ...structuredClone(this.config)
    };

    const taskService = TaskService.getInstance();
    taskService.save(model)
      .then(response => {
        model.assign(response.data.view.single);
        this.tab = 1;
        this.loadTasks();
        this.saveUserPrompt();
        pullInterval = setInterval(() => {
          this.pullTaskStatus(model);
          if (this.asking) {
            clearInterval(pullInterval);
          }
        }, 1000);
      })
      .catch(reason => {
        this.asking = false;
        return this.$root.$zemit.handleError(reason);
      })
  }

  pullTaskStatus(model: TaskModel): Promise<any> {
    return TaskService.getInstance().get(model).then(response => {
      model.assign(response.data.view.single)
      if (model.data.status !== 'running') {
        this.asking = false;
      } else {
        this.loadTasks();
      }
      return model;
    })
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  loadUserPrompt(): void {
    if (this.memoryIsGlobal) {
      this.aiPromptUserContent = localStorage.getItem('ai_user_prompt_project') || '';
    } else if (this.project) {
      this.aiPromptUserContent = localStorage.getItem('ai_user_prompt_project_' + this.project.data.id) || '';
    } else if (this.record) {
      this.aiPromptUserContent = localStorage.getItem('ai_user_prompt_record_' + this.record.data.id) || '';
    }
  }

  saveUserPrompt(): void {
    if (this.memoryIsGlobal) {
      localStorage.setItem('ai_user_prompt_project', this.aiPromptUserContent || '');
    } else if (this.project) {
      localStorage.setItem('ai_user_prompt_project_' + this.project.data.id, this.aiPromptUserContent || '');
    } else if (this.record) {
      localStorage.setItem('ai_user_prompt_record_' + this.record.data.id, this.aiPromptUserContent || '');
    }
  }

  loadTasks(): Promise<any> {
    this.loading = true;
    return TaskService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.project.data.id,
      }]
    })
      .then(response => this.tasks = response.data.view.list)
      .finally(() => this.loading = false);
  }

  loadHistory(): Promise<any> {
    this.loading = true;
    return RecordAiStatusService.getInstance().getAll({
      filters: [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    })
      .then(response => this.history = response.data.view.list)
      .finally(() => this.loading = false);
  }

  countRecordRange(): Promise<any> {
    this.loadingRecordRange = true;
    return RecordService.getInstance().count({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.project.data.id,
      }]
    })
      .then(response => {
        this.maxRecordRange = response.data.view.count;
        this.config.range = [1, this.maxRecordRange];
      })
      .finally(() => this.loadingRecordRange = false);
  }

  loadServerPrompt(action = 'record_review'): Promise<any> {
    this.loadingAiPrompt = true;
    this.aiPromptList = [];
    this.aiPrompt = null;
    return AiPromptService.getInstance().getAll({
      filters: [{
        field: 'action',
        operator: 'equals',
        value: action,
      }]
    })
      .then(response => {
        this.aiPromptList = response.data.view.list
        if (this.aiPromptList[0]) {
          this.aiPrompt = this.aiPromptList[0];
          this.aiPromptSystemContent = this.aiPrompt.data.content;
        }
      })
      .finally(() => this.loadingAiPrompt = false);
  }

  destroyed() {
    clearInterval(pullInterval);
  }

  created() {
    this.config = structuredClone(this.defaultConfig);
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>

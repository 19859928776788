import {Route} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('router/guards/auth.guard');

const authGuard = (to: Route, from: Route, next: NavigationGuardNext) => {

  // If user is already logged in
  if (Identity.isLoggedIn()) {

    // Dispatch to the Home View
    return next({name: 'HomeView'});
  }

  return next();
}

export default authGuard;

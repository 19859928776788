<template>
  <v-file-input
    v-model="model.file"
    v-bind="$attrs"
    v-on="$listeners"
    @change="() => {
      model.data.name = model.file ? model.file.name : null;
      model.data.size = model.file ? model.file.size : null;
    }"
  >
    <template #prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template #append>
      <div class="d-flex h-100 align-center buttons" style="gap: 0.5rem">
        <slot name="append"></slot>
        <v-btn
          v-if="downloadable"
          :disabled="!canDownload"
          :loading="downloading"
          outlined
          small
          @click.stop="onDownloadFile"
        >
          Download
        </v-btn>
      </div>
    </template>
  </v-file-input>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import FileModel from '@/modules/sdk/models/file.model';
import FileService from '@/modules/sdk/services/file.service';
import { saveAs } from 'file-saver';

@Component
export default class FileModelInput extends Vue {
  @VModel({ type: FileModel }) model!: FileModel
  @Prop({ type: Boolean, default: true }) downloadable!: boolean

  downloading = false

  onDownloadFile(): void {
    this.downloading = true;
    FileService.getInstance().downloadAsBlob(this.model)
      .then((response: any) => {
        saveAs(response.data, this.model.data.name);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.downloading = false);
  }

  get canDownload(): boolean {
    return this.model.file?.id && !this.downloading;
  }
}
</script>

<style lang="scss" scoped>
.v-input ::v-deep .v-input__prepend-outer,
.v-input ::v-deep .v-input__append-inner {
  align-self: center !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>

<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'task'"
      v-model="model"
      :service="service"
      :loading="isLoading"
      name="task"
      title="Task"
    >
      <template #options>
        <div class="d-flex align-center" style="gap: 1rem">

          <v-btn
            v-if="showStopTask"
            :disabled="!canStopTask"
            :loading="stoppingTask"
            color="error"
            outlined
            elevation="0"
            @click="onStopTaskClick"
          >
            <v-icon left>mdi-stop</v-icon>
            Stop task
          </v-btn>

          <v-chip :color="getTaskStatusAttrs(model).color" label>
            <v-icon v-if="getTaskStatusAttrs(model).icon" left small>{{ getTaskStatusAttrs(model).icon }}</v-icon>
            <span>{{ getTaskStatusAttrs(model).text }}</span>
          </v-chip>
          <v-chip :color="model.data.count > 0 ? 'success' : ''" label>
            <v-icon left>mdi-check</v-icon>
            <span class="text-h6">{{ model.data.count }}</span>
          </v-chip>
          <v-chip :color="model.data.failedCount > 0 ? 'error' : ''" label>
            <v-icon left>mdi-alert</v-icon>
            <span class="text-h6">{{ model.data.failedCount }}</span>
          </v-chip>
        </div>
      </template>
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="model.data.action"
              :items="taskActionList"
              outlined
              label="Action"
              hide-details="auto"
              cache-items
              deletable-chips
              readonly
            ></v-select>
          </v-col>
          <template v-if="model.data.projectId">
            <v-col cols="12" md="4">
              <v-select
                v-model="model.data.stage"
                :items="stageList"
                outlined
                label="Stage"
                hide-details="auto"
                cache-items
                deletable-chips
                return-object
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <ProjectAutocomplete
                v-model="model.data.projectId"
                :items.sync="relations.projectList"
                :rules="[Rules.required]"
                label="Project"
                hide-details="auto"
                outlined
                readonly
              />
            </v-col>
          </template>
        </v-row>

        <v-row>
          <v-col cols="12" v-if="model.data.meta">
            <v-card
              elevation="2"
              tile
              outlined
              rounded
              max-height="200"
              class="overflow-auto"
            >
              <v-card-text>
                <vue-json-pretty
                  :data="model.data.meta"
                >
                </vue-json-pretty>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              elevation="2"
              tile
              outlined
              rounded
              max-height="60vh"
              class="overflow-auto"
            >
              <v-card-text>
                <vue-json-pretty
                  :data="model.data.result"
                >
                </vue-json-pretty>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </DataForm>

    <RecordAiStatusList
      v-if="model.data.id"
      :filters="[{
        field: 'projectId',
        operator: 'equals',
        value: model.data.projectId
      }, {
        field: 'taskId',
        operator: 'equals',
        value: model.data.id
      }]"
      class="mt-3"
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import TaskModel from '@/models/task.model';
import TaskService from '@/services/task.service';
import ProjectService from '@/services/project.service';
import ProjectAutocomplete from '@/components/ProjectAutocomplete.vue';
import RecordAiStatusList from '@/views/Admin/Component/RecordAiStatusList.vue';
import Rules from '@/modules/sdk/core/rules';
import { stageList, taskStatusList, taskActionList } from '@/enums/global';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

let interval: any;

@Component({
  computed: {
    Rules() {
      return Rules
    }
  },
  components: {
    RecordAiStatusList,
    VueJsonPretty,
    ProjectAutocomplete,
    DataForm,
  }
})
export default class TaskForm extends Vue {
  service = TaskService.getInstance();
  stoppingTask = false;
  loading = false;
  updating = false;
  model: TaskModel = new TaskModel();

  projectListLoading = false;
  relations = {
    projectList: []
  };

  taskActionList = taskActionList
  taskStatusList = taskStatusList
  stageList = stageList;

  get isLoading() {
    return this.loading || this.projectListLoading;
  }

  get showStopTask() {
    return (this.getTaskStatusAttrs(this.model) || {}).value === 'running';
  }

  get canStopTask() {
    return this.showStopTask && !this.stoppingTask
  }

  onStopTaskClick() {
    this.stoppingTask = true;
    TaskService.getInstance().save({
      id: this.model.data.id,
      status: 'canceled',
    })
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.stoppingTask = false);
  }

  getTaskStatusAttrs(item: any): any {
    const found = this.taskStatusList.find(status => status.value === item.data.status);
    if (found) {
      return found;
    }
    return {};
  }

  load(id: number) {
    this.loading = true;
    this.service.get({ id })
      .then(response => {
        this.model = response.data.view.single;
        this.loaded(this.model)
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  async loaded(model: any, delay = 5000) {
    this.model = model;
    const id = model.data.id;
    interval = setInterval(async () => {
      if (this.model.data.status !== 'running' && this.model.data.status !== 'new') {
        clearInterval(interval);
      }
      if (!this.updating) {
        this.updating = true;
        await this.service.get({ id })
          .then(response => this.model = response.data.view.single)
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => this.updating = false);
      }
    }, delay);

    return model;
  }

  loadRelations() {
    this.projectListLoading = true;
    ProjectService.getInstance().getAll()
      .then(response => this.relations.projectList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.projectListLoading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }

    // Load relations
    this.loadRelations();
  }

  destroyed() {
    clearTimeout(interval);
  }
}
</script>

import Logger from '@/modules/sdk/core/logger';

export interface IFilter {
  label: string,
  formattedLabel: string,
  type: string,
  items: Array<string>,
}

export interface IQueryItem {
  category?: string | null,
  field: string | null,
  operator: string | null,
  logic: string | null,
  value: Array<string> | null,
  group: Array<IQueryItem>,
}

export interface IPresetFilter {
  text: string,
  value: Array<IQueryItem> | string
}

export interface IMatch {
  check: boolean,
  amount: number,
  value: boolean,
}

const d = new Logger('zemit/core/query');

export default class Query {

  static convertedFilters(filters: Array<any>, mapper: (condition: any) => any = (condition) => { return condition }) {
    const results: Array<any> = [];
    structuredClone(filters).forEach((filter) => {
      const mapped = mapper(filter);
      const toPush = (Array.isArray(mapped) ? mapped : [mapped]);
      results.push(...toPush);
    })
    return results;
  }

  static getValidFilters(
    filters: Array<IQueryItem>,
    projectId?: number | null,
    mapper: (condition: any) => any = (condition) => { return condition },
    mapFirstLogic = true,
  ): any {
    const mapValidFilters = (items: Array<IQueryItem>) => {
      return this.convertedFilters(items, mapper).filter((item: {[key: string]: any}) => {
        if (item.group.length > 0) {
          item.group = mapValidFilters(item.group);
        }
        return ((!!item.field && !!item.value) || (item.group || []).length > 0)
          || (!!item.field && !item.value && ['is empty', 'is not empty'].includes(item.operator));
      });
    }
    const validFilters = mapValidFilters(filters);

    const prepend = projectId ? [{
      field: 'projectId',
      value: projectId,
      operator: 'equals'
    }] : [];

    const values = this.prepareFilters(validFilters, mapFirstLogic);
    return values.length > 0 ? [...prepend, values] : prepend;
  }

  static prepareFilters(items: Array<any>, mapLogic = true) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // If item contains multiple items (group)
      if (mapLogic && (item.group || []).length) {
        function mapFirstLogic(items: Array<any>, logic: string) {
          if ((items[0].group || []).length) {
            mapFirstLogic(items[0].group, logic);
          } else {
            items[0].logic = logic;
          }
        }
        mapFirstLogic(items[i].group, item.logic);
        items[i] = this.prepareFilters(items[i].group, mapLogic);
      }

      // Flatten values if necessary
      if (Array.isArray(items[i].value)) {
        items[i].value = items[i].value.map((value: any) => {
          return typeof value === 'object' && value.value
            ? value.value
            : value
        })
      }
    }
    items = items.filter(Boolean); // Remove empty objects

    return items;
  }

  static filterItems(filters: Array<IQueryItem>, rows: Array<any>, mapLogic = true) {
    const filteredRows: Array<{[key: string]: string}> = [];
    const validFilters = Query.getValidFilters(filters, undefined, undefined, mapLogic);

    if (validFilters.length === 0) {
      return rows;
    }

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (Query.analyze(validFilters, row)) {
        filteredRows.push(row);
      }
    }

    return filteredRows;
  }

  static analyze(queries: Array<IQueryItem>, values: {[key: string]: any}, match?: IMatch): boolean {
    const results: Array<[string | null, boolean]> = [];

    let totalValidMatches = 0;
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];

      if (Array.isArray(query)) {
        return this.analyze(query, values, match);
      }

      if ((query.group || []).length > 0) {
        results.push([query.logic, this.analyze(query.group, values, match)]);
      } else if (query.field && query.operator) {
        const valueArr = Array.isArray(query.value) ? query.value : [query.value];
        if (match && match.check) {
          const valueItemVal = this.checkValuesOnField(query.operator, valueArr, values[query.field])
          if (valueItemVal === match.value) {
            totalValidMatches++;
          }
        } else {
          results.push([query.logic, this.checkValuesOnField(query.operator, valueArr, values[query.field])]);
        }
      }
    }
    if (match && match.check) {
      results.push([queries[0].logic, totalValidMatches >= match.amount]);
    }
    return this.checkResults(results);
  }

  static checkResults(results: Array<[string | null, boolean]>): boolean {
    let evalStr = '';
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      evalStr += (i > 0
        ? result[0] === 'and' ? ' && ' : ' || '
        : '') + result[1];
    }
    // eslint-disable-next-line no-eval
    return eval(evalStr) || evalStr === '';
  }

  static checkValuesOnField(operator: string, values: Array<any>, comparator: any): boolean {
    const mustBeAllTrue = [
      'does not equal',
      'does not contain',
      'does not contain word',
    ].includes(operator);
    for (let j = 0; j < values.length; j++) {
      const value = values[j];
      const valid = this.checkValueOnField(operator, value, comparator);
      if (!valid && mustBeAllTrue) {
        return false
      } else if (valid && !mustBeAllTrue) {
        return true;
      }
    }
    return mustBeAllTrue;
  }

  static extractFloat(value: any): number | null {
    if (typeof value === 'string') {
      const regex = /[-+]?[0-9]*\.?[0-9]+/g;
      const matches = value.match(regex);
      if (matches && matches.length > 0) {
        return parseFloat(matches[0]);
      }
    }
    return null;
  }

  static checkValueOnField(operator: string, valueA: any, valueB: any): boolean {

    if ((valueA === undefined || valueB === undefined) && valueA !== valueB) {
      return false;
    }

    if ((valueA === null || valueB === null) && valueA !== valueB && !['is empty', 'is not empty'].includes(operator)) {
      return false;
    }

    switch (operator) {
      case 'equals':
      case 'does not equal':
        return (operator === 'equals' && valueA === valueB)
          || (operator === 'does not equal' && valueA !== valueB);
      case 'contains':
      case 'does not contain':
        return (operator === 'contains' && valueB.toString().toLowerCase().indexOf(valueA.toString().toLowerCase()) !== -1)
          || (operator === 'does not contain' && !(valueB.toString().toLowerCase().indexOf(valueA.toString().toLowerCase()) !== -1));
      case 'contains word':
      case 'does not contain word':
        return (operator === 'contains word' && new RegExp('\\b' + valueA.toString() + '\\b', 'i').test(valueB))
          || (operator === 'does not contain word' && !new RegExp('\\b' + valueA.toString() + '\\b', 'i').test(valueB));
      case 'begins with':
      case 'ends with':
        return (operator === 'begins with' && valueB.toString().toLowerCase().startsWith(valueA.toString().toLowerCase()))
          || (operator === 'ends with' && valueB.toString().toLowerCase().endsWith(valueA.toString().toLowerCase()));
      case 'greater than':
      case 'greater than or equal':
      case 'less than':
      case 'less than or equal':
        const compareValueA = this.extractFloat(valueA) || valueA;
        const compareValueB = this.extractFloat(valueB) || valueB;
        return (operator === 'greater than' && compareValueB > compareValueA)
          || (operator === 'greater than or equal' && compareValueB >= compareValueA)
          || (operator === 'less than' && compareValueB < compareValueA)
          || (operator === 'less than or equal' && compareValueB <= compareValueA);
      case 'is empty':
      case 'is not empty':
        return (operator === 'is empty' && (valueB === null || valueB === ''))
          || (operator === 'is not empty' && !(valueB === null || valueB === ''));
      case 'regexp':
      case 'not regexp':
        return (operator === 'regexp' && new RegExp(valueA, 'i').test(valueB))
          || (operator === 'not regexp' && !(new RegExp(valueA, 'i').test(valueB)));
      case 'is multiple':
      case 'is not multiple':
        return (operator === 'is multiple' && (valueB || '').split(',').length > 1)
          || (operator === 'is not multiple' && (valueB || '').split(',').length <= 1);
    }
    return true;
  }
}

import {Route} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import Identity from '@/modules/sdk/core/identity';
import Main from '@/main';
import Auth from '@/Auth.vue';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('router/guards/app.guard');

const appGuard = (to: Route, from: Route, next: NavigationGuardNext) => {

  // If user is not logged in
  if (!Identity.isLoggedIn()) {

    // Dispatch to the Login View
    next({
      name: 'login',
      params: to.name === 'error'? {} : {redirect: to.fullPath}
    });

    if (Main.$vue) {
      Main.render(Auth, (vue: Vue) => {
        if (to.name === 'error') {
          vue.$route.params.redirect = to.fullPath;
        }
      });
    }

    return;
  }

  return next();
}

export default appGuard;

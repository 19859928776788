<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'satisfaction'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="satisfaction"
      title="Satisfaction"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :value="model.data.userentity?.getFullName()"
              :error-messages="formErrors.message"
              label="User"
              :rules="[rules.required]"
              hide-details="auto"
              disabled
              outlined
              clearable
              required
            />
          </v-col>
          <v-col cols="12">
            <h3>Rating</h3>
            <v-rating
              v-model="model.data.rating"
              hover
              length="5"
              color="warning"
              background-color="warning"
              @input="delete formErrors.rating"
            ></v-rating>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="model.data.message"
              :error-messages="formErrors.message"
              label="Message"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.message"
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component} from 'vue-property-decorator'
import SatisfactionService from '@/modules/sdk/services/satisfaction.service'
import Logger from '@/modules/sdk/core/logger'
import DataForm from '@/modules/common/components/DataForm.vue'
import SatisfactionModel from '@/modules/sdk/models/satisfaction.model'

const d = new Logger('views/Admin/Form/SatisfactionForm')

@Component({
  components: {
    DataForm,
  }
})
export default class satisfactionForm extends Vue {

  model: SatisfactionModel = new SatisfactionModel()
  service = SatisfactionService.getInstance()
  loading = false

  load(id: number) {
    this.loading = true
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false)
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id))
    }
  }
}
</script>



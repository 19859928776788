import Model, { Data } from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyModel from '@/models/survey.model';
import SurveyGroupModel from '@/models/survey-group.model';
import SurveyAnswerModel from '@/models/survey-answer.model';
import SurveyChoiceModel from '@/models/survey-choice.model';
import SurveyAiQuestionModel from '@/models/survey-ai-question.model';

export default class SurveyQuestionModel extends Model {

  public answer: any = null;
  public originalAnswer: any = null;

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.data.multiple = this.data.multiple === 1;
    this.prepareAnswer();
    this.setOriginalData();
  }

  prepareAnswer() {
    if (this.isArray() && !Array.isArray(this.answer)) {
      this.answer = [];
    } else if (this.answer === undefined) {
      this.answer = null;
    }
    if (this.isArray() && !Array.isArray(this.originalAnswer)) {
      this.originalAnswer = [];
    } else if (this.originalAnswer === undefined) {
      this.originalAnswer = null;
    }
  }

  isArray(): boolean {
    return this.isMultiple() || this.hasChoices() || this.isRangeType();
  }

  isMultiple(): boolean {
    return this.data.multiple
      || ['checkbox'].includes(this.data.type);
  }

  isDateType(): boolean {
    return ['date', 'datetime', 'time', 'date_range', 'time_range'].includes(this.data.type)
  }

  isRangeType(): boolean {
    return ['date_range', 'time_range', 'number_range', 'time_range', 'datetime_range', 'date_range', 'time_range'].includes(this.data.type)
  }

  hasChoices(): boolean {
    return ['select', 'checkbox', 'radio', 'autocomplete', 'country'].includes(this.data.type)
  }

  default() {
    return {
      description: null,
      rating_count: 0,
      surveychoicelist: [],
      surveyaiquestionlist: [],
    };
  }

  relatedMap () {
    return {
      projectentity: ProjectModel,
      surveyentity: SurveyModel,
      surveygroupentity: SurveyGroupModel,
      surveychoicelist: SurveyChoiceModel,
      surveyanswerlist: SurveyAnswerModel,
      surveyaiquestionlist: SurveyAiQuestionModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveychoicelist: true,
      surveyanswerlist: true,
      surveyaiquestionlist: true,
    };
  }

  columnCast() {
    return {
      required: 'bool',
      negativeOnly: 'bool',
      positiveOnly: 'bool',
      pastOnly: 'bool',
    };
  }
}

<template>

  <v-autocomplete
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :items="_items"
  >

    <template #selection="{ item }">
      <slot name="selection" :item="item">
        <v-chip
          :color="getItemDeleted(item) ? 'error' : null"
          :close="$attrs['deletable-chips']"
          pill
          @click:close="() => onRemoveItem(item)"
        >
          <span v-if="getItemDeleted(item)">
            <v-icon small left>mdi-trash-can-outline</v-icon>
            <span v-text="getItemText(item)" style="text-decoration: line-through;"></span>
          </span>
          <span v-else v-text="getItemText(item)"></span>
        </v-chip>
      </slot>
    </template>

    <template #item="{ item }">
      <slot name="item" :item="item">
        <span v-if="getItemDeleted(item)" class="error--text">
          <v-icon color="error" small left>mdi-trash-can-outline</v-icon>
          <span v-text="getItemText(item)" style="text-decoration: line-through;"></span>
        </span>
        <span v-else v-text="getItemText(item)"></span>
      </slot>
    </template>

  </v-autocomplete>

</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, ModelSync, Emit} from 'vue-property-decorator';
import Model from '@/modules/sdk/core/model';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('components/SoftDeleteAutocomplete');

@Component
export default class SoftDeleteAutocomplete extends Vue {
  @ModelSync('vModel', 'change') model!: Array<Model> | Model | null;
  @Prop({default: () => ([])}) items!: Array<Model>;
  @Prop({default: false}) showSoftDeleted!: boolean;

  get _items() {
    if (this.showSoftDeleted) {
      return this.items;
    }

    return this.items.filter((item: Model) => {
      if (this.getItemDeleted(item) === 1) {
        if (Array.isArray(this.model)) {
          if (this.model.some((item2: Model) => this.getItemValue(item) === this.getItemValue(item2))) {
            return true;
          }
        } else {
          if (this.getItemValue(this.model) === this.getItemValue(item)) {
            return true;
          }
        }
        return false;
      }
      return true;
    });
  }

  onRemoveItem(model: Model) {
    if (Array.isArray(this.model)) {
      const index = this.model.findIndex((item) => this.getItemValue(item) === this.getItemValue(model));
      if (index !== -1) {
        this.model[index].data.deleted = 1;
        this.change();
      }
    } else {
      this.model = null;
      this.change();
    }
  }

  getModelId(model: Model | number | string | null): number | string {
    return model instanceof Model
      ? model.data.id
      : model;
  }

  getItemProp(item: any, prop: string): any
  {
    const ret: any = this.$attrs['item-' + prop];
    return ret
      ? ret instanceof Function
        ? ret(item)
        : item.nestedProp(ret)
      : item[prop];
  }

  getItemText(item: any): any {
    return this.getItemProp(item, 'text');
  }

  getItemValue(item: any): any {
    return this.getItemProp(item, 'value');
  }

  getItemDeleted(item: any): any {
    return this.getItemProp(item, 'deleted');
  }

  @Emit()
  change() {
    return this.model;
  }
}
</script>

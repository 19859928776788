import Vue, { DirectiveBinding, VNode } from 'vue';

interface ICoords {
  top: number,
  left: number,
  width: number,
  height: number,
  colWidth: number,
  combinedColSize: number,
  startX: number,
  startY: number,
  anchors: Array<number>,
  minAnchorIdx: number,
  maxAnchorIdx: number,
}

let handlerMouseDownEventBind: (event: MouseEvent) => void;
let docMouseMouseEventBind: (event: MouseEvent) => void;
let docMouseUpEventBind: (event: MouseEvent) => void;

const handlerMouseDownEvent = function(
  event: MouseEvent,
  nodes: Array<Element>,
  parent: HTMLElement,
  handler: HTMLElement,
  context: any,
  binding: any,
  index: number,
) {
  const overlay = document.createElement('div');
  overlay.classList.add('v-row-resize-overlay');
  document.body.appendChild(overlay);
  handler.classList.add('visible');

  const clientRect = parent.getBoundingClientRect();
  const coords: ICoords = {
    top: clientRect.top,
    left: clientRect.left,
    width: clientRect.width,
    height: clientRect.height,
    colWidth: clientRect.width / 12,
    combinedColSize: 0,
    startX: event.clientX,
    startY: event.clientY,
    anchors: [],
    minAnchorIdx: 0,
    maxAnchorIdx: 0,
  };
  for (let i = 1; i <= 12; i++) {
    coords.anchors.push(coords.left + (i * coords.colWidth));
  }

  nodes.forEach((node, nodeIdx) => {
    const nodeClientRect = node.getBoundingClientRect();
    coords.combinedColSize += Math.round(nodeClientRect.width / coords.colWidth);

    if (nodeIdx === 0) {
      coords.minAnchorIdx = Math.round((nodeClientRect.left - coords.left) / coords.colWidth) + 1;
    } else {
      coords.maxAnchorIdx = Math.round((nodeClientRect.left - coords.left + nodeClientRect.width) / coords.colWidth) + coords.minAnchorIdx - 2;
    }
  });

  // eslint-disable-next-line no-useless-call
  docMouseMouseEventBind = event => docMouseMouseEvent.call(null, event, nodes, parent, handler, context, binding, index, coords);
  // eslint-disable-next-line no-useless-call
  docMouseUpEventBind = event => docMouseUpEvent.call(null, event, nodes, parent, handler, context, binding, index, coords, overlay);
  document.addEventListener('mousemove', docMouseMouseEventBind);
  document.addEventListener('mouseup', docMouseUpEventBind);
};
const docMouseMouseEvent = function(
  event: MouseEvent,
  nodes: Array<Element>,
  parent: HTMLElement,
  handler: HTMLElement,
  context: any,
  binding: any,
  index: number,
  coords: ICoords
) {
  const closestAnchor = coords.anchors.reduce((prev, curr) => {
    return (Math.abs(curr - event.clientX) < Math.abs(prev - event.clientX) ? curr : prev);
  });
  let closestAnchorIdx = coords.anchors.findIndex(anchor => anchor === closestAnchor) + 1;
  if (closestAnchorIdx < coords.minAnchorIdx) {
    closestAnchorIdx = coords.minAnchorIdx;
  } else if (closestAnchorIdx > coords.maxAnchorIdx) {
    closestAnchorIdx = coords.maxAnchorIdx;
  }
  nodes.forEach((node, nodeIdx) => {
    const size = nodeIdx === 0 ? closestAnchorIdx : (coords.maxAnchorIdx - closestAnchorIdx + 1);
    const breakpoint = context.$vuetify.breakpoint.name;
    if (binding.value[nodeIdx + index][breakpoint]) {
      binding.value[nodeIdx + index][breakpoint] = size;
    } else {
      const breakpoints = ['xl', 'lg', 'md', 'sm'];
      for (let i = 0; i < breakpoints.length; i++) {
        const b = breakpoints[i];
        if (binding.value[nodeIdx + index][b]) {
          binding.value[nodeIdx + index][b] = size;
          break;
        }
      }
    }
  });
};
const docMouseUpEvent = function(
  event: MouseEvent,
  nodes: Array<Element>,
  parent: HTMLElement,
  handler: HTMLElement,
  context: any,
  binding: any,
  index: number,
  coords: ICoords,
  overlay: HTMLElement,
) {
  document.removeEventListener('mousemove', docMouseMouseEventBind);
  document.removeEventListener('mouseup', docMouseUpEventBind);
  overlay.remove();
  handler.classList.remove('visible');
};

const bind = (el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) => {

  if (binding.value === false) {
    return;
  }

  let index = 0;
  Array.from(el.children).forEach((node, nodeIdx) => {

    if (node.className.indexOf('col-') === -1) {
      return;
    }

    if (!binding.value[nodeIdx]) {
      binding.value[nodeIdx] = {};
    }

    if (nodeIdx > 0) {
      const nodes = [
        el.children[nodeIdx - 1],
        node,
      ];

      const handler = document.createElement('div');
      const icon = document.createElement('i');
      icon.className = 'v-icon notranslate mdi mdi-drag-vertical';
      handler.classList.add('v-row-resize-handler');

      // @ts-ignore
      const context = vnode.context._original;

      const cb = (index: number) => { // Needs to be in a callback otherwise index increases because the event in launched mousedown, so not directly.
        handlerMouseDownEventBind = event => handlerMouseDownEvent.call(this, event, nodes, el, handler, context, binding, index)
      };
      cb(index);
      handler.addEventListener('mousedown', handlerMouseDownEventBind);
      handler.appendChild(icon);
      node.appendChild(handler);

      index++;
    }
  })
  el.classList.add('v-row-resize');
}

const unbind = (el: HTMLElement) => {
  Array.from(el.children).forEach(() => {
    if (el.classList.contains('v-row-resize-handler')) {
      el.removeEventListener('mousedown', handlerMouseDownEventBind);
    }
  });
  document.removeEventListener('mousemove', docMouseMouseEventBind);
  document.removeEventListener('mouseup', docMouseUpEventBind);
  el.classList.remove('v-row-resize');
}

Vue.directive('row-resize', {
  bind,
  unbind,
  update: (el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) => {
    // unbind(el);
    bind(el, binding, vnode)
  }
});

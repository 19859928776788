import Model from '@/modules/sdk/core/model';
import TagModel from '@/models/tag.model';
import RecordModel from '@/models/record.model';
import ProjectModel from '@/models/project.model';
import CategoryModel from '@/models/category.model'

export default class RecordTagModel extends Model {

  default() {
    return {
      tagentity: [],
      recordentity: [],
      projectentity: [],
      categoryentity: [],
    };
  }

  relatedMap () {
    return {
      tagentity: TagModel,
      recordentity: RecordModel,
      projectentity: ProjectModel,
      categoryentity: CategoryModel,
    }
  }

  relatedDeleteMissing() {
    return {
      tagentity: false,
      recordentity: false,
      projectentity: false,
      categoryentity: false,
    };
  }
}

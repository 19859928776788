<template>
  <div>
    <TutorialModal
      v-model="item"
      :visible.sync="visible"
    />
    <slot name="activator" :on="{
      click: onButtonClick
    }"></slot>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import TutorialModal from '@/modules/common/components/TutorialModal.vue';
import VideoLibraryModel from '@/modules/sdk/models/video-library.model';

@Component({
  components: {
    TutorialModal,
  }
})
export default class InlineTutorial extends Vue {

  @Prop({ type: String, default: null }) slug!: string;

  visible = false
  items: Array<VideoLibraryModel> = []
  item: VideoLibraryModel | null = null

  onButtonClick(): void {
    const item = this.items.find(item => item.data.index === this.slug) || null;
    this.item = item;
    this.visible = true;
  }
}
</script>

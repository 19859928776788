<template>
  <div>
    <ModalDialog
      v-model="exportCsvModal.visible"
      :title="$t('ReviewView.export.title') + ' - ' + project.getLabel()"
      :persistent="exportCsvModal.loading"
      icon="mdi-file-export-outline"
      max-width="800"
      scrollable
    >
      <template #content>
        <v-card-text class="background px-0 pb-0">
          <v-container>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title>
                    <span v-text="$t('label.fields')"></span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="fieldList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span v-text="$t('ReviewView.noFieldAvailable')"></span>
                    </v-alert>
                    <template v-else>
                      <v-checkbox
                        v-model="_allFieldsSelected"
                        :label="$t('ReviewView.allFields')"
                        class="mb-4 mt-0 pt-0 pb-0"
                        hide-details
                      />
                      <template v-for="field in fieldList">
                        <v-checkbox
                          v-model="exportCsvModal.fields"
                          :key="field.value"
                          :value="field.value"
                          :label="field.text"
                          class="mb-0 pb-0"
                          hide-details
                        />
                      </template>
                    </template>
                  </v-card-text>
                </v-card>

                <v-card class="mt-3">
                  <v-card-title>
                    <span>Official Status</span>
                  </v-card-title>
                  <v-card-text>
                    <template v-for="(field, fieldIdx) in officialStatusFieldList">
                      <v-checkbox
                        v-model="exportCsvModal.fields"
                        :key="field.value"
                        :value="field.value"
                        :label="field.text"
                        :class="{
                          'pt-0': !fieldIdx,
                          'mt-0': !fieldIdx
                        }"
                        hide-details
                      />
                    </template>
                  </v-card-text>
                </v-card>

                <v-card class="mt-3">
                  <v-card-title>
                    <span>Extra Fields</span>
                  </v-card-title>
                  <v-card-text>
                    <template v-for="(field, fieldIdx) in extraFieldList">
                      <v-checkbox
                        v-model="exportCsvModal.fields"
                        :key="field.value"
                        :value="field.value"
                        :label="field.text"
                        :class="{
                          'pt-0': !fieldIdx,
                          'mt-0': !fieldIdx
                        }"
                        hide-details
                      />
                    </template>
                  </v-card-text>
                </v-card>

                <v-card class="mt-3" v-if="false">
                  <v-card-title>
                    <span v-text="$t('label.articles')"></span>
                  </v-card-title>
                  <v-card-text>
                    <v-progress-linear
                      v-if="articleLoading"
                      indeterminate
                    />
                    <v-alert
                      v-else-if="articleList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span v-text="$t('ReviewView.noArticleAvailable')"></span>
                    </v-alert>
                    <template v-else>
                      <v-checkbox
                        v-model="_allArticlesSelected"
                        :label="$t('ReviewView.allArticles')"
                        class="mb-4 pb-0"
                        hide-details
                      />
                      <template v-for="article in articleList">
                        <v-checkbox
                          v-model="exportCsvModal.articles"
                          :key="article.data.id"
                          :value="article.data.id"
                          :label="article.data.title"
                          class="mb-0 pb-0"
                          hide-details
                        />
                      </template>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="8">

                <v-card>
                  <v-card-title>
                    <span>User Review Status</span>
                  </v-card-title>
                  <v-card-text>
                    <user-autocomplete-component
                      v-model="exportCsvModal.users"
                      :project-id="project.data.id"
                      include-ai-user
                      label="Users"
                      multiple
                      outlined
                      dense
                      return-object
                    />
                  </v-card-text>
                </v-card>

                <v-card class="mt-3">
                  <v-card-title>
                    <span v-text="$t('label.question')"></span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="totalQuestions === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span v-text="$t('ReviewView.noQuestionAvailable')"></span>
                    </v-alert>
                    <template v-else>
                      <v-checkbox
                        v-model="_allQuestionsSelected"
                        :label="$t('ReviewView.allQuestions')"
                        class="mb-4 pb-0"
                        hide-details
                      />
                      <template v-for="survey in project.data.surveylist">
                        <v-alert
                          v-for="(group, groupIdx) in survey.data.surveygrouplist"
                          :key="group.data.id"
                          :color="group.data.color"
                          :class="{
                                      'mb-0': true,
                                      'mt-3': groupIdx > 0,
                                    }"
                          outlined
                          border="left"
                        >
                          <h3 class="d-flex align-center" @click="selectAllQuestions(group.data.id)"
                              style="cursor: pointer" v-ripple>
                            <v-checkbox
                              :value="_allQuestionSelected[group.data.id]"
                              class="mb-0 pb-0 mt-0"
                              hide-details
                            />
                            <span v-text="group.data.label"></span>
                          </h3>
                          <v-checkbox
                            v-model="exportCsvModal.questions"
                            v-for="question in group.data.surveyquestionlist"
                            :key="question.data.id"
                            :value="question.data.id"
                            :label="question.data.label"
                            class="mb-0 pb-0"
                            hide-details
                          />
                        </v-alert>
                      </template>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </template>

      <template #buttons>

        <v-select
          v-model="exportCsvModal.type"
          :items="typeList"
          :label="$t('exportComponent.type')"
          style="max-width: 15rem"
          hide-details
          outlined
          dense
        />

        <v-spacer />
        <v-btn
          :loading="exportCsvModal.loading"
          :disabled="exportCsvModal.loading || exportCsvModal.questions.length === 0 && exportCsvModal.fields.length === 0"
          color="primary"
          large
          @click="exportCsv"
        >
          <span v-text="$t('btn.export')"></span>
        </v-btn>
        <v-btn
          outlined
          large
          @click="() => exportCsvModal.visible = false"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, PropSync, Emit, Watch} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectModel from '@/models/project.model';
import {fieldList, extraFieldList, officialStatusFieldList} from '@/enums/global';
import Logger from '@/modules/sdk/core/logger';
import ArticleModel from '@/models/article.model';
import ArticleService from '@/services/article.service';
import UserAutocompleteComponent from '@/views/Admin/Component/UserAutocompleteComponent.vue';
import UserModel from '@/modules/sdk/models/user.model';

const d = new Logger('views/Admin/Component/ExportComponent');

@Component({
  components: {
    UserAutocompleteComponent,
    ModalDialog
  }
})
export default class ExportComponent extends Vue {
  @Prop() project!: ProjectModel

  @PropSync('options') exportCsvModal!: {
    visible: boolean,
    loading: boolean,
    type: string,
    fields: Array<string>,
    articles: Array<string>,
    questions: Array<number>,
    users: Array<UserModel>,
  };

  articleLoading = false;
  articleList: Array<ArticleModel> = [];

  fieldList = fieldList;
  extraFieldList = extraFieldList;
  officialStatusFieldList = officialStatusFieldList;
  typeList = [
    { text: 'XLSX', value: 'xlsx' },
    { text: 'CSV', value: 'csv' },
    { text: 'JSON', value: 'json' },
  ]

  @Watch('exportCsvModal.visible', {
    immediate: true,
  })
  onVisibleChanged(visible: boolean) {
    if (visible && !this.articleLoading) {
      this.loadArticles();
    }
  }

  /**
   * Articles
   */
  get _allArticlesSelected(): boolean {
    if (Array.isArray(this.articleList)) {
      return this.exportCsvModal.articles.length === this.articleList.length;
    }
    return false;
  }

  set _allArticlesSelected(value) {
    if (Array.isArray(this.articleList)) {
      const wasAllSelected = this.exportCsvModal.articles.length === this.articleList.length;
      this.exportCsvModal.articles = [];
      if (!wasAllSelected) {
        this.articleList.forEach(article => {
          this.exportCsvModal.articles.push(article.data.id);
        })
      }
    }
  }

  /**
   * Fields
   */
  get _allFieldsSelected(): boolean {
    return this.exportCsvModal.fields.length >= this.fieldList.length;
  }

  set _allFieldsSelected(value) {
    const wasAllSelected = this.exportCsvModal.fields.length === this.fieldList.length;
    this.exportCsvModal.fields = [];
    if (!wasAllSelected) {
      this.fieldList.forEach(field => {
        this.exportCsvModal.fields.push(field.value);
      })
    }
  }

  /**
   * Questions
   */
  get _allQuestionsSelected(): boolean {
    return this.exportCsvModal.questions.length === this.totalQuestions;
  }

  set _allQuestionsSelected(value) {
    const wasAllSelected = this.exportCsvModal.questions.length === this.totalQuestions;
    this.exportCsvModal.questions = [];
    if (!wasAllSelected && this.project) {
      for (let i = 0; i < this.project.data.surveylist.length; i++) {
        const survey = this.project.data.surveylist[i];
        for (let j = 0; j < survey.data.surveygrouplist.length; j++) {
          const group = survey.data.surveygrouplist[j];
          for (let k = 0; k < group.data.surveyquestionlist.length; k++) {
            const question = group.data.surveyquestionlist[k];
            this.exportCsvModal.questions.push(question.data.id);
          }
        }
      }
    }
  }

  get _allQuestionSelected(): { [key: string]: boolean } {
    const groups: { [key: string]: boolean } = {};
    if (this.project) {
      for (let i = 0; i < this.project.data.surveylist.length; i++) {
        const survey = this.project.data.surveylist[i];
        for (let j = 0; j < survey.data.surveygrouplist.length; j++) {
          const group = survey.data.surveygrouplist[j];
          groups[group.data.id] = true;
          for (let k = 0; k < group.data.surveyquestionlist.length; k++) {
            const question = group.data.surveyquestionlist[k];
            if (this.exportCsvModal.questions.findIndex(value => value === question.data.id) === -1) {
              groups[group.data.id] = false;
            }
          }
        }
      }
    }
    return groups;
  }

  get totalQuestions(): number {
    let total = 0;
    if (this.project) {
      for (let i = 0; i < this.project.data.surveylist.length; i++) {
        const survey = this.project.data.surveylist[i];
        for (let j = 0; j < survey.data.surveygrouplist.length; j++) {
          const group = survey.data.surveygrouplist[j];
          total += group.data.surveyquestionlist.length;
        }
      }
    }
    return total;
  }

  open() {
    this.exportCsvModal.visible = true;
  }

  close() {
    this.exportCsvModal.visible = false;
  }

  loadArticles(): void {
    this.articleLoading = true;
    const filters = [{
      field: 'projectId',
      value: this.project.data.id,
      operator: 'equals'
    }];
    ArticleService.getInstance().getAll({filters})
      .then((response) => this.articleList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.articleLoading = false);
  }

  selectAllQuestions(groupId: number): void {
    if (this.project) {
      for (let i = 0; i < this.project.data.surveylist.length; i++) {
        const survey = this.project.data.surveylist[i];
        for (let j = 0; j < survey.data.surveygrouplist.length; j++) {
          const group = survey.data.surveygrouplist[j];
          if (group.data.id === groupId) {

            // Fetch all questions ID and determine if they were all selected or not
            const allQuestionsIds = [];
            let wasAllSelected = true;
            for (let k = 0; k < group.data.surveyquestionlist.length; k++) {
              const question = group.data.surveyquestionlist[k];
              allQuestionsIds.push(question.data.id);
              if (this.exportCsvModal.questions.findIndex(value => value === question.data.id) === -1) {
                wasAllSelected = false;
              }
            }

            // Select all or deselected all questions depending on context
            for (let k = 0; k < allQuestionsIds.length; k++) {
              const questionId = allQuestionsIds[k];
              const index = this.exportCsvModal.questions.findIndex(value => value === questionId);
              if (index !== -1 && wasAllSelected) {
                this.exportCsvModal.questions.splice(index, 1);
              } else if (index === -1 && !wasAllSelected) {
                this.exportCsvModal.questions.push(questionId);
              }
            }
          }
        }
      }
    }
  }

  @Emit()
  exportCsv() {
  }
}
</script>

import { render, staticRenderFns } from "./FullscreenComponent.vue?vue&type=template&id=73a54e47"
import script from "./FullscreenComponent.vue?vue&type=script&lang=ts"
export * from "./FullscreenComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
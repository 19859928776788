import Model, { Data } from '../core/model';

export default class FileModel extends Model {

  downloading = false;

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.file = this.data;
  }

  default() {
    return {
      id: null,
      category: null,
      key: null,
      path: null,
      type: null,
      size: 0,
      name: 'Empty',
    };
  }

  columnCast() {
    return {
      size: 'number',
    }
  }

}

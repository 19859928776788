<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
  >
    <template #activator="{ on, attrs }">

      <!-- BUTTON -->
      <v-btn
        color="default"
        v-bind="{...attrs}"
        v-on="on"
        x-small
        icon
        class="mx-2"
      >
        <v-badge
          :value="originalHasValue"
          color="primary"
          dot
        >
          <v-icon x-small>mdi-filter-variant</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-form @submit.stop.prevent="onApplyClick">
      <v-card>

        <!-- TITLE -->
        <v-card-title class="justify-space-between py-2">
          <div class="d-flex align-center">
            <v-icon small left>mdi-filter-variant</v-icon>
            <span class="subtitle-1">Filter by <span class="font-weight-bold" v-text="header.text"></span></span>
          </div>

          <v-btn color="error" class="ml-6" :disabled="!currentFilter.value" outlined small @click="onClearClick">
            <span v-text="$t('btn.clear')"></span>
          </v-btn>
        </v-card-title>

        <v-card-text class="background pa-3">

          <!-- TEXT -->
          <template v-if="!header.filterable.type || header.filterable.type === 'text'">
            <v-text-field
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :placeholder="$t('placeholder.typeValueHere')"
              :background-color="$vuetify.theme.dark ? null : 'white'"
              hide-details
              single-line
              dense
              outlined
              clearable
              autofocus
              type="text"
            ></v-text-field>
          </template>

          <!-- BOOLEAN -->
          <template v-else-if="header.filterable.type === 'boolean'">
            <v-autocomplete
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :items="[
                { text:  '- ' + $t('label.any') + ' -', value: null },
                { text: header.filterable.labelTrue || $t('label.yes'), value: true },
                { text: header.filterable.labelFalse || $t('label.no'), value: false },
              ]"
              hide-details
              dense
              outlined
              clearable
              autofocus
            />
          </template>

          <!-- NUMBER -->
          <template v-else-if="header.filterable.type === 'number'">
            <v-text-field
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :placeholder="$t('placeholder.typeValueHere')"
              :background-color="$vuetify.theme.dark ? null : 'white'"
              hide-details
              single-line
              outlined
              dense
              clearable
              autofocus
              type="number"
              @input="onNumberInput"
            />
          </template>

          <!-- RANGE -->
          <template v-else-if="header.filterable.type === 'range'">
            <v-range-slider
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :max="header.filterable.min || 100"
              :min="header.filterable.max || 0"
              hide-details
              class="align-center"
              style="min-width: 400px;"
            >
              <template #prepend>
                <v-text-field
                  :value="currentFilter.value[0]"
                  :background-color="$vuetify.theme.dark ? null : 'white'"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  outlined
                  dense
                  type="number"
                  style="min-width: 4rem"
                  @change="$set(currentFilter.value, 0, $event)"
                ></v-text-field>
              </template>
              <template #append>
                <v-text-field
                  :value="currentFilter.value[1]"
                  :background-color="$vuetify.theme.dark ? null : 'white'"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  outlined
                  dense
                  type="number"
                  style="min-width: 4rem"
                  @change="$set(currentFilter.value, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </template>

          <!-- DATE -->
          <template v-else-if="header.filterable.type === 'date'">
    <!--        <v-text-field-->
    <!--            v-model="_dateRangeText"-->
    <!--            prepend-icon="mdi-calendar"-->
    <!--            readonly-->
    <!--        ></v-text-field>-->
            <v-date-picker
                v-bind="{...header.filterable.attrs}"
                v-model="currentFilter.value"
                range
                full-width
                style="max-width: 20rem"
            ></v-date-picker>
          </template>

          <!-- DISTINCT -->
          <template v-else-if="header.filterable.type === 'distinct'">
            <v-autocomplete
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :items="distinctItems"
              :placeholder="$t('placeholder.chooseValue')"
              :background-color="$vuetify.theme.dark ? null : 'white'"
              :loading="loadingDistinct"
              :item-value="header.filterable.itemValue || 'data.id'"
              :item-text="header.filterable.itemText || 'data.label'"
              hide-details
              dense
              multiple
              clearable
              style="width: 20rem"
              outlined
            />
          </template>

          <!-- ENUM -->
          <template v-else-if="header.filterable.type === 'enum'">
            <v-autocomplete
              v-bind="{...header.filterable.attrs}"
              v-model="currentFilter.value"
              :items="header.filterable.items"
              :placeholder="$t('placeholder.chooseValue')"
              :background-color="$vuetify.theme.dark ? null : 'white'"
              hide-details
              dense
              multiple
              clearable
              style="width: 20rem"
              outlined
            />
          </template>

          <!-- UNSUPPORTED -->
          <template v-else>
            <v-alert outlined prominent border="left" type="error">
              {{$t('datatable.filter.unsupported', {type: header.filterable.type})}}
            </v-alert>
          </template>

<!--          <v-autocomplete-->
<!--            v-if="header.filterable === true"-->
<!--            v-model="header.filterable.operator"-->
<!--            :items="[-->
<!--              { value: 'contains', text: 'Contains' },-->
<!--              { value: 'does not contain', text: 'Does not contain' },-->
<!--              { value: 'contains word', text: 'Contains word' },-->
<!--              { value: 'does not contain word', text: 'Does not contain word' },-->
<!--              { value: 'equals', text: 'Equals' },-->
<!--              { value: 'does not equal', text: 'Does not equal' },-->
<!--              { value: 'is empty', text: 'Is empty' },-->
<!--              { value: 'is not empty', text: 'Is not empty' },-->
<!--              { value: 'regexp', text: 'Matches regular expression' },-->
<!--              { value: 'not regexp', text: 'Does not match regular expression' },-->
<!--            ]"-->
<!--            hide-details-->
<!--            dense-->
<!--            clearable-->
<!--            outlined-->
<!--          />-->

        </v-card-text>

        <!-- ACTIONS -->
        <v-card-actions class="d-flex justify-end">
          <v-btn :disabled="!isDifferent" color="primary" small @click="onApplyClick">
            <span v-text="$t('btn.apply')"></span>
          </v-btn>
          <v-btn text small @click="onCancelClick">
            <span v-text="$t('btn.cancel')"></span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Component, Emit, VModel, Prop, Vue, Watch} from 'vue-property-decorator';

interface Filter {
  field?: string,
  operator?: string,
  value?: any,
  type?: string
}

@Component
export default class DataTableColumnFilter extends Vue {

  @VModel({ default: () => [] }) filters!: Array<Filter>;
  @Prop({ default: () => ({ filterable: { type: 'text' } }) }) header!: any;

  currentFilter: Filter = {}
  menu = false;
  loadingDistinct = false;
  distinctLoaded = false
  distinctItems: Array<any> = []

  @Watch('filters', { immediate: true, deep: true })
  onFiltersChanged() {
    this.applyValue();
  }

  @Watch('menu', { immediate: true })
  onMenuChanged(menu: boolean) {
    if (menu) {
      this.applyValue();
      if (this.header.filterable.type === 'distinct' && !this.distinctLoaded && this.header.filterable.service) {
        this.loadDistinct();
      }
    }
  }

  onNumberInput(value: number | string) {
    return value === ''
      ? this.currentFilter.value = null
      : this.currentFilter.value = value;
  }

  get originalHasValue(): boolean {
    const filter = this.getFilter(false) || {};
    return (
      (!Array.isArray(filter.value) && filter.value)
      || (Array.isArray(filter.value) && filter.value.filter(item => item !== null).length > 0)
    );
  }

  get isDifferent(): boolean {
    const original = JSON.stringify(this.getFilter(false) || {});
    const current = JSON.stringify(this.currentFilter);
    return original !== current;
  }

  applyValue(): void {
    try {
      this.currentFilter = JSON.parse(JSON.stringify(this.getFilter(true) || {}));
    } catch {
      this.currentFilter = {};
    }
  }

  getFilter(create = false): Filter | undefined {
    const field = (this.header.filterable.field || this.header.value);
    let filter = this.filters.find((item: any) => item.field === field);
    if (create && !filter) {
      let operator;
      const value = this.getResetValue();
      switch (this.header.filterable.type) {
        case 'text':
          operator = 'contains';
          break;
        case 'date':
        case 'range':
          operator = 'between';
          break;
      }

      if (this.header.filterable === true) {
        operator = 'contains';
      }

      operator = this.header.filterable.operator || operator || 'equals';
      const type = (this.header.filterable && this.header.filterable.type) || 'text';

      filter = {
        operator,
        field,
        value,
        type,
      };
      this.filters.push(filter);
    }
    return filter;
  }

  loadDistinct() {
    this.loadingDistinct = true;
    this.header.filterable.service.getInstance().getAll({
      order: this.header.filterable.itemText || 'label'
    })
      .then((response: any) => {
        this.distinctItems = response.data.view.list;
        this.distinctLoaded = true;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingDistinct = false);
  }

  getResetValue(): Array<null | number> | null {
    if (['date', 'select'].includes(this.header.filterable.type)) {
      return [];
    } else if (this.header.filterable.type === 'range') {
      return [null, null];
    }
    return null;
  }

  onCancelClick() {
    this.cancel();
  }

  onApplyClick() {
    this.apply();
  }

  onClearClick() {
    this.reset();
  }

  @Emit()
  cancel() {
    this.menu = false;
  }

  @Emit()
  apply() {
    this.menu = false;
    const filter = this.getFilter(false);
    if (filter) {
      filter.value = this.currentFilter.value;
    }
    return filter;
  }

  @Emit()
  reset() {
    this.menu = false;
    const filter = this.getFilter(false);
    if (filter) {
      filter.value = this.getResetValue();
    }
    return filter;
  }


}
</script>

import Model from '../core/model';

export default class DatatableStateModel extends Model {

  default() {
    return {
    };
  }

  relatedMap () {
    return {
    }
  }
}

import Model from '../core/model';
import UserModel from './user.model';

export default class NotificationModel extends Model {

  default() {
    return {
      everyone: 1,
      userlist: [],
      useracklist: [],
    };
  }

  relatedMap () {
    return {
      userlist: UserModel,
      useracklist: UserModel,
    }
  }

  relatedDeleteMissing() {
    return {
      userlist: true,
      useracklist: true,
    };
  }
}

<template>
  <ModalDialog
    v-model="visible"
    :title="$t('reviewView.statusReasonModal.title')"
    icon="mdi-alert-rhombus-outline"
    max-width="400"
    scrollable
  >
    <template #body>
      <span v-text="$t('reviewView.statusReasonModal.body')"></span>

      <component
        v-model="reason"
        :is="isDev ? 'v-combobox' : 'v-text-field'"
        :items="reasonList"
        :label="$t('reviewView.statusReasonModal.status')"
        :loading="innerLoading"
        item-text="data.label"
        class="mt-4"
        outlined
        dense
      />
    </template>
    <template #buttons>
      <v-btn
        color="primary"
        :disabled="!canConfirmStatus"
        :loading="loading || applying"
        @click="onConfirmStatusReason"
      >
        <span v-text="$t('btn.confirm')"></span>
      </v-btn>
      <v-btn
        outlined
        @click="onCancel"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import RecordModel from '@/models/record.model';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import {Component, Vue, Prop, VModel, Emit, Watch} from 'vue-property-decorator';
import Identity from '@/modules/sdk/core/identity';

@Component({
  components: {
    ModalDialog,
  }
})
export default class StatusReasonModal extends Vue {

  @VModel({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ default: () => new RecordModel() }) record!: RecordModel;
  @Prop({ default: null }) stage!: string;
  @Prop({ default: null }) status!: string;
  @Prop({ default: null }) defaultReason!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  @Watch('visible', { immediate: true })
  onVisibleChanged(visible: boolean) {
    if (!visible) {
      this.applying = false;
    } else {
      this.load();
      if (this.defaultReason) {
        this.reason = this.defaultReason;
      }
    }
  }

  loaded = false
  innerLoading = false
  applying = false
  reason: string | null = null;
  reasonList: Array<ProjectStatusReasonModel> = [];

  get canConfirmStatus() {
    return !!this.reason
      && !this.applying
      && !this.loading
      && !this.innerLoading
      && (this.reason !== this.defaultReason);
  }

  get isDev() {
    return Identity.hasRole(['dev']);
  }

  onConfirmStatusReason() {
    if (this.status) {
      this.applying = true;
      this.$emit('reason', this.reason);
      if (typeof this.reason === 'string') {
        const data: any = {
          projectId: this.record.data.projectId,
          label: this.reason,
          status: this.status,
          stage: this.stage,
        };

        if (!this.reasonList.find(reason => Object.keys(data).every(key => reason.data[key] === data[key]))) {
          this.reasonList.push(new ProjectStatusReasonModel(data));
        }
      }
    }
  }

  onCancel() {
    this.cancel();
    this.close();
  }

  load(): Promise<any> {
    const filters = [
      [
        {
          field: 'projectId',
          operator: 'equals',
          value: this.record.data.projectId,
        },
        // OR
        {
          field: 'projectId',
          operator: 'is null',
        }
      ],
      // AND
      {
        field: 'stage',
        operator: 'equals',
        value: this.stage,
      },
      // AND
      {
        field: 'status',
        operator: 'equals',
        value: this.status,
      }
    ];

    this.innerLoading = true;
    return ProjectStatusReasonService.getInstance().getAll({filters})
      .then(response => {
        if (response.data.view.list) {
          this.reasonList = response.data.view.list;
        }
        this.loaded = true;
        return response;
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.innerLoading = false);
  }

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  @Emit()
  cancel() {

  }
}
</script>

import SynonymModel from '@/models/synonym.model';

export const queryMapper = (condition: any): any => {

  // If a keyword is selected, keyword will use "or" for the synonyms
  if (Array.isArray(condition.value) && condition.value.length > 0 && condition.value[0].model?.data.synonymlist?.length) {
    const subItems: Array<object> = [];
    const subItemConfig = {
      logic: 'or',
      field: condition.field,
      operator: condition.operator,
      group: [],
    };

    condition.value.forEach((value: any) => {
      value.model.data.synonymlist.forEach((subItem: SynonymModel) => {
        if (!subItem.data.deleted) {
          const newSubItem = {
            ...subItemConfig,
            ...{
              value: subItem.data.label
            }
          };
          if (subItem.data.regexp && newSubItem.operator === 'contains') {
            newSubItem.operator = 'regex';
          }
          if (subItem.data.regexp && newSubItem.operator === 'does not contain') {
            newSubItem.operator = 'not regex';
          }
          if (subItem.data.word_only && newSubItem.operator === 'contains') {
            newSubItem.operator = 'regex';
          }
          if (subItem.data.word_only && newSubItem.operator === 'does not contain') {
            newSubItem.operator = 'not regex';
          }
          subItems.push(newSubItem)
        }
      });
    })

    // if item is currently within an ["and"] brackets, switch to an [["or"]]
    condition.group = subItems;
    condition.value = null;
  } else {
    condition.value = condition.value instanceof Object && !Array.isArray(condition.value) ? condition.value.text : condition.value;
  }

  return condition;
}

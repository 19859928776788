<template>
  <ListView
    v-bind="$attrs"
    title="Record AI Status"
    icon="mdi-robot"
    :headers="headers"
    :service="service"
    :query="filters"
    searchable
    hide-new-button
    index="record_ai_status"
  >
    <template v-slot:item.Record.title="{item}">
      <span class="text-no-wrap">
        {{ item.data.recordentity.data.title }}
      </span>
    </template>
    <template #item.confidence="{ item }">
      {{ item.data.confidence }}%
    </template>
    <template #item.comment="{ item }">
      <div
        v-text="item.data.comment"
        style="width: 30rem; max-height: 5rem"
        class="overflow-auto"
      />
    </template>
    <template #item.rating="{ item }">
      <v-rating
        v-model.number="item.data.rating"
        color="warning"
        background-color="warning"
        large
        readonly
      ></v-rating>
    </template>
  </ListView>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import RecordAiStatusService from '@/services/record-ai-status.service';

@Component({
  components: {
    ListView
  }
})
export default class RecordUserStatusList extends Vue {

  @Prop({ default: () => ([]) }) filters!: Array<{
    field: string,
    operator: string,
    value: any,
  }>

  service = RecordAiStatusService.getInstance();
  headers = [
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Record ID',
      align: 'start',
      sortable: true,
      value: 'recordId',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Record',
      align: 'start',
      sortable: true,
      value: 'Record.title',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Pending', value: 'pending' },
          { text: 'Pass', value: 'pass' },
          { text: 'Halt', value: 'halt' },
          { text: 'Skip', value: 'skip' },
        ],
      }
    },
    {
      text: 'Project Stage',
      align: 'start',
      sortable: true,
      value: 'type',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Screening', value: 'screening' },
          { text: 'Indepth', value: 'indepth' },
          { text: 'Final', value: 'final' },
        ],
      }
    },
    {
      text: 'Confidence',
      align: 'start',
      sortable: true,
      value: 'confidence',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Comment',
      align: 'start',
      sortable: true,
      value: 'comment',
      filterable: {
        type: 'string',
      },
    },
    {
      text: 'Rating',
      align: 'start',
      sortable: true,
      value: 'rating',
      filterable: {
        type: 'number',
        min: 1,
        max: 5,
      },
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Soft delete',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

import Model from '@/modules/sdk/core/model';
import FileModel from '@/modules/sdk/models/file.model';

export default class ArticleModel extends Model {

  default() {
    return {
      title: '',
      content: '',
      fileentity: new FileModel(),
    };
  }

  relatedMap() {
    return {
      fileentity: FileModel,
    }
  }

  uploadMap() {
    return {
      fileentity: {
        key: 'fileId',
        category: 'article',
      },
    }
  }
}

<template>
  <v-expansion-panels
    class="color-expansion"
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="pa-0"
        hide-actions
      >
        <!-- TEXT FIELD -->
        <v-text-field
          v-model="hex"
          v-bind="$attrs"
          @click:clear="onClearColor"
        >
          <template #prepend-inner>
            <slot name="prepend-inner">
              <v-icon
                left
                :color="hex"
              >
                mdi-palette
              </v-icon>
            </slot>
          </template>
        </v-text-field>

      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 pa-0">
        <v-sheet
          class="mt-3 ml-0"
          outlined
          rounded
        >

          <!-- COLOR -->
          <v-color-picker
            v-model="color"
            v-bind="_attrs"
            @input="() => onColorInput(this.hex)"
          ></v-color-picker>
        </v-sheet>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Watch} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('components/TextFieldColorExpansion');

@Component
export default class TextFieldColorExpansion extends Vue {
  @VModel({ type: String }) model !: string;

  defaultColor = '#E0E0E0FF'
  color = ''

  @Watch('model', { immediate: true })
  onModelChanged(color: any) {
    if (color !== null) {
      this.color = color;
    }
  }

  get _attrs(): any {
    return this.$attrs;
  }

  get hex(): string {
    if (typeof this.color === 'object') {
      return this.color[this._attrs.type || 'hexa'];
    }
    return this.color;
  }

  set hex(value: string) {
    // do nothing
  }

  onColorInput(color: string) {
    if (color === this.defaultColor) { // Prevent default color
      this.color = '';
      return;
    }
    this.model = color;
  }

  onClearColor() {
    this.color = '';
    this.model = '';
  }
}
</script>

<style lang="scss">
.color-expansion .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>

import Service from '@/modules/sdk/core/service';
import RecordModel from '@/models/record.model';

export default class RecordService extends Service {
  modelUrl = '/record';
  baseUrl = '/api';
  model = RecordModel;

  endpointList = {
    ...new Service().endpointList,
    ...{
      saveUserStatus: '/save-user-status',
      getMetrics: '/get-metrics',
    }
  }

  getSaveUserStatusUrl = () => this.getUrl('saveUserStatus');
  getMetricsUrl = () => this.getUrl('getMetrics');

  saveUserStatus = (data = {}, config = {}) => this.handleRequest(this.getSaveUserStatusUrl(), data, config);
  getMetrics = (data = {}, config = {}) => this.handleRequest(this.getMetricsUrl(), data, config);
}

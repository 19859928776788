<template>
  <div class="w-100">
    <v-dialog
      v-model="modal"
      :return-value.sync="date"
      ref="dialog"
      persistent
      eager
      width="290px"
      style="flex: 1"
      clearable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          v-bind="{ ...inputAttrs, ...attrs }"
          v-on="on"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          clearable
        ></v-text-field>
      </template>
      <v-time-picker
        v-model="date"
        v-bind="datePickerAttrs"
        full-width
        ampm-in-title
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onCancel"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
        <v-btn
          color="primary"
          @click="onApply"
        >
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class DialogTimeField extends Vue {

  @Prop({
    default: null
  }) value!: string | Date

  @Prop({
    type: Object,
    default: null
  }) inputAttrs?: null

  @Prop({
    type: Object,
    default: null
  }) datePickerAttrs?: null

  modal = false
  date: string | Date | null = null

  @Watch('modal')
  onModalChanged(visible: boolean) {
    if (visible) {
      this.date = this.value;
    }
  }

  @Watch('value', { immediate: true })
  onValueChanged() {
    this.date = this.value;
  }

  onCancel(): void {
    this.modal = false;
  }

  onApply(): void {
    this.$emit('input', this.date);
    this.modal = false;
    // @ts-ignore
    this.$refs.dialog.save(this.date);
  }
}
</script>

import Model from '../core/model';
import UserModel from './user.model';

export default class ContactModel extends Model {

  relatedMap () {
    return {
      userentity: UserModel,
    }
  }

}

import Model from '@/modules/sdk/core/model';
import { gptBaseModelList } from '@/enums/global';

export default class AiModelModel extends Model {

  getBaseModelLabel() {
    return gptBaseModelList.find((item) => item.value === this.data.baseModel)?.text || this.data.baseModel
  }

}

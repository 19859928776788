<template>
  <ModalDialog
    v-model="syncedVisible"
    :max-width="600"
    icon="mdi-check-decagram"
    title="Satisfaction Feedback"
    background-color="background"
    scrollable
    default-buttons
    persistent
  >
    <template #content>
      <v-card-text class="background pt-6">
        <v-container class="pa-0 ma-0" fluid>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <SatisfactionSurveyForm ref="satisfactionSurveyForm" v-if="tick"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template #buttons>
      <v-btn
        v-if="satisfactionSurveyForm && !satisfactionSurveyForm.sent"
        :block="$vuetify.breakpoint.smAndDown"
        :disabled="!satisfactionSurveyForm.canSubmit"
        :loading="satisfactionSurveyForm.sending"
        large
        color="primary"
        @click="satisfactionSurveyForm.onSubmit"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        large
        outlined
        @click="() => syncedVisible = false"
      >
        <span v-if="satisfactionSurveyForm && !satisfactionSurveyForm.sent">Cancel</span>
        <span v-else>Close</span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, PropSync, Ref, Watch} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import SatisfactionSurveyForm from '@/modules/common/components/SatisfactionSurveyForm.vue';

@Component({
  components: {
    ModalDialog,
    SatisfactionSurveyForm,
  }
})
export default class SatisfactionSurveyModal extends Vue {
  @Ref() readonly satisfactionSurveyForm!: SatisfactionSurveyForm;
  @PropSync('visible', {type: Boolean, default: false}) syncedVisible!: boolean;

  tick = 1;

  @Watch('syncedVisible')
  onVisibleChange(visible: boolean) {
    setTimeout(() => {
      this.tick++;
    })
    if (!visible && this.satisfactionSurveyForm && this.satisfactionSurveyForm.sent) {
      setTimeout(() => {
        this.satisfactionSurveyForm.reset()
      }, 333);
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('DataForm',_vm._b({directives:[{name:"test-id",rawName:"v-test-id",value:('survey'),expression:"'survey'"}],attrs:{"service":_vm.service,"loading":_vm.loading,"redirect-on-create":!_vm.projectId,"name":"survey","title":"Data Extraction","icon":"mdi-comment-question-outline","hide-back-button":""},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('Survey',{attrs:{"value":_vm._model,"labels":{
          groups: {
            add: _vm.$t('survey.labels.addGroup'),
            remove: _vm.$t('survey.labels.removeGroup'),
          },
          questions: {
            add: _vm.$t('survey.labels.addQuestion'),
            remove: _vm.$t('survey.labels.removeQuestion'),
          },
          questionsAi: {
            add: _vm.$t('survey.labels.addQuestionAi'),
            remove: _vm.$t('survey.labels.removeQuestionAi'),
          },
        },"group-attrs":{
          label: _vm.$t('survey.group.label')
        },"question-attrs":{
          label: _vm.$t('survey.question.label')
        },"editable":"","sortable":"","disable-if-answered":"","show-templates":""},on:{"append-template":_vm.onAppendTemplate}})]},proxy:true}]),model:{value:(_vm._model),callback:function ($$v) {_vm._model=$$v},expression:"_model"}},'DataForm',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
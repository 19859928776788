import Service from '@/modules/sdk/core/service';
import RecordTagModel from '@/models/record-tag.model';

export default class RecordTagService extends Service {
  modelUrl = '/record-tag';
  baseUrl = '/api';
  model = RecordTagModel;


}

<template>
  <v-menu
    v-model="menu"
    v-bind="$attrs"
    :close-on-content-click="false"
    left
    origin="top right"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: on2, attrs: attrs2 }">
          <v-btn
            v-bind="{...attrs, ...attrs2}"
            v-on="{...on, ...on2}"
            :disabled="disabled"
            icon
          >
            <v-icon>
              mdi-more
            </v-icon>
          </v-btn>
        </template>
        <span>Options</span>
      </v-tooltip>
    </template>

    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <v-list dense>
        <v-list-item-group
          color="primary"
        >
          <!-- items -->
          <v-list-item
            v-for="item in _items"
            :key="item.href"
            :href="item.href"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{item.label}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="_items.length > 0" />

          <!-- HIGHLIGHTING STYLE -->
          <v-subheader>Highlighting style</v-subheader>
          <v-list-item @click="onSelectHighlightingStyle('background')">
            <v-list-item-icon>
              <v-icon v-if="syncedStyle === 'background'">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Background</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onSelectHighlightingStyle('text')">
            <v-list-item-icon>
              <v-icon v-if="syncedStyle === 'text'">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Text</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, PropSync, ModelSync} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('views/Admin/Component/Record/itemsMenuComponent');

@Component({
  components: {
  }
})
export default class itemsMenuComponent extends Vue {
  @ModelSync('vModel', 'change', { default: () => false }) menu!: boolean;
  @Prop({type: Array, default: () => []}) readonly items!: Array<any>;
  @Prop({ default: () => false }) disabled!: boolean;
  @PropSync('highlightingStyle', { type: String, default: 'background' }) syncedStyle!: string;

  get _items(): Array<any> {
    return this.items.filter(item => item.href);
  }

  onSelectHighlightingStyle(style: string): void {
    this.syncedStyle = style;
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('DataForm',{directives:[{name:"test-id",rawName:"v-test-id",value:('data-extraction-presets'),expression:"'data-extraction-presets'"}],attrs:{"service":_vm.service,"loading":_vm.loading,"name":"data-extraction-presets","title":"Data Extraction Presets"},scopedSlots:_vm._u([{key:"form",fn:function({ formErrors, rules }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":formErrors.label,"rules":[rules.required],"label":"Label","outlined":"","hide-details":"auto","clearable":"","required":""},on:{"input":function($event){formErrors = {}}},model:{value:(_vm.model.data.label),callback:function ($$v) {_vm.$set(_vm.model.data, "label", $$v)},expression:"model.data.label"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Survey',{attrs:{"value":_vm.model,"labels":{
              groups: {
                add: _vm.$t('survey.labels.addGroup'),
                remove: _vm.$t('survey.labels.removeGroup'),
              },
              questions: {
                add: _vm.$t('survey.labels.addQuestion'),
                remove: _vm.$t('survey.labels.removeQuestion'),
              },
              questionsAi: {
                add: _vm.$t('survey.labels.addQuestionAi'),
                remove: _vm.$t('survey.labels.removeQuestionAi'),
              },
            },"group-attrs":{
              label: _vm.$t('survey.group.label')
            },"question-attrs":{
              label: _vm.$t('survey.question.label')
            },"editable":"","sortable":"","disable-if-answered":""}})],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Model from '@/modules/sdk/core/model';
import FileModel from '@/modules/sdk/models/file.model';

export default class VideoLibraryModel extends Model {

  default() {
    return {
      videofileentity: new FileModel(),
      thumbnailfileentity: new FileModel(),
      vttfileentity: new FileModel(),
      index: '',
      label: '',
      content: '',
      position: 0,
    };
  }

  relatedMap() {
    return {
      videofileentity: FileModel,
      thumbnailfileentity: FileModel,
      vttfileentity: FileModel,
    }
  }

  columnCast() {
    return {
      position: 'number',
    }
  }

  uploadMap() {
    return {
      videofileentity: {
        key: 'videoFileId',
        category: 'video_library_file',
      },
      thumbnailfileentity: {
        key: 'thumbnailFileId',
        category: 'video_library_thumbnail',
      },
      vttfileentity: {
        key: 'vttFileId',
        category: 'video_library_vtt',
      },
    }
  }
}

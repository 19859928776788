<template>
  <div style="z-index: 999999;"></div>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import confetti from 'canvas-confetti';

const d = new Logger('components/Confetti');

@Component({
  components: {}
})
export default class GlobalConfetti extends Vue {
  options: confetti.Options = {
    // particleCount: 50, // The number of confetti to launch. More is always fun... but be cool, there's a lot of math involved.
    // angle: 90, // The angle in which to launch the confetti, in degrees. 90 is straight up.
    // spread: 45, // How far off center the confetti can go, in degrees. 45 means the confetti will launch at the defined angle plus or minus 22.5 degrees.
    // startVelocity: 45, // How fast the confetti will start going, in pixels.
    // decay: 0, //): How quickly the confetti will lose speed. Keep this number between 0 and 1, otherwise the confetti will gain speed. Better yet, just never change it.
    // gravity: 1, // How quickly the particles are pulled down. 1 is full gravity, 0.5 is half gravity, etc., but there are no limits. You can even make particles go up if you'd like.
    // drift: 0, // How much to the side the confetti will drift. The default is 0, meaning that they will fall straight down. Use a negative number for left and positive number for right.
    // ticks: 200, // How many times the confetti will move. This is abstract... but play with it if the confetti disappear too quickly for you.
    // origin: { // Where to start firing confetti from. Feel free to launch off-screen if you'd like.firing confetti from. Feel free to launch off-screen if you'd like.
    //   x: 0.5, // The x position on the page, with 0 being the left edge and 1 being the right edge.
    //   y: 0.5, // The y position on the page, with 0 being the top edge and 1 being the bottom edge.
    // },
    // colors: [], // An array of color strings, in the HEX format... you know, like #bada55
    // shapes: [], // An array of shapes for the confetti. The possible values are square, circle, and star. The default is to use both squares and circles in an even mix. To use a single shape, you can provide just one shape in the array, such as ['star']. You can also change the mix by providing a value such as ['circle', 'circle', 'square'] to use two third circles and one third squares.
    // scalar: 1, // Scale factor for each confetti particle. Use decimals to make the confetti smaller. Go on, try teeny tiny confetti, they are adorable!
    // zIndex: 999999, // The confetti should be on top, after all. But if you have a crazy high page, you can set it even higher.
    // disableForReducedMotion: false, // Disables confetti entirely for users that prefer reduced motion. The confetti() promise will resolve immediately in this case.
  };

  /**
   * Reset confetti
   */
  reset() {
    confetti.reset();
  }

  /**
   * Fire confetti helper
   * @param opts
   */
  fire(opts: confetti.Options = {}) {
    confetti(Object.assign({}, this.options, opts));
  }

  /**
   * Random in range helper
   * @param min
   * @param max
   */
  randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  /**
   * Basic Cannon Fire Helper
   * @param particleCount
   * @param spread
   * @param origin
   * @param opts
   */
  basicCannon(
    particleCount: number = this.randomInRange(50, 100),
    spread: number = this.randomInRange(50, 70),
    origin = {y: 0.6},
    opts: confetti.Options = {}
  ) {
    this.fire({
      ...opts,
      particleCount,
      spread,
      origin,
    })
  }

  /**
   * Random Direction Fire Helper
   * @param particleCount
   * @param spread
   * @param angle
   * @param origin
   * @param opts
   */
  randomDirection(
    particleCount: number = this.randomInRange(50, 100),
    spread: number = this.randomInRange(50, 70),
    angle: number = this.randomInRange(55, 125),
    origin: {y: 0.6},
    opts: confetti.Options = {}
  ) {
    this.fire({
      ...opts,
      particleCount,
      spread,
      angle,
      origin
    });
  }

  /**
   * Fire a realistic looking cannon
   * @param particleCount
   * @param opts
   */
  realisticLook(
    particleCount: number = this.randomInRange(150, 250),
    opts: confetti.Options = {}
  ) {
    this.fire({
      ...opts,
      particleCount: particleCount * 0.25,
      spread: 26,
      startVelocity: 55,
    });
    this.fire({
      ...opts,
      particleCount: particleCount * 0.2,
      spread: 60,
    });
    this.fire({
      ...opts,
      particleCount: particleCount * 0.35,
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire({
      ...opts,
      particleCount: particleCount * 0.1,
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire({
      ...opts,
      particleCount: particleCount * 0.1,
      spread: 120,
      startVelocity: 45,
    });
  }

  /**
   * Launch fireworks looking confettis
   * @param totalParticleCount
   * @param duration
   * @param intervalTimeout
   * @param opts
   */
  fireworks(
    totalParticleCount: number = this.randomInRange(50, 100),
    duration: number = this.randomInRange(5, 10) * 1000,
    intervalTimeout: number = this.randomInRange(200, 300),
    opts: confetti.Options = { startVelocity: 30, spread: 360, ticks: 60 },
  ) {
    const animationEnd = Date.now() + duration;

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = totalParticleCount * (timeLeft / duration);
      this.fire({
        ...opts,
        particleCount,
        origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      });
      this.fire({
        ...opts,
        particleCount,
        origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      });
    }, intervalTimeout);
  }

  mounted() {
    this.$root.$globalConfetti = this;
  }
}
</script>

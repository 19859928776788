<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'data-extraction-presets'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="data-extraction-presets"
      title="Data Extraction Presets"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Label"
              outlined
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            />
          </v-col>
          <v-col cols="12">
            <Survey
              :value="model"
              :labels="{
                groups: {
                  add: $t('survey.labels.addGroup'),
                  remove: $t('survey.labels.removeGroup'),
                },
                questions: {
                  add: $t('survey.labels.addQuestion'),
                  remove: $t('survey.labels.removeQuestion'),
                },
                questionsAi: {
                  add: $t('survey.labels.addQuestionAi'),
                  remove: $t('survey.labels.removeQuestionAi'),
                },
              }"
              :group-attrs="{
                label: $t('survey.group.label')
              }"
              :question-attrs="{
                label: $t('survey.question.label')
              }"
              editable
              sortable
              disable-if-answered
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import Logger from '@/modules/sdk/core/logger';
import ProjectService from '@/services/project.service';
import SurveyModel from '@/models/survey.model';
import SurveyService from '@/services/survey.service';
import Survey from '@/components/Survey/Survey.vue';

const d = new Logger('views/Admin/Form/DataExtractionPresetsForm');

@Component({
  components: {
    DataForm,
    Survey,
  }
})
export default class DataExtractionPresetsForm extends Vue {

  service = SurveyService.getInstance();
  loading = false;
  model: SurveyModel = new SurveyModel({
    template: 1
  });

  loadingProjectList = false
  projectList = [];
  projectListSearch = '';

  @Watch('projectListSearch')
  onProjectListSearch(search: string) {
    this.loadingProjectList = true;
    ProjectService.getInstance().getAll({search})
      .then(response => this.projectList = response.data.view.list)
      .finally(() => this.loadingProjectList = false);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    const id = this.$route.params.id;
    if (id && id !== 'new') {
      this.load(parseInt(id));
    }
  }
}
</script>

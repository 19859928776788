<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'status-reason'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="status-reason"
      title="Exclusion Reasons"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="model.data.projectId"
              :error-messages="formErrors.projectId"
              :loading="loadingProjectList"
              :search-input.sync="projectListSearch"
              :items="projectList"
              outlined
              label="Project"
              placeholder="Select a project if you want to limit this exclusion to that specific project"
              item-text="data.label"
              item-value="data.id"
              hide-details="auto"
              clearable
              @input="formErrors = {}"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Reason"
              outlined
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="model.data.stage"
              :error-messages="formErrors.stage"
              :rules="[rules.required]"
              :items="stageList"
              outlined
              label="Stage"
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="model.data.status"
              :error-messages="formErrors.status"
              :rules="[rules.required]"
              :items="statusList"
              outlined
              label="Status"
              hide-details="auto"
              clearable
              required
              readonly
              @input="formErrors = {}"
            >
              <template #item="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
              <template #selection="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import Logger from '@/modules/sdk/core/logger';
import ProjectService from '@/services/project.service';
import {stageList, statusList} from '@/enums/global';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';

const d = new Logger('views/Admin/Form/ProjectStatusReasonForm');

@Component({
  components: {
    DataForm,
  }
})
export default class ProjectStatusReasonForm extends Vue {

  service = ProjectStatusReasonService.getInstance();
  loading = false;
  model: ProjectStatusReasonModel = new ProjectStatusReasonModel();

  loadingProjectList = false
  projectList = [];
  projectListSearch = '';

  stageList = stageList.filter(stage => stage.value !== 'screening')
  statusList = statusList.filter(item => item.value !== 'conflict')

  @Watch('projectListSearch')
  onProjectListSearch(search: string) {
    this.loadingProjectList = true;
    ProjectService.getInstance().getAll({search})
      .then(response => this.projectList = response.data.view.list)
      .finally(() => this.loadingProjectList = false);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>

<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'ai-prompts'"
      title="AI Prompts"
      :headers="headers"
      :service="service"
      index="ai-prompt"
      hide-presets
      fill-height
      hide-collapsable
    >
      <!-- LABEL -->
      <template v-slot:item.action="{item}">
        <v-chip class="text-capitalize" label small>
          {{item.getActionLabel()}}
        </v-chip>
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import AiPromptService from '@/services/ai-prompt.service';
import KeywordChip from '@/components/KeywordChip.vue';

@Component({
  components: {
    KeywordChip,
    ListView,
  }
})
export default class AiPromptList extends Vue {
  service = AiPromptService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'AiPromptForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Action',
      align: 'start',
      sortable: true,
      value: 'action',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Title',
      align: 'start',
      sortable: true,
      value: 'title',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Content',
      align: 'start',
      sortable: true,
      value: 'content',
      type: 'truncate',
      item: {
        type: 'truncate',
        width: '400px',
      },
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'status-reasons'"
      title="Exclusion Reasons"
      :headers="headers"
      :service="service"
      searchable
      hide-presets
      index="status_reason"
      fill-height
      hide-collapsable
    >
      <!-- Project -->
      <template v-slot:item.project.label="{item}">
        {{ item.data.projectentity?.data.label }}
      </template>

      <!-- STAGE -->
      <template v-slot:item.stage="{item}">
        <v-chip class="ma-2" label :color="item.data.stage | color('stage')" small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import ProjectService from '@/services/project.service';
import { stageList, statusList } from '@/enums/global';

@Component({
  components: {
    ListView,
  }
})
export default class ProjectStatusReasonList extends Vue {
  name = 'ProjectStatusReasonList';
  service = ProjectStatusReasonService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      value: '_first',
      width: 72,
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'ProjectStatusReasonForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Project',
      align: 'start',
      sortable: true,
      value: 'project.label',
      cellClass: 'text-no-wrap',
      filterable: {
        field: 'projectId',
        type: 'distinct',
        service: ProjectService,
      }
    },
    {
      text: 'Reason',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      filterable: {
        type: 'enum',
        items: statusList
      }
    },
    {
      text: 'Stage',
      align: 'start',
      sortable: true,
      value: 'stage',
      filterable: {
        type: 'enum',
        items: stageList
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>

import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyGroupModel from '@/models/survey-group.model';
import SurveyQuestionModel from '@/models/survey-question.model';
import SurveyChoiceModel from '@/models/survey-choice.model';
import SurveyAiQuestionModel from '@/models/survey-ai-question.model';

export default class SurveyModel extends Model {

  default() {
    return {
      surveygrouplist: [],
    };
  }

  relatedMap () {
    return {
      projectentity: ProjectModel,
      surveygrouplist: SurveyGroupModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveygrouplist: true,
    };
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveygrouplist', 'position');
  }

  setReferenceKeyValue(key: string, value: any): void {
    this.data[key] = value;
    this.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
      group.data[key] = value;
      group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
        question.data[key] = value;
        question.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
          choice.data[key] = value;
        });
        question.data.surveyaiquestionlist.forEach((aiQuestion: SurveyAiQuestionModel) => {
          aiQuestion.data[key] = value;
        });
      })
    })
  }

  cleanReferences(): void {
    this.setReferenceKeyValue('id', null);
    this.setReferenceKeyValue('projectId', null);
    this.setReferenceKeyValue('surveyId', null);
    this.setReferenceKeyValue('surveyGroupId', null);
    this.setReferenceKeyValue('surveyQuestionId', null);
  }

  resetPosition(): void {
    this.data.surveygrouplist.forEach((group: SurveyGroupModel, groupIdx: number) => {
      group.data.position = groupIdx;
    })
  }
}

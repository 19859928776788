<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('manageRecordUserStatusModal.title')"
    :loading="applying"
  >
    <template #body>
      <v-radio-group v-model="reasonId">
        <v-list class="pb-0">
          <v-list-item
            v-for="reason in reasons"
            :key="reason.data.id"
          >
            <v-list-item-icon>
              <v-radio :value="reason.data.id" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-chip :color="getStatus(reason.data.status).color" label small>
                  <span v-text="getStatus(reason.data.status).text"></span>&thinsp;
                  (<span v-text="reason.data.userType"></span>)
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1">
                <span v-text="$t('manageRecordUserStatusModal.byOn', {
                  by: reason.data.userentity? reason.data.userentity.getFullName() : reason.data.userType.toUpperCase(),
                  on: reason.data.createdAt,
                })"></span>
              </v-list-item-subtitle>
              <v-list-item-action-text>
                <span v-text="reason.data.comment"></span>
              </v-list-item-action-text>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-radio-group>
    </template>
    <template #buttons>
      <v-btn
        large
        color="primary"
        :loading="applying"
        :disabled="!canApply"
        @click="onApplyClick"
      >
        <span v-text="$t('btn.apply')"></span>
      </v-btn>
      <v-btn
        large
        text
        :disabled="applying"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import {Vue, Component, Prop, VModel, Watch} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import Rules from '@/modules/sdk/core/rules';
import RecordUserStatusModel from '@/models/record-user-status.model';
import { statusList } from '@/enums/global';

@Component({
  components: {
    ModalDialog,
  }
})
export default class ManageRecordUserStatusModal extends Vue {
  @VModel({
    type: Boolean
  }) visible!: boolean

  @Prop({
    type: Array,
    default: () => ([])
  }) reasons!: Array<RecordUserStatusModel>

  statusList = statusList
  rules: any = {}
  loading = false
  applying = false
  reasonId: number | null = null;

  @Watch('visible')
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.applying = false;
    }
  }

  get canApply(): boolean {
    return !this.applying && this.reasonId !== null;
  }

  onApplyClick(): void {
    this.applying = true;
    if (this.reasonId !== null) {
      this.$emit('apply', this.reasons.find(reason => reason.data.id === this.reasonId));
      this.applying = false;
      this.visible = false;
    }
  }

  onCancelClick(): void {
    this.visible = false;
  }

  getStatus(status: string) {
    return this.statusList.find((item: any) => item.value === status);
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>

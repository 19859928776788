import Vue from 'vue'
import Vuetify, { UserVuetifyPreset } from 'vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import en from 'vuetify/src/locale/en'
import fr from 'vuetify/src/locale/fr'
import store from 'store2'

// const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

const opts: Partial<UserVuetifyPreset> = {
  lang: {
    locales: { en, fr },
    current: 'en',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: localStorage.getItem('dark') === null ? false : store.local.get('dark') === 'true',
    options: {
      cspNonce: 'aFz7r1LqXoY',
      customProperties: true,
      variations: false
    },
    themes: {
      light: {
        gold: '#F5BD02',
        sheet: '#FFFFFF',
        backgroundDark: '#BBBBBB',
        background: '#EEEEEE',
        backgroundLight: '#F0F0F0',
        backgroundVeryLight: '#F6F6F6',
        primary: '#00b588',
        secondary: '#035642',
        third: '#F9BE6C',
        accent: '#82B1FF',
        error: '#ff7676',
        failed: '#ff7676',
        info: '#2196F3',
        success: '#5ba056',
        finished: '#5ba056',
        warning: '#F9BE6C',
        skip: '#E0E0E0',
        text: '#000000',
      },
      dark: {
        gold: '#F5BD02',
        sheet: '#242424',           // Slightly lighter to reduce harshness
        backgroundDark: '#121212',  // A deeper shade for differentiation
        background: '#181818',      // A touch lighter to ease the contrast
        backgroundLight: '#2c2c2c', // Adjusted for better visibility
        backgroundVeryLight: '#343434', // A subtle lightness for layered depth
        primary: '#00b588',         // A brighter hue for better visibility
        secondary: '#035642',       // Slightly lighter for contrast
        third: '#FFD180',           // Adjusted to pop against dark backgrounds
        accent: '#9FA8DA',          // A softer accent for a night mode feel
        error: '#ff5252',           // More vivid to stand out
        failed: '#ff5252',           // More vivid to stand out
        info: '#29B6F6',            // Brighter to be easily noticeable
        success: '#66BB6A',         // A lighter shade for better contrast
        finished: '#66BB6A',         // A lighter shade for better contrast
        warning: '#FFCA28',         // Adjusted for visibility
        skip: '#616161',            // Darker to fit the theme
        text: '#E0E0E0'            // Off-white for reduced eye strain
      },
    },
  },
}

const vuetify = new Vuetify(opts)

Vue.use(Vuetify)
Vue.use(DatetimePicker)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg'
})

export {
  opts
}

export default vuetify;

import Model from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyModel from '@/models/survey.model';
import SurveyGroupModel from '@/models/survey-group.model';
import SurveyQuestionModel from '@/models/survey-question.model';
import SurveyAiQuestionModel from '@/models/survey-ai-question.model';

export default class SurveyAiAnswerModel extends Model {

  default() {
    return {
      content: '',
    };
  }

  relatedMap () {
    return {
      projectentity: ProjectModel,
      surveyentity: SurveyModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
      surveyaiquestionentity: SurveyAiQuestionModel,
    }
  }

}

interface TutorialItem {
  type: 'dev' | 'general'
  key: string
  label: string
  body: string
  thumbnail: string
  youtube: string
}

const contactTypeList = [
  { value: 'help', text: 'General help' },
  { value: 'bug', text: 'Report a bug' },
  { value: 'comment', text: 'Comment only' },
];

const logicList = [
  { value: 'and', text: 'And' },
  { value: 'or', text: 'Or' },
];

const heatmapLegendsList = [
  { color: 'success', text: 'Fit for purpose' },
  { color: 'warning', text: 'Limited fit for purpose' },
  { color: 'error', text: 'Not fit for purpose' },
];

const fieldList = [
  { value: 'id', text: 'Record ID' },
  { value: 'pilotUserId', text: 'Pilot User (Researcher)' },
  { value: 'pid', text: 'Project Record ID' },
  { value: 'sourceType', text: 'Source type' },
  { value: 'authors', text: 'Authors' },
  { value: 'year', text: 'Year' },
  { value: 'title', text: 'Title' },
  { value: 'abstract', text: 'Abstract' },
  { value: 'affiliations', text: 'Affiliations' },
  { value: 'language', text: 'Language' },
  { value: 'references', text: 'References' },
  { value: 'doi', text: 'DOI' },
  { value: 'uid', text: 'Uid' },
  { value: 'url', text: 'URL' },
  { value: 'ovid', text: 'Ovid' },
];

const sourceTypeList = [
  { value: 'literature', text: 'Literature Search' },
  { value: 'snowballing', text: 'Snowballing' },
  { value: 'pragmatic', text: 'Pragmatic Search' },
  { value: 'client', text: 'Client' },
];

const recordFieldList = [
  { value: 'sourceType', text: 'Source Type' },
  { value: 'authors', text: 'Authors' },
  { value: 'title', text: 'Title' },
  { value: 'abstract', text: 'Abstract' },
  { value: 'affiliations', text: 'Affiliations' },
  { value: 'references', text: 'References' },
  { value: 'doi', text: 'DOI' },
  { value: 'url', text: 'URL' },
  { value: 'ovid', text: 'Ovid' },
  { value: 'language', text: 'Language' },
  { value: 'identifier', text: 'Identifier' },
];

const officialStatusFieldList = [
  { value: 'screeningStatus', text: 'Screening' },
  { value: 'indepthStatus', text: 'In-depth' },
  { value: 'finalStatus', text: 'Data Extraction' },
];

const statusReasonFieldList = [
  { value: 'indepthStatusReason', text: 'In-depth (Reason of exclusion)' },
  { value: 'finalStatusReason', text: 'Data Extraction (Reason of exclusion)' },
];

const extraFieldList = [
  { value: 'Tag.label', text: 'Tags' },
  { value: 'Article.title', text: 'Articles (Title)' },
  { value: 'Article.content', text: 'Articles (Body)' },
  { value: 'Comment.content', text: 'Comments (Single)' },
  { value: 'GROUP_CONCAT([Comment].[content])', text: 'Comments (Combined)' },
];

const operatorList = [
  { value: 'contains', text: 'Contains' },
  { value: 'does not contain', text: 'Does not contain' },
  { value: 'contains word', text: 'Contains word' },
  { value: 'does not contain word', text: 'Does not contain word' },
  { value: 'equals', text: 'Equals' },
  { value: 'does not equal', text: 'Does not equal' },
  { value: 'greater than', text: 'Greater than' },
  { value: 'greater than or equal', text: 'Greater than or equal' },
  { value: 'less than', text: 'Less than' },
  { value: 'less than or equal', text: 'Less than or equal' },
  { value: 'is empty', text: 'Is empty' },
  { value: 'is not empty', text: 'Is not empty' },
  { value: 'regexp', text: 'Matches regular expression' },
  { value: 'not regexp', text: 'Does not match regular expression' },
];

const stageList = [
  { value: 'screening', text: 'Screening' },
  { value: 'indepth', text: 'In-depth' },
  { value: 'final', text: 'Data Extraction' },
];

const taskActionList = [
  { value: 'ai_project_review', text: 'AI Project Review' },
  { value: 'project_record_compare', text: 'Project Record Compare' },
];

const taskStatusList = [
  { text: 'New', value: 'new', color: '', icon: null, },
  { text: 'Running', value: 'running', color: 'info', icon: 'mdi-loading mdi-spin', },
  { text: 'Finished', value: 'finished', color: 'success', icon: 'mdi-check', },
  { text: 'Failed', value: 'failed', color: 'error', icon: 'mdi-alert', },
  { text: 'Canceled', value: 'canceled', color: '', icon: 'mdi-close', },
];

const statusList = [
  { value: 'pending', text: 'Pending', color: 'warning' },
  { value: 'halt', text: 'Halt', color: 'error' },
  { value: 'pass', text: 'Pass', color: 'success' },
  { value: 'skip', text: 'Skip', color: 'gray' },
  { value: 'conflict', text: 'Conflict', color: 'yellow' },
];

const projectTypeList = [
  { value: 'systematic_review', text: 'Systematic Review' },
  { value: 'non_systematic_review', text: 'Non-systematic Review' },
  { value: 'data_landscaping', text: 'Data Landscaping' },
];

const projectStatusList = [
  { value: 'new', text: 'New', color: null },
  { value: 'progress', text: 'Progress', color: 'primary' },
  { value: 'finished', text: 'Finished', color: 'success' },
  { value: 'blocked', text: 'Blocked', color: 'error' },
  { value: 'canceled', text: 'Canceled', color: 'grey' },
];

const markerList = [
  { text: 'Circle', value: 'circle', icon: 'mdi-circle-outline', },
  { text: 'Triangle', value: 'triangle', icon: 'mdi-triangle-outline', },
  { text: 'Square', value: 'square', icon: 'mdi-square-outline', },
];

const reviewedByList = [
  { value: 'pass by me', text: 'pass by me' },
  { value: 'pending by me', text: 'pending by me' },
  { value: 'halt by me', text: 'halt by me' },
  { value: 'skip by me', text: 'skip by me' },
  { value: 'reviewed by me', text: 'reviewed by me' },
  { value: 'not reviewed by me', text: 'not reviewed by me' },
  { value: 'reviewed by anyone', text: 'reviewed by anyone' },
  { value: 'not reviewed by anyone', text: 'not reviewed by anyone' },
  { value: 'reviewed by ai', text: 'reviewed by ai' },
  { value: 'not reviewed by ai', text: 'not reviewed by ai' },
  { value: 'reviewed by someone else', text: 'reviewed by someone else' },
  { value: 'not reviewed by someone else', text: 'not reviewed by someone else' },
  { value: 'reviewed by two or more people', text: 'reviewed by two or more people' },
  { value: 'not reviewed by two or more people', text: 'not reviewed by two or more people' },
  { value: 'reviewed by leader', text: 'reviewed by leader' },
  { value: 'not reviewed by leader', text: 'not reviewed by leader' },
  { value: 'reviewed by arbitrator', text: 'reviewed by arbitrator' },
  { value: 'not reviewed by arbitrator', text: 'not reviewed by arbitrator' },
  { value: 'reviewed by researcher', text: 'reviewed by researcher' },
  { value: 'not reviewed by researcher', text: 'not reviewed by researcher' },
  { value: 'reviewed by primary researcher', text: 'reviewed by primary researcher' },
  { value: 'not reviewed by primary researcher', text: 'not reviewed by primary researcher' },
  { value: 'reviewed by secondary researcher', text: 'reviewed by secondary researcher' },
  { value: 'not reviewed by secondary researcher', text: 'not reviewed by secondary researcher' },
];

const orderList = [
  { value: 'id', text: 'Record ID' },
  { value: 'pid', text: 'Project Record ID' },
  { value: 'authors', text: 'Authors' },
  { value: 'year', text: 'Year' },
  { value: 'title', text: 'Title' },
  { value: 'abstract', text: 'Abstract' },
  { value: 'affiliations', text: 'Affiliations' },
  { value: 'language', text: 'Language' },
  { value: 'references', text: 'References' },
  { value: 'doi', text: 'DOI' },
  { value: 'uid', text: 'Uid' },
  { value: 'url', text: 'URL' },
  { value: 'ovid', text: 'Ovid' },
  { value: 'screeningStatus', text: 'Screening Status' },
  { value: 'indepthStatus', text: 'In-depth Status' },
  { value: 'finalStatus', text: 'Data Extraction Status' },
  { value: 'RAND()', text: 'Random' },
];

const projectUserTypeList: Array<{
  value: string,
  text: string,
}> = [
  { value: 'leader', text: 'Leader' },
  { value: 'arbitrator', text: 'Arbitrator' },
  { value: 'researcher', text: 'Primary Researcher' },
  { value: 'secondary-researcher', text: 'Secondary Researcher' },
];

const gptModelList: Array<{
  value: string,
  description: string,
  text: string,
  replacement?: string,
}> = [
  // ChatGPT
  {
    value: 'chatgpt-4o-latest',
    text: 'ChatGPT 4o Latest',
    description: 'The most up-to-date version of GPT-4o, incorporating the latest improvements in performance, multimodal capabilities, and efficiency. This model is continuously updated and is the most capable for complex tasks and real-time interactions.'
  },

  // GPT-4o Models
  {
    value: 'gpt-4o',
    text: 'GPT 4o',
    description: 'Latest GPT-4 model with improved performance for complex tasks, including handling multimodal input like text, audio, and vision.'
  },
  {
    value: 'gpt-4o-2024-05-13',
    text: 'GPT 4o (2024-05-13)',
    description: 'A version of GPT-4o released in May 2024 with improvements in multimodal reasoning and performance.'
  },
  {
    value: 'gpt-4o-2024-08-06',
    text: 'GPT 4o (2024-08-06)',
    description: 'A version of GPT-4o released in August 2024 with further optimizations for handling complex input and output scenarios.'
  },

  // GPT-4o Mini Models
  {
    value: 'gpt-4o-mini',
    text: 'GPT 4o Mini',
    description: 'Cost-efficient version of GPT-4o with smaller size but strong performance in multimodal reasoning.'
  },
  {
    value: 'gpt-4o-mini-2024-07-18',
    text: 'GPT 4o Mini (2024-07-18)',
    description: 'A version of GPT-4o Mini released in July 2024, optimized for low-latency tasks while maintaining strong performance in reasoning.'
  },

  // GPT-4 Models
  {
    value: 'gpt-4',
    text: 'GPT 4',
    description: 'More capable than any GPT-3.5 model, able to do more complex tasks, and optimized for chat. Will be updated with our latest model iteration 2 weeks after it is released.'
  },
  {
    value: 'gpt-4-0613',
    text: 'GPT 4 0613 (Deprecated)',
    description: 'Snapshot of gpt-4 from June 13th 2023 with function calling data. Will be replaced by gpt-4o.',
    replacement: 'gpt-4o'
  },
  {
    value: 'gpt-4-32k',
    text: 'GPT 4 32k',
    description: 'Same capabilities as the base gpt-4 model but with 4x the context length. Will be updated with our latest model iteration.'
  },
  {
    value: 'gpt-4-32k-0613',
    text: 'GPT 4 32k-0613 (Deprecated)',
    description: 'Snapshot of gpt-4-32k from June 13th 2023. Will be replaced by gpt-4o.',
    replacement: 'gpt-4o'
  },
  {
    value: 'gpt-4-turbo',
    text: 'GPT 4 Turbo',
    description: 'Optimized for chat with improved speed and cost-efficiency compared to GPT-4.'
  },
  {
    value: 'gpt-4-turbo-2024-04-09',
    text: 'GPT 4 Turbo (2024-04-09)',
    description: 'A version of GPT-4 Turbo released in April 2024 with improved speed and cost-efficiency for chat applications.'
  },
  {
    value: 'gpt-4-turbo-preview',
    text: 'GPT 4 Turbo Preview (Deprecated)',
    description: 'Early preview version of GPT-4 Turbo. Will be replaced by gpt-4-turbo.',
    replacement: 'gpt-4-turbo'
  },
  {
    value: 'gpt-4-0125-preview',
    text: 'GPT 4 0125 Preview (Deprecated)',
    description: 'Early preview version of GPT-4 from January 2024. Will be replaced by gpt-4o.',
    replacement: 'gpt-4o'
  },
  {
    value: 'gpt-4-1106-preview',
    text: 'GPT 4 1106 Preview (Deprecated)',
    description: 'Early preview version of GPT-4 from November 2023. Will be replaced by gpt-4o.',
    replacement: 'gpt-4o'
  },

  // GPT-3.5 Models
  {
    value: 'gpt-3.5-turbo',
    text: 'GPT 3.5 Turbo',
    description: 'Most capable GPT-3.5 model and optimized for chat at 1/10th the cost of text-davinci-003. Will be updated with our latest model iteration 2 weeks after it is released.'
  },
  {
    value: 'gpt-3.5-turbo-16k',
    text: 'GPT 3.5 Turbo 16k',
    description: 'Same capabilities as the standard gpt-3.5-turbo model but with 4 times the context.'
  },
  {
    value: 'gpt-3.5-turbo-0613',
    text: 'GPT 3.5 Turbo 0613 (Deprecated)',
    description: 'Snapshot of gpt-3.5-turbo from June 13th 2023 with function calling data. Will be replaced by gpt-3.5-turbo-instruct.',
    replacement: 'gpt-3.5-turbo-instruct'
  },
  {
    value: 'gpt-3.5-turbo-16k-0613',
    text: 'GPT 3.5 Turbo 16k-0613 (Deprecated)',
    description: 'Snapshot of gpt-3.5-turbo-16k from June 13th 2023. Will be replaced by gpt-3.5-turbo-instruct.',
    replacement: 'gpt-3.5-turbo-instruct'
  },
  {
    value: 'gpt-3.5-turbo-instruct',
    text: 'GPT 3.5 Turbo Instruct',
    description: 'Replacement for older GPT-3 models such as text-davinci-003, optimized for task completion and instruction following.'
  }
];

const gptBaseModelList: Array<{value: string, text:string}> = [
  { value: 'gpt-4', text: 'GPT 4' },
  { value: 'gpt-3.5-turbo', text: 'GPT 3.5 Turbo' },
];

const tutorialItems: Array<TutorialItem> = [
  // { type: 'general', key: 'general1', youtube: '5e-oScNiseE', label: 'General 1', body: 'Open wound scalp-compl', thumbnail: 'http://dummyimage.com/210x100.png/ff4444/ffffff', },
  { type: 'dev', key: 'dev1', youtube: '5e-oScNiseE', label: 'Developer 1', body: 'Open wound scalp-compl', thumbnail: 'http://dummyimage.com/210x100.png/ff4444/ffffff', },
]

const objectiveList = [
  { label: 'Main Objectives', key: 'mainObjective', },
  { label: 'Specific Objectives', key: 'specificObjective', },
];

const picotList = [
  { label: 'Population', key: 'picotPopulation', hint: 'Clearly define the patient characteristics, such as age range, gender, specific diagnosis and any relevant comorbidities. Example: "Adult patients aged 18-65 with a confirmed diagnosis of Relapsing-Remitting Multiple Sclerosis (RRMS), excluding patients with secondary progressive or primary progressive MS"', },
  { label: 'Intervention', key: 'picotIntervention', hint: 'Specify the type of interventions to be included, such as specific medications, therapeutic procedures, or lifestyle interventions. Example: "Interventions including first-line immunomodulatory drugs for MS, such as interferon-beta or glatiramer acetate" or "Any or no treatment"', },
  { label: 'Comparator', key: 'picotComparator', hint: 'Describe the comparators clearly, like standard care, placebo, another active treatment, or no treatment. Example: "Comparisons to be made against placebo, no treatment, or alternative therapeutic agents like fingolimod or natalizumab."', },
  { label: 'Outcomes', key: 'picotOutcomes', hint: 'List specific outcomes of interest, including primary outcomes (e.g., relapse rate, disability progression) and secondary outcomes (e.g., quality of life, adverse effects). Example: "Primary outcomes include relapse rate and MRI lesion activity. Secondary outcomes encompass patient-reported quality of life, fatigue levels, and adverse drug reactions."', },
  { label: 'Timing', key: 'picotTiming', hint: '', },
  { label: 'Setting', key: 'picotSetting', hint: 'Specify the setting or type of study design, like randomized controlled trials, observational studies, multicenter studies. Example: "Only randomized controlled trials and longitudinal observational studies conducted in clinical settings will be considered."', },
];

const criteriaList = [
  { label: 'Inclusion Criteria', key: 'inclusionCriteria', },
  { label: 'Exclusion Criteria', key: 'exclusionCriteria', },
];

export {
  contactTypeList,
  logicList,
  fieldList,
  taskStatusList,
  taskActionList,
  sourceTypeList,
  recordFieldList,
  extraFieldList,
  officialStatusFieldList,
  statusReasonFieldList,
  operatorList,
  orderList,
  reviewedByList,
  statusList,
  stageList,
  projectUserTypeList,
  projectTypeList,
  projectStatusList,
  gptModelList,
  gptBaseModelList,
  markerList,
  tutorialItems,
  TutorialItem,
  heatmapLegendsList,
  objectiveList,
  picotList,
  criteriaList,
};

<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'videoLibrary'"
      title="Video Library"
      :headers="headers"
      :service="service"
      :hide-new-button="hideNewButton"
      :options="{
        sortBy: ['position'],
        sortDesc: [false]
      }"
      sort-by="position"
      sortable
      index="video-library"
      hide-presets
      fill-height
      hide-collapsable
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import VideoLibraryService from '@/modules/sdk/services/video-library.service';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('Admin/List/VideoLibraryList');

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class VideoLibraryList extends Vue {

  service = VideoLibraryService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 104,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'VideoLibraryForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Index',
      align: 'start',
      sortable: true,
      value: 'index',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Position',
      align: 'start',
      sortable: true,
      value: 'position',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'range',
      }
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Category',
      align: 'start',
      sortable: true,
      value: 'category',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'enum',
        items: [
          { text: 'General', value: 'general' },
          { text: 'Developer', value: 'dev' },
        ]
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date',
        operator: 'starts with',
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  get hideNewButton(): boolean {
    return !Identity.hasRole(['admin']);
  }
}
</script>

import Service from '@/modules/sdk/core/service';
import ArticleModel from '@/models/article.model';

export default class ArticleService extends Service {
  modelUrl = '/article';
  baseUrl = '/api';
  model = ArticleModel;

  endpointList = {
    ...new Service().endpointList,
    ...{
      parseFile: '/parse-file',
    }
  }

  getParseFileUrl = () => this.getUrl('parseFile');
  parseFile = (data = {}, config = {}) => this.handleRequest(this.getParseFileUrl(), data, config);

  uploadPdf(pdf: File) {
    const formData = new FormData();
    formData.append('file', pdf);
    return this.handleRequest(
      this.getBaseUrl() + '/file/upload/article',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }
}

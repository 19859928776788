import Model, { Data } from '@/modules/sdk/core/model'
import ProjectModel from '@/models/project.model'
import Logger from '@/modules/sdk/core/logger'
import TagModel from '@/models/tag.model'
import RecordModel from '@/models/record.model'
import RecordTagModel from '@/models/record-tag.model'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tinycolor = require('tinycolor2')

const d = new Logger('models/category.model')

export default class CategoryModel extends Model {
  selected = false
  changed = false

  default() {
    return {
      projectlist: [],
      taglist: [],
      recordlist: [],
      recordnode: [],
    }
  }

  relatedMap() {
    return {
      projectlist: ProjectModel,
      taglist: TagModel,
      recordlist: RecordModel,
      recordnode: RecordTagModel,
    }
  }

  relatedDeleteMissing() {
    return {
      projectlist: false,
      taglist: false,
      recordlist: false,
      recordnode: false,
    }
  }

  isDark() {
    return tinycolor(this.data.color).isDark()
  }

  getTextClass() {
    return 'text--' + (this.isDark() ? 'lighten-3' : 'darken-3')
  }

  getTextColor(force = false) {
    return this.selected || force ? (this.isDark() ? 'white' : 'black') : this.data.color
  }
}

<template>
  <div
    class="d-flex align-center"
    style="gap: 0.5rem"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text small min-width="0" class="px-1" :disabled="disabled || index === 0" @click="decreaseFontSize">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('fontSize.decrease')"></span>
    </v-tooltip>

    <v-icon>mdi-format-letter-case</v-icon>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text small min-width="0" class="px-1" :disabled="disabled || index === sizes.length - 1" @click="increaseFontSize">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('fontSize.increase')"></span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class FontSize extends Vue {

  @Prop({ default: () => false }) disabled!: boolean;

  sizes = [12, 14, 16, 20, 24, 28, 32, 36, 48]
  index = 1

  decreaseFontSize() {
    this.index--;
    if (this.index < 0) {
      this.index = 0;
    }
    this.updateFontSize();
  }

  increaseFontSize() {
    this.index++;
    if (this.index > this.sizes.length - 1) {
      this.index = this.sizes.length - 1;
    }
    this.updateFontSize();
  }

  updateFontSize() {
    this.sizes.forEach(size => {
      document.body.classList.remove('font-size-' + size);
    });
    document.body.classList.add('font-size-' + this.sizes[this.index]);

    localStorage.setItem('font-size', this.index.toString());
  }

  init() {
    this.index = parseInt(localStorage.getItem('font-size') || '1');
    this.updateFontSize();
  }

  created() {
    this.init();
  }
}
</script>

<style lang="scss">
body.font-size-12 {
  .adjust-font-size {
    font-size: 12px !important;
    line-height: 1.5em;
  }
}
body.font-size-14 {
  .adjust-font-size {
    font-size: 14px !important;
    line-height: 1.5em;
  }
}
body.font-size-16 {
  .adjust-font-size {
    font-size: 16px !important;
    line-height: 1.5em;
  }
}
body.font-size-20 {
  .adjust-font-size {
    font-size: 20px !important;
    line-height: 1.5em;
  }
}
body.font-size-24 {
  .adjust-font-size {
    font-size: 24px !important;
    line-height: 1.5em;
  }
}
body.font-size-28 {
  .adjust-font-size {
    font-size: 28px !important;
    line-height: 1.5em;
  }
}
body.font-size-32 {
  .adjust-font-size {
    font-size: 32px !important;
    line-height: 1.5em;
  }
}
body.font-size-36 {
  .adjust-font-size {
    font-size: 36px !important;
    line-height: 1.5em;
  }
}
body.font-size-48 {
  .adjust-font-size {
    font-size: 48px !important;
    line-height: 1.5em;
  }
}
</style>

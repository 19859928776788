<template>
  <ModalDialog
    v-model="_visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('dashboardChartSettingsDialog.title')"
    :max-width="model?.options.chart.type !== 'datatable' ? 1200 : 1500"
    icon="mdi-card-bulleted-settings-outline"
    background-color="background"
    persistent
  >
    <template #close.prepend>
      <PresetManager
        :value="model"
        :default-item="defaultPresetItem"
        :loading="loadingPresets"
        :saving="savingPreset"
        :default-item-args="{
          type: 'chart',
        }"
        :load-callback="loadPresets"
        :save-callback="savePresets"
        :remove-callback="removePreset"
        ref="presetManager"
        id="visualization_chart_filters"
        label="Presets"
        hide-details="auto"
        outlined
        dense
        clearable
        style="width: 30rem"
        @input="onApplyPresetFilters"
      />
    </template>
    <template #body>
      <v-row v-if="!hidden">
        <v-col cols="12" :md="model?.options.chart.type !== 'datatable' ? 7 : 6">
          <v-form v-if="model" ref="form" class="mt-4" v-model="formIsValid">
            <v-expansion-panels v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center justify-space-between w-100">
                    <div>
                      <v-icon left>mdi-tag-outline</v-icon>
                      General
                    </div>

                    <span v-if="panel !== 0 && model.options.chart.type" class="mr-3">
                      <v-icon v-text="(types.find(item => item.name === model.options.chart.type) || {}).icon" left></v-icon>
                      <strong v-if="model.title"> {{model.title}}</strong>
                      ({{ $t('widget.types.' + model.options.chart.type + '.title') }})
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-select
                    v-model="model.options.chart.type"
                    :items="types"
                    :label="$t('label.type')"
                    :rules="[rules.required]"
                    :menu-props="{
                      maxHeight: 500,
                      maxWidth: 520,
                    }"
                    item-value="name"
                    outlined
                    clearable
                  >
                    <template #item="{ item }">
                      <v-row>
                        <v-col style="flex: 0" class="d-flex align-center">
                          <v-icon v-text="item.icon" color="primary" x-large></v-icon>
                        </v-col>
                        <v-col style="flex: 1" class="d-flex align-center">
                          <div class="py-3">
                            <h3 v-text="$t('widget.types.' + item.name + '.title')"></h3>
                            <p v-text="$t('widget.types.' + item.name + '.desc')" class="mb-0"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template #selection="{ item }">
                      <v-row>
                        <v-col style="flex: 0" class="d-flex align-center">
                          <v-icon v-text="item.icon" color="primary" x-large></v-icon>
                        </v-col>
                        <v-col style="flex: 1" class="d-flex align-center">
                          <div>
                            <p v-text="$t('widget.types.' + item.name + '.title')" class="mb-0"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-select>

                  <v-text-field
                    v-model="model.title"
                    :label="$t('label.title')"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    autofocus
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel :disabled="model.queryOverride && !canConfigure">
                <v-expansion-panel-header class="justify-space-between">
                  <span>
                    <v-icon left>mdi-card-bulleted-settings-outline</v-icon>
                    Data
                  </span>
                  <v-alert
                    v-if="model.options.chart.type === 'datatable' && model.table.headers.length === 0"
                    style="flex: 0"
                    class="ma-0 text-no-wrap mr-4"
                    dense
                    outlined
                    type="warning"
                  >No header yet!</v-alert>
                  <v-alert
                    v-else-if="model.options.chart.type !== 'datatable' && model.series.length === 0"
                    style="flex: 0"
                    class="ma-0 text-no-wrap mr-4"
                    dense
                    outlined
                    type="warning"
                  >No serie yet!</v-alert>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="model.options.chart.type !== 'datatable'">
                    <v-expand-transition>
                      <div v-if="model.options.plotOptions.series.stacking && ['bar', 'column', 'line'].includes(model.options.chart.type)">
                        <v-alert
                          class="mb-6 w-100"
                          border="left"
                          outlined
                        >
                          <h3 class="mb-3">Categories</h3>
                          <ListBuilder
                            v-model="model.options.xAxis.categories"
                            :default-item="null"
                            group="dashboard_widget_xAxis_category"
                            sortable
                          >
                            <template #item="category">
                              <v-text-field
                                v-model="model.options.xAxis.categories[category.index]"
                                :rules="[rules.required]"
                                label="Name"
                                hide-details="auto"
                                outlined
                                clearable
                                persistent-hint
                                hint="This name will appear next to the group value"
                              />
                            </template>
                          </ListBuilder>
                        </v-alert>
                      </div>
                    </v-expand-transition>

                    <v-checkbox
                      v-if="['scatter'].includes(model.options.chart.type)"
                      v-model="model.generateFromField"
                      class="mt-0 pt-0 mb-4"
                      label="Generate series from main field"
                      hint="This will generate a list of series based on all the distinct value of the main field."
                      persistent-hint
                    />

<!--                    <h3>Series <span v-if="!model.generateFromField">({{model.series.length}})</span></h3>-->
<!--                    <v-container class="background pa-0 mt-6 mb-3" fluid>-->
<!--                      <v-row class="px-3">-->
<!--                        <v-col cols="12">-->
<!--                          <v-card>-->
<!--                            <v-card-text>-->
                              <ListBuilder
                                v-model="model.series"
                                :default-item="defaultSerie"
                                :disabled="model.generateFromField"
                                :max="maxSeries"
                                :show-actions="false"
                                group="dashboard_widget_serie"
                                sortable
                              >
                                <template #item="serie">
                                  <div v-if="!['sankey'].includes(model.options.chart.type)">
                                    <v-autocomplete
                                      v-model="serie.item.field"
                                      :rules="[rules.required]"
                                      :items="computedFields"
                                      :hint="model.generateFromField ? 'The series will be generated from that field.' : 'Field to collect the data from.'"
                                      label="Field"
                                      outlined
                                      hide-details="auto"
                                      clearable
                                      persistent-hint
                                      @change="onSerieItemChange(serie)"
                                    />

<!--                                    <div class="d-flex" style="gap: 0.5rem">-->
<!--                                      <v-checkbox-->
<!--                                        v-model="serie.item.labelFieldActive"-->
<!--                                        class="ma-0 pa-0 mt-4 mt-3"-->
<!--                                        color="primary"-->
<!--                                        hide-details-->
<!--                                        @change="onSerieItemChange(serie)"-->
<!--                                      />-->
<!--                                      <v-autocomplete-->
<!--                                        v-model="serie.item.labelField"-->
<!--                                        :rules="serie.item.labelFieldActive ? [rules.required] : []"-->
<!--                                        :items="computedFields"-->
<!--                                        :disabled="!serie.item.labelFieldActive"-->
<!--                                        hint="Overrides the shown label of the field by the above selection"-->
<!--                                        label="Label field"-->
<!--                                        outlined-->
<!--                                        hide-details="auto"-->
<!--                                        clearable-->
<!--                                        persistent-hint-->
<!--                                        @change="onSerieItemChange(serie)"-->
<!--                                      />-->
<!--                                    </div>-->

                                    <template v-if="['scatter'].includes(model.options.chart.type)">
                                      <v-autocomplete
                                        v-model="serie.item.yAxisField"
                                        :rules="[rules.required]"
                                        :items="computedFields"
                                        label="Y Axis Field"
                                        class="mt-3"
                                        hint="The value that will determine where on the Y axis, the marker is going to be placed."
                                        outlined
                                        hide-details="auto"
                                        clearable
                                        persistent-hint
                                      />

                                      <v-autocomplete
                                        v-model="serie.item.xAxisField"
                                        :rules="[rules.required]"
                                        :items="computedFields"
                                        class="mt-3"
                                        hint="The value that will determine where on the X axis, the marker is going to be placed."
                                        label="X Axis Field"
                                        outlined
                                        hide-details="auto"
                                        clearable
                                        persistent-hint
                                      />

                                      <v-alert
                                        class="mb-0 mt-3 w-100"
                                        border="left"
                                        outlined
                                      >
                                        <h3 class="mb-3">Markers ({{serie.item.markers.length}})</h3>
                                        <ListBuilder
                                          v-model="serie.item.markers"
                                          :default-item="'circle'"
                                          group="dashboard_widget_serie_marker"
                                          sortable
                                        >
                                          <template #item="marker">
                                            <v-select
                                              v-model="serie.item.markers[marker.index]"
                                              :rules="[rules.required]"
                                              :items="markerList"
                                              class="mt-3"
                                              label="Marker"
                                              outlined
                                              hide-details="auto"
                                              clearable
                                            >
                                              <template #selection="{ item }">
                                                <v-icon v-text="item.icon" left></v-icon>
                                                <span>{{ item.text }}</span>
                                              </template>
                                              <template #item="{ item }">
                                                <v-icon v-text="item.icon" left></v-icon>
                                                <span>{{ item.text }}</span>
                                              </template>
                                            </v-select>
                                          </template>
                                        </ListBuilder>
                                      </v-alert>
                                    </template>
                                  </div>

                                  <v-alert
                                    v-else
                                    class="mb-0 mt-3 w-100"
                                    border="left"
                                    outlined
                                  >
                                    <h3 class="mb-3">Keys</h3>

                                    <v-autocomplete
                                      v-model="serie.item.keys[0]"
                                      :rules="[rules.required]"
                                      :items="computedFields"
                                      label="From"
                                      class="mt-3"
                                      hide-details="auto"
                                      outlined
                                      clearable
                                    />

                                    <v-autocomplete
                                      v-model="serie.item.keys[1]"
                                      :rules="[rules.required]"
                                      :items="computedFields"
                                      label="To"
                                      class="mt-3"
                                      hide-details="auto"
                                      outlined
                                      clearable
                                    />

                                    <v-autocomplete
                                      v-model="serie.item.keys[2]"
                                      :rules="[rules.required]"
                                      :items="computedFields"
                                      label="Value"
                                      class="mt-3"
                                      hide-details="auto"
                                      outlined
                                      clearable
                                    />
                                  </v-alert>

<!--                                  <v-alert-->
<!--                                    class="mb-0 mt-3 w-100"-->
<!--                                    border="left"-->
<!--                                    outlined-->
<!--                                  >-->
<!--                                    <h3 class="mb-3">Colors  ({{serie.item.colors.length}})</h3>-->
<!--                                    <ListBuilder-->
<!--                                      v-model="serie.item.colors"-->
<!--                                      :default-item="''"-->
<!--                                      group="dashboard_widget_serie_color"-->
<!--                                      sortable-->
<!--                                    >-->
<!--                                      <template #item="color">-->
<!--                                        <ColorInput-->
<!--                                          v-model="serie.item.colors[color.index]"-->
<!--                                          :rules="[rules.required]"-->
<!--                                          :suggestions="[-->
<!--                                            $vuetify.theme.themes.light.success,-->
<!--                                            $vuetify.theme.themes.light.warning,-->
<!--                                            $vuetify.theme.themes.light.error,-->
<!--                                            $vuetify.theme.themes.light.skip,-->
<!--                                          ]"-->
<!--                                          dark-->
<!--                                          block-->
<!--                                        >-->
<!--                                          <v-icon left>mdi-format-color-fill</v-icon>-->
<!--                                          <span>Color #{{color.index + 1}}</span>-->
<!--                                        </ColorInput>-->
<!--                                      </template>-->
<!--                                    </ListBuilder>-->
<!--                                  </v-alert>-->
                                </template>
                              </ListBuilder>
<!--                            </v-card-text>-->
<!--                          </v-card>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->

<!--                    <v-autocomplete-->
<!--                      v-if="['boxplot', 'bar', 'column', 'line'].includes(model.options.chart.type)"-->
<!--                      v-model="model.groupSerieBy"-->
<!--                      :rules="['boxplot'].includes(model.options.chart.type) ? [rules.required] : []"-->
<!--                      :items="computedFields"-->
<!--                      label="Group X Axis by"-->
<!--                      class="mt-6"-->
<!--                      hint="This will group the field by the value selected on the X axis."-->
<!--                      outlined-->
<!--                      hide-details="auto"-->
<!--                      clearable-->
<!--                      persistent-hint-->
<!--                    />-->

                    <v-text-field
                      v-if="['bar', 'column', 'line', 'scatter', 'boxplot', 'sankey'].includes(model.options.chart.type)"
                      v-model="model.options.yAxis.title.text"
                      label="Y Axis Title"
                      class="mt-3"
                      outlined
                      clearable
                      persistent-hint
                      hint="This title will appear on the side of the cart or leave empty to hide it."
                    />

                    <v-text-field
                      v-if="['bar', 'column', 'line', 'scatter', 'boxplot', 'sankey'].includes(model.options.chart.type)"
                      v-model="model.options.xAxis.title.text"
                      label="X Axis Title"
                      outlined
                      clearable
                      persistent-hint
                      hint="This title will appear under the cart or leave empty to hide it."
                    />
                  </template>
                  <template v-else>
                    <v-alert
                      class="w-100"
                      border="left"
                      outlined
                    >
                      <h3 class="mb-6">Headers ({{ model.table.headers.length }})</h3>
                      <ListBuilder
                        v-model="model.table.headers"
                        :default-item="{ text: '', value: '', sortable: true }"
                        group="dashboard_widget_table_header"
                        sortable
                      >
                        <template #item="header">
                          <v-autocomplete
                            v-model="header.item.value"
                            :rules="[rules.required]"
                            :items="headerFields"
                            label="Field"
                            hide-details="auto"
                            outlined
                            clearable
                            class="mb-3"
                            @input="val => header.item.text = val"
                          />

                          <v-checkbox
                            v-model="header.item.sortable"
                            label="Sortable"
                            class="ma-0 pa-0"
                            hide-details="auto"
                          />
                        </template>
                      </ListBuilder>
                    </v-alert>

                    <v-alert
                      class="w-100"
                      border="left"
                      outlined
                    >
                      <h3 class="mb-6">Sorting ({{ model.table.sortBy.length }})</h3>
                      <ListBuilder
                        v-model="model.table.sortBy"
                        :default-item="''"
                        group="dashboard_widget_table_sort"
                        sortable
                      >
                        <template #item="sort">
                          <div class="d-flex align-center" style="gap: 1rem">
                            <v-autocomplete
                              v-model="model.table.sortBy[sort.index]"
                              :rules="[rules.required]"
                              :items="computedFields"
                              label="Field"
                              hide-details="auto"
                              outlined
                              clearable
                            />

                            <v-btn-toggle v-model="model.table.sortDesc[sort.index]">
                              <v-btn>
                                <v-icon>mdi-sort-ascending</v-icon>
                              </v-btn>
                              <v-btn>
                                <v-icon>mdi-sort-descending</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </div>
                        </template>
                      </ListBuilder>
                    </v-alert>

<!--                    <v-autocomplete-->
<!--                      v-model="model.table.groupBy"-->
<!--                      :items="computedFields"-->
<!--                      label="Group by"-->
<!--                      hint="Will present data in collapsable areas in the data table based on your selection."-->
<!--                      hide-details="auto"-->
<!--                      outlined-->
<!--                      clearable-->
<!--                      persistent-hint-->
<!--                    />-->
                  </template>


                  <!--                  <v-checkbox-->
                  <!--                    v-if="['bar', 'column', 'line'].includes(model.options.chart.type)"-->
                  <!--                    v-model="model.options.plotOptions.series.stacking"-->
                  <!--                    class="mt-n3 pt-0 mb-6"-->
                  <!--                    label="Stacked"-->
                  <!--                    persistent-hint-->
                  <!--                    hint="Allows you to categorize your series and stacked your data."-->
                  <!--                  />-->

                  <template v-if="['pie'].includes(model.options.chart.type)">
                    <v-divider class="mt-3 mb-6"></v-divider>
                    <v-checkbox
                      v-model="model.options.plotOptions.pie.showInLegend"
                      label="Show legends"
                      persistent-hint
                      class="mt-0 pt-0 mb-4"
                      hint="Will show a legend under the pie chart"
                      @change="val => {
                        model.options.legend.enabled = val;
                        val ? model.options.plotOptions.pie.dataLabels.enabled = false : null;
                      }"
                    />
                    <v-checkbox
                      v-model="model.options.plotOptions.pie.dataLabels.enabled"
                      label="Show data labels"
                      persistent-hint
                      class="mt-0 pt-0 mb-4"
                      hint="Will show data labels next to each slice."
                      @change="val => {
                        model.options.plotOptions.pie.dataLabels.enabled = val;
                        val ? model.options.plotOptions.pie.showInLegend = false : null;
                      }"
                    />
                    <v-checkbox
                      v-model="model.showPercentageAndNumber"
                      :disabled="!model.options.plotOptions.pie.dataLabels.enabled"
                      label="Show percentage and number"
                      persistent-hint
                      class="mt-0 pt-0 mb-4"
                      hint="Will show the percentage and the total number of items next to data labels."
                    />
                    <v-checkbox
                      v-model="model.allowCombineMultipleValues"
                      label="Allow combining data"
                      persistent-hint
                      class="mt-0 pt-0 mb-4"
                      hint="Will allow the end-user to combine items with multiple values"
                    />
                    <v-text-field
                      v-if="model.allowCombineMultipleValues"
                      v-model="model.combineMultipleValuesLabel"
                      label="Combine multiple values label"
                      class="mt-3"
                      outlined
                      clearable
                      persistent-hint
                      hint="This is the label that will be shown next to the checkbox to the end-user"
                    />
                  </template>
                  <template v-else-if="['bar', 'column', 'line', 'pie', 'scatter', 'boxplot'].includes(model.options.chart.type)">
                    <v-divider class="mt-3 mb-6"></v-divider>
                    <v-checkbox
                      v-model="model.options.legend.enabled"
                      label="Show legends"
                      persistent-hint
                      class="mt-0 pt-0 mb-4"
                      hint="Will show a legend under the graph"
                    />
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="model.options.chart.type !== 'datatable'">
                <v-expansion-panel-header class="justify-space-between">
                  <span>
                    <v-icon left>mdi-sort</v-icon>
                    <span>Sorting</span>
                  </span>
                  <div class="mr-4 text-no-wrap" style="flex: 0">
                    <v-checkbox
                      v-model="model.manualSorting"
                      label="Manual sorting"
                      hide-details
                      class="pa-0 ma-0"
                      @click.stop.prevent
                    />
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <ListBuilder
                    v-model="model.indexes"
                    :can-add="false"
                    :show-actions="false"
                    group="dashboard_widget_indexes"
                    sortable
                  >
                    <template #item="{ item }">
                      <div class="mt-3">{{ item.key }}</div>
                    </template>
                  </ListBuilder>
                </v-expansion-panel-content>
              </v-expansion-panel>
<!--              <v-expansion-panel :disabled="!model.queryOverride">-->
<!--                <v-expansion-panel-header class="justify-space-between">-->
<!--                  <span>-->
<!--                    <v-icon left>mdi-filter-variant</v-icon>-->
<!--                    <span>Filters</span>-->
<!--                    <v-chip-->
<!--                      :color="validFilters.length > 0 ? 'warning' : null"-->
<!--                      class="ml-4"-->
<!--                      pill-->
<!--                      small-->
<!--                      @mousedown.stop-->
<!--                    >{{ validFilters.length }}</v-chip>-->
<!--                  </span>-->
<!--                  <span style="flex: 0; pointer-events: auto" class="text-no-wrap mr-4">-->
<!--                    <v-checkbox-->
<!--                      v-model="model.queryOverride"-->
<!--                      label="Override data"-->
<!--                      class="ma-0 pa-0"-->
<!--                      hide-details-->
<!--                    />-->
<!--                  </span>-->
<!--                </v-expansion-panel-header>-->
<!--                <v-expansion-panel-content eager>-->
<!--                  <component v-model="model.filters" v-bind="queryBuilder(model)" @change="$forceUpdate"></component>-->
<!--                </v-expansion-panel-content>-->
<!--              </v-expansion-panel>-->
            </v-expansion-panels>
          </v-form>
        </v-col>
        <v-col cols="12" :md="model?.options.chart.type !== 'datatable' ? 5 : 6">
          <div class="pt-4 h-100" style="max-height: calc(100vh - 16rem);">
            <DashboardChart
              v-model="model"
              :data="data"
              :fields="fields"
              :filters="previewFilters"
              :is-model="isModel"
              :definitions="definitions"
              :load-override="loadOverride"
              :query-builder="queryBuilder"
              :combine-multiple="model?.allowCombineMultipleValues"
              :combine-multiple-label="model?.combineMultipleValuesLabel"
              can-select-color
              title="Preview"
              style="min-height: 8rem"

              readonly
            />
          </div>
        </v-col>
      </v-row>
    </template>
    <template #buttons>
      <v-btn
        :disabled="!canGoPrevious"
        color="primary"
        outlined
        large
        @click="onPreviousClick"
      >
        <span v-text="$t('btn.previous')"></span>
      </v-btn>
      <v-btn
        v-if="!isLastPanel"
        :disabled="!canGoNext"
        color="primary"
        outlined
        large
        @click="onNextClick"
      >
        <span v-text="$t('btn.next')"></span>
      </v-btn>
      <v-btn
        v-else
        :loading="applying"
        :disabled="!canApplyChanges"
        color="primary"
        large
        @click="applyChanges"
      >
        <span v-text="$t('btn.apply')"></span>
      </v-btn>
      <v-btn
        outlined
        large
        @click="() => _visible = false"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, PropSync, Prop, Watch} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ColorInput from '@/modules/common/components/ColorInput.vue';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import DashboardChartModel, { IDashboardSerie, IDashboardChart } from '@/modules/sdk/models/dashboard-chart.model';
import Rules from '@/modules/sdk/core/rules';
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import Query, { IQueryItem } from '@/modules/sdk/core/query';
import { markerList } from '@/enums/global';
import Sticky from '@/modules/common/components/Sticky.vue';
import VisualizationFilterStateService from '@/modules/sdk/services/visualization-filter-state.service';
import Identity from '@/modules/sdk/core/identity';
import VisualizationFilterStateModel from '@/modules/sdk/models/visualization-filter-state.model';

@Component({
  components: {
    QueryBuilder,
    ModalDialog,
    ListBuilder,
    ColorInput,
    Sticky,
    PresetManager,
    'DashboardChart': () => import('@/modules/common/components/DashboardChart.vue'),
  }
})
export default class DashboardChartSettingsDialog extends Vue {

  @VModel() model!: IDashboardChart | null
  @PropSync('visible') _visible!: boolean
  @Prop({ default: () => ([]) }) fields!: Array<any> | null;
  @Prop({ default: () => null }) data!: Array<any>
  @Prop({ type: Boolean, default: true }) isModel!: boolean;
  @Prop({ type: Function, default: () => () => {} }) queryBuilder!: any;
  @Prop({ type: Array, default: null, }) definitions!: Array<any> | null;
  @Prop({ type: Function, default: () => new Promise(resolve => resolve) }) loadOverride!: (widget: IDashboardChart) => Promise<any>

  panel = 0
  ready = false
  hidden = true
  applying = false
  formIsValid = false
  markerList = markerList
  originalModel: IDashboardChart | null = null
  loadingPresets = false
  savingPreset = false
  defaultPresetItem: Array<any> = [{}];
  rules: any = {}
  defaultSerie: IDashboardSerie = {
    name: '',
    colors: [],
    keys: [],
    field: '',
    labelFieldActive: false,
    labelField: '',
    yAxisField: '',
    xAxisField: '',
    markers: [],
  }

  types: Array<{
    icon: string,
    name: string,
  }> = [
    { icon: 'mdi-chart-bar', name: 'column' },
    { icon: 'mdi-chart-bar mdi-rotate-90', name: 'bar' },
    { icon: 'mdi-chart-pie', name: 'pie' },
    { icon: 'mdi-table', name: 'datatable' },
    // { icon: 'mdi-chart-line', name: 'line' },
    // { icon: 'mdi-chart-scatter-plot', name: 'scatter' },
    // { icon: 'mdi-distribute-horizontal-center', name: 'boxplot' },
    // { icon: 'mdi-chart-sankey', name: 'sankey' },
  ]

  @Watch('model.generateFromField')
  onGenerateFromFieldChange() {
    if (this.model) {
      if (this.model.series.length === 0) {
        this.model.series.push(structuredClone(this.defaultSerie));
      } else if (this.model.series.length > 1) {
        this.model.series.splice(1, this.model.series.length);
      }
    }
  }

  /**
   * Used to prefill data in the advanced query builder (Filters)
   */
  @Watch('model.queryOverride')
  onQueryOverrideChange(checked: boolean) {
    if (!checked) {
      this.$forceUpdate();
    }
  }

  @Watch('model.series', { deep: true, immediate: true })
  onModelSeriesChanged() {
    if (this.model?.options.chart.type !== 'datatable') {
      this.updateSortableValues();
    }
  }

  @Watch('panel')
  onPanelChanged() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300)
  }

  @Watch('visible', {
    immediate: true,
  })
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.panel = 0;
      const form: any = this.$refs.form;
      form && form.resetValidation();
      this.originalModel = structuredClone(this.model);

      if (this.model?.series.length === 0) {
        this.injectDefaultSerie();
      }

      this.hidden = false;

      setTimeout(() => {
        this.ready = true;
      })
    } else {
      setTimeout(() => {
        this.hidden = true;
      }, 300)
      this.ready = false
    }
  }

  @Watch('model.options.chart.type')
  onModelChartTypeChanged() {
    if (this.ready) {
      this.resetModel(false);
    }
  }

  onApplyPresetFilters(preset: any) {
    this.model = preset;
  }

  get validFilters(): Array<IQueryItem> {
    return Query.getValidFilters(this.model?.filters || []);
  }

  get canConfigure(): boolean {
    return !!(this.model?.queryOverride);
  }

  get maxSeries(): number {
    return 1;
    // return this.model?.options.chart.type === 'pie' ? 1 : 999;
  }

  get previewFilters(): Array<IQueryItem> {
    return this.model?.queryOverride
      ? this.model?.filters
      : [];
  }

  get computedFields(): Array<any> {
    if (this.fields !== null) {
      return this.fields;
    }
    return [];
  }

  get headerFields(): Array<any> {
    if (this.fields !== null) {
      return this.fields;
    }
    return [];
  }

  get isDifferent(): boolean {
    return JSON.stringify(this.originalModel) !== JSON.stringify(this.model);
  }

  get canApplyChanges(): boolean {
    return !this.applying && this.formIsValid && this.isDifferent;
  }

  get canGoPrevious(): boolean {
    return this.panel > 0 && !isNaN(this.panel);
  }

  get canGoNext(): boolean {
    return !this.isLastPanel && !isNaN(this.panel);
  }

  get isLastPanel(): boolean {
    return this.panel >= 1;
  }

  onPreviousClick() {
    this.panel--;
    // if (!this.canConfigure && this.panel === 1) {
    //   this.panel--;
    // }
  }

  onNextClick() {
    this.panel++;
    // if (!this.canConfigure && this.panel === 1) {
    //   this.panel++;
    // }
  }

  onSerieItemChange(serie: any) {
    serie.item.name = serie.item.labelFieldActive
      ? serie.item.labelField
      : serie.item.field;
  }

  resetModel(complete = true): void {
    if (this.model) {

      const originalField = this.model.series.length > 0 ? this.model.series[0].field : null;

      if (complete) {
        this.model.projectId = null;
        this.model.trackerId = null;
        this.model.filters = [];
        this.model.generateFromField = false;
      }

      const newModel = new DashboardChartModel();
      this.model.series = newModel.data.series;
      this.model.table = newModel.data.table;
      this.model.options.yAxis.title.text = '';
      this.model.options.xAxis.title.text = '';
      this.model.options.plotOptions.series = {};
      this.model.options.xAxis.categories = [];

      this.injectDefaultSerie(originalField || '');
    }
  }

  updateSortableValues() {
    const values: any[] = [];
    this.data.forEach(item => {
      if (this.model && this.model.series.length > 0 && this.model.series[0].field) {
        const field = this.model.series[0].field;
        const _values = item[field].split(',').map((field: string) => field.trim());
        _values.forEach((value: string) => {
          if (!values.includes(value)) {
            values.push(value);
          }
        })
      }
    })
    if (this.model) {
      this.model.indexes = values.map((value, index) => {
        const position = (this.model?.indexes.find(item => item.key === value) || {}).position;
        return {
          key: value,
          position: position === undefined ? index : position,
        }
      });
    }
  }

  injectDefaultSerie(field = '') {
    if (this.model) {
      this.model.series.push(structuredClone(this.defaultSerie));
      this.model.series[0].field = field;
      // this.model.series[0].field = field || (this.fields && this.fields.length > 0 ? this.fields[0] : '');
    }
  }

  applyChanges(): void {
    const form: any = this.$refs.form;
    if (form && form.validate()) {
      this._visible = false;
      this.$emit('on-apply', this.model);
    }
  }

  loadPresets(): Promise<any> {
    return VisualizationFilterStateService.getInstance().getAll({
      filters: [{
        field: 'type',
        operator: 'equals',
        value: 'chart',
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: VisualizationFilterStateModel) {
    return VisualizationFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: VisualizationFilterStateModel) {
    return VisualizationFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  created(): void {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
  }
}
</script>

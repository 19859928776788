<template>
  <div>
    <v-autocomplete
      ref="autocomplete"
      v-bind="$attrs"
      v-model="model"
      :items="items"
      :search-input.sync="searchInput"
      :loading="loading"
      item-value="data.id"
      :item-text="(item)=> item.getLabel()"
      prepend-inner-icon="mdi-account"
      hide-details="auto"
      cache-items
      clearable
    >
      <template #selection="{ item }">
        <v-chip
          v-if="item.data.id === 'ai'"
          class="ma-1"
          color="info"
          small
          dark
          label
          close
          @click:close="removeItem(item)"
        >{{ item.getLabel() }}
        </v-chip>
        <v-chip
          v-else
          class="ma-1"
          small
          label
          close
          @click:close="removeItem(item)"
        >
          {{ item.getLabel() }}
        </v-chip>
      </template>
      <template #item="{ item }">
        <v-chip
          v-if="item.data.id === 'ai'"
          class="ma-1"
          color="info"
          small
          label
        >
          {{ item.getLabel() }}
        </v-chip>
        <span v-else>
          {{ item.getLabel() }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>
<script lang="ts">
import 'reflect-metadata'
import {Vue, Component, Prop, Watch, ModelSync, Ref} from 'vue-property-decorator'
import Logger from '@/modules/sdk/core/logger'
import UserModel from '@/modules/sdk/models/user.model'
import UserService from '@/modules/sdk/services/user.service'
import { debounce } from 'debounce'
// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete/VAutocomplete'

const d = new Logger('views/Admin/Component/UserAutocompleteComponent')

@Component
export default class ProgressComponent extends Vue {
  @Ref() readonly autocomplete!: VAutocomplete;

  @ModelSync('vModel', 'change', { default: () => [] }) model!: Array<UserModel>|UserModel|number|null
  @Prop({ default: 0 }) readonly projectId!: number
  @Prop({ type: Boolean, default: false }) readonly includeAiUser!: boolean
  @Prop({ type: Array, default: () => ([]) }) readonly appendItems!: Array<UserModel>
  @Prop({ type: Array, default: () => ([]) }) readonly prependItems!: Array<UserModel>

  loading = false
  searchInput = ''
  items: Array<UserModel> = []
  aiUser: UserModel = new UserModel({
    id: 'ai',
    firstName: 'AI',
    lastName: 'Service'
  })

  removeItem(item: UserModel) {
    if (Array.isArray(this.model)) {
      const index = this.model.findIndex((user: UserModel) => user.data.id === item.data.id)
      if (index >= 0) this.model.splice(index, 1)
    } else {
      this.model = null;
    }
  }

  loadUser(search: string = this.searchInput) {
    this.loadUserDebounce.clear()
    const order = 'firstName, lastName, email'
    const advanced = this.projectId ? { activeUserProjectId: this.projectId } : {}
    this.loading = true
    UserService.getInstance().getAll({ search, order, advanced })
    .then(response => {
      this.items = this.prependItems.concat(response.data.view.list).concat(this.appendItems)
      if (this.includeAiUser) {
        this.items.unshift(this.aiUser)
      }
    })
    .finally(() => this.loading = false)
  }

  loadUserDebounce = debounce(() => {
    this.loadUser()
  }, 333)

  @Watch('projectId')
  @Watch('searchInput')
  onUserSearch() {
    this.loadUserDebounce()
  }

  created() {
    this.loadUser()
  }
}
</script>
